import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import shortid from 'shortid';

import { Pen } from '@stratumn/icons';

import { Smartlabel } from '@stratumn/atomic';

import { formatNumber, roundNumber } from 'utils';
import { useFocus } from 'utils/hooks';

import styles from './number.style';

const applyFormat = (nb, format) => (nb === '' ? '' : formatNumber(nb, format));

// simple debounced number input
// if a header is provided we preserve the border and handle header placement
// when data is not empty, as well as border color
const NumberInput = ({
  classes,
  dataStr,
  onChange,
  placeholder,
  format,
  saveFormatted,
  delay,
  className,
  header,
  disabled
}) => {
  // focus state
  const [isFocused, focus, blur, inputRef] = useFocus(false);

  const data = dataStr === null || dataStr === '' ? '' : Number(dataStr);

  // input state
  const [inputNb, setInputNb] = useState(data);
  const updateInputNb = useCallback(e => {
    setInputNb(e.target.value);
  }, []);

  // on blur handler
  const handleBlur = useCallback(() => {
    if (saveFormatted && format.options?.maximumFractionDigits) {
      setInputNb(val =>
        roundNumber(val, format.options.maximumFractionDigits).toString()
      );
    }
    blur();
  }, []);

  // change the number displayed if the data provided changes
  useEffect(
    () => {
      setInputNb(data);
    },
    [data] // Only re-call effect if data changes
  );

  // debounce update of the number input
  useEffect(
    () => {
      if (inputNb === data) return undefined;

      // call onChange after delay
      const handler = setTimeout(() => {
        const newValue = inputNb === '' ? null : Number(inputNb);
        onChange(newValue);
      }, delay);

      // Cancel the timeout if value changes (also on unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [inputNb] // Only re-call effect if inputStr changes
  );

  const id = useMemo(() => shortid.generate(), []);

  const numberStr = applyFormat(data, format);

  // just display the formatted number string if the field is disabled
  if (disabled)
    return (
      <div className={classnames(className, classes.disabledInput)}>
        {numberStr}
      </div>
    );

  // compact field is when the field is in a batch edition form, with the Smart Label
  const renderCompactField = () => (
    <div className={classnames(className, classes.numberCompactInputWrapper)}>
      <Smartlabel
        text={header}
        forId={id}
        inputHasFocus={isFocused}
        inputHasValue={inputNb !== ''}
      >
        <div
          className={classes.numberCompactInput}
          data-has-focus={isFocused}
          data-has-value={inputNb !== ''}
        >
          <input
            ref={inputRef}
            id={id}
            placeholder={header}
            className={classes.numberCompactInputField}
            type={isFocused ? 'number' : 'text'}
            onChange={updateInputNb}
            onFocus={focus}
            onBlur={handleBlur}
            value={!isFocused ? numberStr : inputNb}
            step="any"
          />
        </div>
      </Smartlabel>
    </div>
  );

  // inline field is when the field is included in the parent view directly (like a table)
  const renderInlineField = () => (
    <div
      className={classnames(className, classes.numberInput)}
      onClick={focus}
      data-input-focused={isFocused}
      data-cy="number-input"
    >
      <input
        ref={inputRef}
        className={classes.numberInputField}
        type={isFocused ? 'number' : 'text'}
        placeholder={placeholder}
        onChange={updateInputNb}
        onFocus={focus}
        onBlur={handleBlur}
        value={!isFocused ? numberStr : inputNb}
        step="any"
      />
      {!isFocused && (
        <Pen
          className={classes.numberInputIcon}
          data-is-visible={inputNb !== ''}
        />
      )}
    </div>
  );

  return header ? renderCompactField() : renderInlineField();
};
NumberInput.propTypes = {
  classes: PropTypes.object.isRequired,
  dataStr: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  format: PropTypes.object,
  saveFormatted: PropTypes.bool,
  delay: PropTypes.number,
  className: PropTypes.string,
  header: PropTypes.string,
  disabled: PropTypes.bool
};
NumberInput.defaultProps = {
  placeholder: '',
  format: {},
  saveFormatted: false,
  delay: 250,
  className: '',
  header: '',
  disabled: false
};

export default injectSheet(styles)(React.memo(NumberInput));
