import React from 'react';
import PropTypes from 'prop-types';

import GroupsTaskListItem from './groupsTaskListItem';

// groups task list row, simple wrapper for react-window client
const GroupsTaskListRow = React.memo(({ index, style, data }) => {
  const {
    priorities,
    pagination: { buffer, hasNextPage, onLoadMore },
    displayGroupInfo
  } = data;

  // first trigger onLoadMore if too close to last loaded row
  if (hasNextPage && index >= priorities.length - buffer) {
    onLoadMore();
  }

  // then read row data and render
  const priority = priorities[index];
  return priority ? (
    <div style={style}>
      <GroupsTaskListItem
        priority={priority}
        displayGroupInfo={displayGroupInfo}
      />
    </div>
  ) : null;
});

GroupsTaskListRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default GroupsTaskListRow;
