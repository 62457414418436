export default theme => ({
  root: {
    fontSize: 14,
    border: `1px solid ${theme.grey5}`,
    borderRadius: 2,
    backgroundColor: theme.grey9,
    color: theme.grey2,
    padding: '5px 10px 5px 10px',
    width: '100%',
    outline: 'none',
    '&:focus': {
      borderColor: theme.indigo3
    },
    '&::placeholder': {
      color: theme.grey4
    }
  },
  rootDynamic: {
    marginTop: props => !props.top && 15,
    marginRight: props => !props.right && 15
  }
});
