import PropType from 'prop-types';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';
import classNames from 'classnames';
import { DocumentNode } from 'graphql';

import { REFERENCES, REFERENCED_BY } from 'constant/chainscript';
import Reference, { fragment as referenceFragment } from './reference';

import styles from './references.style';
import { LinkQueryResult } from '../traceInspector.types';

export const fragment: DocumentNode = gql`
  fragment ReferencesFragment on Link {
    references {
      totalCount
      nodes {
        ...ReferenceFragment
      }
    }
    referencedBy {
      totalCount
      nodes {
        ...ReferenceFragment
      }
    }
  }
  ${referenceFragment}
`;

type ReferencesProps = {
  classes: any;
  link: LinkQueryResult;
  traceId: string;
  handleClick: () => void;
  collapsed: boolean;
};

export const References = ({
  classes,
  link,
  traceId,
  handleClick,
  collapsed
}: ReferencesProps) => {
  const referenceNodes = link.references.nodes.filter(node => node.workflow);
  const referencedByNodes = link.referencedBy.nodes.filter(
    node => node.workflow
  );

  const referencesCount: number =
    referenceNodes.length + referencedByNodes.length;

  if (!referencesCount) return null;

  if (referencesCount === 1) {
    return (
      <div className={classes.singleReference}>
        <div
          className={classNames(classes.references, classes.referencesTitle)}
          data-prefix="*Reference:"
        >
          <Reference
            link={
              referencedByNodes.length
                ? referencedByNodes[0]
                : referenceNodes[0]
            }
            traceId={traceId}
            workflowId={link.workflowId}
            referenceType={
              referencedByNodes.length ? REFERENCED_BY : REFERENCES
            }
            refCount={referencesCount}
          />
        </div>
      </div>
    );
  }

  const RefList = () => (
    <ul>
      {referenceNodes.map((referenceLink, index) => (
        <li key={`references_${index}`} className={classes.referenceItem}>
          <Reference
            link={referenceLink}
            traceId={traceId}
            workflowId={link.workflowId}
            referenceType={REFERENCES}
            refCount={referencesCount}
          />
        </li>
      ))}
      {referencedByNodes.map((referenceLink, index) => (
        <li key={`referenced_by_${index}`} className={classes.referenceItem}>
          <Reference
            link={referenceLink}
            traceId={traceId}
            workflowId={link.workflowId}
            referenceType={REFERENCED_BY}
            refCount={referencesCount}
          />
        </li>
      ))}
    </ul>
  );

  const ToggleLabel = () => (
    <span className={classes.toggleLabel}>
      {collapsed ? 'View details' : 'close'}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        viewBox="0 0 32 32"
        aria-hidden="true"
        className={classes.toggleLabelIcon}
      >
        {collapsed ? (
          <path
            fillRule="nonzero"
            d="M15.99 23.59c-.501.005-.965-.263-1.21-.7L7.64 10.52c-.239-.429-.239-.951 0-1.38.251-.436.717-.703 1.22-.7h14.29c.5-.003.963.265 1.21.7.25.437.25.973 0 1.41l-7.14 12.34c-.253.439-.724.706-1.23.7zM8.86 9.71c-.028-.016-.062-.016-.09 0-.023.029-.023.071 0 .1l7.14 12.43c.058.02.122.02.18 0l7.15-12.38c.011-.032.011-.068 0-.1-.029-.013-.061-.013-.09 0L8.86 9.71z"
          />
        ) : (
          <path
            fillRule="nonzero"
            d="M15.99 8.44c-.501-.005-.965.263-1.21.7L7.64 21.51c-.239.429-.239.951 0 1.38.251.436.717.703 1.22.7h14.29c.5.003.963-.264 1.21-.7.25-.437.25-.973 0-1.41L17.22 9.14c-.253-.439-.724-.706-1.23-.7zM8.86 22.32c-.028.016-.062.016-.09 0-.023-.029-.023-.071 0-.1l7.14-12.43c.058-.02.122-.02.18 0l7.15 12.38c.011.033.011.068 0 .1-.029.013-.061.013-.09 0l-14.29.05z"
          />
        )}
      </svg>
    </span>
  );

  return (
    <dl className={classes.references}>
      <dt
        className={classes.referencesTitle}
        data-prefix="*References:"
        onClick={handleClick}
        aria-expanded={!collapsed}
      >
        This action has {referencesCount} references.
        <ToggleLabel />
      </dt>
      <dd className={classes.referenceList} aria-hidden={collapsed}>
        <RefList />
      </dd>
    </dl>
  );
};

References.propTypes = {
  classes: PropType.object.isRequired,
  link: PropType.object.isRequired,
  collapsed: PropType.bool.isRequired,
  handleClick: PropType.func.isRequired,
  traceId: PropType.string.isRequired
};

export default injectSheet(styles)(References);
