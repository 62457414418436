export default {
  booleanView: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  booleanViewIcon: {
    width: 24,
    height: 24
  }
};
