export default theme => ({
  labelsView: {
    width: '100%',
    overflow: 'auto hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '1px',
    padding: '2px 0',
    ...theme.scrollbar,
    '&::-webkit-scrollbar': {
      cursor: 'pointer',
      height: 5
    }
  },
  labelsViewElement: {
    fontSize: 11, // 85%
    letterSpacing: 0.2,
    margin: 1,
    background: 'rgba(8, 43, 120, 0.047)',
    border: '0.5px solid rgb(223, 225, 228)',
    borderRadius: '0.2em',
    padding: '2px 5px',
    height: 18,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgb(60, 65, 73)',
    flexShrink: 0
  }
});
