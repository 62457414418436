export default theme => ({
  errorsSnackbarContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center'
  },
  errorsSnackbar: {
    zIndex: 5,
    position: 'absolute',
    top: 30,
    height: 60,
    width: '100%',
    maxWidth: 750,
    backgroundColor: theme.warningRed,
    color: theme.white1,
    borderRadius: 5,
    padding: '10px 20px',
    fontSize: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'pre',
    boxShadow: '0px 3px 20px rgba(232, 84, 77, 0.2)'
  },
  errorsNavigation: {
    color: theme.white1,
    padding: '9px 14px',
    position: 'absolute',
    top: 14, // required for safari, as it does not handle well position absolute mixed with flex items
    right: 20,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    letterSpacing: '0.03em',
    userSelect: 'none'
  },
  numbers: {
    width: 48,
    textAlign: 'center'
  },
  arrow: {
    cursor: 'pointer',
    display: 'block',
    flexShrink: 0,
    width: 15,
    height: 15,
    '&[data-is-disabled="true"]': {
      color: theme.warningRed,
      cursor: 'unset'
    }
  }
});
