export default theme => ({
  dropZone: {
    backgroundColor: theme.white1,
    borderRadius: 2,
    padding: 20,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    '&[data-drag-enter=true]': {
      border: `solid 1px ${theme.indigo3}`
    }
  },
  dropZoneTitle: {
    fontSize: 13
  },
  dataImporterButton: {
    color: theme.indigo3,
    backgroundColor: 'transparent',
    border: `1px solid currentColor`,
    borderRadius: 4,
    padding: '7px 15px',
    fontSize: 9,
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 1,
    cursor: 'pointer',
    transition: 'color 100ms linear',
    '&:focus': {
      outline: 'none'
    }
  },
  dropZoneIcons: {
    margin: 25,
    position: 'relative',
    width: 48,
    height: 45
  },
  dropZoneFileIcon1: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.grey4
  },
  dropZoneFileIcon2: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundColor: theme.white1
  },
  dropZoneError: {
    marginTop: 15,
    color: theme.warningRed,
    fontSize: 11,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  dropZoneErrorFileName: {
    fontWeight: 'bold',
    marginRight: 4
  },
  dropZoneErrorInfoWrapper: {
    marginLeft: 4
  },
  dropZoneErrorInfo: {
    fontSize: 9,
    borderRadius: '50%',
    color: theme.white1,
    backgroundColor: theme.warningRed,
    padding: '0px 4px',
    fontWeight: 'bolder',
    cursor: 'default'
  }
});
