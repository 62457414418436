import gql from 'graphql-tag';

export default {
  // get all participants of a group
  groupSettings: gql`
    fragment GroupSettingsFragment on Group {
      rowId
      id
      createdAt
      updatedAt
      label
      name
      avatar
      workflow {
        rowId
        name
      }
      members {
        totalCount
        nodes {
          ...GroupMemberFragment
        }
      }
    }

    fragment GroupMemberFragment on GroupMember {
      accountId
      reader
      admin
      account {
        rowId
        id
        entity {
          ... on Account_User {
            ...UserFragment
          }
          ... on Account_Bot {
            rowId
            name
            avatar
          }
          ... on Account_Team {
            rowId
            name
            avatar
            accountId
            members {
              nodes {
                userId
                user {
                  ...UserFragment
                }
              }
            }
            bots {
              nodes {
                rowId
                name
                avatar
              }
            }
          }
          ... on Account_Organization {
            rowId
            name
            avatar
            accountId
            members {
              nodes {
                userId
                user {
                  ...UserFragment
                }
              }
            }
            bots {
              nodes {
                rowId
                name
                avatar
              }
            }
          }
        }
      }
    }

    fragment UserFragment on Account_User {
      rowId
      name
      avatar
      email
      pending
      inviteExpired
    }
  `
};
