export default theme => ({
  wrapper: {
    backgroundColor: theme.white1,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start'
  },
  picker: {
    position: 'relative',
    // Calendar single days
    '& .CalendarDay': {
      outline: 'none',
      color: theme.grey1
    },
    '& .CalendarDay:hover': {
      background: theme.indigoPastel2
    },
    '& .CalendarDay__selected': {
      background: theme.indigo2,
      color: theme.white1,
      border: `1px solid ${theme.white1}`,
      '&:hover': {
        background: theme.indigo2
      }
    },
    // Calendar left and right arrows buttons
    '& .DayPickerNavigation_button': {
      border: 'none',
      '&:focus': {
        outline: 'none'
      }
    },
    '& .CalendarMonth_caption': {
      paddingTop: 15
    },
    '& .DayPicker_weekHeader': {
      top: 53
    }
  },
  monthNavigationIcon: {
    color: theme.grey1,
    display: 'block',
    width: 30,
    height: 30,
    marginTop: 14,
    marginLeft: 20,
    '&[data-is-right=true]': {
      marginLeft: 0,
      marginRight: 20
    }
  },
  resetBtnWrapper: {
    marginTop: -10,
    marginBottom: 10,
    zIndex: 2,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    paddingLeft: 22,
    transition: '250ms',
    cursor: 'pointer',
    color: theme.grey3,
    fontSize: 11,
    '& svg': {
      fill: 'currentColor',
      height: 14,
      width: 'auto',
      marginRight: 10,
      transition: '250ms'
    },
    '&:hover': {
      opacity: 0.7
    }
  }
});

export const dayPickerNavigationInlineStyles = {
  display: 'flex',
  justifyContent: 'space-between'
};
