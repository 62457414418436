export default theme => ({
  root: {
    '& > div:first-of-type': {
      lineHeight: 'inherit',
      borderRadius: '3px',
      '& ul': {
        ...theme.scrollbar,
        scrollbarGutter: 'stable',
        maxHeight: 31,
        '&::-webkit-scrollbar': {
          height: 2
        }
      }
    },
    '& input': {
      padding: '5px 10px 5px 10px',
      fontSize: 13
    }
  }
});
