import * as Sentry from '@sentry/react';
import { ErrorResponse, onError } from 'apollo-link-error';
import { history } from 'components/root';
import { ROUTE_LOGIN, ROUTE_UNAUTHORIZED } from 'constant/routes';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ServerError, ServerParseError } from 'apollo-link-http-common';

export default onError((props: ErrorResponse) => {
  const { graphQLErrors, networkError, operation } = props;

  if (networkError) {
    let finalErrorMessage = networkError.message;
    const { operationName } = operation;
    const queryVariables = operation.variables.input;
    console.error('networkError: ', networkError);

    if (isServerError(networkError)) {
      try {
        const errorsMessage = networkError.result.filter(
          result => result.errors.length > 0
        );
        const allErrors = errorsMessage?.map(result =>
          result.errors.reduce((acc, err) => acc + err.message, '')
        );

        finalErrorMessage = allErrors?.join(', ');
      } catch (e) {
        console.warn('Error parsing network error', e, networkError);
      }
    }

    Sentry.setContext('Stratumn GraphQL', {
      variables: JSON.stringify(queryVariables, null, 2),
      operationName: operationName
    });

    Sentry.captureMessage(finalErrorMessage);

    if (hasStatusCode(networkError)) {
      if (
        networkError.statusCode === 401 &&
        window.location.pathname !== ROUTE_LOGIN
      ) {
        // use second argument (state) to pass redirect variable
        history.push(ROUTE_LOGIN, {
          redirect: `${window.location.pathname}${window.location.search}`
        });
        return;
      }

      if (networkError.statusCode === 403) {
        // redirect to the unauthorized page
        history.push(ROUTE_UNAUTHORIZED);
        return;
      }

      if (networkError.statusCode >= 500) {
        Sentry.captureMessage(finalErrorMessage, 'error');
        return;
      }
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      const { message, locations, path } = err;
      console.error('GraphQL error', message, locations, path);

      // When receiving a 502 we refresh the current page.
      if (message === 'Response not successful: Received status code 502') {
        history.go(0);
        return;
      }

      if (
        'status' in err &&
        err.status === 401 &&
        window.location.pathname !== ROUTE_LOGIN
      ) {
        // use second argument (state) to pass redirect variable
        history.push(ROUTE_LOGIN, {
          redirect: `${window.location.pathname}${window.location.search}`
        });
        return;
      }

      if ('status' in err && err.status === 403) {
        // redirect to the unauthorized page
        history.push(ROUTE_UNAUTHORIZED);
        return;
      }

      if (
        'status' in err &&
        typeof err.status === 'number' &&
        err.status >= 500
      ) {
        Sentry.captureMessage(message, 'error');
      }
    });
  }
});

const isServerError = (
  error: ErrorResponse['networkError']
): error is ServerError => {
  return !!error && 'result' in error;
};

const hasStatusCode = (
  error: ErrorResponse['networkError']
): error is ServerError | ServerParseError => {
  return !!error && 'statusCode' in error;
};
