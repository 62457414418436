export default theme => ({
  progressContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  progressView: {
    overflow: 'hidden',
    height: 10,
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    borderRadius: 5,
    backgroundColor: theme.grey9
  },
  progressViewFilled: {
    height: '100%'
  },
  progressViewTooltipAnchor: {
    height: '100%',
    width: 0
  },
  progressViewVoid: {
    height: '100%',
    backgroundColor: theme.grey7
  }
});
