export default theme => ({
  link: {
    color: 'unset',
    textDecoration: 'none',
    cursor: 'pointer'
  },
  workflowName: {
    fontSize: 14,
    lineHeight: '18px',
    textTransform: 'capitalize',
    padding: '15px 0px'
  },
  numberCell: {
    textAlign: 'left',
    fontSize: 14,
    lineHeight: '18px'
  },
  documentationWrapper: {
    color: theme.grey1,
    fontSize: 12
  },
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '20px'
  },
  linkIcon: {
    paddingLeft: 4,
    paddingRight: 10
  },
  downloadFileWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  uploadDocumentation: {
    display: 'flex',
    color: theme.grey3
  },
  iconBtnAdd: {
    paddingLeft: 4,
    paddingRight: 10
  },
  deleteBtnWrapper: {
    flexShrink: 0,
    marginLeft: 5,
    cursor: 'pointer',
    outline: 'none',
    transition: 'color 100ms linear',
    '&:hover': {
      color: theme.indigo3
    }
  },
  deleteBtn: {
    display: 'block',
    width: 30,
    height: 'auto'
  },
  actionsWrapper: {
    display: 'flex',
    gap: '15px'
  },
  actionIconBtn: {
    display: 'block',
    color: theme.grey2,
    '&:hover:not(:disabled), &:focus-visible:not(:disabled)': {
      color: theme.indigo3
    },
    '&:disabled': {
      color: theme.grey5,
      cursor: 'not-allowed'
    }
  },
  actionIcon: {
    boxSizing: 'content-box',
    display: 'block',
    flexShrink: 0,
    width: 18,
    height: 18
  },
  actionIconActive: {
    color: theme.indigo3
  },
  subscriptionTooltipArrowUp: {
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: `6px solid ${theme.white1}`,
    boxShadow: theme.indigoShadow2
  },
  subscriptionTooltipWrapper: {
    background: theme.white1,
    boxShadow: theme.indigoShadow2,
    borderRadius: '10px',
    overflow: 'hidden'
  }
});
