export default theme => ({
  tabs: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    backgroundColor: theme.grey8,
    padding: '0px 14px',
    position: 'relative',
    overflowX: 'auto'
  },
  tab: {
    flexShrink: 0,
    cursor: 'pointer',
    marginRight: 22,
    paddingTop: 8,
    paddingBottom: 15
  },
  activeTabIndicator: {
    position: 'absolute',
    bottom: 0,
    height: 4,
    backgroundColor: theme.grey1,
    borderRadius: 1,
    transition:
      'left 0.3s cubic-bezier(0.2, 0.86, 0.35, 1), width 0.3s cubic-bezier(0.3, 0.94, 0.21, 1.04);'
  }
});
