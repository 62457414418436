export const getNextActionsArray = ({ nextActions, groups, actions }) => {
  const groupMap = groups.reduce((obj, group) => {
    // Only include groups on which the user can act
    if (!group.canAct) return obj;
    return Object.assign(obj, { [group.label]: group });
  }, {});
  const actionMap = actions.reduce(
    (obj, action) => Object.assign(obj, { [action.key]: action }),
    {}
  );

  // Use the filtered groups map for the filtered next actions object
  const result = [];
  Object.keys(groupMap).forEach(groupLabel => {
    if (!nextActions[groupLabel] && !nextActions['*']) return;

    let nextActionsArray;
    // Check if nextActions[groupLabel] is iterable (e.g. Array or Set)
    if (
      Array.isArray(nextActions[groupLabel]) ||
      nextActions[groupLabel] instanceof Set
    ) {
      // v1 next actions: { [group]: [action1, action2, ...] }
      // Clean up duplicates
      const nextActionsSet = new Set(nextActions[groupLabel]);
      // Clean up non-existing actions
      nextActionsArray = Array.from(nextActionsSet).reduce((arr, actionKey) => {
        if (actionMap[actionKey]) arr.push(actionMap[actionKey]);
        return arr;
      }, []);
    } else {
      // v2 next actions: { [group]: { action1: bool, action2: bool, ... } }
      // Clean up non-existing actions and falsey values
      nextActionsArray = actions.reduce((arr, action) => {
        const group = nextActions[groupLabel] || {};
        const wildcard = nextActions['*'] || {};
        if (
          // If wildcard, check if there's not an override taking priority
          (group['*'] && group[action.key] !== false) ||
          (wildcard['*'] && wildcard[action.key] !== false) ||
          // Otherwise, check if the action value is truthy
          group[action.key] ||
          wildcard[action.key]
        ) {
          arr.push(actionMap[action.key]);
        }
        return arr;
      }, []);
    }

    if (!nextActionsArray.length) return;

    result.push({
      group: groupMap[groupLabel],
      actions: nextActionsArray
    });
  });

  return result;
};

export const toTransitionsToNextActions = async (to, nextActions = {}) => {
  if (!to) return nextActions;
  const toKeys = Object.keys(to);
  toKeys.forEach(toKey => {
    to[toKey].forEach(({ groups, condition }) => {
      if (groups === '*') {
        if (!nextActions['*']) nextActions['*'] = {};
        nextActions['*'][toKey] = condition === undefined || condition;
      } else {
        groups.forEach(groupLabel => {
          if (!nextActions[groupLabel]) nextActions[groupLabel] = {};
          nextActions[groupLabel][toKey] = condition === undefined || condition;
        });
      }
    });
  });
  return nextActions;
};
