export default theme => ({
  modalWrapper: {
    width: '100%',
    height: '100%'
  },
  modalBodyContainer: {
    fontSize: 13,
    lineHeight: 1.25,
    color: theme.grey1
  }
});
