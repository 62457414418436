import React, { useRef, useState } from 'react';
import injectSheet from 'react-jss';
import InfoTooltip from 'components/ui/utils/infoTooltip';
import { getByPath } from 'utils/widgets';

import { formatNumber } from 'utils';
import styles from './status.style';
import { Progress } from './progress/progress';

type StatusView = {
  type: 'status';
  /** The path to the value of the status (text) */
  path: string;
  /** The path to the progress of the status */
  progressPath: string;
  /** The size of the progress. @default 16px */
  progressSize?: number;
  /**
   * In some workflows (ex TA) 0% is not cancel, so we neeed to specify a cancel value to prevent 0% overide
   * @default 0
   * */
  cancelValue?: number;
};

type Props = {
  classes: any;
  view: StatusView;
  data: object;
};

type Status = {
  value: string;
  progress: number;
};
// simple text to display
const StatusViewImpl = React.memo(({ classes, view, data }: Props) => {
  const { path, progressPath, progressSize, cancelValue = 0 } = view;
  const tooltipAnchorRef = useRef(null);
  const [showToolip, setShowTooltip] = useState(false);

  const onMouseEnter = () => {
    setShowTooltip(true);
  };
  const onMouseLeave = () => {
    setShowTooltip(false);
  };

  const status: Status = {
    value: getByPath(data, path) ?? 'Status not found',
    progress: getByPath(data, progressPath) ?? -1
  };

  return (
    <div
      className={classes.statusView}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={classes.status}>
        <div className={classes.progress} ref={tooltipAnchorRef}>
          <Progress
            progress={status.progress}
            size={progressSize || 16}
            cancelValue={cancelValue}
          />
          {showToolip && (
            <InfoTooltip
              clientRef={tooltipAnchorRef}
              text={`Progress: ${formatNumber(status.progress, {
                options: { style: 'percent', minimumFractionDigits: 0 }
              })}`}
              textColor="white"
              backgroundColor="black"
              delay={400}
              minWidth={0}
              position={{
                place: 'above',
                adjustPlace: true
              }}
            />
          )}
        </div>
        <div className={classes.statusValue}>{status.value}</div>
      </div>
    </div>
  );
});

// sort defaults to text on path
const getSortConfig = (view: StatusView) => ({
  type: 'number',
  path: view.progressPath
});

// filtering defaults to text search on path
const getFilterConfig = (view: StatusView) => ({
  type: 'text',
  path: view.progressPath,
  placeholder: 'Eg >0.5, 0.25<<0.75',
  interpreter: {
    type: 'number'
  }
});

// width defaults to 'medium'
const getDefaultWidth = () => 'small';

// stringifies data at path
const getStringifyFunction = (view: StatusView) => {
  debugger;
  const { path } = view;
  return data => getByPath(data, path);
};

// get edited path
// returns path if editor is set, null otherwise
const getEditedPath = view => {
  const { path, editor } = view;
  return editor ? path : null;
};

const getAggregationConfig = view => {
  const { path, aggregationValue } = view;
  let aggregatedView;
  if (aggregationValue) {
    aggregatedView = {
      view: {
        path: path,
        aggregationValue: aggregationValue
      }
    };
    return aggregatedView;
  }
  return null;
};

export default {
  component: injectSheet(styles)(StatusViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction,
  getEditedPath,
  getAggregationConfig
};
