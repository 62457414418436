export default theme => ({
  dateView: {
    overflow: 'hidden',
    textAlign: 'left',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  dateViewValue: {
    textOverflow: 'ellipsis',
    paddingTop: 1,
    '&[data-warning=true]': {
      minWidth: 77,
      fontWeight: 'bold',
      color: theme.lightWarningColor
    }
  },
  dateViewDeadlineIcon: {
    flexShrink: 1,
    width: 24,
    height: 24,
    marginLeft: 10,
    color: theme.lightWarningColor
  },
  defaultDeadlineIcon: {
    flexShrink: 1,
    width: 16,
    height: 16,
    marginLeft: 10,
    color: theme.lightWarningColor
  }
});
