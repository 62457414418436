import React, { useCallback, useEffect, useMemo } from 'react';
import { RichEditor } from '@stratumn/atomic';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import merge from 'lodash.merge';

import { getByPath, stringifyData } from 'utils/widgets';

import {
  TextInput,
  NumberInput,
  SelectDropdown,
  CommentInput,
  MultiselectInput,
  DateInput
} from './editors';

import styles from './text.style';

// text editor types
export const TEXT_EDITOR_INPUT = 'input';
export const TEXT_EDITOR_NUMBER = 'number';
export const TEXT_EDITOR_SELECT = 'select';
export const TEXT_EDITOR_COMMENT = 'comment';
export const TEXT_EDITOR_MULTISELECT = 'multiselect';
export const TEXT_EDITOR_DATE = 'date';

const getTextData = (data, path, defaultValue) => {
  const textData = getByPath(data, path);
  return stringifyData(textData, defaultValue);
};

// simple text to display
const TextViewImpl = React.memo(({ classes, view, data, update }) => {
  const { path, default: defaultValue, editor, isRichText } = view;
  const { patch, onChange, setIsPatched, editorOverrides = {} } = update || {};
  let textData;

  const disabledPath = editor?.disabledPath;
  const disabledFromData = !!getByPath(data, disabledPath);

  if (view?.aggregationValue) {
    textData = view.aggregationValue;
  } else {
    textData = getTextData(data, path, defaultValue);
  }
  // if no editor and no patch, directly return a simple text view
  if (!editor && !patch)
    if (!isRichText) {
      return <div className={classes.textView}>{textData}</div>;
    } else {
      return <RichEditor initialValue={textData} readOnly />;
    }

  // if the view is editable display a specific view

  // check if the data to display has been patched
  const dataWithPatchApplied = useMemo(() => merge({}, data, patch), [
    data,
    patch
  ]);

  const displayData = getTextData(dataWithPatchApplied, path);

  const handleChange = useCallback(
    value => {
      const isSameAsData =
        value === textData || (textData === undefined && value === ''); // handle the case where initial data is unset and the text input was cleared
      onChange({ path, value: isSameAsData ? undefined : value });
    },
    [path, onChange]
  );

  useEffect(() => {
    if (setIsPatched) setIsPatched(displayData !== textData);
  }, [textData, displayData]);
  // if no editor but patch, just output the potential patch value
  if (!editor) {
    return <div className={classes.textView}>{displayData}</div>;
  }

  const { type: editorType = TEXT_EDITOR_INPUT, placeholder } = editor;
  const { header } = editorOverrides;

  const disabled =
    editorOverrides.disabled ||
    editor.disabled ||
    !onChange ||
    disabledFromData;

  switch (editorType.toLowerCase()) {
    case TEXT_EDITOR_NUMBER:
      return (
        <NumberInput
          dataStr={displayData || null}
          onChange={handleChange}
          format={editor.format}
          saveFormatted={editor.saveFormatted}
          placeholder={placeholder}
          delay={editor.delay}
          header={header}
          disabled={disabled}
        />
      );
    case TEXT_EDITOR_SELECT: {
      let { options } = editor;
      if (editor.optionsPath) {
        options = getByPath(dataWithPatchApplied, editor.optionsPath);
      }

      return (
        <SelectDropdown
          dataStr={displayData || ''}
          options={options}
          onChange={handleChange}
          placeholder={placeholder}
          header={header}
          disabled={disabled}
        />
      );
    }
    case TEXT_EDITOR_COMMENT:
      return (
        <CommentInput
          dataStr={displayData || ''}
          onChange={handleChange}
          placeholder={placeholder}
          delay={editor.delay}
          header={header}
          disabled={disabled}
        />
      );
    case TEXT_EDITOR_MULTISELECT: {
      let { options } = editor;
      if (editor.optionsPath) {
        options = getByPath(dataWithPatchApplied, editor.optionsPath);
      }

      return (
        <MultiselectInput
          data={displayData ? JSON.parse(displayData) : []}
          options={options}
          onChange={handleChange}
          placeholder={placeholder}
          header={header}
          disabled={disabled}
        />
      );
    }
    case TEXT_EDITOR_DATE:
      return (
        <DateInput
          dataStr={displayData || ''}
          onChange={handleChange}
          inputFormat={editor.inputFormat}
          displayFormat={editor.format}
          placeholder={placeholder}
          header={header}
          disabled={disabled}
        />
      );
    default:
      return (
        <TextInput
          dataStr={displayData || ''}
          onChange={handleChange}
          placeholder={placeholder}
          delay={editor.delay}
          header={header}
          disabled={disabled}
        />
      );
  }
});
TextViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.object
};
TextViewImpl.defaultProps = {
  update: null
};

// sort defaults to text on path
const getSortConfig = view => ({
  type: 'text',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = view => ({
  type: 'text',
  path: view.path
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// stringifies data at path
const getStringifyFunction = view => {
  const { path, default: defaultValue } = view;
  return data => getTextData(data, path, defaultValue);
};

// get edited path
// returns path if editor is set, null otherwise
const getEditedPath = view => {
  const { path, editor } = view;
  return editor ? path : null;
};

const getAggregationConfig = view => {
  const { path, aggregationValue } = view;
  let aggregatedView;
  if (aggregationValue) {
    aggregatedView = {
      view: {
        path: path,
        aggregationValue: aggregationValue
      }
    };
    return aggregatedView;
  }
  return null;
};

export default {
  component: injectSheet(styles)(TextViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction,
  getEditedPath,
  getAggregationConfig
};
