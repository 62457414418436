export default {
  wrapper: {
    display: 'flex',
    gap: '10px'
  },
  checkbox: {
    marginTop: props => {
      switch (props.type) {
        default:
          return -4;
      }
    },
    alignSelf: props => {
      switch (props.type) {
        case 'file':
          return 'center';
        default:
          return 'flex-start';
      }
    }
  },
  children: {
    flex: '1'
  }
};
