import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { getByPath } from 'utils/widgets';

import styles from './labels.style';

const LabelsViewImpl = React.memo(({ classes, view, data }) => {
  const { path } = view;

  const labels = getByPath(data, path);
  if (!labels) return null;
  return (
    <div className={classes.labelsView}>
      {labels.map((label, idx) => (
        <div key={idx} className={classes.labelsViewElement}>
          {label}
        </div>
      ))}
    </div>
  );
});
LabelsViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to length on path
const getSortConfig = view => ({
  type: 'length',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = view => ({
  type: 'text',
  path: view.path
});

// width defaults to 'large'
const getDefaultWidth = () => 'large';

// stringifies data at path
const getStringifyFunction = view => {
  const { path } = view;
  return data => {
    const labels = getByPath(data, path);
    if (!labels) return '';

    return labels.join(', ');
  };
};

export default {
  component: injectSheet(styles)(LabelsViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
