import classNames from 'classnames';
import React from 'react';
import injectSheet from 'react-jss';
import styles from './footer.styles';

interface FooterProps {
  classes: any;
  customClass: any;
}

export const Footer: React.FC<FooterProps> = ({
  classes = {},
  customClass = ''
}) => {
  const copyrightText: string = `Stratumn © ${new Date().getFullYear()}. All rights reserved.`;
  const privacyPolicyLink: string = '/gdpr-privacy-policy';
  const privacyPolicyText: string = 'GDPR privacy policy';
  return (
    <footer className={classNames([classes.footer, customClass])}>
      <span>{copyrightText}</span>
      <span>
        <a target="_blank" rel="noopener noreferrer" href={privacyPolicyLink}>
          {privacyPolicyText}
        </a>
      </span>
    </footer>
  );
};

export default injectSheet(styles)(Footer);
