import axios from 'axios';
import { ACCOUNT_API_URL } from 'constant/api';
import { withSpanAsync, SpanType } from '../tracing';

export const logout = () =>
  withSpanAsync('logout', SpanType.outgoingRequest, async () =>
    axios.request({
      url: `${ACCOUNT_API_URL}/logout`,
      withCredentials: true
    })
  );
