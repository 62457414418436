import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import styles from './ellipsis.style';

/**
 * @function
 * @param {object} props
 * @param {string} props.children
 * @param {string} props.maxWidth Either we set a maxWidth in the parent node or
 * we pass it as a prop
 */
// eslint-disable-next-line no-unused-vars
export const Ellipsis = ({ classes, children, maxWidth }) => (
  <div className={classes.root}>{children}</div>
);

Ellipsis.defaultProps = {
  maxWidth: 'inherit'
};

Ellipsis.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string
};

export default injectSheet(styles)(Ellipsis);
