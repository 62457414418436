import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';

import injectSheet from 'react-jss';

import { useFocus } from 'utils/hooks';

import ValueLabel from './valueLabel';

import styles from './multipleValuesTextArea.style';

// This component behaves like a textarea
// where the user can input multiple independent string values
// that can be confirmed by hitting Enter, and also closed one by one
export const MultipleValuesTextArea = ({
  classes,
  values,
  updateValues,
  placeholder,
  splitKeys,
  toLowerCase,
  errorFn
}) => {
  // state for focusing text input
  const [isFocused, focus, blur, inputRef] = useFocus(false);

  // callbacks for updating values list
  const addValue = useCallback(
    value =>
      updateValues([...values, toLowerCase ? value.toLowerCase() : value]),
    [values, updateValues, toLowerCase]
  );
  const removeValue = useCallback(
    valueIdx => {
      const newValues = [...values];
      newValues.splice(valueIdx, 1);
      updateValues(newValues);

      // clicking the close icon has blurred the input hence changed the border color
      // so focus it explicitly again
      if (inputRef.current) inputRef.current.focus();
    },
    [values, updateValues]
  );

  // state for inputting a new value
  const [inputValue, setInputValue] = useState('');

  // state for error handling
  const [errorMsg, setErrorMsg] = useState(null);

  // callbacks to update input
  const handleChange = useCallback(
    event => {
      setInputValue(event.target.value);
      // clean error as soon as new text typed in
      setErrorMsg(null);
    },
    [inputValue, addValue]
  );

  // callbacks to listen for key events
  // check if the event key is in splitKeys
  const handleKeyDown = useCallback(
    event => {
      if (inputValue.length && splitKeys.includes(event.key)) {
        event.preventDefault();
        const validationErrMessage = errorFn(inputValue);
        if (validationErrMessage) {
          setErrorMsg(validationErrMessage);
          return;
        }
        addValue(inputValue.trim());
        setInputValue('');
      }
    },
    [inputValue, addValue, splitKeys, errorFn]
  );

  return (
    <div className={classes.root}>
      <div
        className={classes.area}
        data-is-focused={isFocused}
        data-is-invalid={!!errorMsg}
      >
        {values.map((value, index) => (
          <ValueLabel
            key={`${value}-${index}`} // note: might have same value string accross the list so to ensure unique keys we mix index and value
            index={index}
            value={value}
            removeValue={removeValue}
          />
        ))}
        <input
          ref={inputRef}
          className={classes.inputField}
          placeholder={placeholder}
          type="text"
          value={inputValue}
          onFocus={focus}
          onBlur={blur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          data-cy="text-input"
        />
      </div>
      {errorMsg && <div className={classes.errorMsg}>{errorMsg}</div>}
    </div>
  );
};
MultipleValuesTextArea.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  updateValues: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  splitKeys: PropTypes.arrayOf(PropTypes.string),
  errorFn: PropTypes.func,
  toLowerCase: PropTypes.bool
};
MultipleValuesTextArea.defaultProps = {
  values: [],
  placeholder: 'Type a new value',
  errorFn: () => null,
  splitKeys: ['Enter', 'Tab'],
  toLowerCase: false
};

export default compose(injectSheet(styles), React.memo)(MultipleValuesTextArea);
