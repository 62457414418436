import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { CheckboxTick } from '@stratumn/icons';
import { getByPath } from 'utils/widgets';

import styles from './boolean.style';

const BooleanViewImpl = React.memo(({ classes, view, data }) => {
  const { path } = view;

  // if the path doesn't return a truthy value when applied to data, we don't show the check mark
  if (!getByPath(data, path)) {
    return null;
  }

  return (
    <div className={classes.booleanView}>
      <CheckboxTick className={classes.booleanViewIcon} />
    </div>
  );
});
BooleanViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to text on path
const getSortConfig = view => ({
  type: 'text',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = view => ({
  type: 'text',
  path: view.path
});

// width defaults to 'small'
const getDefaultWidth = () => 'small';

// stringifies data at path
const getStringifyFunction = view => {
  const { path } = view;
  if (path !== undefined) return data => (!!getByPath(data, path)).toString();
  return () => '';
};

export default {
  component: injectSheet(styles)(BooleanViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
