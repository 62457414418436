export default theme => ({
  modalTitle: {
    padding: 6,
    fontSize: 18,
    lineHeight: '23px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  modalTitleNbRows: {
    color: theme.grey4,
    fontSize: 15
  },
  modalBody: {
    padding: '2px 6px',
    fontSize: 14,
    lineHeight: '18px',
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  modalBodyHeader: {
    paddingTop: 14,
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&[data-empty-warning=true]': {
      color: theme.warningRed
    }
  },
  modalBodyCells: {
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  modalBodyCell: {
    paddingTop: 18,
    position: 'relative'
  },
  modalActions: {
    padding: '14px 24px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});
