export default theme => ({
  colHeaders: {
    width: 'fit-content',
    fontSize: 10,
    letterSpacing: 1,
    color: theme.grey3,
    textAlign: 'left',
    borderBottom: `2px solid ${theme.grey5}`,
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  firstColumnPlaceholder: {
    borderRight: `1px solid ${theme.grey6}`
  },
  colHeadersCell: {
    padding: '0px 6px',
    borderRight: `1px solid ${theme.grey6}`,
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&[data-is-sorted=true]': {
      backgroundColor: theme.grey7,
      color: theme.grey2
    }
  },
  colHeadersLabel: {
    paddingTop: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.grey3
  },
  colHeadersIconsContainer: {
    paddingTop: 4,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  colHeaderFilterIcon: {
    display: 'block',
    flexShrink: 0,
    width: 12,
    height: 12
  },
  colHeaderSortIconWrapper: {
    flexShrink: 0,
    width: 15,
    height: 15,
    overflow: 'hidden'
  },
  colHeaderSortIcon: {
    cursor: 'pointer',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    '& > g': {
      transform: 'scale(2) translateX(5px) translateY(1px)'
    }
  }
});
