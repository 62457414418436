import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import moment from 'moment';
import { Icon } from '@stratumn/atomic';

import { getByPath, formatNumber } from 'utils';

import styles from './dataTab.style';

// basic interface to display some summary information about a piece of data
// should serve as an intermediary step for eg a modal display of detailed data
export const dataTabViewImpl = React.memo(({ classes, view, data }) => {
  const { namePath, iconPath, path, editedByPath, editedAtPath } = view;

  // concrete data label and value (to get the length and then more..)
  const dataName = getByPath(data, namePath) || 'Data';
  const dataIcon = getByPath(data, iconPath) || 'Table';
  const dataValue = getByPath(data, path);

  // display last activity on this data
  const editedBy = getByPath(data, editedByPath);
  const editedAt = getByPath(data, editedAtPath);

  // return nothing if dataValue does not exist
  if (dataValue === undefined) return null;

  // handle limited set of data types for meta info, otherwise stringify
  let dataInfoMetaMsg = dataValue.toString();
  if (typeof dataValue === 'object') {
    dataInfoMetaMsg = Array.isArray(dataValue)
      ? `${formatNumber(dataValue.length)} rows`
      : `${formatNumber(Object.keys(dataValue).length)} values`;
  }

  return (
    <div className={classes.dataTab}>
      <div className={classes.dataLabel}>
        <div className={classes.dataIcon}>
          <Icon name={dataIcon} size={20} />
        </div>
        <div className={classes.dataInfo}>
          <div className={classes.dataName}>{dataName}</div>
          <div className={classes.dataInfoMeta}>{dataInfoMetaMsg}</div>
        </div>
      </div>
      <div className={classes.dataInfoActivity}>
        <div className={classes.dataInfoWho}>{editedBy}</div>
        <div className={classes.dataInfoWhen}>
          {editedAt &&
            `Last update: ${moment(editedAt).format('DD/MM/YYYY LT')}`}
        </div>
      </div>
    </div>
  );
});

dataTabViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to text on name
const getSortConfig = view => ({
  type: 'text',
  path: view.namePath
});

// filtering defaults to text search on name
const getFilterConfig = view => ({
  type: 'text',
  path: view.namePath
});

// width defaults to 'large'
const getDefaultWidth = () => 'large';

export default {
  component: injectSheet(styles)(dataTabViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth
};
