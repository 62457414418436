export default () => ({
  overviewTableContainer: {
    height: '100%',
    width: '100%',
    padding: 15,
    marginBottom: 25,
    display: 'flex',
    overflow: 'hidden'
  },
  overviewTraysContainer: {
    position: 'fixed',
    zIndex: 5,
    top: 0,
    bottom: 0,
    right: 0,
    width: 'auto',
    display: 'flex',
    flexFlow: 'row-reverse nowrap',
    '&[data-is-left=true]': {
      right: 'unset',
      left: 0,
      flexFlow: 'row nowrap'
    }
  },
  overviewFooter: {
    backgroundColor: 'white',
    width: '100%',
    bottom: -25
  }
});
