export default theme => {
  const breadcrumbSpace = 5;

  return {
    title: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: '0.03em',
      color: theme.grey1,
      '&[data-is-link="true"]': {
        transition: '250ms',
        textDecoration: 'none',
        '&:hover': {
          opacity: 0.8
        }
      }
    },
    breadcrumb: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '18px',
      color: theme.grey3,
      '&[data-has-enabledlinks="true"]': {
        '&:not(:first-child):not(:last-child)': {
          '&::after': {
            content: '"|"',
            fontSize: 18,
            fontWeight: 100,
            padding: `0 ${breadcrumbSpace}px`,
            color: theme.grey3
          }
        }
      },
      '&[data-has-enabledlinks="false"]': {
        '&:not(:last-child)': {
          '&::after': {
            content: '"|"',
            fontSize: 18,
            fontWeight: 100,
            padding: `0 ${breadcrumbSpace}px`,
            color: theme.grey3
          }
        }
      },
      '&:not(:first-child)': {
        fontWeight: 'normal'
      },
      '&[data-is-disabled="false"]': {
        transition: '250ms',
        textDecoration: 'none',
        '&:hover': {
          opacity: 0.8
        }
      }
    },
    linkWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& > a': {
        textDecoration: 'none'
      },
      '& button': {
        marginLeft: 10
      }
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 12,
      lineHeight: '15px',
      '& > *': {
        color: theme.grey2,
        transition: 'none',
        '&:hover': {
          color: 'black'
        }
      }
    },
    actionsLabel: {
      color: theme.grey1,
      marginRight: 10
    },
    viewToggleWrapper: {
      display: 'flex',
      order: 2,
      marginLeft: 23
    },
    viewToggle: {
      display: 'flex',
      '& > button:not(:last-child)': {
        borderRightWidth: 0.5,
        borderRightStyle: 'solid',
        paddingRight: breadcrumbSpace,
        marginRight: breadcrumbSpace,
        borderColor: theme.grey1
      }
    },
    arrowSeparator: {
      margin: '0 8px',
      fontWeight: 500,
      color: theme.grey3
    },
    linksWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    linkStyle: {
      fontSize: 12,
      lineHeight: '15px',
      textAlign: 'right',
      letterSpacing: '0.03em',
      color: theme.grey1,
      '&::first-letter': {
        textTransform: 'capitalize'
      }
    },
    link: {
      '&[data-is-active=true]': {
        // only style the link and not its suffix
        '& > div > span:first-child': {
          extend: 'linkStyle',
          fontWeight: 700,
          textDecoration: 'underline'
        }
      },
      '& a': {
        fontWeight: 600,
        extend: 'linkStyle',
        '&:hover': {
          fontWeight: 700
        }
      }
    },
    betaLogoWrapper: {
      display: 'flex',
      marginTop: 2,
      backgroundColor: theme.grey3,
      borderRadius: 10
    },
    betaLogoLabel: {
      padding: '1px 3px',
      fontWeight: 600,
      fontSize: 6,
      lineHeight: '8px',
      letterSpacing: '0.15em',
      textTransform: 'uppercase',
      color: theme.white1
    }
  };
};
