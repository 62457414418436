export default theme => ({
  team: {
    padding: '7px 10px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.grey9
    },
    '&[data-is-selected=true]': {
      backgroundColor: theme.greyTable
    }
  },
  teamLeft: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  teamLabel: {
    fontSize: 14,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    overflow: 'hidden',
    textTransform: 'capitalize'
  },
  teamInfo: {
    color: theme.grey3,
    fontSize: 12,
    lineHeight: '14px',
    textTransform: 'none'
  },
  teamRight: {
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  }
});
