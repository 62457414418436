import gql from 'graphql-tag';

export default {
  workflowQuery: gql`
    query workflowQuery($workflowId: BigInt!) {
      workflow: workflowByRowId(rowId: $workflowId) {
        rowId
        name
      }
    }
  `,
  searchTracesQuery: gql`
    query searchTracesQuery(
      $workflowId: BigInt!
      $after: Datetime!
      $before: Datetime!
      $limit: Int!
      $cursor: Cursor
    ) {
      workflow: workflowByRowId(rowId: $workflowId) {
        rowId
        traces(
          first: $limit
          after: $cursor
          filter: {
            updatedAt: {
              lessThanOrEqualTo: $before
              greaterThanOrEqualTo: $after
            }
          }
        ) {
          pageInfo {
            endCursor
            hasNextPage
          }
          nodes {
            rowId
            createdAt
            updatedAt
            state {
              rowId
              data
            }
          }
        }
      }
    }
  `
};
