import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { withClientState } from 'apollo-link-state';
import { dataIdFromObject } from 'utils';
import introspectionQueryResultData from './fragmentTypesTrace.json';
import resolvers from './resolvers';
import defaults from './defaults';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

export const cache = new InMemoryCache({
  dataIdFromObject,
  fragmentMatcher
});

export default withClientState({
  cache,
  resolvers,
  defaults
});
