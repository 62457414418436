import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import styles from './tooltip.style';

import { positionTooltipElementAndShow } from './position';

// tooltip component
const Tooltip = React.memo(
  ({
    classes,
    clientEl,
    portalEl,
    children,
    position,
    arrowUp,
    delay,
    onClickOutside
  }) => {
    // use callback to reposition the tooltip when it finished mounting
    // wait until 'delay' to actually display it
    const arrowRef = useRef(null);
    const tooltipRef = useRef(null);
    useEffect(() => {
      positionTooltipElementAndShow(
        tooltipRef.current,
        clientEl,
        position,
        arrowRef.current,
        delay
      );
    }, [clientEl]);

    const backgroundRef = useRef(null);
    const mouseDownRef = useRef(null);
    const handleMouseDown = e => {
      mouseDownRef.current = e.target;
    };
    const handleMouseUp = e => {
      if (
        onClickOutside &&
        mouseDownRef.current === e.target &&
        e.target === backgroundRef.current
      ) {
        onClickOutside();
      }
      e.stopPropagation();
      mouseDownRef.current = null;
    };

    // note: tooltip content is scrollable but arrow remains fixed
    // hence we split the portal content into 2 absolutely positioned areas
    return ReactDOM.createPortal(
      <>
        <div className={classes.tooltipArrowWrapper} ref={arrowRef}>
          {arrowUp || <div />}
        </div>
        <div
          className={classes.tooltipBackground}
          ref={backgroundRef}
          data-is-clickable={!!onClickOutside}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          data-cy="tooltip-background"
        >
          <div className={classes.tooltipContainer} ref={tooltipRef}>
            {children}
          </div>
        </div>
      </>,
      portalEl
    );
  }
);

Tooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  clientEl: PropTypes.instanceOf(Element).isRequired,
  portalEl: PropTypes.instanceOf(Element).isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.object,
  arrowUp: PropTypes.node,
  delay: PropTypes.number,
  onClickOutside: PropTypes.func
};

Tooltip.defaultProps = {
  position: {},
  arrowUp: null,
  delay: 0,
  onClickOutside: null
};

export default injectSheet(styles)(Tooltip);
