export default () => ({
  body: {
    textAlign: 'center',
    margin: 60
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 18
  },
  icon: {
    width: 350,
    marginBottom: 12
  },
  bigText: {
    fontSize: 'larger'
  },
  smallText: {
    fontSize: 'small'
  }
});
