import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import NewLink from 'components/newLink';
import AnswerCard from 'components/ui/answerCard';

import injectSheet from 'react-jss';
import styles from './answers.style';

export const Answers = ({
  classes,
  action,
  traces,
  match,
  history,
  traceId,
  link,
  workflowContext,
  answers,
  groups,
  linkCreatedByAccount,
  nextActions,
  refetchTraceQuery
}) => {
  const location = {
    search: {
      actionKey: 'answerComment',
      groupKey: link.group.label,
      traceIds: match.params.id
    }
  };
  const matchParamsWithWorklowId = {
    ...match.params,
    wfid: workflowContext.rowId
  };
  const newMatch = {
    ...match,
    ...Object.assign(match.params, matchParamsWithWorklowId)
  };

  const answerData = {
    action,
    traces,
    location,
    traceId,
    workflowRowId: workflowContext.rowId,
    groupLabel: link.group.label,
    groups,
    actionLinkHash: link.linkHash
  };

  const answerCardData = answer => {
    const cardData = {
      linkCreatedByAccount,
      link: answer,
      traceId,
      workflowContext
    };
    return cardData;
  };

  const nextActionsIncludeAnswerComment = nextActions.some(n =>
    n.actions.some(a => a.key === 'answerComment')
  );

  return (
    <div>
      {answers?.length > 0 && (
        <div className={classes.previousAnswersContainer}>
          <h2 className={classes.answersTitle}>
            {`Answer${answers?.length > 1 ? 's' : ''}`}{' '}
            {answers?.length > 1 && `(${answers?.length})`}
          </h2>
          {answers?.map((answer, i) => (
            <AnswerCard data={answerCardData(answer)} traceInspector key={i} />
          ))}
        </div>
      )}
      {nextActionsIncludeAnswerComment && (
        <NewLink
          answerAction={action}
          answerTraces={traces}
          match={newMatch}
          history={history}
          answerTraceId={traceId}
          answerGroupLabel={link.group.label}
          answerData={answerData}
          answer
          refetchTraceQuery={refetchTraceQuery}
        />
      )}
    </div>
  );
};

Answers.propTypes = {
  action: PropTypes.object.isRequired,
  traces: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  traceId: PropTypes.string.isRequired,
  link: PropTypes.object.isRequired,
  workflowContext: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  linkCreatedByAccount: PropTypes.object.isRequired,
  nextActions: PropTypes.array.isRequired,
  refetchTraceQuery: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default compose(injectSheet(styles), withRouter)(Answers);
