import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';

import style from './icon.style';

class Icon extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string
  };

  static defaultProps = {
    className: null
  };

  render() {
    const { classes, className } = this.props;
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 280 84"
        className={className}
      >
        <defs>
          <clipPath id="clip-path">
            <circle className={classes.cls1} cx="41.75" cy="42.08" r="41.75" />
          </clipPath>
          <clipPath id="clip-path-2">
            <circle className={classes.cls1} cx="238.25" cy="42.11" r="41.75" />
          </clipPath>
          <linearGradient
            id="linear-gradient"
            x1="121.51"
            y1="75.22"
            x2="157.01"
            y2="10.95"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2e058d" />
            <stop offset="1" stopColor="#4b35d2" />
          </linearGradient>
          <clipPath id="clip-path-3">
            <circle className={classes.cls1} cx="140" cy="41.75" r="41.75" />
          </clipPath>
        </defs>
        <title>Stop</title>
        <g className={classes.cls2}>
          <circle className={classes.cls3} cx="41.75" cy="42.08" r="41.75" />
          <g className={classes.cls4}>
            <polygon
              className={classes.cls5}
              points="105.19 70 59.34 24.16 53.6 42.83 34.92 24.16 23.97 59.77 65.55 100.67 73.56 84.53 89.96 100.67 105.19 70"
            />
          </g>
          <rect
            className={classes.cls6}
            x="23.44"
            y="23.51"
            width="12.35"
            height="37.15"
            rx="2.77"
            ry="2.77"
          />
          <rect
            className={classes.cls6}
            x="47.71"
            y="23.51"
            width="12.35"
            height="37.15"
            rx="2.77"
            ry="2.77"
          />
        </g>
        <g className={classes.cls2}>
          <circle className={classes.cls3} cx="238.25" cy="42.11" r="41.75" />
          <g className={classes.cls7}>
            <path
              className={classes.cls5}
              d="M225.52,59.24l44.94,44.94,13.33-36.25L256.23,40.37Z"
            />
          </g>
          <path
            className={classes.cls6}
            d="M255.21,39.49,229.42,24.6a3,3,0,0,0-4.55,2.63V57a3,3,0,0,0,4.55,2.63l25.79-14.89A3,3,0,0,0,255.21,39.49Z"
          />
        </g>
        <circle className={classes.cls8} cx="140" cy="41.75" r="41.75" />
        <circle className={classes.cls8} cx="140" cy="41.75" r="41.75" />
        <g className={classes.cls9}>
          <ellipse
            className={classes.cls10}
            cx="35.98"
            cy="-72.32"
            rx="154.06"
            ry="141.15"
          />
        </g>
        <g className={classes.cls9}>
          <path
            className={classes.cls11}
            d="M122.62,61.12l34.68-35.8,45.84,45.84L164.19,102s-41.57-40.9-41.57-41.57"
          />
          <circle className={classes.cls1} cx="140" cy="41.75" r="41.75" />
        </g>
        <rect
          className={classes.cls6}
          x="121.51"
          y="24.18"
          width="37.15"
          height="37.15"
          rx="4.89"
          ry="4.89"
        />
      </svg>
    );
  }
}

export default injectSheet(style)(Icon);
