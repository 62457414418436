import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import styles from './input.style';

export const Input = ({ classes, top, right, ...props }) => (
  <input className={classNames(classes.root, classes.rootDynamic)} {...props} />
);

Input.propTypes = {
  classes: PropType.object.isRequired,
  top: PropType.bool,
  right: PropType.bool
};

Input.defaultProps = {
  top: false,
  right: false
};

export default injectSheet(styles)(Input);
