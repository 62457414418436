export default theme => ({
  dataEditor: {
    height: 'calc(100% - 83px)',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center'
  },
  summaryDataBoard: {
    width: '100%',
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 13
  },
  summaryDataGoBack: {
    color: theme.grey2,
    padding: '0px 15px',
    backgroundColor: theme.white1,
    boxShadow: '0px 2px 20px rgba(75, 53, 210, 0.1)',
    borderRadius: 5,
    fontSize: 11,
    lineHeight: '34px',
    fontWeight: 600,
    textTransform: 'uppercase',
    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      boxShadow: '0px 2px 20px rgba(75, 53, 210, 0.25)'
    }
  },
  summaryDataSubmit: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  summaryDataCount: {
    color: theme.grey1,
    marginLeft: 15,
    marginRight: 15,
    fontSize: 12
  },
  editorDataTable: {
    padding: '20px 20px 0px 20px',
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  dataEditorFooter: {
    zIndex: 2,
    width: '100%',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    backgroundColor: theme.white1,
    padding: '12px 20px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    fontSize: 14,
    flexShrink: 0
  },
  dataEditorFooterLeft: {
    flexGrow: 1,
    height: 32,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '15px'
  },
  dataEditorCommentsContainer: {
    width: '100%',
    maxWidth: 500,
    transition: 'height 1s',
    height: 'auto'
  },
  dataEditorFooterRight: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '15px'
  },
  dataEditorNbModifiedRows: {
    color: theme.grey1
  },
  batchUpdateCacheMessage: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    height: 60,
    width: '100%',
    maxWidth: 750,
    backgroundColor: theme.teal1,
    color: theme.white1,
    borderRadius: 3,
    margin: '20px 0px',
    padding: '10px 20px',
    fontSize: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'pre',
    boxShadow: '0px 5px 15px #30CECA4D'
  },
  batchUpdateUndoBtn: {
    fontSize: 11,
    fontWeight: 600,
    borderRadius: 2,
    color: theme.white1,
    border: `1px solid ${theme.white1}`,
    padding: '9px 14px',
    textTransform: 'uppercase',
    letterSpacing: 1,
    position: 'absolute',
    right: 20,
    top: 11
  }
});
