export default theme => ({
  jsonEditor: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    '&[data-is-in-modal=true]': {
      overflow: 'auto'
    }
  },
  jsonEditorBody: {
    height: '100%',
    overflow: 'hidden',
    '& .codemirrorEditor': {
      height: '100%'
    },
    '& .CodeMirror': {
      height: '100%'
    },
    fontSize: 13,
    lineHeight: 1.25,
    '&[data-is-in-modal=true]': {
      overflow: 'auto',
      padding: 14
    }
  },
  jsonEditorFooter: {
    height: 70,
    width: '100%',
    padding: 10,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  jsonEditorErrorMessage: {
    color: theme.warningRed,
    fontSize: 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  jsonEditorActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  jsonEditorAction: {
    marginLeft: 10
  }
});
