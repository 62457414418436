export default theme => ({
  card: {
    margin: '5px 0px',
    backgroundColor: theme.white1,
    borderRadius: 5,
    border: `1px solid ${theme.grey7}`,
    padding: '16px 20px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.mediaQueries.medium]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  cardLeft: {
    flexGrow: 1,
    minWidth: '30%',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  groupLabel: {
    marginLeft: 11,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    overflow: 'hidden',
    textTransform: 'capitalize'
  },
  groupInfo: {
    color: theme.grey3,
    fontSize: 12,
    lineHeight: '14px',
    '&[data-is-collapsable=true]': {
      [theme.mediaQueries.medium]: {
        marginTop: 5
      }
    }
  },
  cardRight: {
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    [theme.mediaQueries.medium]: {
      marginTop: 10
    },
    [theme.mediaQueries.small]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  creationDate: {
    width: 300
  },
  participantsCount: {
    width: 150
  },
  teamsCount: {
    width: 150
  }
});
