export default theme => ({
  root: {
    wordBreak: 'break-word',
    color: theme.grey1,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.grey7}`,
    padding: '0 15px 15px 15px',
    marginInline: '-15px',
    width: '100%'
  },
  avatar: {
    flexShrink: 0,
    marginRight: 10,
    paddingTop: 3
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 0',
    gap: '1rem',
    width: '100%'
  },
  name: {
    fontSize: 18,
    lineHeight: '25px',
    fontWeight: 700,
    marginRight: 7
  },
  keyText: {
    fontSize: 11,
    fontWeight: 400,
    color: theme.grey3,
    '&:hover': {
      color: theme.indigo3
    }
  },
  '@media (max-width: 600px), (min-width: 800px) and (max-width: 950px)': {
    keyText: {
      display: 'none'
    }
  },
  details: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.grey1,
    lineHeight: '18px',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        color: theme.indigo3
      }
    }
  },
  email: {},
  phone: {}
});
