import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { TOOLTIP_PORTAL } from 'constant/htmlIds';

import theme from 'style';

import Tooltip from '../tooltip';

import styles from './infoTooltip.style';

// tooltip configured with simple content to display text in a given color...
const InfoTooltip = React.memo(
  ({
    classes,
    clientRef,
    text,
    textColor,
    backgroundColor,
    position,
    delay,
    minWidth,
    maxWidth,
    boxShadow
  }) => {
    // allow to specify theme colors
    const usedBackgroundColor = theme[backgroundColor] || backgroundColor;
    const usedTextColor = theme[textColor] || textColor;

    return (
      <Tooltip
        clientEl={clientRef.current}
        portalEl={document.getElementById(TOOLTIP_PORTAL)}
        position={position}
        arrowUp={
          <div
            className={classes.infoTooltipArrowUp}
            style={{ borderBottomColor: usedBackgroundColor, boxShadow }}
          />
        }
        delay={delay}
      >
        <div
          className={classes.infoTooltipBody}
          style={{
            color: usedTextColor,
            backgroundColor: usedBackgroundColor,
            boxShadow, // note: ideally we'd like to pass only the boxShadow base color here, but we'll need a toolbox to lower colors opacity, will be refactored later
            minWidth,
            maxWidth
          }}
        >
          {text}
        </div>
      </Tooltip>
    );
  }
);

InfoTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  clientRef: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  position: PropTypes.object.isRequired,
  delay: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  boxShadow: PropTypes.string
};

InfoTooltip.defaultProps = {
  delay: 100,
  minWidth: 100,
  maxWidth: 200,
  boxShadow: null
};

export default injectSheet(styles)(InfoTooltip);
