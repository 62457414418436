import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';
import { Dropdown, OptionDrop } from '@stratumn/atomic';

import styles from './displayMenu.style';

// display menu
const DisplayMenu = React.memo(
  ({
    classes,
    tableConfig,
    displayConfig,
    toggleColumnSelection,
    setGroupBy
  }) => {
    const selectedColumnsKeys = displayConfig.columns.map(
      colDisplayConfig => colDisplayConfig.key
    );

    const handleChangeGroupBy = e => {
      const groupLabel = e.target.value;
      const group = tableConfig.groups.find(grp => grp.label === groupLabel);
      setGroupBy(group);
    };

    return (
      <>
        <div className={classes.displayMenu}>
          <div className={classes.displayMenuTitle}>Display columns</div>
          <div className={classes.displayMenuList}>
            {tableConfig.columns.map(colDef => (
              <button
                key={colDef.key}
                className={classes.displayMenuItem}
                onClick={() => toggleColumnSelection(colDef.key)}
                data-is-selected={selectedColumnsKeys.includes(colDef.key)}
              >
                <span className={classes.displayMenuItemHeader}>
                  {colDef.header}
                </span>
              </button>
            ))}
          </div>
        </div>
        {tableConfig.groups && (
          <div className={classes.displayMenu}>
            <div className={classes.displayMenuTitle}>Group by</div>
            <div className={classes.displayMenuGroupBy}>
              <Dropdown onValueChange={handleChangeGroupBy}>
                <OptionDrop
                  label="No grouping"
                  value=""
                  selected={!displayConfig.groupBy}
                />
                {tableConfig.groups.map(group => (
                  <OptionDrop
                    key={group.label}
                    label={group.label}
                    value={group.label}
                    selected={
                      displayConfig.groupBy &&
                      displayConfig.groupBy.path === group.path
                    }
                  />
                ))}
              </Dropdown>
            </div>
          </div>
        )}
      </>
    );
  }
);
DisplayMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  tableConfig: PropTypes.object.isRequired,
  displayConfig: PropTypes.object.isRequired,
  toggleColumnSelection: PropTypes.func.isRequired,
  setGroupBy: PropTypes.func.isRequired
};

export default injectSheet(styles)(DisplayMenu);
