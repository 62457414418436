import React from 'react';
import PropType from 'prop-types';
import { SUPPORT_EMAIL } from 'constant/support';
import { Navbar, Pushbutton } from '@stratumn/atomic';

import styles from './unexpectedErrorBoundary.style';

export class UnexpectedErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    document.title = 'An unexpected error occurred';
    return {
      hasError: true,
      body: [
        'Do not modify below this line',
        '------------------------------------------------------------------------',
        Buffer.from(
          JSON.stringify({
            error: {
              name: error.name,
              fileName: error.fileName,
              message: error.message,
              stack: error.stack,
              lineNumber: error.lineNumber,
              columnNumber: error.columnNumber
            },
            window: {
              location: window.location.href,
              screen: {
                width: window.screen.width,
                height: window.screen.height
              },
              navigator: {
                userAgent: navigator.userAgent,
                language: navigator.language
              }
            }
          })
        ).toString('base64')
      ].join('\n')
    };
  }

  static propTypes = {
    children: PropType.node.isRequired
  };

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Navbar />
          <div style={styles.errorPage}>
            <h1>An unexpected error occurred.</h1>
            <h1>
              Please send an email to {SUPPORT_EMAIL} with the following body
            </h1>
            <div style={styles.emailBody}>{this.state.body}</div>
            <div style={styles.sendMailButton}>
              <Pushbutton
                secondary
                onClick={() => {
                  window.location = `mailto:${SUPPORT_EMAIL}?subject=%5BCRASH%5D&body=${encodeURI(
                    this.state.body
                  )}`;
                }}
              >
                Send email
              </Pushbutton>
            </div>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

export default UnexpectedErrorBoundary;
