import indigoBackground from 'assets/IndigoTrace_Map.svg';
import { ITEM_BORDER_RADIUS } from '../userDashboard.style';

export default theme => ({
  header: {
    position: 'relative',
    backgroundColor: theme.indigo1,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    padding: 30,
    color: theme.white1,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 238,
    overflow: 'hidden',
    transition: 'height 200ms, padding-top 200ms, padding-bottom 200ms',
    '&[data-is-collapsed=true]': {
      height: 103,
      paddingTop: 20,
      paddingBottom: 20
    },
    '&:before': {
      content: '""',
      backgroundImage: `url(${indigoBackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%',
      opacity: 0.3,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute'
    },
    '& > *': {
      zIndex: 1
    }
  },
  sectionName: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 8,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  headerLeft: {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    overflow: 'hidden'
  },
  headerWelcome: {
    overflow: 'hidden',
    flexShrink: 0,
    width: 300,
    transition: 'width 200ms linear',
    '&[data-is-collapsed=true]': {
      width: 220
    }
  },
  welcome: {
    fontSize: 20,
    lineHeight: '25px'
  },
  firstName: {
    fontSize: 30,
    lineHeight: '38px',
    fontWeight: 600
  },
  welcomeMessage: {
    fontSize: 14,
    marginTop: 15,
    opacity: 1,
    transition: 'opacity 400ms ease-in, height 400ms ease-in',
    '&[data-is-collapsed=true]': {
      opacity: 0,
      height: 0
    }
  },
  headerMembership: {
    flexGrow: 1,
    marginLeft: 30,
    marginRight: 30,
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
    '&[data-is-collapsed=true]': {
      flexFlow: 'row nowrap'
    }
  },
  headerMembershipSection: {
    overflow: 'hidden',
    marginRight: 10,
    '&[data-is-collapsed=true]': {
      marginRight: 50
    }
  },
  organizationSection: {
    flexShrink: 0,
    marginBottom: 15,
    '&[data-is-collapsed=true]': {
      marginBottom: 0
    }
  },
  headerMembershipItem: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    overflow: 'hidden',
    marginRight: 15,
    marginBottom: 7
  },
  headerMembershipItemName: {
    marginLeft: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  organizationName: {
    fontSize: 25,
    lineHeight: 1,
    '&[data-is-collapsed=true]': {
      fontSize: 14
    }
  },
  teamsSection: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  groupsCount: {
    marginLeft: 5
  },
  teams: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    flexGrow: 1
  },
  teamName: {
    fontSize: 14
  },
  teamsEllipsisInfo: {
    paddingBottom: 7,
    fontSize: 14,
    lineHeight: 1
  },
  teamsTooltip: {
    fontSize: 14,
    lineHeight: 1
  },
  emptyOrgTeamMsg: {
    fontSize: 14,
    lineHeight: '25px'
  },
  headerRight: {
    width: 280,
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'column nowrap',
    opacity: 1,
    transition: 'width 200ms linear, opacity 400ms ease-in',
    '&[data-is-collapsed=true]': {
      width: 0,
      opacity: 0
    }
  },
  today: {
    fontSize: 30,
    fontWeight: 300,
    lineHeight: 1,
    whiteSpace: 'nowrap'
  },
  collapseIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 25,
    borderRadius: ITEM_BORDER_RADIUS,
    cursor: 'pointer',
    color: theme.white1,
    transform: 'rotate(180deg)',
    transition: 'all 0.2s ease-in-out',
    outline: 'none',
    '&[data-is-collapsed=true]': {
      transform: 'rotate(0deg)',
      '&:hover': {
        transform: 'rotate(0deg)'
      }
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 25,
      aspectRatio: '1 / 1',
      borderRadius: '50%',
      backgroundColor: `${theme.indigo2}70`,
      opacity: 0,
      transition: 'all 0.1s'
    },
    '&:hover, &:focus-visible': {
      '&:before': {
        opacity: 1
      }
    }
  }
});
