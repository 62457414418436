// Default display config document
export const displayConfig = {
  selectBoxWidth: 34,
  columnsWidth: 'medium',
  minColumnsWidth: 'small',
  rowsHeight: 42,
  minRowsHeight: 30,
  dataSelectorPath: 'meta.traceId',
  defaultDisplay: {
    columns: [
      {
        key: 'trace'
      },
      {
        key: 'group'
      },
      {
        key: 'lastAction'
      },
      {
        key: 'steps'
      },
      {
        key: 'lastActivity'
      }
    ]
  },
  columns: [
    {
      key: 'trace',
      header: 'Trace',
      width: 'medium',
      cell: {
        view: {
          type: 'text',
          path: 'meta.traceName' || 'meta.traceShortId'
        },
        link: {
          rootUrl: '/trace/',
          urlPath: 'meta.traceId',
          openInNewTab: true
        }
      }
    },
    {
      key: 'group',
      header: 'Group',
      width: 'medium',
      cell: {
        view: {
          type: 'text',
          path: 'meta.head.group.name'
        }
      }
    },
    {
      key: 'lastAction',
      header: 'Last Action',
      width: 'medium',
      cell: {
        view: {
          type: 'icon',
          iconPath: "meta.head.action.icon || 'Document'",
          labelPath: 'meta.head.action.title'
        }
      }
    },
    {
      key: 'steps',
      header: 'Actions',
      width: 'small',
      cell: {
        view: {
          type: 'number',
          path: 'meta.links.totalCount',
          format: {
            options: {
              minimumIntegerDigits: 2
            }
          }
        }
      }
    },
    {
      key: 'lastActivity',
      header: 'Last Activity',
      cell: {
        view: {
          type: 'activity',
          who: {
            path: 'meta.head.createdBy.name'
          },
          when: {
            path: 'meta.head.createdAt',
            format: 'DD MMM, YYYY',
            showCountdown: false
          }
        }
      }
    },
    {
      key: 'traceState',
      header: 'Trace State',
      width: 'small',
      cell: {
        view: {
          type: 'icon',
          icon: 'Document'
        },
        modal: {
          large: true,
          title: {
            view: {
              type: 'text',
              path: "[meta.traceId,' Trace State'].join('',@)"
            }
          },
          body: {
            view: {
              type: 'code',
              path: '',
              codemirrorOptions: {
                theme: 'material'
              }
            }
          }
        }
      },
      filter: {
        type: 'text',
        path: ''
      }
    }
  ],
  overscanRowCount: 1
};
