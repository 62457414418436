import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';

import injectSheet from 'react-jss';

import { Check } from '@stratumn/atomic';

import { pluralize } from 'utils';

import styles from './team.style';

// A single clickable team row
export const Team = ({
  classes,
  accountId,
  name,
  nbParticipants,
  isSelected,
  toggleTeam
}) => {
  const toggleThisTeam = useCallback(() => {
    toggleTeam(accountId);
  }, [toggleTeam, accountId]);

  return (
    <div key={accountId} className={classes.team} data-is-selected={isSelected}>
      <div
        className={classes.teamLeft}
        onClick={toggleThisTeam}
        data-cy="toggle-team-name"
      >
        <div className={classes.teamLabel}>
          {name}
          <div className={classes.teamInfo}>
            {pluralize(nbParticipants, 'participant')}
          </div>
        </div>
      </div>
      <div className={classes.teamRight}>
        <Check
          label=""
          checked={isSelected}
          showLabel={false}
          handleChange={toggleThisTeam}
          disabled={false}
        />
      </div>
    </div>
  );
};
Team.propTypes = {
  classes: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nbParticipants: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  toggleTeam: PropTypes.func.isRequired
};
Team.defaultProps = {};

export default compose(injectSheet(styles), React.memo)(Team);
