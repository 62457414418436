import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import moment from 'moment';

import { Avatar } from '@stratumn/atomic';

import { getByPath } from 'utils/widgets';

import styles from './activity.style';

const ActivityViewImpl = React.memo(({ classes, view, data }) => {
  const { who, when } = view;
  const lastActivityDateStr = getByPath(data, when.path);
  const lastActivityDate = moment.utc(lastActivityDateStr).local();
  const lastActivityAuthor = getByPath(data, who.path);
  const lastActivityAuthorAvatar = getByPath(data, who.avatarPath);
  return (
    <div className={classes.activityView}>
      {who.avatarPath && (
        <div className={classes.activityViewAvatar}>
          <Avatar size={28} src={lastActivityAuthorAvatar} nth={0} />
        </div>
      )}
      <div className={classes.activityViewContent}>
        <div className={classes.activityViewWho}>{lastActivityAuthor}</div>
        <div className={classes.activityViewWhen}>
          {when.showCountdown
            ? lastActivityDate.fromNow()
            : lastActivityDate.format(when.format || 'DD.MM.YY')}
        </div>
      </div>
    </div>
  );
});
ActivityViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to text on when (text is ok assuming iso dates)
const getSortConfig = view => ({
  type: 'text',
  path: view.when.path
});

// filtering defaults to text search on who
const getFilterConfig = view => ({
  type: 'text',
  path: view.who.path
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// stringifies data at path
const getStringifyFunction = view => {
  const { who, when } = view;

  return data => {
    const lastActivityDateStr = getByPath(data, when.path);
    const lastActivityDate = moment.utc(lastActivityDateStr).local();
    const lastActivityAuthor = getByPath(data, who.path);
    return `${lastActivityAuthor} - ${lastActivityDate.format(
      when.format || 'DD.MM.YY'
    )}`;
  };
};

export default {
  component: injectSheet(styles)(ActivityViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
