import React from 'react';
import { graphql } from 'react-apollo';

// The graphql function exported by react-apollo returns a function
// which will “enhance” any component with reactive GraphQL capabilities
// using the Higher-Order Component (HOC) pattern.
// We need to wrap this function using the same pattern in order to enrich
// a query or a mutation with the `traceFilters` contained in the wrapped
// component's props instead of explicitly passing these filters every time.

const injectFilters = (options, props) => {
  const {
    filters: { __typename, ...traceFilters }
  } = props.filtersQuery;

  return {
    ...options,
    variables: {
      ...options.variables,
      search: traceFilters.search
    }
  };
};

const combinedOptions = options => props => {
  const oldOptions = options(props);
  return injectFilters(oldOptions, props);
};

const queryHOC = (query, config) => Component => {
  const QueryWrapper = props => {
    const {
      [config.name]: { subscribeToMore }
    } = props;
    const newProps = {
      ...props,
      [config.name]: {
        ...props[config.name],
        subscribeToMore: options =>
          subscribeToMore(injectFilters(options, props))
      }
    };
    return <Component {...newProps} />;
  };

  return graphql(query, {
    ...config,
    options: combinedOptions(config.options)
  })(QueryWrapper);
};

const mutationHOC = (query, config) => Component => {
  const MutationWrapper = props => {
    const { [config.name]: mutation } = props;
    const newProps = {
      ...props,
      [config.name]: options => mutation(injectFilters(options, props))
    };
    return <Component {...newProps} />;
  };
  return graphql(query, config)(MutationWrapper);
};

// Decorate the options' variables param in the graphql call with filters.
// This expects the prop filtersQuery to be present.
export default (query, config) => {
  const {
    definitions: [{ operation }]
  } = query;
  switch (operation) {
    case 'query':
      return queryHOC(query, config);
    case 'mutation':
      return mutationHOC(query, config);
    default:
      return null;
  }
};
