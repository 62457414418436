export default theme => ({
  container: {
    flexShrink: 0,
    overflow: 'hidden',
    backgroundColor: theme.white1,
    padding: '30px 15px',
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  header: {
    marginBottom: 10,
    padding: '0px 15px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between'
  },
  headerTitle: {
    flexShrink: 0,
    marginRight: 30,
    display: 'flex',
    flexFlow: 'column nowrap',
    fontWeight: 600,
    fontSize: 16,
    textAlign: 'justify'
  },
  headerTitleUnderline: {
    backgroundColor: theme.teal1,
    width: 15,
    height: 5,
    borderRadius: 10
  },
  filter: {
    display: 'flex',
    flexFlow: 'row nowrap',
    '&[data-is-button=true]': {
      alignItems: 'center',
      marginBottom: 10
    }
  },
  filterHeader: {
    flexShrink: 0,
    marginRight: 20,
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  filterButton: {
    '& > button': {
      borderRadius: 5,
      border: `1px solid ${theme.grey7}`,
      minWidth: 300
    }
  },
  filterItems: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  filterItem: {
    marginRight: 15,
    marginBottom: 13,
    display: 'flex',
    flexFlow: 'column nowrap',
    cursor: 'pointer'
  },
  filterItemContent: {
    margin: '0px 2px',
    fontSize: 14,
    fontWeight: 'normal',
    textTransform: 'none',
    letterSpacing: 0,
    color: theme.grey1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&[data-is-active=true]': {
      fontWeight: 'bold'
    }
  },
  allGroupsIcon: {
    marginRight: 5,
    width: 22,
    flexShrink: 0
  },
  groupAvatar: {
    marginRight: 5,
    flexShrink: 0
  },
  filterItemUnderline: {
    marginTop: 3,
    width: '100%',
    height: 5,
    borderRadius: 10,
    '&[data-is-active=true]': {
      backgroundColor: theme.teal1
    }
  },
  listContainer: {
    minHeight: 300,
    position: 'relative'
  },
  emptyListMessage: {
    fontSize: 14,
    textAlign: 'center',
    color: theme.grey1
  },
  emptyListMessageGroupName: {
    color: theme.teal1
  },
  listInfoContainer: {
    height: 300,
    width: '100%',
    opacity: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingListIcon: {
    margin: 0
  }
});
