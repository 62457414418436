export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 100px 0 50px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 80,
      left: 0,
      width: 90,
      height: 1,
      backgroundColor: theme.grey6,
      zIndex: -1
    }
  },
  pullDownContainer: {
    padding: '33px 0 17px 0'
  }
});
