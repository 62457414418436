import { memo, useCallback, useState } from 'react';
import { compose } from 'react-apollo';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import Path from 'path-to-regexp';
import { History } from 'history';

import { ModalActions, Pushbutton } from '@stratumn/atomic';

import { ROUTE_NEW_LINK } from 'constant/routes';

import GroupActionsList, { Link, PrioritySettings } from './groupActionsList';
import { NextAction, Tasks } from './actionList.types';
import styles from './actionsList.style';

interface ActionListProps {
  classes: any;
  history: History;
  workflowId: string;
  nextActions?: NextAction[];
  tasks?: Tasks | null;
  message?: string;
  traceIds?: string[];
  toggleTray?: () => void;
}

export const ActionsList = memo(
  ({
    classes,
    history,
    workflowId,
    nextActions = [],
    tasks = null,
    message = '',
    traceIds = [],
    toggleTray = () => {}
  }: ActionListProps) => {
    const [selectedLink, setSelectedLink] = useState<Partial<Link>>({});

    const actionsHandler = useCallback(() => {
      const { groupKey, actionKey } = selectedLink;

      const baseUrl = Path.compile(ROUTE_NEW_LINK)({
        wfid: workflowId
      });
      return history.push(
        `${baseUrl}?groupKey=${groupKey}&actionKey=${actionKey}${
          traceIds !== null ? `&traceIds=${traceIds.join(',')}` : ''
        }`,
        {
          from: history.location,
          goToTraceInspector: traceIds.length === 0
        }
      );
    }, [selectedLink, workflowId, history, traceIds]);

    const usedNextActions = nextActions || [];

    const getPrioritySettings = (
      nextAction: NextAction
    ): PrioritySettings | undefined => {
      if (!tasks || !tasks.groups) return undefined;
      const todos = tasks.groups?.[nextAction.group?.label]?.todo;
      if (!todos?.length) return undefined;

      return {
        priorityActionKey: todos,
        priorityGroupLabel: nextAction.group.label // if we are here, then this label is in the tasks groups
      };
    };

    return (
      <div className={classes.actionsWrapper}>
        <div className={classes.actionsBody}>
          <div className={classes.actionsMessage}>{message}</div>
          {usedNextActions.map(nextAction => (
            <GroupActionsList
              key={nextAction.group.label}
              groupActions={nextAction}
              priority={getPrioritySettings(nextAction)}
              selectedLink={selectedLink}
              setSelectedLink={setSelectedLink}
              initDisplay={
                usedNextActions.length <= 3 ||
                nextAction.group.label ===
                  getPrioritySettings(nextAction)?.priorityGroupLabel
              } // note: "3"... ask product
            />
          ))}
        </div>
        <div className={classes.actionsFooter}>
          <ModalActions>
            <Pushbutton dataCy="cancel" secondary onClick={() => toggleTray()}>
              cancel
            </Pushbutton>
            <Pushbutton
              primary
              dataCy="submit"
              onClick={() => actionsHandler()}
              disabled={!selectedLink.groupKey || !selectedLink.actionKey}
            >
              Perform action
            </Pushbutton>
          </ModalActions>
        </div>
      </div>
    );
  }
);

export default compose(injectSheet(styles), withRouter)(ActionsList);
