import stringify from 'csv-stringify';
import moment from 'moment';
import { downloadExcel } from 'react-export-table-to-excel';

import { saveBlob } from 'utils';
import { getStringifyFunction as getWidgetStringifyFunction } from 'components/ui/widget';

/**
 * we use the module csv-stringify
 * options: https://csv.js.org/stringify/options/
 *
 * Stringify's data is return in a callback.
 * We create a promise to wait for the response.
 */
const toCsv = (columns, rows, options) =>
  new Promise((resolve, reject) => {
    const { delimiter } = options;

    stringify(
      rows,
      {
        header: true,
        columns: columns,
        delimiter: delimiter || ';'
      },
      (err, data) => {
        if (err) reject(err);
        resolve(data);
      }
    );
  });

export const exportDataTable = async (
  generatePayload,
  displayConfig,
  csvOptions,
  workflowContext,
  fileType
) => {
  const { fixedColumns, columns } = displayConfig;

  // function to build a column export configuration
  const buildColumnExportConfig = column => {
    const {
      def: { header, cell }
    } = column;
    return {
      header,
      toString: getWidgetStringifyFunction(cell || {}, workflowContext)
    };
  };

  // read columns configuration to build the export function
  const columnsStringifyConfig = [
    ...fixedColumns.map(buildColumnExportConfig),
    ...columns.map(buildColumnExportConfig)
  ];

  // Prepare columns header
  const headers = columnsStringifyConfig.map(c => c.header);

  // Prepare rows
  const rowPayloadFn = row => columnsStringifyConfig.map(c => c.toString(row));
  const payload = await generatePayload(rowPayloadFn);

  if (fileType === 'csv') exportDataToCsv(headers, payload, csvOptions);
  if (fileType === 'excel') exportDataToExcel(headers, payload, csvOptions);
};

/**
 * @description Export data to csv by reading a table display config
 * @param {function} generatePayload a function that will apply the single row parsing to all the exportable data
 * @param {object} displayConfig table columns configuration (widgets)
 * @param {object} csvOptions
 * @param {string} csvOptions.delimiter csv delimiter
 * @param {string} csvOptions.filename csv output file name
 * @param {object} workflowContext context to translate workflow info such as group labels to names...
 */
export const exportDataToCsv = async (headers, payload, csvOptions) => {
  const csvString = await toCsv(headers, payload, csvOptions);

  const fileName = `${moment().format('YYYY-MM-DD')}-${
    csvOptions.filename
  }.csv`;
  // Make csv compatible with excel using a BOM
  // https://en.wikipedia.org/wiki/Byte_order_mark
  // https://github.com/silverwind/save-csv/blob/v4.1.0/save-csv.js#L92
  saveBlob(`\ufeff${csvString}`, fileName);
};

export const exportDataToExcel = async (headers, payload, csvOptions) => {
  await downloadExcel({
    fileName: `${moment().format('YYYY-MM-DD')}-${csvOptions.filename}`,
    sheet: `${moment().format('YYYY-MM-DD')}-${csvOptions.filename}`,
    tablePayload: {
      header: headers,
      body: payload
    }
  });
};
