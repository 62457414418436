import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import WorkflowRow, { fragments as workflowRowFragments } from './row';

import styles from './workflowsList.style';

// workflows list of the user dashboard
export const WorkflowsList = React.memo(
  ({ classes, className, workflows, isSuperuser, userId }) => {
    const { nodes: workflowsList = [] } = workflows;

    const columnsClassNames = useMemo(
      () => ({
        columnName: classes.columnName,
        columnTraces: classes.columnTraces,
        columnGroups: classes.columnGroups,
        columnDocumentation: classes.columnDocumentation,
        columnActions: classes.columnActions
      }),
      [classes]
    );

    const workflowsListContent =
      workflowsList.length > 0 ? (
        <table className={classes.workflowsTable}>
          <thead>
            <tr>
              <th className={columnsClassNames.columnName}>Name</th>
              <th className={columnsClassNames.columnTraces}>Traces</th>
              <th className={columnsClassNames.columnGroups}>Groups</th>
              <th className={columnsClassNames.columnDocumentation}>
                Workflow documentation
              </th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th className={columnsClassNames.columnActions} />
            </tr>
          </thead>
          <tbody>
            {workflowsList.map(w => (
              <WorkflowRow
                key={w.rowId}
                columnsClassNames={columnsClassNames}
                workflow={w}
                isSuperuser={isSuperuser}
                userId={userId}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div className={classes.listInfoContainer}>
          <div className={classes.emptyListMessage}>No workflow.</div>
        </div>
      );

    return (
      <div className={classnames(className, classes.container)}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            Your workflows
            <div className={classes.headerTitleUnderline} />
          </div>
        </div>
        <div className={classes.listContainer}>{workflowsListContent}</div>
      </div>
    );
  }
);

WorkflowsList.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  workflows: PropTypes.object,
  isSuperuser: PropTypes.bool,
  userId: PropTypes.string.isRequired
};
WorkflowsList.defaultProps = {
  className: '',
  workflows: {},
  isSuperuser: false
};

export default injectSheet(styles)(WorkflowsList);

// export the workflows table query
export const fragments = {
  workflows: gql`
    fragment WorkflowsFragment on Query {
      workflows(filter: { draft: { equalTo: false } }) {
        totalCount
        nodes {
          rowId
          ...WorkflowRowFragment
        }
      }
    }
    ${workflowRowFragments.workflowRow}
  `
};
