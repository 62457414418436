export default theme => ({
  groupAvatar: {
    lineHeight: 'normal',
    marginRight: 10
  },
  groupName: {
    flexGrow: 1,
    outline: 'none',
    padding: '0px 10px',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '30px',
    textTransform: 'capitalize',
    transition: 'background-color 0.2s',
    overflow: 'hidden',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: theme.grey7,
      outline: `1px solid ${theme.grey7}`
    },
    '&:focus': {
      backgroundColor: theme.white1,
      outline: `1px solid ${theme.indigo2}`
    }
  }
});
