import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { Modal, ModalContent, Pushbutton } from '@stratumn/atomic';

import { setAtPath, formatNumber } from 'utils';

import { Widget, getEditedPath } from 'components/ui/widget';

import styles from './batchEditModal.style';

const modalData = {};
export const BatchEditModal = React.memo(
  ({ classes, selectedRows, tableConfig, onSubmit, onCancel }) => {
    const { columns } = tableConfig;

    const [modalPatch, setModalPatch] = useState({});

    const editableCells = useMemo(
      () =>
        columns.reduce((prevEditableCells, column) => {
          const { cell = {}, header, key } = column;
          const editedPath = getEditedPath(cell);
          if (editedPath !== null) {
            prevEditableCells.push({
              key,
              header,
              widget: cell,
              editedPath
            });
          }
          return prevEditableCells;
        }, []),
      [columns]
    );

    const editableCellsUpdates = useMemo(
      () =>
        editableCells.map(cell => {
          const { header } = cell;

          return {
            patch: modalPatch,
            onChange: dataToChange => {
              const {
                path: modifiedDataPath,
                value: modifiedDataValue
              } = dataToChange;

              const newModalPatch = {
                ...modalPatch
              };

              // if value is empty ("") unset the patch at this path
              // ie batch won't update selected rows at this path
              setAtPath(
                newModalPatch,
                modifiedDataPath,
                modifiedDataValue === '' || modifiedDataValue === null
                  ? undefined
                  : modifiedDataValue
              );

              // update modal patch
              setModalPatch(newModalPatch);
            },
            editorOverrides: {
              header
            }
          };
        }),
      [editableCells, modalPatch]
    );

    const submitPatch = useCallback(() => {
      onSubmit(
        modalPatch,
        editableCells.map(cell => cell.editedPath)
      );
    }, [editableCells, modalPatch]);

    return (
      <Modal
        handleCollapse={onCancel}
        hideCloseButton
        title={
          <div className={classes.modalTitle}>
            <div>Edit selection</div>
            <div className={classes.modalTitleNbRows}>{`${formatNumber(
              selectedRows.length
            )} row${selectedRows.length > 1 ? 's' : ''} selected`}</div>
          </div>
        }
      >
        <ModalContent>
          <div className={classes.modalBody}>
            <div className={classes.modalBodyHeader}>
              Bulk edit the following columns values:
            </div>
            <div className={classes.modalBodyCells}>
              {editableCells.map((cell, cellIdx) => {
                const { key, widget } = cell;
                return (
                  <div key={key} className={classes.modalBodyCell}>
                    <Widget
                      className={classes.modalItemWidget}
                      widget={widget}
                      data={modalData}
                      update={editableCellsUpdates[cellIdx]}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </ModalContent>
        <div className={classes.modalActions}>
          <Pushbutton onClick={onCancel}>Cancel</Pushbutton>
          <Pushbutton
            primary
            onClick={submitPatch}
            disabled={Object.keys(modalPatch).length === 0}
            dataCy="confirm-batch-edit"
          >
            Edit selected rows
          </Pushbutton>
        </div>
      </Modal>
    );
  }
);
BatchEditModal.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableConfig: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

BatchEditModal.defaultProps = {};

export default injectSheet(styles)(BatchEditModal);
