export default theme => ({
  label: {
    margin: 3,
    color: theme.white1,
    backgroundColor: theme.grey1,
    borderRadius: 2,
    padding: '2px 5px',
    fontSize: 14,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  removeIcon: {
    flexShrink: 0,
    marginLeft: 7,
    cursor: 'pointer',
    color: theme.white1
  }
});
