export default theme => ({
  uploadFileContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0 10px',
    cursor: 'pointer'
  },
  uploadFileInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    color: '#000'
  },
  uploadFileBtn: {
    color: theme.grey3,
    '&:hover': {
      color: theme.indigo3
    },
    '& svg': {
      width: '20px'
    }
  },
  editableIcon: {
    marginLeft: 5,
    flexShrink: 0,
    height: 17,
    width: 17,
    margin: '0 2px',
    color: theme.grey4,
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      color: theme.indigo3
    }
  },
  noCursor: {
    cursor: 'not-allowed'
  },
  fileView: {
    color: '#34323A',
    height: '100%',
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'start',
    cursor: 'pointer'
  },
  fileName: {
    flexGrow: 1,
    overflow: 'hidden',
    marginLeft: 5,
    textDecoration: 'underline',
    lineHeight: '25px'
  }
});
