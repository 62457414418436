export default {
  statusView: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingInline: '5px'
  },
  status: {
    display: 'flex',
    gap: '11px',
    alignItems: 'center',
    width: '100%'
  },
  statusValue: {
    flexGrow: 1,
    marginBottom: '-2px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0
  }
};
