import { StatusView } from '../status.types';

type Props = {
  size: number;
  color?: StatusView.HexaColor;
};
export const TodoProgress = ({ size, color = '#95999f' }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none">
      <circle
        cx="7"
        cy="7"
        r="6"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeDasharray="1.4 1.74"
        strokeDashoffset="0.65"
      />
      <circle
        cx="7"
        cy="7"
        r="2"
        fill="none"
        stroke={color}
        strokeWidth="4"
        strokeDasharray="0 100"
        strokeDashoffset="0"
        transform="rotate(-90 7 7)"
      />
    </svg>
  );
};
