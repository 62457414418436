export default () => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dropdown: {
    margin: '0px 5px',
    width: 65
  }
});
