export default theme => ({
  whoDidWhatWhen: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '18px',
    color: theme.grey3,
    gap: '10px'
  },
  whenDate: {
    fontWeight: 700,
    paddingRight: 7
  },
  whoDidWhat: {
    background: '#f4f4f6',
    color: '#2C2F35',
    padding: '5px 10px',
    borderRadius: '14px',
    lineHeight: '10px',
    height: 21,
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    whiteSpace: 'nowrap'
  }
});
