import React, { useContext } from 'react';

import { Pushbutton } from '@stratumn/atomic';

import { multipleFileDownload } from 'utils/downloadFile/multipleFileDownload';
import { TicketContext } from 'components/ui/ticketBar';

import { useSelectableItemsContext } from '..';

const useActionHandler = () => {
  const { setTickets } = useContext(TicketContext);

  const selectedItems = useSelectedItems();

  const {
    selectableConfig,
    setIdsCompleted,
    handleSelectAll,
    wrapperData
  } = useSelectableItemsContext();

  switch (selectableConfig.action?.type) {
    case 'download':
      return async () => {
        const requestBody = {
          files: [
            {
              trace_uuid: wrapperData.trace.rowId,
              digests: selectedItems.map(item => item.data.digest)
            }
          ],
          workflow_id: wrapperData.meta?.head.process.name
        };

        const ticket = await multipleFileDownload(requestBody);

        setTickets(currentTickets => [
          ...currentTickets,
          {
            ticket_id: ticket,
            status: 'pending'
          }
        ]);

        setIdsCompleted(selectedItems.map(item => item.id));
        handleSelectAll(false);
      };
    case undefined:
      return () => {
        console.error(`Selectable config action is not defined`);
      };
    default:
      return () => {
        console.error(
          `Selectable config action type "${selectableConfig.action?.type}" is not defined`
        );
      };
  }
};

// const getIsDisabled = treeValue => {
//   if (treeValue.selected) {
//     return false;
//   }
//   return treeValue.children.reduce((acc, child) => {
//     if (child.selected) return false;
//     return getIsDisabled(child);
//   }, true);
// };

const reduceValuesByType = type => (previousValues, treeItem) => {
  const value =
    treeItem.type === type && treeItem.selected
      ? [...previousValues, { data: treeItem.data, id: treeItem.id }]
      : previousValues;
  return treeItem.children.reduce(reduceValuesByType(type), value);
};

const getSelectedValuesOfType = (treeValue, type) => {
  const value =
    treeValue.type === type && treeValue.selected
      ? [{ data: treeValue.data, id: treeValue.id }]
      : [];
  return treeValue.children.reduce(reduceValuesByType(type), value);
};

const useSelectedItems = () => {
  const { selectableConfig, treeValue } = useSelectableItemsContext();

  switch (selectableConfig.action?.type) {
    case 'download':
      return getSelectedValuesOfType(treeValue, 'file');
    default:
      return [];
  }
};

const ActionButton = () => {
  const selectableItemsContext = useSelectableItemsContext();
  // Directly return null if the item is not wrapped within a selectableItems context
  if (!selectableItemsContext) return null;

  const { selectableConfig } = selectableItemsContext;

  const selectedItems = useSelectedItems();
  const isDisabled = !selectedItems?.length;

  const handleClick = useActionHandler();

  return (
    <div>
      <Pushbutton
        primary={!isDisabled}
        disabled={isDisabled}
        onClick={handleClick}
      >
        {selectableConfig.action?.label}
      </Pushbutton>
    </div>
  );
};

export default ActionButton;
