import to from 'await-to-js';

import {
  DATA_PARSING_STATUS_DONE,
  DATA_PARSING_STATUS_ERROR
} from 'constant/dataParsing';

import { parseCsv } from '.';

// eslint-disable-next-line
import CsvParserWorker from 'worker-loader!utils/csv/csv.worker';

// create the csv parser worker
let csvParserWorker = null;
if (window.Worker) {
  csvParserWorker = new CsvParserWorker();
}

// run the csv parsing by checking if workers are enabled and handling both cases
export const runCsvParser = async inputs => {
  // check if we can use a webworker for this
  if (csvParserWorker) {
    parseCsvUsingWorker(inputs);
    return;
  }
  await awaitParseCsv(inputs);
};

// if no webworker run the parseCsv function awaiting for its result before returning
const awaitParseCsv = async inputs => {
  const {
    csvInput,
    csvParserConfig,
    mappingConfig,
    fileEncoding,
    beforeDataHooks,
    onError = () => {},
    onSuccess = () => {}
  } = inputs;

  // run the parsing function and await
  // don't provide reporting config as we'll await for the result
  const [err, parsingResult] = await to(
    parseCsv(
      csvInput,
      csvInput instanceof File,
      beforeDataHooks,
      csvParserConfig,
      mappingConfig,
      undefined,
      undefined,
      fileEncoding
    )
  );

  // catch potential errors of the parseCsv function
  if (err) {
    onError(err.message);
    return;
  }

  // callback in case of success
  onSuccess(parsingResult);
};

// if webworker run the parseCsv function without awaiting but attach worker messages handling
const parseCsvUsingWorker = async inputs => {
  const {
    onError = () => {},
    onSuccess = () => {},
    onReport = () => {},
    ...rest
  } = inputs;

  // run the worker
  csvParserWorker.postMessage({
    ...rest,
    isFile: inputs.csvInput instanceof File // note: Safari does not support instanceof File inside workers...
  });

  // attach messages handling
  // respond to updates from the worker
  csvParserWorker.onmessage = event => {
    const { status, step, message, progress, parsingResult } = event.data;
    switch (status) {
      case DATA_PARSING_STATUS_ERROR:
        // catch the error
        onError(message);
        // stop listening to worker messages
        csvParserWorker.onmessage = null;
        break;
      case DATA_PARSING_STATUS_DONE:
        // complete the data parsing
        onSuccess(parsingResult);
        // stop listening to worker messages
        csvParserWorker.onmessage = null;
        break;
      default:
        // intermediate reporting
        // set the state to parsing and report the progress
        onReport(status, step, progress);
    }
  };
};
