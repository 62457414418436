import moment from 'moment';

const isToday = d => {
  const now = moment();
  return (
    d.date() === now.date() &&
    d.month() === now.month() &&
    d.year() === now.year()
  );
};

export const getNow = () => moment().toISOString();

export const formatDate = date => {
  const d = moment.utc(date).local();

  if (!isToday(d)) {
    return d.format('D MMM');
  }

  return d.format('H:mm');
};

export const shortFormat = date => moment.utc(date).local().format('D.MM.YY');

export const formatDateWithoutSeconds = date => {
  const newdate = moment(date).format('DD MMMM YYYY LT');
  return newdate;
};
