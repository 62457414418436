import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import DataImporterReader from './dataImporterReader';
import DataEditorReader from './dataEditorReader';

import styles from './dataActionReader.style';

export const DataActionReader = React.memo(({ classes, action, data }) => {
  // switches between dataEditorReader and dataImporter reader and factorizes commments
  const dataTool = action.dataImporter || action.dataEditor;

  // comments data
  const { commentsKey = 'comments', commentsTitle = 'Comments' } =
    dataTool || {};
  const { [commentsKey]: comments } = data;

  return (
    <div className={classes.readerBody}>
      {action.dataImporter ? (
        <DataImporterReader
          classes={classes}
          dataImporter={dataTool}
          data={data}
        />
      ) : (
        <DataEditorReader classes={classes} dataEditor={dataTool} data={data} />
      )}
      {comments && (
        <div className={classes.readerItem}>
          <div className={classes.readerItemTitle}>{commentsTitle}</div>
          <div className={classes.readerItemValue}>{comments}</div>
        </div>
      )}
    </div>
  );
});
DataActionReader.propTypes = {
  classes: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

DataActionReader.defaultProps = {};

export default injectSheet(styles)(DataActionReader);
