export default theme => ({
  footer: {
    display: 'flex',
    gap: '24px',
    padding: '3px 12px',
    position: 'absolute',
    bottom: 0,
    fontSize: 10,
    color: theme.indigo1,
    '& a': {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
});
