/**
 * @desc format a number to a string
 * @desc defaults to toLocaleString for now but could support additional features later
 * @desc see https://www.w3schools.com/jsref/jsref_tolocalestring_number.asp for all the options
 * @param {number} nbInput - the input to display as a number
 * @param {object} formatDesc - an object holding the parameters of the formatting
 * @returns {string} the formatted number string
 */
export const formatNumber = (nbInput, formatDesc) => {
  // if input is not a real number just return an empty string
  const nb = Number(nbInput);
  if (Number.isNaN(nb)) return '';

  // format description should be an object if it's not the case fallback on default settings
  const usedFormat = typeof formatDesc === 'object' ? formatDesc : {};
  const { locale, options } = usedFormat;

  const language = locale || (navigator && navigator.language) || 'en-US';

  return nb.toLocaleString(language, options);
};
