import CsvParse from 'csv-parse';
import parseDecimalNumber from 'parse-decimal-number';
import moment from 'moment';

import {
  DATA_PARSING_STATUS_DONE,
  DATA_PARSING_STATUS_ERROR,
  DATA_PARSING_STATUS_PARSING,
  PARSING_STEP_IMPORTING,
  PARSING_STEP_OPENING
} from 'constant/dataParsing';

const castToBoolean = value => {
  switch (value.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(value);
  }
};

const reportErrorAndThrow = (err, reportingFn) => {
  reportingFn({
    status: DATA_PARSING_STATUS_ERROR,
    message: err
  });
  throw new Error(err);
};

// Interface to call the csv-parse library

// The way the csv-parse lib works is that you pass all the callbacks
// fired when reading first line, when reading each element, when finishing a whole line etc...
// to an 'option' parameter on the CsvParse function
// so we create a standalone function here to build this object with functions bound to the evaluation parameters
// ie all the parsing, mapping and reporting configs
const prepareCsvParsingSetup = (
  expectedNbOfLines,
  parserConfig,
  mappingConfig,
  reportingConfig,
  reportingFn
) => {
  const { columns: mappedColumns } = mappingConfig;

  // reportStep indicates parsing steps that trigger a message sent
  // to the function caller
  const { reportStep = 0.05 } = reportingConfig;

  // expected number of lines and reporting steps
  const reportingBatchSize = Math.ceil(reportStep * expectedNbOfLines);
  let nextReportingStep = 0;

  // prepare columns parsing setup
  // this is an object that provide fast access to a columnMap which gives direct to 'from'=>'to'
  // and to a parser functions map that gives fast acces to 'to' => parser(value)
  // this is used to speedup execution of the CsvParse callbacks
  const setup = !mappedColumns
    ? {
        columnsParsers: {} // default no parser and no list of mapped columns so we can later fallback on picking up all the columns
      }
    : mappedColumns.reduce(
        (currentSetup, column) => {
          const { columnsMap, columnsParsers } = currentSetup;
          const { from, to, parser: columnParserConfig } = column;

          // check if this mapping has already been defined
          if (Object.values(columnsMap).includes(to)) {
            reportErrorAndThrow(
              [
                'Error mapping csv columns to data fields',
                'Several columns map to the same field:',
                to
              ].join('\n'),
              reportingFn
            );
          }
          // else create a new mapping
          columnsMap[from] = to;

          let parser;
          if (columnParserConfig) {
            const parserType = columnParserConfig.type.toLowerCase();
            switch (parserType) {
              case 'number': {
                const { delimiters } = columnParserConfig;
                // if delimiters have been specified (ie non standard number str format)
                // just use them to reformat the string
                // otherwise cast to Number directly, removing commas that might be used as thousands separators ("en" format)
                parser = value =>
                  parseDecimalNumber(
                    value,
                    delimiters || { thousands: ',', decimals: '.' }
                  );
                break;
              }
              case 'date': {
                const { inputFormat, outputFormat } = columnParserConfig;
                parser = value =>
                  moment.utc(value, inputFormat).format(outputFormat);
                break;
              }
              case 'boolean': {
                parser = castToBoolean;
                break;
              }
              default:
            }
          }
          columnsParsers[to] = parser;

          return currentSetup;
        },
        {
          columnsMap: {},
          columnsParsers: {}
        }
      );

  // csv-parse options object instantiating all the callbacks
  const { columnsMap, columnsParsers } = setup;
  const foundColumns = [];
  const parserOptions = {
    on_record: (record, context) => {
      const { lines } = context;
      if (lines >= nextReportingStep) {
        // report the current status
        reportingFn({
          status: DATA_PARSING_STATUS_PARSING,
          step: PARSING_STEP_IMPORTING,
          progress: nextReportingStep / expectedNbOfLines
        });
        // update the next reporting step
        nextReportingStep += reportingBatchSize;
      }
      return record;
    },
    columns: firstLine => {
      // check if we have a predefined list of the columns we want to export and the name of the keys to use in the constructed objects
      if (columnsMap) {
        return firstLine.map(line => columnsMap[line]);
      }
      foundColumns.push(...firstLine); // if no mapping fallback on taking all the columns
      return firstLine;
    },
    cast: (value, context) => {
      // apply the parser for this column if specified
      if (value === '') return undefined;
      const parser = columnsParsers[context.column];
      if (parser) return parser(value);
      return value;
    },
    ...parserConfig
  };

  return { parserOptions, foundColumns };
};

// parse a csv by reading the parser / mapping / reporting configurations
// by default take all the columns found and keep the column names
// this calls the csv-parse library, binding the configurations documents to the library callbacks first in 'prepareCsvParsingSetup'
// and handling final result reporting using {resolve, reject} parametr provided by the caller
const parseCsvString = (
  csvString,
  beforeDataHooks,
  parserConfig,
  mappingConfig,
  reportingConfig,
  reportingFn,
  { resolve, reject }
) => {
  // Apply hooks
  let csvStringModified = csvString;
  let mappingConfigModified = mappingConfig;
  let hookOutput;
  try {
    beforeDataHooks.forEach(hook => {
      // eslint-disable-next-line no-eval
      const functTest = eval(hook);
      hookOutput = functTest({
        csvStringModified: csvStringModified,
        mappingConfigModified: mappingConfigModified
      });
      csvStringModified = hookOutput?.csvStringModified || csvStringModified;
      mappingConfigModified =
        hookOutput?.mappingConfigModified || mappingConfigModified;
    });
  } catch (err) {
    reportingFn({
      status: DATA_PARSING_STATUS_ERROR,
      message: err.message
    });
    reject(new Error(err.message));
    return;
  }
  // prepare the csv parser options
  const expectedNbOfLines = csvStringModified.split('\n').length - 1;
  const { parserOptions, foundColumns } = prepareCsvParsingSetup(
    expectedNbOfLines,
    parserConfig,
    mappingConfig,
    reportingConfig,
    reportingFn
  );

  // start the parsing
  CsvParse(csvStringModified, parserOptions, (err, data) => {
    if (err) {
      // report the error and reject
      reportingFn({
        status: DATA_PARSING_STATUS_ERROR,
        message: err.message
      });
      reject(new Error(err.message));
      return;
    }
    // report the % status before
    reportingFn({
      status: DATA_PARSING_STATUS_PARSING,
      step: PARSING_STEP_IMPORTING,
      progress: 1
    });
    // report the completion of the parsing
    const parsingResult = {
      data,
      foundColumns: foundColumns.length > 0 ? foundColumns : null
    };
    reportingFn({
      status: DATA_PARSING_STATUS_DONE,
      parsingResult
    });
    resolve(parsingResult);
  });
};

// csv parsing interface
// this is the function accessible to the oustside world
// csvInput can be a file or a string
// so the function reads the file first and report if need be
// it is interfaced as a promise
export const parseCsv = async (
  csvInput,
  isFile,
  beforeDataHooks = [],
  csvParserConfig = {},
  mappingConfig = {},
  reportingConfig = {},
  reportingFn = () => {},
  fileEncoding = 'utf8'
) =>
  new Promise((resolve, reject) => {
    // bind the function to run the parser
    const doParse = csvString =>
      parseCsvString(
        csvString,
        beforeDataHooks,
        csvParserConfig,
        mappingConfig,
        reportingConfig,
        reportingFn,
        { resolve, reject }
      );

    // check if this is a string or a file
    if (isFile) {
      // warn client that we are opening the file
      reportingFn({
        status: DATA_PARSING_STATUS_PARSING,
        step: PARSING_STEP_OPENING,
        progress: 0
      });
      // setup the file reader
      const reader = new FileReader();
      reader.onload = () => {
        doParse(reader.result);
      };
      reader.onerror = err => {
        reportingFn({
          status: DATA_PARSING_STATUS_ERROR,
          message: err.message
        });
        reject(new Error(err.message));
      };
      reader.readAsText(csvInput, fileEncoding);
      return;
    }

    // otherwise directly parse the csv string
    if (typeof csvInput !== 'string') {
      const errorMessage = 'Provided csv input is not a string !';
      reportingFn({
        status: DATA_PARSING_STATUS_ERROR,
        message: errorMessage
      });
      reject(new Error(errorMessage));
      return;
    }
    doParse(csvInput);
  });
