export const FORMS_WIDTH = 520;
export const FORMS_MARGIN_RIGHT = 20;

export default theme => ({
  form: {
    flexShrink: 0,
    width: FORMS_WIDTH,
    borderRadius: 5,
    backgroundColor: theme.white1,
    position: 'relative',
    padding: '16px 14px',
    marginRight: FORMS_MARGIN_RIGHT,
    border: `1px solid transparent`,
    transition: 'border-color 400ms',
    '&[data-is-main=true]': {
      position: 'sticky',
      zIndex: 3, // note: set at least to 3 because some fields in atomic/forms have zIndex:2 ...
      left: 0,
      flexShrink: 0,
      boxShadow: theme.indigoShadow2
    },
    '&[data-error-focus=true]': {
      borderColor: theme.warningRed
    }
  },
  formHeader: {
    width: '100%',
    height: 25,
    color: theme.grey3,
    fontSize: 10,
    lineHeight: '13px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'relative'
  },
  closeFormIcon: {
    color: theme.grey1,
    cursor: 'pointer',
    position: 'absolute',
    right: '-5px'
  },
  shadowSubmitBtn: {
    display: 'none'
  }
});
