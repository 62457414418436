export default theme => ({
  input: {
    border: `4px solid ${theme.grey5}`,
    borderRadius: 2,
    backgroundColor: theme.grey9,
    color: theme.grey2,
    padding: '5px 10px 5px 10px',
    width: '20%',
    outline: 'none',
    fontSize: 14,
    '&:focus': {
      borderColor: theme.indigo3
    },
    '&::placeholder': {
      color: theme.grey4
    }
  },
  inputDescription: {
    fontSize: 20,
    fontWeight: 400,
    color: theme.grey2,
    marginBottom: 5
  },
  passwordContainer: {
    textAlign: 'center',
    margin: '20px 20px'
  },
  keyIcon: {
    width: '20%',
    height: '20%'
  },
  badPassword: {
    width: '20%',
    height: '20%',
    color: 'red',
    animation: 'spinnerAnimation 0.4s linear'
  },
  '@keyframes spinnerAnimation': {
    '20%, 60%': {
      transform: 'translate(-15px)'
    },
    '40%, 80%': {
      transform: 'translate(15px)'
    }
  }
});
