import i18next from 'i18next';
import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%ds',
    m: '1min',
    mm: '%dmin',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy'
  }
});

// In prevision of the day we have multi language,
// TODO:
// - get lng from the user's cookies or something
// - load translation resources from somewhere

const options = {
  lng: 'en',
  keySeparator: '>>>',
  nsSeparator: '__'
};

i18next.init(options);

export default i18next;
