export default theme => ({
  content: {
    padding: 20,
    paddingTop: 50,
    width: '100%'
  },
  title: {
    fontSize: 20,
    lineHeight: 2.5,
    fontWeight: 600
  },
  description: {
    fontSize: 16
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    margin: '25px 0'
  },
  field: {
    marginRight: 20
  },
  icon: {
    color: theme.indigo3,
    display: 'flex',
    alignItems: 'center'
  },
  radioButtons: {
    padding: '10px',
    marginBottom: '10px',
    '&>label ': {
      margin: '5px 0'
    }
  }
});
