export default theme => ({
  container: {
    width: '100%',
    marginTop: 35,
    height: 'calc(100vh - 105px)',
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    backgroundColor: theme.grey8,
    scrollbarGutter: 'stable',
    ...theme.scrollbar,
    marginRight: 2
  },
  answerContainer: {
    width: '100%',
    overflowY: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 20,
    paddingTop: 10,
    borderTop: `solid 1px ${theme.grey7}`
  },
  formBody: {
    width: 'calc(100vw - 50px)',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    position: 'relative'
  },
  answerFormBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    position: 'relative'
  },
  form: {
    backgroundColor: theme.white1,
    padding: 20,
    margin: '20px',
    marginBottom: '100px',
    width: '48%',
    height: '100%',
    boxSizing: 'border-box',
    background: theme.white1,
    border: `1px solid ${theme.grey7}`,
    borderRadius: 5,
    '&[data-has-traceinfo=true]': {
      maxWidth: 600,
      minWidth: 300
    }
  },
  answerForm: {
    backgroundColor: theme.white1,
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    borderRadius: 2,
    '&[data-has-traceinfo=true]': {
      maxWidth: 600,
      minWidth: 300
    }
  },
  traceInfo: {
    margin: '20px',
    backgroundColor: 'inherit',
    width: '48%',
    borderRadius: 2,
    minWidth: 300
  },
  actionButtonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 50
  },
  actionButton: {
    marginRight: 3.5,
    marginLeft: 3.5
  },
  dataToolBody: {
    backgroundColor: theme.grey8,
    top: '35px',
    position: 'relative',
    height: 'calc(100vh - 105px)',
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between'
  },
  formsBody: {
    backgroundColor: theme.grey8,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between'
  },
  traysContainer: {
    position: 'fixed',
    zIndex: 5,
    top: 0,
    bottom: 0,
    right: 0,
    width: 'auto',
    display: 'flex',
    flexFlow: 'row-reverse nowrap',
    '&[data-is-left=true]': {
      right: 'unset',
      left: 0,
      flexFlow: 'row nowrap'
    }
  },
  traceInfoTray: {
    fontSize: 13,
    lineHeight: 1.25,
    color: theme.grey1,
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%'
  },
  traceInfoTrayBody: {
    flexGrow: 1,
    overflow: 'auto'
  },
  traceInfoTrayContent: {
    padding: 10
  },
  traceInfoTrayFooter: {
    flexShrink: 0,
    height: 70,
    width: '100%',
    padding: 14,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  traceInfoTrayActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  footer: {
    position: 'relative',
    width: '100%',
    alignSelf: 'end'
  }
});
