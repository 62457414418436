export default () => ({
  segmentContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20
  },
  loader: {
    width: 40,
    height: 40,
    margin: '60px auto'
  }
});
