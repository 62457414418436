import background from 'assets/IndigoTrace_Map.svg';

export default () => ({
  root: {
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    minHeight: 'calc(100vh - 68px)',
    minWidth: 'min-content',
    display: 'flex'
  },
  indigo: {
    backgroundColor: 'rgba(46, 18, 139, 1)',
    backgroundImage: `url(${background})`
  },
  white: {
    backgroundImage: `url(${background})`
  }
});
