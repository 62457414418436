import { MEDIA_API_URL } from 'constant/api';

/**
 * @function multipleFileDownload
 * @param {array} data // {fileRequest: [{trace_uuid: "", digest: ["", "" ... ]} ... ]}
 */
export const multipleFileDownload = async data => {
  const ticketId = await fetch(`${MEDIA_API_URL}/files/zip`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(data)
  });

  const { id } = await ticketId.json();

  return id;
};
