export default theme => ({
  colHeadersEditor: {
    width: '100%',
    fontSize: 10,
    letterSpacing: 1,
    color: theme.grey3,
    textAlign: 'left',
    borderBottom: `2px solid ${theme.grey5}`,
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    position: 'relative'
  },
  colHeadersEditorSelectBoxArea: {
    boxSizing: 'content-box',
    flexShrink: 0,
    borderRight: `1px solid ${theme.grey6}`
  },
  colHeadersEditorDroppable: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  colHeadersEditorDraggableItem: {
    '&[data-is-dragging=true]': {
      borderRadius: 3,
      color: 'white',
      backgroundColor: theme.indigo3,
      opacity: 0.5,
      fontSize: 10,
      textAlign: 'left',
      letterSpacing: 1
    }
  }
});
