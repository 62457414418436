import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { getByPath } from 'utils/widgets';
import Table from 'components/ui/table';

import styles from './table.style';

// table data embedded in a view
const TableViewImpl = React.memo(({ classes, view, data, update }) => {
  const { path, config = {}, height = '75vh', userConfigKeyPath } = view;

  const tableData = getByPath(data, path) || [];

  // caching the user display config is configurable in the view
  // and key can be contextualized (like traceId_dataName)
  const userConfigKey = userConfigKeyPath
    ? getByPath(data, userConfigKeyPath)
    : undefined;

  const tableUpdate = useMemo(
    () => ({
      ...update,
      userDisplay: {
        localStorageKey: userConfigKey
      }
    }),
    [update, userConfigKey]
  );

  return (
    <div className={classes.tableView} style={{ height }}>
      <Table data={tableData} config={config} update={tableUpdate} />
    </div>
  );
});
TableViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.object
};
TableViewImpl.defaultProps = {
  update: null
};

// sort defaults to length on path
const getSortConfig = view => ({
  type: 'length',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = view => ({
  type: 'text',
  path: view.path
});

// width defaults to 'large'
const getDefaultWidth = () => 'large';

export default {
  component: injectSheet(styles)(TableViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth
};
