const SUCCESS_ICON_SIZE = 20;
const SUCCESS_ICON_SPACING = 3;

export default theme => ({
  mainContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    paddingRight: SUCCESS_ICON_SIZE + SUCCESS_ICON_SPACING * 2
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50px',
    border: `1px solid ${theme.indigo3}`,
    overflow: 'hidden'
  },
  tab: {
    minWidth: 90,
    padding: '5px 10px',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.white1,
    borderRight: `1px solid ${theme.indigo3}`,
    cursor: 'pointer',
    outline: 'none',
    transition: 'background 0.3s ease, color 0.3s ease',
    '&:last-child': {
      borderRight: 'none'
    },
    '&:not($tabSelected)': {
      '&:hover, &:focus-visible': {
        color: theme.indigo3,
        backgroundColor: theme.indigoPastel3
      },
      '&:disabled': {
        cursor: 'not-allowed',
        backgroundColor: theme.grey9,
        color: theme.grey4
      }
    }
  },
  tabSelected: {
    backgroundColor: theme.indigo3,
    color: theme.white1,
    cursor: 'default'
  },
  tabLoading: {
    cursor: 'wait'
  },
  successIcon: {
    position: 'absolute',
    right: SUCCESS_ICON_SPACING,
    top: '50%',
    width: SUCCESS_ICON_SIZE,
    height: SUCCESS_ICON_SIZE,
    marginTop: -SUCCESS_ICON_SIZE / 2,
    color: theme.indigo3,
    opacity: 1,
    animationName: 'successIconShowHideAnimation, successIconShowHideAnimation',
    animationDuration: '200ms, 200ms',
    animationDelay: '0ms, 1300ms',
    animationDirection: 'normal, reverse',
    animationTimingFunction: 'ease-in, ease-out',
    animationFillMode: 'both, both'
  },
  '@keyframes successIconShowHideAnimation': {
    from: {
      transform: 'translateY(-5px)',
      opacity: 0
    }
  }
});
