import { useMemo } from 'react';
import mergeRefs from 'react-merge-refs';

import { ResizeObserver as polyfill } from '@juggle/resize-observer';
import useMeasureExt from 'react-use-measure';

// this hook combines potentially a plain ref that the client provides with a ref used by react-use-measure
// this is important because react-measure uses a functionnal ref that cannot be used afterwards by the client to eg manipulate the DOM
// also provides a polyfill to react-use-measure for ResizeObserver compatibility with Safari etc...
export default (extRef, delay) => {
  // instantiate a functional ref with the react-measure resize observer
  const options = useMemo(
    () => ({
      debounce: delay,
      scroll: false,
      polyfill
    }),
    [delay]
  );
  const [measureClientRef, bounds] = useMeasureExt(options);

  // merge with client ref
  const measureRef = extRef
    ? mergeRefs([extRef, measureClientRef])
    : measureClientRef;

  return [measureRef, bounds];
};
