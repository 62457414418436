import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

// Re-resizable
import { Resizable } from 're-resizable';

// Tooltip to show the current row height
import InfoTooltip from 'components/ui/utils/infoTooltip';

import styles from './resizableRow.style';

// resizable ghost row with a tooltip to show the current rowsHeight
const ResizableRow = React.memo(
  ({ classes, height, minHeight, setRowsHeight }) => {
    const [isResizing, setIsResizing] = useState(false);
    const [currentHeight, setCurrentHeight] = useState(height);
    const rowRef = useRef();

    // update the current height if the prop changes
    useEffect(() => {
      if (height !== currentHeight) setCurrentHeight(height);
    }, [height]);

    const tooltipLabel = `Rows Height:\n${currentHeight} px`;
    return (
      <div className={classes.resizableRow} style={{ top: 0 }}>
        <Resizable
          minHeight={minHeight}
          size={{
            // width: '100%',
            height: currentHeight
          }}
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
          onResizeStart={() => setIsResizing(true)}
          onResizeStop={() => {
            setIsResizing(false);
            if (currentHeight !== height) {
              setRowsHeight(currentHeight);
            }
          }}
          onResize={(e, direction, ref, d) => {
            setCurrentHeight(height + d.height);
          }} // update the tooltip content dynamically
        >
          <div ref={rowRef} />
        </Resizable>
        {isResizing && (
          <InfoTooltip
            clientRef={rowRef}
            text={tooltipLabel}
            textColor="white"
            backgroundColor="black"
            position={{
              place: 'above',
              placeShift: 3,
              adjustPlace: true,
              anchor: 'left'
            }}
            delay={0}
          />
        )}
      </div>
    );
  }
);

ResizableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  minHeight: PropTypes.number.isRequired,
  setRowsHeight: PropTypes.func.isRequired
};

export default injectSheet(styles)(ResizableRow);
