export default theme => ({
  previousAnswersContainer: {
    marginTop: 10,
    paddingTop: 10,
    borderTop: `solid 1px ${theme.grey7}`
  },
  answersTitle: {
    fontSize: 15,
    fontWeight: 500
  }
});
