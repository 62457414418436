import React from 'react';

import WorkflowOverview from './workflowOverview';
import WorkflowOverviewContextProvider from './context';

export default React.memo(props => (
  <WorkflowOverviewContextProvider>
    <WorkflowOverview {...props} />
  </WorkflowOverviewContextProvider>
));
