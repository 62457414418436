import React from 'react';
import injectSheet from 'react-jss';
import PropType from 'prop-types';
import { compose, graphql } from 'react-apollo';
import moment from 'moment';

import { traceClient } from 'gql';
import { FILTERS_QUERY } from 'gql/localQueries';
import { graphqlWithFilters } from 'utils';
import { notify } from 'components/toast';
import { Pushbutton, LoaderTraceLogo, RadioButton } from '@stratumn/atomic';
import { Calendar } from '@stratumn/icons';
import DatePicker from 'components/ui/datepicker';
import { Navbar, Footer } from 'components/layouts';

import queries from './queries';
import styles from './workflowExport.style';
import downloadData from './csv/default';
import downloadCNPData from './csv/cnp';

const LIMIT = 5000;

export const WorkflowExport = ({ classes, match, workflowQuery }) => {
  const today = moment().toDate();
  const [isExporting, setIsExporting] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [fileType, setFileType] = React.useState('csv');
  const { loading, workflow } = workflowQuery;

  React.useEffect(() => {
    if (workflow && workflow.name)
      document.title = `Export data - ${workflow.name} - Trace`;
  }, [workflow]);

  const onStartDateChange = React.useCallback(date => setStartDate(date), []);
  const onEndDateChange = React.useCallback(date => setEndDate(date), []);
  const onSubmit = React.useCallback(async () => {
    setIsExporting(true);
    const isCNP = workflow.name === 'CNP Décès';

    try {
      let stop = false;
      let cursor;
      const traces = [];
      while (!stop) {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await traceClient.query({
          query: queries.searchTracesQuery,
          variables: {
            workflowId: match.params.id,
            before: moment(endDate).add(1, 'day').format(),
            after: startDate,
            limit: LIMIT,
            cursor
          }
        });

        // Build the export as a list of trace states
        traces.push(
          ...data.workflow.traces.nodes.map(
            ({ rowId, createdAt, updatedAt, state }) => ({
              traceId: rowId,
              createdAt,
              updatedAt,
              data: state.data
            })
          )
        );

        // Update cursor or exit
        if (data.workflow.traces.pageInfo.hasNextPage) {
          cursor = data.workflow.traces.pageInfo.endCursor;
        } else {
          stop = true;
        }
      }
      if (!traces.length) {
        notify.error('No traces have been found');
      } else if (isCNP) {
        downloadCNPData({ workflow, traces, fileType });
      } else {
        downloadData({ workflow, traces, fileType });
      }
    } catch (err) {
      console.error(err);
      notify.error('Something went wrong');
    }

    setIsExporting(false);
  }, [workflow, startDate, endDate, match.params.id, fileType]);

  const configHeader = {
    loading,
    bottomLevel: {
      infoContext: {
        links: [
          {
            label: 'Export data'
          }
        ]
      }
    }
  };

  return (
    <>
      <Navbar config={configHeader} />
      <div className={classes.content}>
        <h1 className={classes.title}>Export data</h1>
        <h1 className={classes.description}>
          Select a date range and export all the trace data for that period in
          CSV or Excel format.
        </h1>
        <div className={classes.form}>
          <div className={classes.field}>
            <DatePicker
              label="From"
              selected={startDate}
              onChange={onStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={today}
            />
          </div>
          <div className={classes.field}>
            <DatePicker
              label="To"
              selected={endDate}
              onChange={onEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={today}
            />
          </div>
          <div className={classes.icon}>
            <Calendar />
          </div>
        </div>
        <div className={classes.radioButtons}>
          <RadioButton
            dataCy="select"
            showLabel
            label="CSV (.csv)"
            value="csv"
            checked={fileType === 'csv'}
            handleChange={() => setFileType('csv')}
          />
          <RadioButton
            dataCy="select"
            showLabel
            label="Excel (.xls)"
            value="excel"
            checked={fileType === 'excel'}
            handleChange={() => setFileType('excel')}
          />
        </div>
        {isExporting ? (
          <LoaderTraceLogo />
        ) : (
          <Pushbutton
            onClick={onSubmit}
            disabled={!startDate || !endDate}
            primary
          >
            Download file
          </Pushbutton>
        )}
      </div>
      <Footer />
    </>
  );
};

WorkflowExport.propTypes = {
  classes: PropType.object.isRequired,
  match: PropType.object.isRequired,
  workflowQuery: PropType.object.isRequired
};

export default compose(
  graphql(FILTERS_QUERY, {
    name: 'filtersQuery'
  }),
  graphqlWithFilters(queries.workflowQuery, {
    name: 'workflowQuery',
    options: ({ match }) => ({
      variables: { workflowId: match.params.id }
    })
  }),
  injectSheet(styles)
)(WorkflowExport);
