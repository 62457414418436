import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';

import SelectableItemWrapper from './selectableItemWrapper';

export const SelectableItemsContext = createContext();
SelectableItemsContext.displayName = 'SelectableItemsContext';

export const useSelectableItemsContext = () =>
  useContext(SelectableItemsContext);

const mapArrayItems = selectableChildren => item => {
  if (typeof item === 'object') {
    if (Array.isArray(item)) {
      return item.map(mapArrayItems(selectableChildren));
    }
    return loopObject(item, selectableChildren, false);
  }
  return item;
};

const reduceObjectKeys = selectableChildren => (acc, [key, value]) => {
  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return {
        ...acc,
        [key]: value.map(mapArrayItems(selectableChildren))
      };
    }
    return {
      ...acc,
      [key]: loopObject(value, selectableChildren, key === 'view')
    };
  }
  return { ...acc, [key]: value };
};

const loopObject = (obj, selectableChildren, isView) => {
  let enhancedObject = obj;
  if (isView && selectableChildren.includes(obj.type)) {
    enhancedObject = { ...obj, selectable: true };
  }
  return Object.entries(enhancedObject).reduce(
    reduceObjectKeys(selectableChildren),
    {}
  );
};

export const enhanceSelectableView = view =>
  loopObject(
    { ...view, selectable: view.selectableItems.self },
    view.selectableItems.children,
    true
  );

export const getIsAllSelected = treeItem =>
  !treeItem.selected
    ? false
    : treeItem.children.reduce(
        (acc, childTreeItem) =>
          acc === false ? acc : getIsAllSelected(childTreeItem),
        true
      );

// Custom hook that well return a string (from a react ref so it keeps the same value at every render) that can be used as an ID
const useUniqueId = () => {
  const { current: id } = useRef(Math.floor(Math.random() * 1e16).toString());
  return id;
};

// Reursive function that will apply a selected value to an item and all its children
const recursivelySelectChildren = (children, selected) =>
  children.map(child => ({
    ...child,
    selected,
    children: recursivelySelectChildren(child.children, selected)
  }));

// Function to generate default selectable item value with a given id
const getDefaultValue = (id, type, data) => ({
  id,
  data,
  type,
  selected: false,
  children: []
});

export const withSelectableContext = Component => {
  const WrappedComponent = ({ view, ...props }) => {
    // Get unique ID from custom hook
    const id = useUniqueId();

    // Get the parent selectable context
    const parentContext = useSelectableItemsContext();

    // Is it the top component ? (has no selectable context above it in the tree)
    const isTopComponent = !parentContext;

    // State variable that will only be used by the top selectable item, the other ones will get teir value from the parent item's context.
    const [contextValue, setContextValue] = useState(() =>
      isTopComponent ? getDefaultValue(id, view.type) : undefined
    );

    // State variable that will store each item's data.
    const [itemData, setItemData] = useState();

    // State variable that will store the list of completed items (the ones where the action was already triggered).
    const [idsCompleted, setIdsCompleted] = useState([]);

    const isCompleted = isTopComponent
      ? idsCompleted.indexOf(id) >= 0
      : parentContext.idsCompleted.indexOf(id) >= 0;

    // If the selectable item is wrapped inside another one, its value should come from it.
    const value = isTopComponent
      ? contextValue
      : parentContext.children?.find(child => child.id === id);

    const updateValue = newValue => {
      if (isTopComponent) {
        setContextValue(newValue);
      } else {
        parentContext.updateChild(newValue);
      }
    };

    // Callback function to update a child in the parent context (or the state of the top item)
    // This function will be exposed in the context's value so each child can update its state in the parent component
    // It won't be used directly in this component, meaning we'll call parentContext.registerChild(...) instead of registerChild(...)
    const updateChildCallback = child => {
      const newChildren = value.children.map(x =>
        x.id === child.id ? child : x
      );
      const newValue = {
        ...value,
        children: newChildren,
        selected: newChildren.some(x => x.selected)
      };
      updateValue(newValue);
    };

    // Callback function to register a child in the parent context (or the state of the top item)
    // This function will be exposed in the context's value and wont be used directly in this component, meaning we'll call parentContext.registerChild(...) instead of registerChild(...)
    const registerChildCallback = child => {
      const newValue = {
        ...value,
        children: [...value.children, child]
      };
      updateValue(newValue);
    };

    const handleChangeCheck = e => {
      const selected = e.target.checked;
      const newValue = {
        ...value,
        selected,
        children: recursivelySelectChildren(value.children, selected)
      };
      updateValue(newValue);
    };

    const handleSelectAll = (selected = true) => {
      const newValue = {
        ...value,
        selected,
        children: recursivelySelectChildren(value.children, selected)
      };
      updateValue(newValue);
    };

    // Callback function so any child can set a list of ids to be considered completed.
    const setIdsCompletedCallback = ids => {
      if (isTopComponent) {
        setIdsCompleted(prevIds =>
          ids.reduce(
            (acc, itemId) =>
              acc.indexOf(itemId) >= 0 ? acc : [...acc, itemId],
            prevIds
          )
        );
      } else {
        parentContext.setIdsCompleted(ids);
      }
    };

    // Effect to register items
    useEffect(() => {
      // Test if it was not registered yet
      if (
        // Parent has children? Can be undefined :
        // - if there is no parent (selectable context at the top of the tree)
        // - during the first renders, while the parent was not registered yet
        parentContext?.children &&
        // Isn't the item already in the parent list?
        !parentContext.children.find(x => x.id === id)
      ) {
        // Register the item in the parent context
        parentContext.registerChild(getDefaultValue(id, view.type, itemData));
      }
    }, [parentContext]);

    // Effect to update item data if it changes
    useEffect(() => {
      // Only apply change if the item already has a value (meaning that it was already registered in its parent)
      if (value && itemData) {
        updateValue({ ...value, data: itemData });
      }
    }, [itemData]);

    return (
      <SelectableItemsContext.Provider
        value={{
          id,
          selectableConfig: isTopComponent
            ? view.selectableItems
            : parentContext.selectableConfig,
          treeValue: isTopComponent ? contextValue : parentContext.treeValue,
          idsCompleted: isTopComponent
            ? idsCompleted
            : parentContext.idsCompleted,
          selected: value?.selected,
          children: value?.children,
          isCompleted,
          wrapperData: isTopComponent ? props.data : parentContext.wrapperData,
          handleChangeCheck,
          handleSelectAll,
          setItemData,
          registerChild: registerChildCallback,
          updateChild: updateChildCallback,
          setIdsCompleted: setIdsCompletedCallback
        }}
      >
        {view.selectable && itemData ? (
          <SelectableItemWrapper type={view.type}>
            <Component
              {...props}
              view={view}
              setSelectableItemData={setItemData}
            />
          </SelectableItemWrapper>
        ) : (
          <Component
            {...props}
            view={view}
            setSelectableItemData={setItemData}
          />
        )}
      </SelectableItemsContext.Provider>
    );
  };
  WrappedComponent.propTypes = {
    view: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
  };
  return WrappedComponent;
};
