import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';

import injectSheet from 'react-jss';

import { Avatar } from '@stratumn/atomic';

import styles from './participantCard.style';

// A single participant card
export const ParticipantCard = ({
  classes,
  participant,
  participantAccountId,
  removeGroupMembers
}) => {
  const { avatar, name, email, pending, inviteExpired } = participant;

  const remove = useCallback(() => {
    removeGroupMembers([participantAccountId], name);
  }, [participant, removeGroupMembers]);

  return (
    <div className={classes.card}>
      <div className={classes.cardLeft}>
        <Avatar size={28} src={avatar} />
        <div className={classes.userLabel}>
          <div className={classes.userName}>{name}</div>
          <a className={classes.userInfo} href={`mailto:${email}`}>
            {email}
          </a>
        </div>
      </div>
      <div className={classes.cardRight}>
        {pending && (
          <div className={classes.inviteStatus}>
            {inviteExpired ? 'Invite expired' : 'Pending invite'}
          </div>
        )}
        {participantAccountId && (
          <div
            className={classes.removeAllButton}
            onClick={remove}
            data-cy="remove-button"
          >
            Remove
          </div>
        )}
      </div>
    </div>
  );
};
ParticipantCard.propTypes = {
  classes: PropTypes.object.isRequired,
  participant: PropTypes.object.isRequired,
  participantAccountId: PropTypes.string,
  removeGroupMembers: PropTypes.func
};
ParticipantCard.defaultProps = {
  participantAccountId: null,
  removeGroupMembers: null
};

export default compose(injectSheet(styles), React.memo)(ParticipantCard);
