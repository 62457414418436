import { CircularProgress } from './circularProgress';
import { DoneProgress } from './doneProgress';
import { CancelProgress } from './cancelProgress';
import { TodoProgress } from './todoProgress';

type Props = {
  progress: number;
  size: number;
  cancelValue: number;
};
export const Progress = ({ progress, size, cancelValue }: Props) => {
  if (progress === cancelValue || progress < 0) {
    return <CancelProgress size={size} />;
  }
  // ? Since cancelValue defaults to 0, we'll rarely trigger the following condition
  if (progress === 0) return <TodoProgress size={size} />;
  if (progress >= 1) return <DoneProgress size={size} />;
  return <CircularProgress progress={progress} size={size} />;
};
