import React from 'react';
import PropTypes from 'prop-types';

import jmespath from 'jmespath';

const getStyleObject = (data, rules, currentStyle) =>
  rules.reduce((styles, { path, style }) => {
    const isStyleApplicable = !path || jmespath.search(data, path);
    return isStyleApplicable ? { ...styles, ...style } : styles;
  }, currentStyle || {});

const InlineStyle = React.memo(
  ({ data, rules, element, children, style, ...props }) => {
    const Element = element;
    return (
      <Element style={getStyleObject(data, rules, style)} {...props}>
        {children}
      </Element>
    );
  }
);

export default InlineStyle;

InlineStyle.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      style: PropTypes.object.isRequired
    }).isRequired
  ).isRequired,
  element: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired
};

InlineStyle.defaultProps = {
  element: 'div',
  style: {}
};
