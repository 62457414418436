import React from 'react';
import PropType from 'prop-types';
import qs from 'query-string';

import { getToken } from 'client';
import { ROUTE_WORKFLOW_DASHBOARD } from 'constant/routes';
import { setAuthToken } from 'utils';

export default class OAuth extends React.Component {
  static propTypes = {
    location: PropType.object.isRequired,
    history: PropType.object.isRequired
  };

  getAuthCode = () => {
    const {
      location: { search }
    } = this.props;
    const { authCode } = qs.parse(search);
    return authCode;
  };

  // eslint-disable-next-line
  UNSAFE_componentWillMount = async () => {
    const authCode = this.getAuthCode();
    const token = await getToken(authCode);
    setAuthToken(token);
    this.props.history.push(ROUTE_WORKFLOW_DASHBOARD);
  };

  render() {
    return null;
  }
}
