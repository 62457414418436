export default theme => ({
  button: {
    outline: 'none',
    position: 'relative',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    backgroundColor: 'white',
    border: `1px solid ${theme.grey5}`,
    transition: 'border-color 150ms linear 0ms',
    borderRadius: 3,
    padding: '5px 10px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    fontSize: 13,
    color: theme.grey4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&[data-is-set=true]': {
      color: theme.grey2
    },
    '&:focus, &[data-is-focused=true]': {
      borderColor: theme.indigo3
    },
    '&::-moz-focus-inner': {
      border: 0 // disable inner dotted border on focused button for firefox
    }
  },
  buttonLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tooltipContent: {
    backgroundColor: theme.white1,
    borderRadius: 3,
    boxShadow: '0px 5px 15px #28128733',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    overflow: 'hidden'
  },
  dayPickerInputs: {
    marginLeft: 20,
    marginRight: 20,
    margin: '0px 20px',
    borderBottom: `1px solid ${theme.grey7}`,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  dayPickerInputsArrow: {
    margin: '0px 13px',
    flexShrink: 0,
    height: 18,
    width: 18
  },
  dayPickerDate: {
    position: 'relative'
  },
  dayPickerDateInput: {
    padding: '15px 0px',
    outline: 'none',
    fontSize: 14,
    lineHeight: '18px',
    color: theme.grey1,
    '&::placeholder': {
      color: theme.grey4
    }
  },
  dayPickerDateFocusedIndicator: {
    backgroundColor: theme.indigo3,
    position: 'absolute',
    bottom: 0,
    height: 2,
    left: 0,
    width: '100%'
  },
  dayPickerRangeContainer: {
    flexGrow: 1,
    '& .CalendarDay__selected_span, .CalendarDay__hovered_span:hover, .CalendarDay__hovered_span': {
      background: theme.indigoPastel3,
      color: 'black',
      border: `1px solid ${theme.white1}`
    },
    '& .CalendarDay__selected': {
      background: theme.indigo2,
      color: theme.white1,
      border: `1px solid ${theme.white1}`
    },
    // Calendar left and right arrows
    '& .DayPickerNavigation_button': {
      border: 'none',
      '&:focus': {
        outline: 'none'
      }
    }
  },
  calendarInfoWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: 22,
    paddingBottom: 22,
    transition: '250ms',
    '& svg': {
      fill: 'currentColor',
      height: 14,
      width: 'auto',
      marginRight: 10,
      transition: '250ms'
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7
    }
  },
  calendarInfoText: {
    lineHeight: '1.18px',
    fontSize: 11,
    color: '#343132'
  }
});
