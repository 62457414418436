import React, { useRef } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { AvatarGroup } from '@stratumn/atomic';

import DynamicIcon from 'components/ui/dynamicIcon';
import Tooltip from 'components/ui/utils/tooltip';

import { shortFormat } from 'utils/dates';

import { useToggle } from 'utils/hooks';
import { countFn as count } from '../count';

import styles from './segment.style';

const Segment = ({
  classes,
  link,
  isActive,
  interval,
  isFirst,
  isLast,
  answersCount
}) => {
  const listViewRef = useRef(null);
  const [showTooltip, toggleShowTooltip] = useToggle(false);

  const hasAnswer = !!answersCount;
  const renderActionIcon = () => {
    const icon = (link.action && link.action.icon) || 'Doc';

    return (
      <div
        className={classes.actionIcon}
        data-is-last={isLast}
        data-is-active={isActive}
      >
        <DynamicIcon icon={icon} size={35} />
      </div>
    );
  };

  const renderInterval = () => {
    if (!interval) return null;
    return <div className={classes.interval}>{interval}</div>;
  };

  const renderWhat = () => {
    const { action, actionKey, referencedBy, references } = link;
    const what = action ? action.title : actionKey;
    const postfix =
      !!referencedBy.totalCount || !!references.totalCount ? '*' : '';
    return `${what.slice(0, 35).trim()}${what.slice(35) && '…'}${postfix}`;
  };

  const renderActiveIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        aria-hidden="true"
        className={classes.topActiveIcon}
        data-is-active={isActive}
      >
        <path d="M50 89.489L0 0h100L50 89.489z" />
      </svg>
    );
  };

  return (
    <div
      className={classnames(
        classes.root,
        { [classes.rootOnly]: isFirst && isLast },
        { [classes.rootFirst]: isFirst && !isLast },
        { [classes.rootLast]: isLast && !isFirst }
      )}
    >
      <div className={classes.top} data-is-active={isActive}>
        <div className={classes.topActive}>{renderActiveIcon()}</div>
        <div className={classes.topCount}>{count(link.height)}</div>
        <div className={classes.topWhat}>{renderWhat()}</div>
        {hasAnswer && (
          <>
            <div
              ref={listViewRef}
              className={classes.answerTooltip}
              onMouseEnter={toggleShowTooltip}
              onMouseLeave={toggleShowTooltip}
            >
              <DynamicIcon icon="lucide_MessagesSquare" size={20} />
            </div>
            {showTooltip && (
              <Tooltip
                clientEl={listViewRef.current}
                portalEl={document.body}
                arrowUp={<div className={classes.answerTooltipArrowUp} />}
              >
                <div className={classes.answerTooltipText}>
                  {answersCount} answer{answersCount > 1 && 's'}
                </div>
              </Tooltip>
            )}
          </>
        )}
      </div>
      <div
        className={classes.middle}
        data-is-first={isFirst}
        data-is-last={isLast}
      >
        <div className={classes.middleInterval}>{renderInterval()}</div>
        <div className={classes.middleAction}>{renderActionIcon()}</div>
      </div>
      <div className={classes.bottom} data-is-active={isActive}>
        <div className={classes.bottomWhen}>{shortFormat(link.createdAt)}</div>
        <div className={classes.bottomWho}>{link.group.name}</div>
        <div className={classes.bottomAvatar}>
          <div className={classes.avatarContainer} data-is-active={isActive}>
            <AvatarGroup size={36} src={link.group.avatar} />
          </div>
        </div>
      </div>
    </div>
  );
};

Segment.propTypes = {
  classes: PropTypes.object.isRequired,
  link: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  interval: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  answersCount: PropTypes.number
};

Segment.defaultProps = {
  isActive: false,
  interval: null,
  isFirst: false,
  isLast: false,
  answersCount: 0
};

export default injectSheet(styles)(Segment);
