export default {
  activityView: {
    overflow: 'hidden',
    textAlign: 'left',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  activityViewContent: {
    overflow: 'hidden'
  },
  activityViewWho: {
    textOverflow: 'ellipsis',
    fontSize: 12, // 90%
    fontWeight: 'bold',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  activityViewAvatar: {
    flexShrink: 0,
    marginRight: 7
  },
  activityViewWhen: {
    paddingTop: 2,
    textOverflow: 'ellipsis',
    fontSize: 11 // 85%
  }
};
