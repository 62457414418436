import { getOperationAST } from 'graphql';
import { disableFragmentWarnings } from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { RestLink } from 'apollo-link-rest';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from 'apollo-link-ws';
import * as Sentry from '@sentry/react';
import {
  GRAPHQL_API_URL,
  ROOT_API_URL,
  NOTIFICATION_API_URL
} from 'constant/api';
import { getAuthToken } from 'utils';
import errorLink from './errorLink';
import { SpanType } from '../../tracing';
import stateLink, { cache } from './stateLink';

// Disable warning if multiple fragments have the same name.

disableFragmentWarnings();

// tell the browser to send the authentication
// cookie along with the request.
const httpLink = new BatchHttpLink({
  uri: GRAPHQL_API_URL,
  credentials: 'include',
  fetch: (uri, options) =>
    Sentry.startSpan(
      {
        name: uri,
        op: SpanType.reqOut
      },
      () => fetch(uri, options)
    )
});

const restLink = new RestLink({
  uri: ROOT_API_URL,
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json'
  },
  fetch: (uri, options) =>
    Sentry.startSpan(
      {
        name: uri,
        op: SpanType.reqOut
      },
      () => fetch(uri, options)
    )
});

const wsClient = new SubscriptionClient(NOTIFICATION_API_URL, {
  reconnect: true,
  lazy: true,
  connectionParams: () => ({
    authToken: getAuthToken()
  })
});

const wsLinkSplitter = operation => {
  // check if it is a subscription
  const operationAST = getOperationAST(
    operation.query,
    operation.operationName
  );
  return !!operationAST && operationAST.operation === 'subscription';
};

const wsLink = new WebSocketLink(wsClient);

export default new ApolloClient({
  link: ApolloLink.from([errorLink, restLink, stateLink]).split(
    wsLinkSplitter,
    wsLink,
    httpLink
  ),
  cache,
  connectToDevTools: true
});
