import React, { useMemo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { LayoutKanban, LayoutKanbanMain } from '@stratumn/atomic';

import { ROUTE_WORKFLOW_DASHBOARD } from 'constant/routes';
import { Footer } from 'components/layouts';
import { withError } from 'components/errorBoundary';
import {
  TRAY_PORTAL_RIGHT,
  TRAY_PORTAL_LEFT,
  TOOLTIP_PORTAL
} from 'constant/htmlIds';
import { deepGet, formatNumber } from 'utils';

import { withUser } from 'contexts';
import { FIRST } from 'constant/pagination';
import fragments from './fragments';
import WorkflowOverviewHeader from './workflowOverviewHeader';
import WorkflowOverviewActions from './workflowOverviewActions';
import WorkflowOverviewTable from './workflowOverviewTable';
import { withWorkflowOverviewContext } from './context';
import { generatePayload } from './export';

import {
  getUserDisplayConfig,
  getSortFromUserDisplayConfig,
  getGroupFromUserDisplayConfig,
  getFiltersFromUserDisplayConfig,
  findGroupTraceStatesByIndex,
  countTraces
} from './utils';

import styles from './workflowOverview.style';

export const WorkflowOverview = React.memo(props => {
  const {
    classes,
    match,
    errorContext: { handleError },
    user,
    workflowQuery,
    context: { userDisplayConfig }
  } = props;

  const fetchMorePromise = useRef(null);

  const {
    error,
    fetchMore,
    refetch,
    workflowByRowId: workflow,
    loading: workflowLoading
  } = workflowQuery;

  // Is loading if the user info or the workflow info is missing,
  // and the gql call is loading.
  // The goal here is to render the old data when we are refetching
  const { loading: userLoading, me } = user;
  const loading = (!workflow || !me) && (workflowLoading || userLoading);

  const selectedGroupIndex = deepGet(userDisplayConfig, 'groupBy.selected');

  // Raise 404 page when workflow not found.
  useEffect(() => {
    const { params } = match;

    // This check avoids rerendering the component after the erroBoundary has been triggered
    if (!workflowLoading && !workflow) {
      handleError('workflow', params.id, ROUTE_WORKFLOW_DASHBOARD);
    }
  }, [workflowQuery, match]);

  useEffect(() => {
    const { name } = workflow || {};
    if (name) document.title = `${name} - Overview - Trace`;
  }, [workflow]);

  const handleOnLoadMore = useCallback(() => {
    // If a fetch more query is in progress, do not try to load
    if (fetchMorePromise.current) return;
    // If grouping is in effect and all groups are collapsed, do not try to load
    // TODO: Handle group pagination; right now, only traces within the expanded group are paginated

    const groupBy = getGroupFromUserDisplayConfig(userDisplayConfig);
    if (groupBy && selectedGroupIndex === undefined) return;

    const { rowId: workflowRowId, groupedTraceStates } = workflow;

    // fetchMore will only fetch more traces for one group
    // (if groupBy is null, all the additional traces will be available in one group with index 'null')
    const traceStates = findGroupTraceStatesByIndex(
      groupedTraceStates,
      selectedGroupIndex
    );

    const { endCursor } = traceStates.pageInfo;

    fetchMorePromise.current = fetchMore({
      query: WORKFLOW_QUERY,
      variables: {
        workflowId: workflowRowId,
        first: FIRST,
        cursor: endCursor,
        filter: getFiltersFromUserDisplayConfig(userDisplayConfig),
        orderBy: getSortFromUserDisplayConfig(userDisplayConfig),
        groupBy
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousGroups =
          previousResult.workflowByRowId.groupedTraceStates;
        const newGroups = fetchMoreResult.workflowByRowId.groupedTraceStates;
        const previousTraceStates = findGroupTraceStatesByIndex(
          previousGroups,
          selectedGroupIndex
        );
        const newTraces = findGroupTraceStatesByIndex(
          newGroups,
          selectedGroupIndex
        );

        const {
          pageInfo: previousPageInfo,
          nodes: previousTraceStateNodes
        } = previousTraceStates;
        const { pageInfo: newPageInfo, nodes: newTraceStateNodes } = newTraces;

        return {
          workflowByRowId: {
            ...previousResult.workflowByRowId,
            groupedTraceStates: {
              ...previousGroups,
              nodes: previousGroups.nodes.map(g => {
                // If selectedGroupIndex is undefined and grouping is not enabled,
                // it should update all the groups (there's only 1 group in that case).
                // Otherwise, pagination should only update the currently selected group
                // (i.e. prevent updates in non-selected groups here).
                if (groupBy && g.index !== selectedGroupIndex) return g;
                return {
                  ...g,
                  traceStates: {
                    ...g.traceStates,
                    // By updating page info with the `cursor` here, we update `fetchMore` function
                    pageInfo: {
                      ...previousPageInfo,
                      ...newPageInfo
                    },
                    nodes: [
                      // Put the new trace states after the previous ones
                      ...previousTraceStateNodes,
                      ...newTraceStateNodes
                    ]
                  }
                };
              })
            }
          }
        };
      }
    }).then(() => {
      fetchMorePromise.current = null;
    });
  }, [workflow, userDisplayConfig]);

  const exportSetup = useMemo(() => {
    const nbTraces = countTraces(workflow || {});
    const { rowId, name } = workflow || {};
    return {
      generateExportPayload: async tracePayloadFn =>
        generatePayload(rowId, name, userDisplayConfig, tracePayloadFn),
      getConfirmationSetup: () => ({
        title: 'Export **data**',
        content: `You’re about to export data from the **${name}** overview table. Note that this will include all active filters, sorting and display options.`,
        confirmBtnText: `Export ${formatNumber(nbTraces)} traces`
      })
    };
  }, [workflow, userDisplayConfig]);

  const main = useMemo(
    () => (
      <>
        <WorkflowOverviewTable
          workflow={workflow}
          handleOnLoadMore={handleOnLoadMore}
          refetchTraces={refetch}
          exportSetup={exportSetup}
        />
        <WorkflowOverviewActions
          workflow={workflow}
          selectedGroupIndex={selectedGroupIndex}
        />
      </>
    ),
    [workflow, handleOnLoadMore, refetch, selectedGroupIndex]
  );

  // If the workflow id doesn't exist,
  // we return null and let errorBoundary render the oops page
  if (error || (!workflow && !loading)) {
    return null;
  }

  return (
    <>
      <div id={TOOLTIP_PORTAL} />
      <div
        id={TRAY_PORTAL_LEFT}
        className={classes.overviewTraysContainer}
        data-is-left
      />
      <div id={TRAY_PORTAL_RIGHT} className={classes.overviewTraysContainer} />
      <LayoutKanban>
        <WorkflowOverviewHeader
          workflowQuery={workflowQuery}
          isLoading={loading}
        />
        <LayoutKanbanMain classes={classes.root} loading={loading}>
          {!loading && main}
          <Footer customClass={classes.overviewFooter} />
        </LayoutKanbanMain>
      </LayoutKanban>
    </>
  );
});

WorkflowOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  errorContext: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  workflowQuery: PropTypes.object.isRequired,
  context: PropTypes.shape({
    userDisplayConfig: PropTypes.object
  }).isRequired
};

const WORKFLOW_QUERY = gql`
  query workflowQuery(
    $workflowId: BigInt!
    $first: Int
    $cursor: Cursor
    $orderBy: [TraceStatesOrderByPath!]
    $filter: TraceStateFilter
    $groupBy: String
  ) {
    workflowByRowId(rowId: $workflowId) {
      ...WorkflowQueryFragment
    }
  }
  ${fragments.workflow}
`;

export default compose(
  graphql(WORKFLOW_QUERY, {
    name: 'workflowQuery',
    options: ({ match }) => {
      const udc = getUserDisplayConfig(match.params.id);
      const filter = getFiltersFromUserDisplayConfig(udc);
      const orderBy = getSortFromUserDisplayConfig(udc);
      const groupBy = getGroupFromUserDisplayConfig(udc);
      return {
        variables: {
          workflowId: match.params.id,
          first: FIRST,
          filter,
          orderBy,
          groupBy
        },
        fetchPolicy: 'cache-and-network'
      };
    }
  }),
  injectSheet(styles),
  withRouter,
  withUser,
  withError,
  withWorkflowOverviewContext
)(WorkflowOverview);
