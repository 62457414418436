export default {
  array: {
    display: 'flex',
    flexFlow: 'column nowrap',
    gap: '10px'
  },
  searchBarContainer: {
    position: 'absolute',
    top: 0,
    right: 15
  },
  noResults: {
    minHeight: 30
  }
};
