export default {
  avatarView: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'start'
  },
  avatarPic: {
    flexShrink: 0
  },
  avatarName: {
    marginLeft: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};
