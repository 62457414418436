import React from 'react';
import PropTypes from 'prop-types';

/* eslint react/no-danger: 0 */
import DOMPurify from 'dompurify';

import injectSheet from 'react-jss';

import { getByPath } from 'utils/widgets';

import styles from './html.style';

// separate the function that will create the html markup
const createMarkup = (htmlData, sanitizerConfig) => {
  const cleanHTML = DOMPurify.sanitize(htmlData.toString(), sanitizerConfig);
  return { __html: cleanHTML };
};

// view to inject sanitized html
const HTMLViewImpl = React.memo(({ classes, view, data }) => {
  const { path, default: defaultValue } = view;

  const htmlData = getByPath(data, path) || defaultValue;
  if (htmlData === undefined) return null;

  // sanitize and render HTML
  return (
    <div
      className={classes.htmlView}
      dangerouslySetInnerHTML={createMarkup(htmlData)}
    />
  );
});
HTMLViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to text on path
const getSortConfig = view => ({
  type: 'text',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = view => ({
  type: 'text',
  path: view.path
});

// width defaults to 'xlarge'
const getDefaultWidth = () => 'xlarge';

/**
 * stringifies data at path
 * note: this html is NOT SANITIZED
 *
 * Sanitizing is down to the client that will consume it.
 * On our side, we only take care of the raw table data export.
 * The export is not meant to be rendered in html in any of our web pages.
 */

const getStringifyFunction = view => {
  const { path, default: defaultValue } = view;
  return data => getByPath(data, path) || defaultValue || '';
};

export default {
  component: injectSheet(styles)(HTMLViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
