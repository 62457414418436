export default theme => ({
  button: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.grey1,
    fontSize: 14,
    lineHeight: '18px',
    padding: 5,
    outline: 'none'
  },
  icon: {
    marginLeft: 9,
    flexShrink: 0,
    height: 12,
    width: 12,
    color: theme.grey3,
    transition: 'transform 250ms ease-in-out',
    '&[data-is-up=true]': {
      transform: 'rotate(180deg)'
    }
  },
  options: {
    boxShadow: theme.indigoShadow2,
    backgroundColor: theme.white1,
    borderRadius: 2,
    fontSize: 12,
    lineHeight: '15px',
    maxHeight: 150,
    overflowY: 'auto',
    width: 100,
    ...theme.scrollbar
  },
  option: {
    borderRadius: 2,
    padding: '5px 10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.indigoPastel3
    },
    '&[data-is-selected=true]': {
      backgroundColor: theme.indigo2,
      color: theme.white1
    }
  }
});
