export default theme => ({
  trayWrapper: {
    width: '100%',
    height: '100%'
  },
  trayWrapperContent: {
    fontSize: 13,
    lineHeight: 1.25,
    color: theme.grey1,
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%'
  },
  trayWrapperBody: {
    height: '100%',
    overflow: 'hidden'
  },
  trayWrapperFooter: {
    flexShrink: 0,
    height: 70,
    width: '100%',
    padding: 14,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  trayWrapperActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
});
