import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import InfoTooltip from '../../../utils/infoTooltip';

import styles from './infoTooltipWrapper.style';

// provides access to an info tooltip when hovered
const InfoTooltipWrapper = React.memo(
  ({
    classes,
    children,
    text,
    textColor,
    backgroundColor,
    position,
    delay,
    minWidth,
    maxWidth,
    boxShadow,
    setClientFocus
  }) => {
    // handle tooltip attached to the element
    const [showToolip, setShowTooltip] = useState(false);
    const wrapperRef = useRef(null);
    const onMouseEnter = useCallback(() => {
      setShowTooltip(true);
      setClientFocus(true);
    }, [setClientFocus]);
    const onMouseLeave = useCallback(() => {
      setShowTooltip(false);
      setClientFocus(false);
    }, [setClientFocus]);

    return (
      <>
        <div
          className={classes.infoTooltipWrapper}
          ref={wrapperRef}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
          {showToolip && (
            <InfoTooltip
              clientRef={wrapperRef}
              text={text}
              textColor={textColor}
              backgroundColor={backgroundColor}
              position={position}
              delay={delay}
              minWidth={minWidth}
              maxWidth={maxWidth}
              boxShadow={boxShadow}
            />
          )}
        </div>
      </>
    );
  }
);

InfoTooltipWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  position: PropTypes.object.isRequired,
  delay: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  boxShadow: PropTypes.string,
  setClientFocus: PropTypes.func
};

InfoTooltipWrapper.defaultProps = {
  delay: null,
  minWidth: null,
  maxWidth: null,
  boxShadow: null,
  setClientFocus: () => {}
};

export default injectSheet(styles)(InfoTooltipWrapper);
