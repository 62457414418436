export default theme => ({
  outsideIcon: {
    width: 13,
    height: 13,
    display: 'inline-block',
    marginLeft: 2
  },
  readerBody: {
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  readerItemTitle: {
    marginBottom: 5,
    color: theme.grey2,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '0.03em'
  },
  readerItemValue: {
    fontSize: 14
  },
  readerInterfaceContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center'
  },
  readerInterfaceItem: {
    padding: '7px 15px',
    fontSize: 14,
    width: '50%'
  },
  readerInterface: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    backgroundColor: theme.grey8,
    borderRadius: 3,
    '&:hover': {
      backgroundColor: theme.grey6
    }
  },
  readerInterfaceLink: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  readerInterfaceIcon: {
    color: theme.indigo2,
    marginRight: 10,
    width: 36,
    height: 36,
    display: 'inline-block'
  },
  readerInterfaceInfoSubHeader: {
    fontSize: 12,
    color: theme.grey3
  },
  readerModalBody: {
    height: '80vh',
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center'
  },
  readerModalTitle: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    fontSize: 18
  },
  readerModalTitleSubHeader: {
    fontSize: 12,
    fontWeight: 'normal',
    marginLeft: 15,
    marginRight: 15
  },
  readerInterfaceDownloadContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  readerInterfaceFile: {
    marginBottom: 4,
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    width: 'fit-content',
    '&:hover': {
      color: theme.indigo3
    },
    '&[data-is-loading=true]': {
      cursor: 'not-allowed',
      opacity: 0.5
    }
  },
  readerInterfaceFileSize: {
    fontSize: 10
  }
});
