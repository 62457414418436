export default () => ({
  container: {
    display: 'flex',
    gap: '6px'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    marginLeft: '-1px',
    marginRight: '4px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontWeight: 500,
    lineHeight: '1.5'
  },
  description: {
    lineHeight: '1.4'
  }
});
