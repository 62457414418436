export default theme => ({
  card: {
    margin: '5px 0px',
    backgroundColor: theme.white1,
    borderRadius: 5,
    border: `1px solid ${theme.grey7}`,
    padding: '15px 20px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardLeft: {
    flexGrow: 1,
    minWidth: '30%',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  userLabel: {
    marginLeft: 11,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    overflow: 'hidden'
  },
  userName: {
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'capitalize'
  },
  userInfo: {
    color: theme.grey3,
    fontSize: 12,
    lineHeight: '14px',
    textDecoration: 'none'
  },
  cardRight: {
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  removeAllButton: {
    paddingLeft: 20,
    cursor: 'pointer',
    color: theme.grey1,
    fontSize: 12,
    lineHeight: '14px',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.warningRed
    }
  },
  inviteStatus: {
    paddingLeft: 20,
    color: theme.grey4,
    fontSize: 12,
    lineHeight: '14px'
  }
});
