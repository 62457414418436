import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';
import InlineStyle from 'components/ui/utils/inlineStyle';

import styles from './inlineStyleWrapper.style';

const InlineStyleWrapper = React.memo(
  ({ data, rules, children, classes, ...props }) => (
    <InlineStyle className={classes.root} data={data} rules={rules} {...props}>
      {children}
    </InlineStyle>
  )
);

InlineStyleWrapper.propTypes = {
  data: PropTypes.object.isRequired,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      style: PropTypes.object.isRequired
    }).isRequired
  ).isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default injectSheet(styles)(InlineStyleWrapper);
