export default theme => ({
  files: {
    color: theme.grey3,
    fontSize: 12,
    position: 'relative',
    paddingLeft: 16
  },
  paperClip: {
    height: 16,
    transform: 'rotate(30deg)',
    position: 'absolute',
    left: -10,
    top: 2
  },
  answersTitle: {
    color: '#B4B1BD',
    textDecoration: 'underline',
    fontSize: 12,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      height: 16,
      transform: 'rotate(30deg)'
    }
  },
  answer: {
    display: 'flex',
    marginTop: 15,
    padding: '0px 15px',
    marginLeft: 9,
    borderLeft: `solid 2px ${theme.grey7}`
  },
  answerAvatar: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.grey9,
    minWidth: 20,
    height: 20,
    borderRadius: '50%',
    position: 'relative',
    top: 5
  },
  userId: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center'
  },
  answerContainer: {
    width: '100%',
    margin: '0 13px'
  },
  answerUserName: {
    color: theme.grey1,
    fontSize: 12,
    fontWeight: 700,
    marginRight: 10
  },
  answerUserGroup: {
    color: theme.grey2,
    fontSize: 10,
    fontWeight: 400,
    paddingLeft: 10,
    borderLeft: `1px solid ${theme.grey4}`,
    lineHeight: '12px'
  },
  answerDate: {
    color: theme.grey2,
    fontSize: 10,
    fontWeight: 400
  },
  answerRoot: {
    fontSize: 12,
    lineHeight: '13px',
    fontWeight: 500,
    color: theme.grey1,
    '& h1': {
      fontSize: 14,
      fontWeight: 700,
      color: theme.grey1
    },
    '& strong': {
      fontWeight: 700,
      color: theme.grey1
    },
    '& li': {
      position: 'relative',
      paddingLeft: 22,
      fontSize: 11,
      '&::before': {
        position: 'absolute',
        content: '"•"',
        color: theme.indigo3,
        fontWeight: 'bold',
        width: '1em',
        marginLeft: '-1em'
      }
    }
  }
});
