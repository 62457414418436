import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { Pushbutton } from '@stratumn/atomic';
import { history } from 'components/root';
import { ROUTE_WORKFLOW_DASHBOARD } from 'constant/routes';
import UnauthorizedIcon from './icon';

import style from './pageUnauthorized.style';

export class PageUnauthorized extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  gotoHome = () => history.push(ROUTE_WORKFLOW_DASHBOARD);

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.body}>
        <UnauthorizedIcon className={classes.icon} />
        <div className={classes.bigText}>Stop!</div>
        <div className={classes.smallText}>
          You don&#39;t have access to this page
        </div>
        <div className={classes.btn}>
          <Pushbutton onClick={this.gotoHome} primary>
            Take me home
          </Pushbutton>
        </div>
      </div>
    );
  }
}

export default injectSheet(style)(PageUnauthorized);
