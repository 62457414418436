import React from 'react';
import ReactDOM from 'react-dom';

import { Root } from 'components';

// configure internationalization on load
import 'utils/i18n';

// polyfills
import 'utils/polyfills';

ReactDOM.render(<Root />, document.getElementById('root'));
