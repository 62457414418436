import React, { memo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { ArrowRightLight, ArrowLeftLight } from '@stratumn/icons';

import { FORMS_BATCH_SET_ACTIVE_ERROR_FORM } from '../reducers';

import styles from './errorsSnackbar.style';

export const ErrorsSnackbar = ({ classes, errors, updateForms }) => {
  const { forms, activeFormId } = errors;

  const nbErrors = forms.length;
  const currentFormIdx = forms.findIndex(id => id === activeFormId); // note: will always be > 0

  return createPortal(
    <div className={classes.errorsSnackbarContainer}>
      <div className={classes.errorsSnackbar}>
        <b>{`${nbErrors} form${nbErrors > 1 ? 's' : ''} `}</b>
        {`currently contain${nbErrors === 1 ? 's' : ''} errors`}
        {nbErrors > 1 && (
          <div className={classes.errorsNavigation}>
            <ArrowLeftLight
              className={classes.arrow}
              data-cy="errors-snackbar-previous"
              data-is-disabled={currentFormIdx === 0}
              onClick={
                currentFormIdx > 0
                  ? () => {
                      updateForms({
                        type: FORMS_BATCH_SET_ACTIVE_ERROR_FORM,
                        id: forms[currentFormIdx - 1]
                      });
                    }
                  : null
              }
            />
            <span className={classes.numbers}>
              {currentFormIdx + 1} of {forms.length}
            </span>
            <ArrowRightLight
              className={classes.arrow}
              data-cy="errors-snackbar-next"
              data-is-disabled={currentFormIdx === forms.length - 1}
              onClick={
                currentFormIdx < forms.length - 1
                  ? () => {
                      updateForms({
                        type: FORMS_BATCH_SET_ACTIVE_ERROR_FORM,
                        id: forms[currentFormIdx + 1]
                      });
                    }
                  : null
              }
            />
          </div>
        )}
      </div>
    </div>,
    document.body
  );
};

ErrorsSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.shape({
    forms: PropTypes.arrayOf(PropTypes.string).isRequired,
    activeFormId: PropTypes.string.isRequired
  }).isRequired,
  updateForms: PropTypes.func.isRequired
};

export default injectSheet(styles)(memo(ErrorsSnackbar));
