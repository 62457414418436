export default theme => ({
  root: {
    position: 'relative'
  },
  file: {
    padding: 10,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    overflow: 'hidden',
    color: theme.grey1,
    backgroundColor: theme.grey9,
    borderRadius: 2,
    border: `1px solid ${theme.greyTable}`,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    transition: 'border-color 250ms',
    '&:hover': {
      borderColor: theme.indigo2
    },
    '&:active': {
      backgroundColor: theme.grey8
    },
    '&[data-is-loading=true]': {
      opacity: 0.5
    }
  },
  left: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '10px',
    overflow: 'hidden'
  },
  fileIcon: {
    color: theme.indigo2
  },
  fileInfo: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'column nowrap',
    overflow: 'hidden'
  },
  fileName: {
    fontSize: 12,
    lineHeight: '15px',
    overflow: 'hidden'
  },
  fileSize: {
    fontSize: 10,
    lineHeight: '13px',
    color: theme.grey4
  },
  right: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-end',
    fontSize: 10,
    lineHeight: '13px'
  },
  uploadDate: {
    color: theme.grey4
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
