export default theme => ({
  item: {
    margin: '0px 15px',
    padding: 0,
    border: `1px solid ${theme.grey7}`,
    borderRadius: 5,
    overflow: 'hidden'
  },
  header: {
    width: '100%',
    borderBottom: `1px solid ${theme.grey7}`,
    padding: '12px 10px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative'
  },
  headerLeft: {
    marginRight: 20,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '5px 10px',
    cursor: 'pointer',
    color: 'unset',
    textDecoration: 'none',
    overflow: 'hidden'
  },
  traceName: {
    lineHeight: '20px',
    fontWeight: 600,
    fontSize: 16,
    color: theme.grey1,
    marginRight: 21
  },
  workflowInfo: {
    fontSize: 14,
    color: theme.grey4,
    marginRight: 21,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  workflowName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  workflowNameSeparator: {
    margin: '0px 10px'
  },
  groupOwnerName: {
    flexShrink: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  headerRight: {
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  deadlineOnTime: {
    marginRight: 15,
    color: theme.grey1,
    fontWeight: 500,
    fontSize: 12
  },
  deadlineLate: {
    marginRight: 15,
    padding: '3px 15px',
    backgroundColor: theme.warningRed,
    borderRadius: 20,
    color: theme.white1,
    fontWeight: 500,
    fontSize: 10,
    textTransform: 'uppercase'
  },
  actions: {
    '& button': {
      borderRadius: 5
    }
  },
  icon: {
    flexShrink: 0,
    display: 'block',
    width: 18,
    height: 18,
    '&[data-is-svg=true]': {
      transform: 'translateX(-1px)',
      '& > path': {
        transform: 'scale(0.75) translateX(2px) translateY(1px)'
      }
    }
  },
  content: {
    paddingInline: '20px',
    paddingBlock: '10px',
    backgroundColor: theme.grey8
  },
  contentItemHeader: {
    lineHeight: '15px',
    fontSize: 12,
    fontWeight: 600,
    color: theme.grey2,
    marginBottom: 5
  },
  contentItemProperties: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  contentItemProperty: {
    flexGrow: 1,
    flexBasis: 0,
    marginRight: 15,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    overflow: 'hidden'
  },
  contentItemPropertyInfo: {
    marginLeft: 8,
    lineHeight: '18px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    color: theme.grey1
  },
  navLink: {
    cursor: 'pointer',
    color: 'unset',
    textDecoration: 'none'
  }
});
