import gql from 'graphql-tag';

export default {
  workflow: gql`
    fragment WorkflowQueryFragment on Workflow {
      rowId
      name
      canUpdate
      isReader
      config {
        rowId
        overview
        initState
        initActions
        stateSchema
        actions
        transitions
      }
      # If $groupBy is null, all the traces will be available in one group with index 'null'
      groupedTraceStates(path: $groupBy) {
        nodes {
          index
          traceStates(
            first: $first
            after: $cursor
            filter: $filter
            orderBy: $orderBy
          ) {
            totalCount
            pageInfo {
              endCursor
              hasNextPage
            }
            nodes {
              ...TraceStateFragment
            }
          }
        }
      }
      groups {
        nodes {
          ...GroupFragment
        }
      }
    }

    fragment TraceStateFragment on TraceState {
      rowId
      data
      nextActions
      meta
      trace {
        rowId
      }
    }

    fragment GroupFragment on Group {
      rowId
      label
      canAct: canActQuick
      name
      avatar
    }
  `
};
