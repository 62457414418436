export default theme => ({
  dataTab: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px'
  },
  dataLabel: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px'
  },
  dataIcon: {
    color: theme.indigo2
  },
  dataInfo: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    gap: '2px'
  },
  dataName: {
    fontSize: 12,
    lineHeight: '15px'
  },
  dataInfoMeta: {
    fontSize: 10,
    lineHeight: '13px',
    color: theme.grey4
  },
  dataInfoActivity: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-end',
    gap: '4px',
    fontSize: 10,
    lineHeight: '13px'
  },
  dataInfoWho: {
    color: theme.grey1
  },
  dataInfoWhen: {
    color: theme.grey4
  }
});
