import { getSortConfig as getWidgetSortConfig } from 'components/ui/widget';
import { sortData as doSortData } from 'utils/sorting';

// client side table sort

// get the relevant sort config to apply
// ie either specified in the column definition or default provide by the cell widget itself
export const getSortConfig = colConfig =>
  colConfig.def.sort || getWidgetSortConfig(colConfig.def.cell || {});

export const sortData = (dataArray, displayConfig) => {
  const { fixedColumns, columns, sortSetup } = displayConfig;

  if (!sortSetup || !sortSetup.key) return dataArray;

  // find the column to sort on
  const column =
    fixedColumns.find(col => col.key === sortSetup.key) ||
    columns.find(col => col.key === sortSetup.key);
  if (!column) return dataArray;

  // find the sort specification either directly in the column definition or ask the cell widget its default behaviour
  const sortConfig = getSortConfig(column);

  return doSortData(dataArray, sortConfig, sortSetup.direction);
};
