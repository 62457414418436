export default theme => ({
  subHeader: {
    flexShrink: 0,
    margin: '0px 21px',
    padding: '16px 0px',
    borderBottom: `1px solid ${theme.grey6}`,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  subHeaderLeft: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    [theme.mediaQueries.small]: {
      flexFlow: 'column nowrap',
      alignItems: 'flex-start'
    }
  },
  subHeaderTitle: {
    marginRight: 20,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '30px'
  },
  searchInput: {
    lineHeight: 'normal',
    [theme.mediaQueries.small]: {
      marginTop: 10
    }
  },
  subHeaderRight: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      paddingLeft: 10,
      paddingRight: 1,
      height: 25
    }
  },
  groupsCount: {
    margin: '0px 10px',
    fontSize: 14,
    color: theme.grey4
  },
  addGroupLabel: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    fontSize: 10,
    lineHeight: '13px',
    fontWeight: 500
  },
  addGroupIcon: {
    flexShrink: 0,
    display: 'block',
    height: 28,
    width: 28
  }
});
