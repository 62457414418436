export default theme => ({
  forms: {
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    padding: '14px 14px 14px 0px',
    marginLeft: 14
  },
  plusZone: {
    position: 'sticky',
    top: 0,
    marginRight: 20,
    flexShrink: 0,
    width: 350,
    height: 150,
    borderRadius: 5,
    border: `2px dashed ${theme.grey5}`,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.grey5,
    transition: '250ms',
    '&[data-is-disabled=true]': {
      cursor: 'not-allowed'
    },
    '&[data-is-disabled=false]:hover': {
      color: theme.grey2,
      borderColor: theme.grey2
    }
  },
  footer: {
    zIndex: 4, // note: set at least to 4 as main form has zIndex:3 because some fields in atomic/forms have zIndex:2 ...
    width: '100%',
    boxShadow: theme.indigoShadow1,
    backgroundColor: theme.white1,
    padding: '12px 20px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    fontSize: 14,
    flexShrink: 0
  }
});
