import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { DragDrop } from '@stratumn/icons';

// Re-resizable
import { Resizable } from 're-resizable';

// Tooltip to show the current column width
import InfoTooltip from 'components/ui/utils/infoTooltip';

import styles from './resizableColHeader.style';

import { columnsWidthPresetsInverted } from '../displayConfigUtils';

// resizable column header with a tooltip to show current width
// (its icon bears the draggable handle as well)
const ResizableColHeader = React.memo(
  ({
    classes,
    width,
    height,
    columnKey,
    columnHeader,
    setColumnWidth,
    dragHandleProps,
    allowResizing
  }) => {
    const [isResizing, setIsResizing] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(width);
    const headerRef = useRef();

    // update the current width if the prop changes
    // eg if another column has been resized manually and this one has been subsequently resized
    useEffect(() => {
      if (width !== currentWidth) setCurrentWidth(width);
    }, [width]);

    const currentWidthPresetLabel = columnsWidthPresetsInverted[currentWidth];
    const tooltipLabel = `Column Width:\n${currentWidth} px${
      currentWidthPresetLabel ? ` (${currentWidthPresetLabel})` : ''
    }`;

    return (
      <>
        <Resizable
          className={classes.resizableColHeader}
          data-is-resizable={allowResizing}
          size={{
            width: currentWidth,
            height
          }}
          grid={[5, 1]}
          enable={{
            top: false,
            right: allowResizing,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
          onResizeStart={() => setIsResizing(true)}
          onResizeStop={() => {
            setIsResizing(false);
            if (currentWidth !== width) {
              setColumnWidth(columnKey, currentWidth);
            }
          }}
          onResize={(e, direction, ref, d) => {
            setCurrentWidth(width + d.width);
          }} // update the tooltip content dynamically
        >
          <div ref={headerRef} className={classes.resizableColHeaderCell}>
            <div className={classes.resizableColHeaderLabel}>
              {columnHeader.toUpperCase()}
            </div>
            <div
              className={classes.resizableColHeaderIconWrapper}
              {...dragHandleProps}
            >
              <DragDrop
                className={classes.resizableColHeaderIcon}
                data-is-dragging={!allowResizing}
              />
            </div>
          </div>
        </Resizable>
        {isResizing && (
          <InfoTooltip
            clientRef={headerRef}
            text={tooltipLabel}
            textColor="white"
            backgroundColor="black"
            position={{
              place: 'above',
              placeShift: 3,
              adjustPlace: true,
              anchor: 'left'
            }}
            delay={100}
          />
        )}
      </>
    );
  }
);

ResizableColHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  columnKey: PropTypes.string.isRequired,
  columnHeader: PropTypes.string.isRequired,
  setColumnWidth: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.object.isRequired,
  allowResizing: PropTypes.bool.isRequired
};

export default injectSheet(styles)(ResizableColHeader);
