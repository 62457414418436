export default theme => ({
  contextMenuWrapper: {
    position: 'absolute',
    zIndex: 6,
    top: 7,
    right: 'calc(100% - 42px)'
  },
  contextMenuContent: {
    ...theme.scrollbar,
    minWidth: 150,
    maxWidth: 400,
    overflow: 'auto',
    color: theme.grey1,
    backgroundColor: theme.white1,
    borderRadius: 5,
    border: `1px solid ${theme.grey5}`,
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    overscrollBehavior: 'contain'
  },
  contextMenuArrow: {
    width: 20,
    height: 10,
    display: 'block',
    position: 'absolute',
    top: -8,
    right: 22,
    zIndex: 3,
    stroke: theme.grey5,
    fill: theme.grey9,
    strokeLinecap: 'round',
    strokeWidth: '1',
    strokeLinejoin: 'round'
  }
});
