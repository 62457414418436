import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';
import {
  SortingIconGeneral,
  SortingIconAscending,
  SortingIconDescending
} from '@stratumn/icons';

import { SORT_ASCENDING, SORT_DESCENDING } from 'utils/sorting';
import { getSortConfig } from '../sort';

import styles from './colHeaders.style';

// click event on sort icon
// sortSetup is the setup for sorting the table, ie a sorting configuration identified by a colKey and a sorting direction
// clicking on a sort icon will replace or modify the sorting configuration
const clickSortIcon = (
  colConfig, // the config of the column to be used for the sort
  sortSetup, // the global table sort setup described above
  setSortSetup // function called to modify the table sorting setup
) => {
  // first check what the sort setup should be for this column
  let newSortSetup = null;
  if (sortSetup && sortSetup.key === colConfig.key) {
    if (sortSetup.direction === SORT_ASCENDING) {
      // next switch to descending
      newSortSetup = { key: colConfig.key, direction: SORT_DESCENDING };
    }
  } else {
    // sort ascending
    newSortSetup = { key: colConfig.key, direction: SORT_ASCENDING };
  }

  const sortConfig = getSortConfig(colConfig);
  setSortSetup(newSortSetup, sortConfig);
};

// table column headers
const ColHeaders = React.memo(
  ({ classes, height, selectBoxWidth, columns, sortSetup, setSortSetup }) => (
    <div className={classes.colHeaders}>
      {selectBoxWidth > 0 && (
        <div
          className={classes.firstColumnPlaceholder}
          style={{
            width: selectBoxWidth,
            height
          }}
        />
      )}
      {columns.map((colConfig, index) => {
        const isSorted = sortSetup && sortSetup.key === colConfig.key;
        const sortThisColumn = () => {
          clickSortIcon(colConfig, sortSetup, setSortSetup);
        };
        let SortIcon = SortingIconGeneral;
        if (isSorted) {
          SortIcon =
            sortSetup.direction === SORT_ASCENDING
              ? SortingIconAscending
              : SortingIconDescending;
        }
        return (
          <div
            key={index}
            className={classes.colHeadersCell}
            data-is-sorted={isSorted}
            style={{
              width: colConfig.width,
              height
            }}
          >
            <div className={classes.colHeadersLabel}>
              {colConfig.def.header.toUpperCase()}
            </div>
            <div className={classes.colHeadersIconsContainer}>
              <div className={classes.colHeaderSortIconWrapper}>
                <SortIcon
                  className={classes.colHeaderSortIcon}
                  onClick={sortThisColumn}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  )
);
ColHeaders.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  selectBoxWidth: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortSetup: PropTypes.object,
  setSortSetup: PropTypes.func.isRequired
};
ColHeaders.defaultProps = {
  selectBoxWidth: 0,
  sortSetup: null
};

export default injectSheet(styles)(ColHeaders);
