import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import DynamicIcon from 'components/ui/dynamicIcon';

import { getByPath } from 'utils/widgets';

import styles from './icon.style';

const IconViewImpl = React.memo(({ classes, view, data }) => {
  const { path, iconPath, labelPath, icon } = view;

  // if a 'path' has been specified we assume this is to check whether the icon should be displayed or not
  if (path && !getByPath(data, path)) {
    return null;
  }
  const iconStr = getByPath(data, iconPath) || icon || 'Document';
  const iconComponent = <DynamicIcon icon={iconStr} size={24} />;
  const label = getByPath(data, labelPath);
  return (
    <div className={classes.iconView}>
      {iconComponent}
      {label && <span className={classes.iconViewLabel}>{label}</span>}
    </div>
  );
});
IconViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to text on iconPath or labelPath, or binary on path
const getSortConfig = view => {
  const { path, labelPath, iconPath } = view;
  if (!labelPath && !iconPath) return { type: 'binary', path };
  return {
    type: 'text',
    path: labelPath || iconPath
  };
};

// filtering defaults to text search on iconPath
const getFilterConfig = view => ({
  type: 'text',
  path: view.labelPath || view.iconPath || view.path
});

// width defaults to 'medium' if a label is shown, 'small' otherwise
const getDefaultWidth = view => (!view.labelPath ? 'small' : 'medium');

// stringifies data at path
const getStringifyFunction = view => {
  const { path, labelPath } = view;

  // output either labelPath if specified
  if (labelPath !== undefined) return data => getByPath(data, labelPath);

  // or just the boolean value associated to path (that triggered the icon rendering)
  if (path !== undefined) return data => (!!getByPath(data, path)).toString();

  return () => '';
};

export default {
  component: injectSheet(styles)(IconViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
