export default theme => ({
  displayMenu: {
    padding: '10px',
    '&:first-child': {
      borderBottom: `1px solid ${theme.grey6}`
    }
  },
  displayMenuTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: theme.grey3
  },
  displayMenuList: {
    fontSize: 12,
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    minWidth: '200px'
  },
  displayMenuGroupBy: {
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
    gap: '5px',
    minWidth: '200px',
    '& > label > div': {
      padding: 0
    },
    '& button': {
      fontSize: 12,
      height: '26px',
      '& div': {
        height: 11
      }
    }
  },
  displayMenuItem: {
    display: 'inline-flex',
    verticalAlign: 'top',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    flexShrink: 0,
    margin: '0px',
    borderRadius: '5px',
    minWidth: '24px',
    height: '24px',
    padding: '0px 8px',
    border: '0.5px solid rgb(216, 216, 216)',
    boxShadow:
      'rgba(0, 0, 0, 0.02) 0px 4px 4px -1px, rgba(0, 0, 0, 0.06) 0px 1px 1px 0px',
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(48, 48, 49)',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.grey7
    },
    '&[data-is-selected=false]': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      color: theme.grey3
    }
  }
});
