import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import injectSheet from 'react-jss';
import pathToRegexp from 'path-to-regexp';
import classnames from 'classnames';
import { IconbuttonAdd, Icon } from '@stratumn/atomic';
import { notify } from 'components/toast';
import { Cross, Settings, Bell, BellFill } from '@stratumn/icons';
import Tooltip from 'components/ui/utils/tooltip';
import { useTooltip } from 'utils/hooks';
import InfoTooltip from 'components/ui/utils/infoTooltip';
import theme from 'style';

import { formatNumber } from 'utils';
import {
  ROUTE_WORKFLOW_OVERVIEW,
  ROUTE_WORKFLOW_GROUPS
} from 'constant/routes';

import to from 'await-to-js';
import Ellipsis from 'components/ui/ellipsis';
import { withSpanAsync, SpanType } from '../../../../tracing';

import mutation from './uploadDocumentationModal/mutation';
import DownloadFile from './downloadFile';
import UploadDocumentationModal from './uploadDocumentationModal';
import SubscriptionsMenu from './subscriptionsMenu';

import styles from './row.style';

const TOOLTIP_POSITION = {
  place: 'below',
  adjustPlace: true
};

export const WorkflowRow = React.memo(
  ({
    classes,
    columnsClassNames,
    workflow,
    isSuperuser,
    updateWorkflowDocumentation,
    userId
  }) => {
    const [showModal, setShowModal] = useState(false);
    const [showSubscriptionsMenu, setShowSubscriptionsMenu] = useState(false);

    const subscriptionsButtonRef = useRef(null);

    const {
      rowId,
      canUpdate,
      name,
      traces,
      groups,
      documentation,
      actions: { nodes: actions },
      settings,
      notificationSubscriptionsUpdated: { nodes: notificationSubscriptions }
    } = workflow;

    const subscriptionsDisabled =
      settings?.disableSubscriptions === true ||
      !notificationSubscriptions?.length;

    const toggleModal = useCallback(() => {
      setShowModal(!showModal);
    }, [showModal]);

    const deleteWorkflowDocumentation = () => {
      const { url, file } = documentation;
      let documentationName;

      if (url) documentationName = url;
      if (file) documentationName = file.name;

      withSpanAsync(
        'updateWorkflowDocumentation',
        SpanType.processing,
        async () => {
          const promise = updateWorkflowDocumentation({
            variables: { rowId: rowId, url: '', fileDigest: null }
          });

          notify.promise(promise, {
            loading: 'Deleting documentation...',
            success: `${documentationName} documentation deleted`,
            error: `${documentationName} documentation could not be deleted`
          });
          await to(promise);
        }
      );
    };

    const renderDeleteWorkflowDoc = () => (
      <button
        className={classes.deleteBtnWrapper}
        data-cy="delete-button"
        type="reset"
        title="delete documentation"
        onClick={deleteWorkflowDocumentation}
      >
        <Cross className={classes.deleteBtn} />
      </button>
    );

    const renderDocumentation = () => {
      if (documentation) {
        const { file, url } = documentation;

        if (url) {
          return (
            <div className={classes.linkWrapper}>
              <div className={classes.linkIcon}>
                <Icon name="LinkLight" size={22} />
              </div>
              <Ellipsis>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {url}
                </a>
              </Ellipsis>
              {canUpdate && renderDeleteWorkflowDoc()}
            </div>
          );
        }
        if (file) {
          return (
            <div className={classes.downloadFileWrapper}>
              <DownloadFile data={file} />
              {canUpdate && renderDeleteWorkflowDoc(0)}
            </div>
          );
        }
      }

      if (!canUpdate) return null;

      return (
        <>
          <div className={classes.uploadDocumentation}>
            <div className={classes.iconBtnAdd}>
              <IconbuttonAdd
                onClick={toggleModal}
                data-cy="open-workflow-documentation-modal"
              />
            </div>
            Add documentation
          </div>
          {showModal && (
            <UploadDocumentationModal
              workflowRowId={rowId}
              onClose={toggleModal}
            />
          )}
        </>
      );
    };

    const toggleSubscriptions = useCallback(() => {
      setShowSubscriptionsMenu(!showSubscriptionsMenu);
    }, [showSubscriptionsMenu]);

    const [
      onSubscribeDisabledEnter,
      onSubscribeDisabledLeave,
      showSubscriptionsDisabledTooltip
    ] = useTooltip(true);

    const renderSubscriptionsMenu = () => (
      <Tooltip
        clientEl={subscriptionsButtonRef.current}
        portalEl={document.body}
        position={TOOLTIP_POSITION}
        onClickOutside={toggleSubscriptions}
        arrowUp={<div className={classes.subscriptionTooltipArrowUp} />}
      >
        <div className={classes.subscriptionTooltipWrapper}>
          <SubscriptionsMenu
            userId={userId}
            workflowId={workflow.rowId}
            actions={actions}
            subscriptions={notificationSubscriptions}
            onCrossClick={toggleSubscriptions}
          />
        </div>
      </Tooltip>
    );

    const renderSubscriptionsDisabledTooltip = () =>
      subscriptionsDisabled ? (
        <InfoTooltip
          clientRef={subscriptionsButtonRef}
          text={
            !notificationSubscriptions?.length
              ? 'No notifications available for this workflow'
              : 'Notification subscriptions are disabled for this workflow'
          }
          textColor={theme.white1}
          backgroundColor={theme.grey1}
          position={TOOLTIP_POSITION}
          delay={200}
        />
      ) : (
        false
      );

    return (
      <tr>
        <th className={columnsClassNames.columnName}>
          <Link
            className={classes.link}
            to={pathToRegexp.compile(ROUTE_WORKFLOW_OVERVIEW)({ id: rowId })}
            data-cy="workflow-row-name"
          >
            <div className={classes.workflowName}>
              <Ellipsis>{name}</Ellipsis>
            </div>
          </Link>
        </th>
        <td
          className={classnames(
            columnsClassNames.columnTraces,
            classes.numberCell
          )}
          data-cy="trace-count"
        >
          {formatNumber(traces.totalCount)}
        </td>
        <td
          className={classnames(
            columnsClassNames.columnGroups,
            classes.numberCell
          )}
          data-cy="group-count"
        >
          {formatNumber(groups.totalCount)}
        </td>
        <td
          className={classnames(
            columnsClassNames.columnDocumentation,
            classes.documentationWrapper
          )}
          data-cy="documentation"
        >
          {renderDocumentation()}
        </td>
        <td className={columnsClassNames.columnActions}>
          <div className={classes.actionsWrapper}>
            <button
              ref={subscriptionsButtonRef}
              className={classes.actionIconBtn}
              onClick={toggleSubscriptions}
              disabled={subscriptionsDisabled}
              data-cy="subscriptions-button"
            >
              <div
                onMouseEnter={
                  subscriptionsDisabled ? onSubscribeDisabledEnter : undefined
                }
                onMouseLeave={
                  subscriptionsDisabled ? onSubscribeDisabledLeave : undefined
                }
              >
                {showSubscriptionsMenu ? (
                  <BellFill
                    className={classnames(
                      classes.actionIcon,
                      classes.actionIconActive
                    )}
                  />
                ) : (
                  <Bell className={classnames(classes.actionIcon)} />
                )}
              </div>
            </button>
            {showSubscriptionsDisabledTooltip &&
              renderSubscriptionsDisabledTooltip()}
            {showSubscriptionsMenu && renderSubscriptionsMenu()}

            {canUpdate && isSuperuser && (
              <Link
                className={classes.actionIconBtn}
                to={pathToRegexp.compile(ROUTE_WORKFLOW_GROUPS)({ id: rowId })}
                data-cy="workflow-settings-link"
              >
                <Settings className={classes.actionIcon} />
              </Link>
            )}
          </div>
        </td>
      </tr>
    );
  }
);

WorkflowRow.propTypes = {
  classes: PropTypes.object.isRequired,
  columnsClassNames: PropTypes.shape({
    columnName: PropTypes.string,
    columnTraces: PropTypes.string,
    columnGroups: PropTypes.string,
    columnDocumentation: PropTypes.string,
    columnActions: PropTypes.string
  }).isRequired,
  workflow: PropTypes.object.isRequired,
  isSuperuser: PropTypes.bool,
  updateWorkflowDocumentation: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
};
WorkflowRow.defaultProps = {
  isSuperuser: false
};

export const fragments = {
  workflowRow: gql`
    fragment WorkflowRowFragment on Workflow {
      rowId
      canUpdate
      name
      description
      actions {
        nodes {
          key
          title
        }
      }
      settings
      # https://stratumn.atlassian.net/browse/ENG-248
      # https://github.com/stratumn/trace-api/pull/599
      # workflow.traces calls the workflow_traces() function, which seems to go through all workflow traces.

      # We just want the count in this case, and tracesDeprecated iterates on the raw table which is more optimized.
      # This reduces the user dashboard loading time from ~18s to ~5.5s for admin@stratumn.com on staging,
      # with a couple of workflows with thousands of traces.

      # Making the change in the backend is not possible because workflow.traces as it is
      # is used by cnp-proxy-server to get filtered traces.
      traces: tracesDeprecated {
        totalCount
      }
      groups {
        totalCount
      }
      documentation {
        url
        file {
          name
          size
          digest
        }
      }
      notificationSubscriptionsUpdated {
        nodes {
          rowId
          actionkey
          subscription
        }
      }
    }
  `
};

export default compose(
  graphql(mutation.updateWorkflowDocumentation, {
    name: 'updateWorkflowDocumentation'
  }),
  injectSheet(styles)
)(WorkflowRow);
