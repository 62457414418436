import { read, utils } from 'xlsx';

export const readXls = ({ inputFile }) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(inputFile);

    reader.onload = () => {
      try {
        const wb = read(reader.result, { type: 'array' });
        if (wb.SheetNames.length > 1) {
          reject(
            new Error('Multiples sheets', {
              cause: { type: 'multiplesSheets', workbook: wb }
            })
          );
        }
        const worksheet = wb.Sheets[wb.SheetNames[0]];
        const xlsFile = utils.sheet_to_json(worksheet);

        resolve(xlsFile);
      } catch (error) {
        reject(
          new Error(error.message, { cause: { type: 'dataNotReadable' } })
        );
      }
    };

    reader.onerror = () =>
      reject(new Error(reader.error, { cause: { type: 'dataNotReadable' } }));
  });

export const runXlsParser = ({
  xlsFile,
  mappingConfig,
  onError = () => {},
  onSuccess = () => {}
}) => {
  try {
    const { columns } = mappingConfig;

    const data = xlsFile.map(row => {
      const res = Object.keys(row).reduce((prev, curr) => {
        const header = columns.find(item => item.from === curr);
        if (header) {
          return { ...prev, [header.to]: row[curr] };
        }
        return {};
      }, {});

      return res;
    });
    const parsingResult = {
      data
    };
    onSuccess(parsingResult);
  } catch (error) {
    onError(error.message);
  }
};

export const sheetToJson = (selectedSheet, workbook) => {
  const sheetName = selectedSheet || workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  return utils.sheet_to_json(worksheet);
};
