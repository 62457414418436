export default theme => ({
  windowRoot: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginBottom: 10,
    background: theme.white1,
    border: `1px solid ${theme.grey7}`,
    borderRadius: 5,
    width: '100%'
  },
  windowTitleWrapper: {
    padding: '7.5px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
    width: '100%',
    cursor: props => (props.view.collapsable ? 'pointer' : 'unset')
  },
  windowTitle: {
    paddingLeft: '7.5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 17,
    lineHeight: '23px',
    fontWeight: 600,
    color: theme.grey1,
    flexGrow: 1,
    '&::first-letter': {
      textTransform: 'uppercase'
    },
    userSelect: 'none'
  },
  windowInfo: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: '10px'
  },
  icon: {
    color: theme.blue900,
    transition: 'transform 0.2s ease-in-out',
    '&[data-is-collapse="true"]': {
      transform: 'rotate(-180deg)',
      color: theme.grey1
    }
  },
  windowItemCount: {
    whiteSpace: 'nowrap',
    fontSize: 12,
    color: theme.grey4
  },
  gridWrapper: {
    display: 'grid',
    gridTemplateRows: '0fr',
    transition: 'all 0.1s ease-out',
    overflow: 'hidden',
    borderTop: `1px solid transparent`
  },
  isCollapsed: {
    gridTemplateRows: '1fr',
    paddingBlock: '15px',
    borderTop: `1px solid ${theme.grey7}`
  },
  itemsWrapper: {
    padding: '0px 15px 0px 15px',
    display: 'flex',
    flexFlow: 'column nowrap',
    gap: '20px',
    overflow: 'hidden',
    position: 'relative'
  },
  itemWidget: {
    '&:empty': {
      display: 'none'
    },
    '&[data-is-hoverable="true"]': {
      padding: 10,
      cursor: 'pointer',
      backgroundColor: theme.grey9,
      borderRadius: 2,
      border: `1px solid ${theme.greyTable}`,
      transition: 'border-color 250ms',
      '&:hover': {
        borderColor: theme.indigo2
      },
      '&:active': {
        backgroundColor: theme.grey8
      }
    }
  }
});
