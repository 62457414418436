// data parsing and reporting constants
export const DATA_PARSING_STATUS_DONE = 'done';
export const DATA_PARSING_STATUS_ERROR = 'error';
export const DATA_PARSING_STATUS_PARSING = 'parsing';
export const DATA_PARSING_STATUS_AGGREGATING = 'aggregating';

// csv reporting steps
export const PARSING_STEP_OPENING = 'Opening';
export const PARSING_STEP_IMPORTING = 'Importing';

// data aggregation reporting steps
export const AGGREGATION_STEP_INDEXING = 'Indexing';
export const AGGREGATION_STEP_AGGREGATING = 'Aggregating';
