import saveCsv from 'save-csv';
import { downloadExcel } from 'react-export-table-to-excel';

import { deepGet } from 'utils';

const fieldBlacklist = new Set(['__typename', 'form', 'version', 'headLink']);

export const feedFieldsWithObject = (fields, obj, prefix = '') => {
  if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
    if (prefix) fields.add(prefix);
    return;
  }
  Object.keys(obj).forEach(key => {
    if (fieldBlacklist.has(key)) return;
    const newPrefix = prefix ? `${prefix}.${key}` : key;
    feedFieldsWithObject(fields, obj[key], newPrefix);
  });
};

export default ({ workflow, traces, fileType }) => {
  // Build a complete list of featured fields
  const fieldSet = new Set();
  traces.forEach(trace => feedFieldsWithObject(fieldSet, trace));
  const header = Array.from(fieldSet).map(key => key.replace(/data\./g, ''));

  const payload = traces.map(trace => {
    const row = {};
    const fields = fieldSet.values();
    for (
      let { value: field, done } = fields.next();
      !done;
      { value: field, done } = fields.next()
    ) {
      let value = deepGet(trace, field);
      if (typeof value !== 'string') value = JSON.stringify(value);
      row[field.replace(/data\./g, '')] = value;
    }
    return row;
  });

  if (fileType === 'csv')
    saveCsv(payload, {
      filename: `${workflow.name}.csv`
    });

  if (fileType === 'excel')
    downloadExcel({
      fileName: workflow.name,
      sheet: workflow.name,
      tablePayload: {
        header,
        body: payload
      }
    });
};
