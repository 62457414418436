export default theme => ({
  subscriptionsMenuContainer: {
    width: 600
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 15px 15px 20px',
    borderBottom: `1px solid ${theme.grey6}`
  },
  headerTitle: {
    display: 'flex',
    flexFlow: 'column nowrap',
    fontWeight: 600,
    fontSize: 16
  },
  headerTitleUnderline: {
    backgroundColor: theme.teal1,
    width: 15,
    height: 5,
    borderRadius: 10
  },
  headerButtonGrp: {
    display: 'flex',
    alignItems: 'center'
  },
  crossBtn: {
    color: theme.grey3,
    marginBottom: -10,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    '&:hover, &:focus-visible': {
      color: theme.grey1
    },
    '&:focus-visible': {
      outline: `3px solid ${theme.indigoPastel3}`
    }
  },
  crossBtnIcon: {
    width: '40px',
    height: '40px'
  },
  helpBtn: {
    color: theme.grey3,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    '&:hover, &:focus-visible': {
      color: theme.grey1
    },
    '&:focus-visible': {
      outline: `3px solid ${theme.indigoPastel3}`
    }
  },
  helpBtnIcon: {
    width: '25px',
    height: '25px'
  },
  main: {
    display: 'flex',
    padding: '20px 0 20px 20px',
    flexFlow: 'column nowrap',
    maxHeight: 275,
    overflowY: 'auto',
    fontSize: 14
  },
  subscription: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
    alignItems: 'center',
    marginBottom: 20,
    '&:last-child': {
      marginBottom: 0
    }
  },
  helperTooltipArrowUp: {
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: `6px solid ${theme.white1}`,
    boxShadow: theme.indigoShadow2
  },
  helperTooltipWrapper: {
    background: theme.white1,
    boxShadow: theme.indigoShadow2,
    borderRadius: '10px',
    overflow: 'hidden',
    padding: '20px',
    fontSize: 14,

    '& ul': {
      listStyleType: 'disc',
      paddingLeft: 20
    },
    '& li': {
      marginBottom: '8px'
    },
    '& li:last-child': {
      marginBottom: '0'
    }
  },
  helperParameterName: {
    fontWeight: 600
  }
});
