import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import qs from 'query-string';
import { LogoTriangle, Pushbutton } from '@stratumn/atomic';

import { deepGet } from 'utils';
import { ACCOUNT_LOGIN_URL, CLIENT_ID } from 'constant/api';
import logo from 'assets/IndigoTrace_StackLogo.svg';

import styles from './login.style';

export class Login extends React.Component {
  static propTypes = {
    classes: PropType.object.isRequired
  };

  componentDidMount() {
    // clear the cookie when we try loggin in
    document.cookie =
      'local_stratumn_account=; expires=Thu, 01 Jan 1970 00:00:01 GMT;stratumn_account=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.title = 'Log in - Trace';
  }

  getRedirectUri = () => {
    const redirect = deepGet(this.props, 'location.state.redirect');
    return `${window.location.origin}${redirect || ''}`;
  };

  login = () => {
    const params = qs.stringify({
      clientId: CLIENT_ID,
      redirectUri: this.getRedirectUri()
    });
    window.location.replace(`${ACCOUNT_LOGIN_URL}?${params}`);
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.loginBG} role="none" />
        <div className={classes.container}>
          <div className={classes.topContainer}>
            <img className={classes.logo} src={logo} alt="" />
            <div className={classes.punchline}>
              The new backbone to business collaboration
            </div>
            <div className={classes.loginContainer}>
              <div className={classes.loginTitle}>
                Sign in with Stratumn Account
              </div>
              <Pushbutton
                onClick={this.login}
                dataCy="loginButton"
                primary
                prefix={<LogoTriangle fillWhite size={13} />}
              >
                Sign in
              </Pushbutton>
            </div>
          </div>
          <div className={classes.footer}>
            Built by{' '}
            <a
              href="https://stratumn.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stratumn
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default injectSheet(styles)(Login);
