import { createContext } from 'react';
import PropTypes from 'prop-types';

const BoxSectionContext = createContext({
  index: null
});

BoxSectionContext.PropTypes = {
  index: PropTypes.number.isRequired
};

export default BoxSectionContext;
