import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { Pushbutton } from '@stratumn/atomic';
import styles from './pushButton.style';

export class PushbuttonInspector extends Component {
  static defaultProps = {
    disabled: false,
    title: 'Next action',
    primary: true
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    primary: PropTypes.bool
  };

  render = () => {
    const { classes, onClick, primary, disabled } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.pullDownContainer}>
          <Pushbutton onClick={onClick} primary={primary} disabled={disabled}>
            {this.props.title}
          </Pushbutton>
        </div>
      </div>
    );
  };
}

export default injectSheet(styles)(PushbuttonInspector);
