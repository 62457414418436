import moment from 'moment';
import { getByPath } from 'utils/widgets';

export const SORT_ASCENDING = 'ASC';
export const SORT_DESCENDING = 'DESC';

export const DEFAULT_SORT_WORKFLOW_TRACE_STATE_PATH = 'head.createdAt';
export const DEFAULT_SORT_WORKFLOW_TRACE_STATE_COLUMN = 'meta';

// common wrapper to handle undefined when comparing
// undefined values are equal and placed first vs real values
const handleUndefinedComparison = compareValuesFn => (lhs, rhs) => {
  const lhsValue = lhs.value;
  const rhsValue = rhs.value;
  if (lhsValue === undefined) {
    if (rhsValue === undefined) return 0;
    return -1;
  }
  if (rhsValue === undefined) return 1;
  return compareValuesFn(lhsValue, rhsValue);
};

// simple text sorting
const getText = value => {
  if (typeof value === 'object') return JSON.stringify(value); // fallback if the sorter points at an object
  if (value === undefined) return '';
  return value.toString();
};
const textSort = sortConfig => {
  const mapping = (el, idx) => {
    const { path } = sortConfig;
    const value = getText(getByPath(el, path));
    return {
      idx,
      value
    };
  };

  // note: no handleUndefinedComparison wrapper here as getText already transforms undefined to ''
  const sorting = (lhs, rhs) => lhs.value.localeCompare(rhs.value);

  return { mapping, sorting };
};

// binary sort: just group according to an indicator
const binarySort = sortConfig => {
  const mapping = (el, idx) => {
    const { path } = sortConfig;
    const value = getByPath(el, path) ? 1 : 0;
    return {
      idx,
      value
    };
  };

  const sorting = handleUndefinedComparison(
    (lhsValue, rhsValue) => lhsValue - rhsValue
  );

  return { mapping, sorting };
};

// sorting on numbers
const numberSort = sortConfig => {
  const mapping = (el, idx) => {
    const { path } = sortConfig;
    let value = Number(getByPath(el, path));
    if (Number.isNaN(value)) {
      value = undefined;
    }
    return {
      idx,
      value
    };
  };

  const sorting = handleUndefinedComparison(
    (lhsValue, rhsValue) => lhsValue - rhsValue
  );

  return { mapping, sorting };
};

// sorting on dates
// note: normally text sort is enough for iso dates sorting
// but here we keep the functionality to create moments from strings just in case a client sends date in non iso form
const dateSort = sortConfig => {
  const mapping = (el, idx) => {
    const { path, inputFormat } = sortConfig;
    let value = getByPath(el, path);
    if (value !== undefined) {
      value = moment(value, inputFormat);
      if (value === null || !value.isValid()) value = undefined;
    }
    return {
      idx,
      value
    };
  };

  const sorting = handleUndefinedComparison((lhsValue, rhsValue) => {
    const isSame = lhsValue.isSame(rhsValue);
    if (isSame) return 0;
    return lhsValue.isBefore(rhsValue) ? -1 : 1;
  });

  return { mapping, sorting };
};

// sorting on length of sthg (string, array size)
const lengthSort = sortConfig => {
  const mapping = (el, idx) => {
    const { path } = sortConfig;
    const valueToCheck = getByPath(el, path);
    let value;
    if (typeof valueToCheck === 'string' || Array.isArray(valueToCheck)) {
      value = valueToCheck.length;
    }
    return {
      idx,
      value
    };
  };

  const sorting = handleUndefinedComparison(
    (lhsValue, rhsValue) => lhsValue - rhsValue
  );

  return { mapping, sorting };
};

// sorting on occurrences of a pattern in a text
const occurrenceSort = sortConfig => {
  const mapping = (el, idx) => {
    const { path, search, searchPath } = sortConfig;
    // build the text in which we want to search
    const text = getText(getByPath(el, path)).toUpperCase();
    // check what we need to search for exactly
    const findText = getText(getByPath(el, searchPath) || search).toUpperCase();
    const value = text.split(findText).length - 1;
    return {
      idx,
      value
    };
  };

  const sorting = handleUndefinedComparison(
    (lhsValue, rhsValue) => lhsValue - rhsValue
  );

  return { mapping, sorting };
};

/* sorters map */
const sortersMap = {
  text: textSort,
  binary: binarySort,
  number: numberSort,
  date: dateSort,
  length: lengthSort,
  occurrence: occurrenceSort
};
const defaultSorter = textSort;

/* interface for map-sorting functions */
export const getMapSortFn = sortConfig => {
  if (!sortConfig) return defaultSorter({ path: '' });
  return (sortersMap[sortConfig.type] || defaultSorter)(sortConfig);
};

// client side sort

// apply 'map sorting' to avoid expensive data re-processing
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#Sorting_with_map

const reverse = sorting => (lhs, rhs) => -sorting(lhs, rhs);

export const sortData = (dataArray, sortConfig, direction) => {
  // console logging of the sorting execution, very useful for debugging but commented out in prod not to slow UX down
  // console.log(`sorting ${JSON.stringify(sortConfig)} ${direction}`);

  // get the {mapping,sorting} pair corresponding to this config
  const { mapping, sorting } = getMapSortFn(sortConfig);

  // apply the mapping
  const mapped = dataArray.map(mapping);

  // sort the mapped array
  mapped.sort(
    (direction || SORT_ASCENDING).toUpperCase() === SORT_ASCENDING
      ? sorting
      : reverse(sorting)
  );

  // map back
  const sorted = mapped.map(el => dataArray[el.idx]);

  return sorted;
};
