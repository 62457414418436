export const isJSON = str => {
  try {
    const result = JSON.parse(str);
    return !!str && typeof result === 'object';
  } catch (e) {
    return false;
  }
};

export const checkCommentExists = comment => {
  const isHtml = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
  if (isHtml(comment)) {
    return true;
  }

  const commentIsNotEmpty =
    isJSON(comment) &&
    JSON.parse(comment)
      .map(c => c.children.filter(child => child.text !== ''))
      .map(c => c.length > 0);
  if (isJSON(comment) && commentIsNotEmpty.every(v => v === true)) {
    return true;
  }
  if (
    isJSON(comment) &&
    JSON.parse(comment).length > 1 &&
    commentIsNotEmpty.some(v => v === true)
  ) {
    return true;
  }
  if (comment?.length > 0 && !isJSON(comment) && !isHtml(comment)) {
    return true;
  }
  return false;
};
