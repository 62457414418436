import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import injectSheet from 'react-jss';

import { DownArrowFill } from '@stratumn/icons';

import { useFocus } from 'utils/hooks';
import Tooltip from 'components/ui/utils/tooltip';

import styles from './select.style';

const TOOLTIP_POSITION = {
  place: 'below',
  adjustPlace: true,
  anchor: 'left',
  adjustAnchor: true
};

// custom select dropdown for the date picker
export const Select = ({
  classes,
  className,
  value,
  options,
  onSelect,
  labelDisplay
}) => {
  // dropdown state
  const [isOpen, open, close, btnRef] = useFocus(false);

  // ref to scroll to currently selected value
  const selectedRef = useRef(null);

  // find current selected option
  const selected = useMemo(
    () => options.find(option => option.value === value),
    [options, value]
  );

  const { label } = selected || {};

  // build options list component
  const buildOptionsList = () =>
    options.map(option => (
      <div
        key={option.label}
        className={classes.option}
        data-is-selected={selected === option}
        ref={selected === option ? selectedRef : null} // add a ref to the selected option
        onClick={() => {
          close();
          if (selected !== option) onSelect(option.value);
        }}
        data-cy="select-option"
      >
        {option.label}
      </div>
    ));

  // scroll to selected on list open
  useEffect(() => {
    if (isOpen && selectedRef.current) {
      selectedRef.current.scrollIntoView({
        block: 'center'
      });
    }
  }, [isOpen]);

  // callback to close tooltip on escape
  const closeOnEsc = useCallback(e => {
    if (e.key === 'Escape') {
      close();
    }
  }, []);

  return (
    <>
      <button
        ref={btnRef}
        className={classnames(classes.button, className)}
        onClick={open}
        onKeyDown={closeOnEsc}
        data-input-open={isOpen}
        data-cy="select-btn"
      >
        <div className={classes.label}>
          {labelDisplay ? labelDisplay(label) : label}
        </div>
        <DownArrowFill className={classes.icon} size={18} data-is-up={isOpen} />
      </button>
      {isOpen && (
        <Tooltip
          clientEl={btnRef.current}
          portalEl={document.body}
          position={TOOLTIP_POSITION}
          onClickOutside={close}
        >
          <div className={classes.options}>{buildOptionsList()}</div>
        </Tooltip>
      )}
    </>
  );
};
Select.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  labelDisplay: PropTypes.func // function provided to potentially modify the selected label to display
};
Select.defaultProps = {
  className: '',
  labelDisplay: null
};

export default injectSheet(styles)(React.memo(Select));
