import gql from 'graphql-tag';

export default gql`
  mutation login($input: LoginArgs!) {
    login(input: $input)
      @rest(type: "LoginPayload", method: "POST", path: "/login") {
      authToken
    }
  }
`;
