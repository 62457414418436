import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import { Pen } from '@stratumn/icons';

import { FieldTextCompact } from '@stratumn/atomic';

import styles from './input.style';

// simple debounced text input
// if a header is provided we preserve the border and handle header placement
// when dataStr is not empty, as well as border color
const TextInput = React.memo(
  ({
    classes,
    dataStr,
    onChange,
    placeholder,
    delay,
    className,
    header,
    disabled
  }) => {
    // focus state
    const inputRef = useRef(null);
    const containerRef = useRef(null);
    const [isFocused, setFocus] = useState(false);
    const focus = useCallback(() => {
      setFocus(true);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);
    const blur = useCallback(() => {
      setFocus(false);
    }, []);

    const [inputStr, setInputStr] = useState(dataStr);
    const updateInputStr = useCallback(e => {
      setInputStr(e.target.value);
    }, []);

    // change the text displayed if the data provided changes
    useEffect(
      () => {
        setInputStr(dataStr);
      },
      [dataStr] // Only re-call effect if dataStr changes
    );

    // debounce update of the text input
    useEffect(
      () => {
        if (inputStr === dataStr) return undefined;

        // call onChange after delay
        const handler = setTimeout(() => {
          onChange(inputStr);
        }, delay);

        // Cancel the timeout if value changes (also on unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [inputStr] // Only re-call effect if inputStr changes
    );

    if (disabled)
      return (
        <div className={classnames(className, classes.disabledInput)}>
          {dataStr}
        </div>
      );

    return header ? (
      <div className={classnames(className, classes.textCompactInput)}>
        <FieldTextCompact
          label={header}
          onValueChange={updateInputStr}
          value={inputStr}
          addClear
        />
      </div>
    ) : (
      <div
        className={classnames(className, classes.textInput)}
        ref={containerRef}
        onClick={focus}
        data-input-focused={isFocused}
        data-cy="text-input"
      >
        <input
          ref={inputRef}
          className={classes.textInputField}
          type={isFocused ? 'search' : 'text'}
          placeholder={placeholder}
          onChange={updateInputStr}
          onFocus={focus}
          onBlur={blur}
          value={inputStr}
        />
        {!isFocused && (
          <Pen className={classes.textInputIcon} data-is-visible={!!inputStr} />
        )}
      </div>
    );
  }
);
TextInput.propTypes = {
  classes: PropTypes.object.isRequired,
  dataStr: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  delay: PropTypes.number,
  className: PropTypes.string,
  header: PropTypes.string,
  disabled: PropTypes.bool
};
TextInput.defaultProps = {
  placeholder: '',
  delay: 250,
  className: '',
  header: '',
  disabled: false
};

export default injectSheet(styles)(TextInput);
