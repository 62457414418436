export default () => ({
  cls1: {
    fill: 'none'
  },
  cls2: {
    opacity: 0.5
  },
  cls3: {
    fill: '#5246f7'
  },
  cls4: {
    clipPath: 'url(#clip-path)'
  },
  cls5: {
    opacity: 0.6,
    fill: '#4b35d2'
  },
  cls6: {
    fill: '#fff'
  },
  cls7: {
    clipPath: 'url(#clip-path-2)'
  },
  cls8: {
    fill: 'url(#linear-gradient)'
  },
  cls9: {
    clipPath: 'url(#clip-path-3)'
  },
  cls10: {
    fill: '#5246f7',
    opacity: 0.05
  },
  cls11: {
    fill: '#18074f',
    opacity: 0.3
  }
});
