import { useState, useCallback, useRef } from 'react';

/**
 * @function useInput
 * @desc this hook provides a simple input state updated with the content of an event.target.value
 * @desc also, a callback function call can be debounced after the state update
 * @param {any} initInput - initial input value
 * @param {function} callbackFn - function to call after the state update
 * @param {number} callbackDelay - delay before triggereing the callbackFn
 * @returns {Array} - an array that contains input, update
 */
export default (initInput, callbackFn, callbackDelay = 0) => {
  // State and setter for input
  const [input, setInput] = useState(initInput);

  // callback
  const timeoutRef = useRef(null);
  const update = useCallback(
    e => {
      const newInputValue = e.target.value;

      // set the state new value
      setInput(newInputValue);

      // if a callback has been provided, call it after a potential delay
      if (callbackFn) {
        if (!callbackDelay) {
          callbackFn(newInputValue);
          return;
        }

        // Cancel the previous timeout if already set
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }

        // then call callback fn after a delay, unless something is changed again
        timeoutRef.current = setTimeout(() => {
          callbackFn(newInputValue);
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }, callbackDelay);
      }
    },
    [callbackFn, callbackDelay]
  );

  return [input, update];
};
