export default theme => ({
  listViewSimpleText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  listView: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover, &[data-highlight=true]': {
      color: theme.indigo2
    }
  },
  listContent: {
    listStylePosition: 'inside',
    listStyleType: 'disc',
    borderRadius: 2,
    backgroundColor: theme.grey2,
    color: theme.white1,
    padding: '10px 10px 10px 15px',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    '&[data-is-ordered=true]': {
      listStyleType: 'decimal'
    }
  },
  tooltipArrowUp: {
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: `6px solid ${theme.grey2}`
  }
});
