import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { getByPath } from 'utils/widgets';

import CodeWiewer from 'components/ui/utils/code';

import styles from './code.style';

// display a code snippet in codemirror
const CodeViewImpl = React.memo(({ classes, view, data }) => {
  const { path, codemirrorOptions } = view;

  let codeData = getByPath(data, path);
  if (codeData === undefined) return null;

  codeData = JSON.stringify(codeData, null, 2);

  return (
    <div className={classes.codeView}>
      <CodeWiewer codeString={codeData} codemirrorOptions={codemirrorOptions} />
    </div>
  );
});
CodeViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to text on path
const getSortConfig = view => ({
  type: 'text',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = view => ({
  type: 'text',
  path: view.path
});

// width defaults to 'xlarge'
const getDefaultWidth = () => 'xlarge';

// stringifies data at path
const getStringifyFunction = view => {
  const { path } = view;

  return data => {
    const codeData = getByPath(data, path);
    if (codeData === undefined) return '';

    return JSON.stringify(codeData, null, 2);
  };
};

export default {
  component: injectSheet(styles)(CodeViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
