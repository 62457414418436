import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import merge from 'lodash.merge';
import injectSheet from 'react-jss';

import { Icon, TextEllipsis } from '@stratumn/atomic';

import { downloadFile } from 'utils/downloadFile';
import { getByPath } from 'utils/widgets';
import { getFileIconStr } from 'utils';

import LoadingIndicator from 'components/ui/LoadingIndicator';
import UploadFile from './editors/uploadFile';
import styles from './fileCompact.style';

// compact file view
const FileCompactImpl = React.memo(({ classes, view, data, update }) => {
  const [loading, setLoading] = useState(false);
  const [downloadResponse, setDownloadResponse] = useState(null);

  const { path, defaultIcon, editor } = view;
  const { patch, onChange, setIsPatched, editorOverrides = {} } = update || {};

  const dataWithPatchApplied = useMemo(() => merge({}, data, patch), [
    data,
    patch
  ]);

  const fileData = getByPath(dataWithPatchApplied, path);

  const downloadFileFn = useCallback(
    () => downloadFile(fileData, setLoading, loading, setDownloadResponse),
    [fileData]
  );

  const disabledPath = editor?.disabledPath;
  const disabledFromData = !!getByPath(data, disabledPath);

  const disabled = editorOverrides.disabled || !onChange || disabledFromData;

  if ((!fileData || typeof fileData !== 'object') && !editor) return null;

  switch (editor?.type) {
    case 'uploadFile':
      return (
        <UploadFile
          path={path}
          data={data}
          update={update}
          fileData={fileData}
          onChange={onChange}
          setIsPatched={setIsPatched}
          disabled={disabled}
          defaultIcon={defaultIcon}
        />
      );
    default: {
      const iconStr = getFileIconStr(
        fileData.mimetype,
        fileData.name,
        defaultIcon || 'DocumentDownload'
      );

      return (
        <LoadingIndicator
          spinning={loading}
          showSuccess={downloadResponse !== null}
        >
          <div
            className={classes.fileCompactView}
            onClick={downloadFileFn}
            data-cy="download-link"
          >
            <Icon name={iconStr} size={24} />
            <TextEllipsis
              className={classes.fileName}
              text={fileData.name}
              middleEllipsis
            />
          </div>
        </LoadingIndicator>
      );
    }
  }
});
FileCompactImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.object.isRequired
};

// sort defaults to text on file name
const getSortConfig = view => ({
  type: 'text',
  path: `${view.path}.name`
});

// filtering defaults to text search on file name
const getFilterConfig = view => ({
  type: 'text',
  path: `${view.path}.name`
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// return file name
const getStringifyFunction = view => {
  const { path } = view;
  return data => {
    const fileData = getByPath(data, path);
    return (fileData && fileData.name) || '';
  };
};

export default {
  component: compose(injectSheet(styles))(FileCompactImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
