export default theme => ({
  downloadFileWrapper: {
    overflow: 'hidden',
    display: 'flex',
    '&[data-is-downloadable=true]': {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  },
  documentIcon: {
    flexShrink: 0,
    display: 'block',
    height: 30,
    width: 'auto'
  },
  fileDetailsWrapper: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    letterSpacing: 1.16,
    fontSize: 12,
    lineHeight: '15px',
    marginLeft: 5
  },
  fileName: {
    maxWidth: '100%',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  fileSize: {
    maxWidth: '100%',
    color: theme.grey5,
    textTransform: 'uppercase',
    fontSize: 10,
    lineHeight: '13px'
  }
});
