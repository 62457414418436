import { useState, useCallback } from 'react';

/**
 * @function useFunctionAsState
 * @desc  a hook to help manage state variables that are function and bypass the lazy initialization mechanism of useState
 * @desc  inspired from https://github.com/facebook/react/issues/14087#issuecomment-574060340
 * @param {function} fn - a function to set as state variable
 * @returns {Array} - an array that contains the value and setValue, just like useState
 */
export default fn => {
  const [val, setVal] = useState(() => fn);

  const setFunc = useCallback(newFn => {
    setVal(() => newFn);
  }, []);

  return [val, setFunc];
};
