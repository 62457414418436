export default theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    overflow: 'hidden'
  },
  area: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    padding: 3,
    minHeight: 80,
    backgroundColor: theme.grey9,
    border: `1px solid ${theme.grey5}`,
    borderRadius: 2,
    transition: 'border-color 200ms',
    '&[data-is-focused=true]': {
      borderColor: theme.indigo3
    },
    '&[data-is-invalid=true]': {
      borderColor: theme.warningRed
    }
  },
  inputField: {
    margin: 3,
    display: 'inline-block',
    flexGrow: 1,
    appearance: 'none',
    boxSizing: 'border-box',
    outline: 'none',
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderRadius: 'inherit',
    padding: '2px 5px',
    fontSize: 14,
    fontWeight: 400,
    minWidth: 200,
    color: theme.grey1,
    '&::placeholder': {
      color: theme.grey4
    }
  },
  errorMsg: {
    color: theme.warningRed,
    fontSize: 12,
    marginTop: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});
