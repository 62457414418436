import { useState } from 'react';

/**
 * @function useTooltip
 * @param {boolean} tooltip - determines if a tooltip exists.
 * @returns {Array} - an array that contains onMouseEnter, onMouseLeave functions and showTooltip boolean value
 */
const useTooltip = tooltip => {
  const [showTooltip, setShowTooltip] = useState(false);

  let onMouseEnter = null;
  let onMouseLeave = null;

  if (tooltip) {
    onMouseEnter = () => {
      setShowTooltip(true);
    };
    onMouseLeave = () => {
      setShowTooltip(false);
    };
  }

  return [onMouseEnter, onMouseLeave, showTooltip];
};

export default useTooltip;
