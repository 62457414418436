import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { getByPath } from 'utils/widgets';

import styles from './link.style';

// html link
const LinkViewImpl = React.memo(({ classes, view, data }) => {
  const { path, urlPath, rootUrl, openInNewTab } = view;

  const textData = getByPath(data, path);
  const urlData = getByPath(data, urlPath || path);
  return (
    <div className={classes.linkView}>
      <a
        href={`${rootUrl}${urlData}`}
        target={openInNewTab ? '_blank' : null}
        rel="noopener noreferrer"
      >
        {textData}
      </a>
    </div>
  );
});
LinkViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to text on path
const getSortConfig = view => ({
  type: 'text',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = view => ({
  type: 'text',
  path: view.path
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// stringifies text data at path
const getStringifyFunction = view => {
  const { path } = view;
  return data => getByPath(data, path);
};

export default {
  component: injectSheet(styles)(LinkViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
