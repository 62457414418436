export default () => ({
  tabHeader: {
    fontSize: 12,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  tabHeaderIcon: {
    marginRight: 5,
    flexShrink: 0,
    display: 'block',
    width: 20,
    height: 20
  }
});
