export default {
  linkWrapper: {
    width: '100%',
    height: '100%',
    '&:link, &:visited': {
      color: 'unset',
      textDecoration: 'unset',
      cursor: 'unset'
    },
    '&:link:active, &:visited:active': {
      color: 'unset'
    }
  }
};
