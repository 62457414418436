import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';

import { getByPath } from 'utils/widgets';

import { Icon } from '@stratumn/atomic';
import { Widget } from 'components/ui/widget';
import SelectAllButton from 'components/ui/widget/contexts/selectableItems/selectAllButton';
import ActionButton from 'components/ui/widget/contexts/selectableItems/actionButton';
import { LocalStorageContext } from 'contexts';

import { pluralize } from 'utils/strings';

import classNames from 'classnames';
import BoxSectionContext from '../box/context';

import styles from './window.style';

export const WindowView = React.memo(({ classes, view, data }) => {
  const {
    title,
    displayItemCount,
    collapsable,
    items,
    conditionPath,
    itemName = title?.toLocaleLowerCase()?.includes('comment')
      ? 'comment'
      : 'item'
  } = view;

  if (items.length === 0) {
    return null;
  }

  // if conditionPath is set and falsy, just return nothing
  if (conditionPath !== undefined && !getByPath(data, conditionPath)) {
    return null;
  }

  /**
   * Checks if trace state includes items.
   * If no items found, we do not render the window widget
   */
  const deepItems = getByPath(data, items[0].view.itemsPath);

  if (!Array.isArray(items) && !Array.isArray(deepItems)) {
    return null;
  }

  const { index } = useContext(BoxSectionContext);
  const { userInfoConfig, setLocalStorage } =
    useContext(LocalStorageContext) || {};

  const initCollapse = userInfoConfig
    ? userInfoConfig[index]?.isCollapsed
    : true;

  const [collapse, setCollapse] = useState(initCollapse);

  const itemsCount = (deepItems || []).length;
  const itemCountLabel = pluralize(itemsCount, itemName);

  const collapseHandler = () => {
    setCollapse(!collapse);
    if (setLocalStorage) setLocalStorage({ index, isCollapsed: !collapse });
  };

  const rootProps = {
    className: classes.windowRoot,
    'data-is-collapse': collapse
  };

  const titleProps = {
    'data-cy': 'window-toggle-expand',
    className: classes.windowTitleWrapper,
    onClick: collapsable ? collapseHandler : null,
    'data-is-collapse': collapse
  };

  const iconProps = {
    className: classes.icon,
    'data-is-collapse': collapse
  };

  return (
    <div {...rootProps}>
      <div {...titleProps}>
        <div className={classes.windowTitle}>{title}</div>
        <div className={classes.windowInfo}>
          {displayItemCount && (
            <div className={classes.windowItemCount}>{itemCountLabel}</div>
          )}
          {collapsable && (
            <div {...iconProps}>
              <Icon size={25} name="ArrowMinTop" />
            </div>
          )}
        </div>
      </div>

      <section
        className={classNames({
          [classes.gridWrapper]: true,
          [classes.isCollapsed]: !collapse
        })}
      >
        <div className={classes.itemsWrapper}>
          <SelectAllButton />
          {view.items.map((item, idx) => (
            <Widget
              key={idx}
              widget={item}
              data={data}
              className={classes.itemWidget} // this is so that window view can eg specify how wrapped children are styled
            />
          ))}
          <ActionButton />
        </div>
      </section>
    </div>
  );
});

export default {
  component: compose(injectSheet(styles))(WindowView)
};

WindowView.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  collapsable: PropTypes.bool,
  title: PropTypes.string,
  setSelectableItemData: PropTypes.func
};

WindowView.defaultProps = {
  collapsable: true,
  title: '',
  setSelectableItemData: undefined
};
