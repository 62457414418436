export default theme => ({
  aggregationRow: {
    width: 'fit-content',
    fontSize: 12,
    lineHeight: '15px',
    color: theme.white1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  firstColumnPlaceholder: {
    borderRight: `1px solid ${theme.white1}`,
    backgroundColor: theme.indigoPastel1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  aggregationCellWrapper: {
    '&[data-is-computed=true]': {
      backgroundColor: theme.indigoPastel1
    }
  },
  aggregationCell: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: '3px 6px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    overflow: 'hidden',
    cursor: 'default',
    borderRight: `1px solid ${theme.white1}`,
    '&[data-is-hoverable=true]': {
      cursor: 'pointer'
    },
    '&[data-is-hoverable=true]:hover, &[data-is-focus=true]': {
      padding: '1px 5px 1px 4px',
      border: `2px solid ${theme.white1}`,
      borderRadius: 1
    }
  }
});
