import React, { Component } from 'react';
import to from 'await-to-js';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import injectSheet from 'react-jss';
import Path from 'path-to-regexp';
import { FILTERS_QUERY } from 'gql/localQueries';
import { withRouter, Switch, Route } from 'react-router-dom';

import {
  ROUTE_WORKFLOW_DASHBOARD,
  ROUTE_NEW_LINK,
  ROUTE_WORKFLOW_OVERVIEW,
  ROUTE_INSPECT_TRACE_LINK
} from 'constant/routes';
import { TRAY_PORTAL_RIGHT, TOOLTIP_PORTAL } from 'constant/htmlIds';
import {
  TYPE_WORKFLOW,
  TYPE_WORKFLOW_CONFIG,
  TYPE_UPDATE_WORKFLOW_CONFIG_PAYLOAD
} from 'gql/types';

import { withError } from 'components/errorBoundary';
import { Navbar, Footer } from 'components/layouts';
import { Tray, Pushbutton } from '@stratumn/atomic';
import { Settings } from '@stratumn/icons';
import { stringify } from '@stratumn/canonicaljson';

import { getNextActionsArray } from 'utils';
import {
  getUserInfoDisplayConfig,
  manageLocalStorage,
  sectionsLocalStorage
} from 'components/ui/utils/localStorage';
import { withUser, LocalStorageContext } from 'contexts';
import TraceIconSpinner from 'components/ui/traceIconSpinner';
import { Widget } from 'components/ui/widget';
import JsonEditor from 'components/ui/utils/jsonEditor';

import { WorkflowContext, buildWorkflowContext } from 'utils/workflowContext';

import { notify } from 'components/toast';
import { TraceInspectorContext } from './context';
import SegmentList from './segmentList';
import SegmentInfo from './segmentInfo';
import PushbuttonInspector from './pushButton';
import fragments from './fragments';

import { ActionsList } from '../ui';

import styles from './traceInspector.style';

const configEditorCodemirrorOptions = {
  theme: 'material'
};

export class TraceInspector extends Component {
  static propTypes = {
    traceQuery: PropTypes.object.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    user: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    updateTraceInfoConfigMutation: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    activeSegment: undefined,
    menuOpen: false,
    showUpdateTraceTray: false,
    showTraceInfoConfigEditor: false,
    showTraceState: false
  };

  setDocTitle() {
    const { traceQuery: { traceById: { name } = {} } = {} } = this.props;
    if (name) document.title = `${name} - Inspector - Trace`;
  }

  getLinkActionConfig = (link, workflowActions) => {
    const actionConfig = workflowActions?.find(a => a.key === link?.actionKey);
    return actionConfig;
  };

  setInitialUrl = async () => {
    const { history, match, traceQuery } = this.props;
    const { traceById } = await traceQuery;

    const queryIsLoaded = !traceQuery.loading;

    if (queryIsLoaded && !match.params.linkid) {
      let headLink = traceById.head;

      // get hidden property from link's action config
      const headTraceActionIsHidden = await this.getLinkActionConfig(
        traceById?.head,
        traceById?.workflow?.actions?.nodes
      )?.hidden;

      // if head trace action is hidden (e.g: answer) we will filter the links with
      // highest height, and find the one which is not hidden to inject it as head
      // instead of the 'real' head
      if (headTraceActionIsHidden) {
        const links = traceById?.links.nodes;

        // finding the highest height present in the links
        const highestHeight =
          links && Math.max(...links?.map(link => link.height));

        // finding the links with this highest height
        const highestLinks = links?.filter(
          link => link.height === highestHeight
        );

        // updating the headLink variable with the highest link whithout a hidden property
        headLink = highestLinks?.find(
          l =>
            !this.getLinkActionConfig(l, traceById?.workflow?.actions?.nodes)
              ?.hidden
        );
      }

      history.replace(
        Path.compile(ROUTE_INSPECT_TRACE_LINK)({
          id: traceById.rowId,
          linkid: !headTraceActionIsHidden
            ? traceById.head.linkHash
            : headLink?.linkHash
        })
      );
    }
  };

  componentDidMount() {
    this.setDocTitle();
  }

  scrollToTop() {
    const contentDiv = document.getElementById('segmentContent');
    if (contentDiv) {
      contentDiv.scrollTop = 0;
    }
  }

  componentDidUpdate(prevProps) {
    this.scrollToTop();
    if (prevProps.traceQuery !== this.props.traceQuery) this.setDocTitle();
    // updates segment view when actions are selected
    if (prevProps.match?.params?.linkid !== this.props.match?.params?.linkid) {
      this.setActiveSegment();
    }
  }

  handleTraceInfoConfigUpdate = async newTraceInfoConfigStr => {
    const {
      traceQuery: {
        traceById: { workflow }
      },
      updateTraceInfoConfigMutation
    } = this.props;

    const {
      rowId: workflowRowId,
      config: { rowId: workflowConfigId } = {}
    } = workflow;
    const newTraceInfoConfig = newTraceInfoConfigStr
      ? JSON.parse(newTraceInfoConfigStr)
      : null;

    const updateTraceInfoPromise = updateTraceInfoConfigMutation({
      variables: {
        workflowConfigId,
        newTraceInfoConfig
      },
      optimisticResponse: {
        updateWorkflowConfigByRowId: {
          workflow: {
            rowId: workflowRowId,
            config: {
              rowId: workflowConfigId,
              info: newTraceInfoConfig,
              __typename: TYPE_WORKFLOW_CONFIG
            },
            __typename: TYPE_WORKFLOW
          },
          __typename: TYPE_UPDATE_WORKFLOW_CONFIG_PAYLOAD
        }
      }
    });

    notify.promise(updateTraceInfoPromise, {
      loading: 'Updating trace info configuration...',
      success: 'The trace info config was correctly updated',
      error: data => {
        return data.message;
      }
    });

    await to(updateTraceInfoPromise);
  };

  toggleShowWorkflowConfigEditor = () =>
    this.setState(prevState => ({
      showTraceInfoConfigEditor: !prevState.showTraceInfoConfigEditor
    }));

  toggleShowStateEditor = () =>
    this.setState(prevState => ({
      showTraceState: !prevState.showTraceState
    }));

  toggleMenu = () =>
    this.setState(prevState => ({ menuOpen: !prevState.menuOpen }));

  subscribeToTrace(props) {
    const { traceQuery, match } = props;
    this.unsubscribeFromTrace = traceQuery.subscribeToMore({
      document: subscriptions.trace,
      variables: { id: `trace:${match.params.id}` }
    });
  }

  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    this.subscribeToTrace(this.props);
  }

  componentWillUnmount() {
    if (this.unsubscribeFromTrace) this.unsubscribeFromTrace();
  }

  shouldComponentUpdate(props) {
    const {
      traceQuery: { loading, traceById: trace, error }
    } = props;
    return !loading && (!!trace || !!error);
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps = nextProps => {
    const {
      traceQuery: { loading, traceById: trace },
      errorContext: { handleError },
      match: { params }
    } = nextProps;
    if (!params?.linkid) {
      this.setInitialUrl();
    }

    if (!loading && params?.linkid) {
      this.setActiveSegment();
    }
    // This check avoids rerendering the component after the erroBoundary has been triggered
    if (!loading && !trace) {
      handleError('trace', params.id, ROUTE_WORKFLOW_DASHBOARD);
      return;
    }

    if (
      !loading &&
      trace &&
      (!this.state.activeSegment ||
        this.state.activeSegment.linkHash !== trace.head.linkHash)
    ) {
      const height = this?.state?.activeSegment?.height;

      this.setState({
        activeSegment: this.getActiveSegment(trace, height)
      });
    }
  };

  getActiveSegment = (trace, height) => {
    const workflowActions = trace.workflow.actions.nodes;
    // filters links to remove hidden actions (e.g: answers)
    const notHiddenLinks = trace.links.nodes.filter(l => {
      if (workflowActions.includes(l.actionKey)) {
        return workflowActions.some(a => a.key === l.actionKey && !a.hidden);
      }
      return true;
    });

    if (height) {
      const h = parseInt(height, 10);
      const idx = notHiddenLinks.findIndex(l => l.height === h);
      if (idx === -1) {
        throw new Error(`Invalid trace height ${h}`);
      }
      return notHiddenLinks[idx];
    }

    return trace.head;
  };

  setActiveSegment = async () => {
    const {
      traceQuery: { traceById, loading }
    } = this.props;
    if (!loading) {
      const { links, head, workflow } = traceById;

      const headTraceActionIsHidden = this.getLinkActionConfig(
        head,
        workflow?.actions?.nodes
      )?.hidden;

      const segment = links.nodes.find(l =>
        !this.props.match.params.linkid && !headTraceActionIsHidden
          ? l.linkHash === head.linkHash
          : l.linkHash === this.props.match.params.linkid
      );

      this.setState({
        activeSegment: segment
      });
    }
  };

  getNextActions = ({ excludeHidden, excludeComment } = {}) => {
    const {
      traceQuery: {
        traceById: {
          workflow: { groups, actions },
          state: { nextActions }
        }
      }
    } = this.props;

    const nextActionsArray = getNextActionsArray({
      nextActions,
      groups: groups.nodes,
      actions: actions.nodes
    });

    return nextActionsArray?.reduce((result, actionsByGroups) => {
      const filteredActions = actionsByGroups.actions?.filter(action => {
        if (
          (excludeHidden && action.hidden) ||
          (excludeComment && action.key === 'comment')
        ) {
          return false;
        }
        return true;
      });

      if (filteredActions?.length > 0) {
        result.push({ ...actionsByGroups, actions: filteredActions });
      }
      return result;
    }, []);
  };

  toggleShowUpdateTraceTray = () => {
    const { showUpdateTraceTray } = this.state;
    this.setState({
      showUpdateTraceTray: !showUpdateTraceTray
    });
  };

  getTasks = () => {
    const {
      traceQuery: {
        traceById: {
          state: { tasks }
        }
      }
    } = this.props;
    return tasks;
  };

  getWorkflowId = () => {
    const {
      traceQuery: { traceById: trace }
    } = this.props;
    return trace.workflow.rowId;
  };

  renderHeader = () => {
    const {
      traceQuery: { loading: traceLoading, traceById: trace },
      user: { loading: userLoading, me }
    } = this.props;
    const loading = traceLoading || userLoading;

    const { showTraceInfoConfigEditor, showTraceState } = this.state;

    let isSuperuser = false;
    let info;
    let state;
    let traceInfoConfigStr;
    let traceStateStr;
    if (!loading) {
      ({
        workflow: {
          config: { info }
        },
        state
      } = trace);
      ({ isSuperuser } = me);
      traceInfoConfigStr = stringify(info, null, 2);
      traceStateStr = stringify(state, null, 2);
    }

    const configHeader = {
      loading,
      bottomLevel: {
        workflowPage: true,
        infoContext: {
          links: [
            {
              icon: !loading ? 'Trace' : null,
              label: !loading ? trace.name : null
            }
          ]
        },
        actions: isSuperuser
          ? {
              links: [
                {
                  icon: <Settings />,
                  label: 'See Trace State',
                  onClick: this.toggleShowStateEditor
                },
                {
                  icon: <Settings />,
                  label: 'Trace Info configuration',
                  onClick: this.toggleShowWorkflowConfigEditor
                }
              ]
            }
          : null
      }
    };

    if (trace?.workflow) {
      configHeader.bottomLevel.infoContext.links.unshift({
        icon: 'TableColumns',
        label: trace.workflow?.name ?? 'Workflow',
        path: Path.compile(ROUTE_WORKFLOW_OVERVIEW)({
          id: trace.workflow?.rowId
        })
      });
    }

    return (
      <>
        <Navbar config={configHeader} />
        {isSuperuser && showTraceInfoConfigEditor && (
          <JsonEditor
            title="Trace Info configuration"
            jsonString={traceInfoConfigStr}
            onSubmit={this.handleTraceInfoConfigUpdate}
            onClose={this.toggleShowWorkflowConfigEditor}
            codemirrorOptions={configEditorCodemirrorOptions}
          />
        )}
        {isSuperuser && showTraceState && (
          <JsonEditor
            title="Trace State"
            jsonString={traceStateStr}
            onClose={this.toggleShowStateEditor}
            codemirrorOptions={configEditorCodemirrorOptions}
          />
        )}
      </>
    );
  };

  handleLocalStorage = ({ index, isCollapsed }) => {
    const {
      traceQuery: { traceById }
    } = this.props;
    return manageLocalStorage(traceById, { index, isCollapsed });
  };

  goToActionLink = (link, traceIds) => {
    const { groupKey, actionKey } = link;

    const baseUrl = Path.compile(ROUTE_NEW_LINK)({
      wfid: this.getWorkflowId()
    });

    let traceIdsString = '';
    if (traceIds && traceIds.length > 0) {
      traceIdsString = `&traceIds=${traceIds.join(',')}`;
    }

    return this.props.history.push(
      `${baseUrl}?groupKey=${groupKey}&actionKey=${actionKey}${traceIdsString}`,
      {
        from: this.props.history.location
      }
    );
  };

  renderNextActionButton = () => {
    const { classes } = this.props;
    const {
      traceQuery: {
        variables: { traceId }
      }
    } = this.props;

    const nextActionsNotHidden = this.getNextActions({
      excludeHidden: true
    });

    // No actions = nothing to do
    if (nextActionsNotHidden.length === 0) {
      return (
        <div className={classes.nothingButton}>
          <PushbuttonInspector primary={false} disabled title="Nothing to do" />
        </div>
      );
    }
    // Just one group can act
    if (nextActionsNotHidden.length === 1) {
      // Only one action is possible
      if (nextActionsNotHidden[0].actions.length === 1) {
        const action = nextActionsNotHidden[0].actions[0];
        const { group } = nextActionsNotHidden[0];
        return (
          <div
            className={action.key === 'comment' ? classes.commentButton : ''}
          >
            <PushbuttonInspector
              primary={action.key !== 'comment'}
              title={action.title}
              onClick={() => {
                this.goToActionLink(
                  {
                    groupKey: group.label,
                    actionKey: action.key
                  },
                  [traceId]
                );
              }}
            />
          </div>
        );
      }

      // Get the possible "comment" next action
      const commentAction = nextActionsNotHidden[0].actions.find(
        action => action.key === 'comment'
      );

      // 2 possible actions, on of them is "comment"
      if (nextActionsNotHidden[0].actions.length === 2 && !!commentAction) {
        const primaryAction = nextActionsNotHidden[0].actions.find(
          action => action.key !== 'comment'
        );
        const { group } = nextActionsNotHidden[0];
        return (
          <div>
            <PushbuttonInspector
              title={primaryAction.title}
              onClick={() => {
                this.goToActionLink(
                  {
                    groupKey: group.label,
                    actionKey: primaryAction.key
                  },
                  [traceId]
                );
              }}
            />
            <div className={classes.doubleButton}>
              <Pushbutton
                onClick={() => {
                  this.goToActionLink(
                    {
                      groupKey: group.label,
                      actionKey: commentAction.key
                    },
                    [traceId]
                  );
                }}
              >
                {commentAction.title}
              </Pushbutton>
            </div>
          </div>
        );
      }
    }

    // Multiple groups we display the standard "Next Actions" button that opens a tray
    return (
      <PushbuttonInspector primary onClick={this.toggleShowUpdateTraceTray} />
    );
  };

  renderInspector = () => {
    const {
      traceQuery: { traceById: trace }
    } = this.props;
    const { activeSegment, showUpdateTraceTray } = this.state;
    const nextActionsNotHidden = this.getNextActions({ excludeHidden: true });
    const tasks = this.getTasks();

    const { rowId: traceId } = trace;

    let newTracesTrayMessage =
      'The following actions are available for this trace.';
    if (showUpdateTraceTray && !nextActionsNotHidden.length) {
      newTracesTrayMessage = 'No actions are available for this trace.';
    }

    return (
      !this.props.traceQuery.loading && (
        <>
          <SegmentList
            activeSegment={activeSegment}
            links={trace.links.nodes}
            pulldown={this.renderNextActionButton()}
            traceId={trace.rowId}
          />
          {showUpdateTraceTray && (
            <Tray
              portalEl={document.getElementById(TRAY_PORTAL_RIGHT)}
              title="Next action"
              onClose={this.toggleShowUpdateTraceTray}
            >
              <ActionsList
                nextActions={nextActionsNotHidden}
                tasks={tasks}
                workflowId={this.getWorkflowId()}
                traceIds={[traceId]}
                message={newTracesTrayMessage}
                toggleTray={this.toggleShowUpdateTraceTray}
              />
            </Tray>
          )}
        </>
      )
    );
  };

  renderSegment = () => {
    const {
      traceQuery: { refetch, traceById: trace },
      classes
    } = this.props;
    const { activeSegment } = this.state;
    const nextActions = this.getNextActions();
    const { rowId: traceId, workflow } = trace;

    // populating groups with a hidden nextAction (e.g: answers)
    const groups = nextActions
      .filter(a => a.actions.find(b => b.hidden))
      .map(n => n.group);

    const answers = trace.links.nodes
      .filter(l => l.data?.commentLinkHash === activeSegment.linkHash)
      .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));

    // build the workflow context passed to the form reader
    const workflowContext = buildWorkflowContext(workflow);

    return (
      <Switch>
        <Route exact path={ROUTE_INSPECT_TRACE_LINK}>
          <div className={classes.segmentWrapper}>
            <div className={classes.segment}>
              <SegmentInfo
                activeSegment={activeSegment}
                traceId={traceId}
                workflowContext={workflowContext}
                traceHead={trace.head}
                links={trace.links.nodes}
                answers={answers}
                groups={groups}
                nextActions={nextActions}
                refetchTraceQuery={() => refetch()}
              />
            </div>
          </div>
        </Route>
      </Switch>
    );
  };

  renderTraceInfo = () => {
    const {
      traceQuery: { traceById: trace },
      match,
      classes
    } = this.props;

    const { rowId: traceId, state, workflow, links } = trace;
    // const commentAnswers = links.nodes.filter(
    //   l => l.actionKey === 'answerComment'
    // );

    const {
      config: { info },
      rowId: workflowRowId
    } = workflow;
    const linksList = links.nodes;

    // build the workflow context passed to the form reader
    const workflowContext = buildWorkflowContext(workflow);

    const wfUserDisplayConfig = getUserInfoDisplayConfig(workflowRowId);

    const localStorageContext = {
      userInfoConfig:
        (wfUserDisplayConfig && wfUserDisplayConfig[traceId]) ||
        sectionsLocalStorage(workflow?.config?.info?.view?.sections || []),
      setLocalStorage: this.handleLocalStorage
    };

    const traceInspectorContext = {
      links: linksList,
      traceId: trace.rowId,
      currentHash: match.params.linkid
    };

    // Get list of comments from links list
    // const comments = linksList.reduce((acc, link) => {
    //   // gets action config for the link
    //   const matchingAction = this.getLinkActionConfig(link, workflowActions);

    //   // gets the list of answerableComments from config
    //   const answerableLink = matchingAction?.answerableComments;

    //   // To determine if a link is a comment, we look in the action config if the answerableComments list
    //   // is not empty and if one it's entries matches the link's actionKey
    //   const linkComment =
    //     answerableLink?.length > 0 &&
    //     Object.entries(link.data)?.find(([key]) =>
    //       answerableLink.some(a => a === key)
    //     )?.[1]; // If we find one matching key, we consider it's value as the comment data

    //   // If a comment was found, we populate de accumulator with a comment object
    //   // (same as the one from the trace's state but with more data in it, especially the 'height' or the 'linkHash')
    //   if (linkComment) {
    //     const answers = commentAnswers?.filter(
    //       a => a.data?.commentLinkHash === link.linkHash
    //     );

    //     return [
    //       ...acc,
    //       {
    //         action: link.action.title,
    //         answers,
    //         comment: linkComment,
    //         date: link.createdAt,
    //         files: link.data.files,
    //         group: link.group.name,
    //         groupId: link.group.rowId,
    //         groupLabel: link.group.label,
    //         height: link.height,
    //         id: link.id,
    //         linkHash: link.linkHash,
    //         user: {
    //           name: link.createdBy?.name || 'Unknown user',
    //           avatar: link.createdBy?.avatar,
    //           accountId: link.createdBy?.id
    //         }
    //       }
    //     ];
    //   }
    //   return acc;
    // }, []);

    // It the original state had comments in it, we replace it with the ones we got from the links
    // if (state.data.comments) {
    //   state.data.comments = comments;
    // }

    return (
      <div className={classes.traceInfo}>
        {info && (
          <WorkflowContext.Provider value={workflowContext}>
            <LocalStorageContext.Provider value={localStorageContext}>
              <TraceInspectorContext.Provider value={traceInspectorContext}>
                <Widget widget={info} data={state} />
              </TraceInspectorContext.Provider>
            </LocalStorageContext.Provider>
          </WorkflowContext.Provider>
        )}
      </div>
    );
  };

  render = () => {
    const {
      traceQuery: { loading, error, traceById: trace },
      classes
    } = this.props;

    const { activeSegment } = this.state;

    /**
     * If the trace id doesn't exist,
     * we return null and let errorBoundary render the oops page
     */
    if (error || (!trace && !loading)) {
      return null;
    }

    return (
      <>
        <div id={TOOLTIP_PORTAL} />
        <div
          id={TRAY_PORTAL_RIGHT}
          className={classes.inspectorTraysContainer}
        />
        {this.renderHeader()}
        {loading || !activeSegment ? (
          <TraceIconSpinner />
        ) : (
          <div className={classes.body}>
            {this.renderInspector()}
            <div className={classes.content} id="segmentContent">
              {this.renderSegment()}
              {this.renderTraceInfo()}
              <Footer customClass={classes.footer} />
            </div>
          </div>
        )}
      </>
    );
  };
}

export const queries = {
  traceQuery: gql`
    query traceQuery($traceId: UUID!) {
      traceById(id: $traceId) {
        ...TraceInspectorFragment
      }
    }
    ${fragments.trace}
  `
};

export const mutations = {
  updateTraceInfoConfig: gql`
    mutation updateTraceInfoConfigMutation(
      $workflowConfigId: BigInt!
      $newTraceInfoConfig: JSON
    ) {
      updateWorkflowConfigByRowId(
        input: {
          rowId: $workflowConfigId
          patch: { info: $newTraceInfoConfig }
        }
      ) {
        workflow {
          rowId
          config {
            rowId
            info
          }
        }
      }
    }
  `
};

export const subscriptions = {
  trace: gql`
    subscription listenTrace($id: String!) {
      listen(topic: $id) {
        relatedNodeId
        relatedNode {
          id
          ... on Trace {
            ...TraceInspectorFragment
          }
        }
      }
    }
    ${fragments.trace}
  `
};

export default compose(
  withUser,
  withRouter,
  graphql(FILTERS_QUERY, {
    name: 'filtersQuery'
  }),
  graphql(mutations.updateTraceInfoConfig, {
    name: 'updateTraceInfoConfigMutation'
  }),
  graphql(queries.traceQuery, {
    name: 'traceQuery',
    options: ({ match }) => ({
      variables: { traceId: match.params.id },
      fetchPolicy: 'cache-and-network'
    })
  }),
  injectSheet(styles),
  withError
)(TraceInspector);
