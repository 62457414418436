export default theme => ({
  content: {
    position: 'fixed',
    top: '105px',
    backgroundColor: theme.grey8,
    height: 'calc(100% - 105px)',
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    color: theme.grey1
  },
  sectionsListContainer: {
    flexGrow: 1,
    overflow: 'auto'
  },
  sectionsList: {
    padding: '3px 21px',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  noParticipantsMessage: {
    padding: '20px 21px',
    fontSize: 14,
    lineHeight: '18px'
  },
  footer: {
    flexShrink: 0,
    height: 63,
    padding: '10px 21px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  }
});
