export default {
  groupIconContainer: {
    width: 26,
    position: 'relative',
    marginRight: 3
  },
  tallyContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(33%, -50%) scale(.75)'
  }
};
