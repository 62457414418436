import gql from 'graphql-tag';

export default {
  updateNotificationSubscription: gql`
    mutation updateNotificationSubscription(
      $userId: BigInt!
      $workflowId: BigInt!
      $actionkey: String!
      $subscription: NotificationSubscriptionMode!
    ) {
      updateNotificationSubscriptionByUserIdAndWorkflowIdAndActionkey(
        input: {
          patch: { subscription: $subscription }
          userId: $userId
          workflowId: $workflowId
          actionkey: $actionkey
        }
      ) {
        notificationSubscription {
          rowId
          subscription
          userId
          workflowId
          actionkey
        }
      }
    }
  `
};
