import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import moment from 'moment';

import { AvatarGroup, Icon } from '@stratumn/atomic';

import { nameToInitials } from 'utils';
import Ellipsis from 'components/ui/utils/ellipsis';

import { parseEntities } from '../utils';

import styles from './userDashboardHeader.style';

const WELCOME_MESSAGE =
  'Welcome back to your dashboard! All your monitoring information is here. You can see pending traces, your tasks and more.';

const missingItemsInfo = nbMissingItems =>
  `+ ${nbMissingItems} other team${nbMissingItems > 1 ? 's' : ''}`;

// header of the user dashboard
export const UserDashboardHeader = React.memo(
  ({ classes, className, me, collapsed, setCollapsed }) => {
    const { firstName, memberOf: { nodes: entities = [] } = {} } = me;

    const { organization, teams } = useMemo(() => parseEntities(entities), [
      entities
    ]);

    const { name: organizationName, avatar: organizationAvatar } =
      organization || {};

    return (
      <div
        className={classnames(className, classes.header)}
        data-is-collapsed={collapsed}
      >
        <div className={classes.headerLeft}>
          <div className={classes.headerWelcome} data-is-collapsed={collapsed}>
            <div className={classes.welcome}>
              Welcome back{firstName ? `,` : `!`}
            </div>
            {firstName && (
              <div className={classes.firstName}>{`${firstName}!`}</div>
            )}
            <div
              className={classes.welcomeMessage}
              data-is-collapsed={collapsed}
            >
              {WELCOME_MESSAGE}
            </div>
          </div>
          <div
            className={classes.headerMembership}
            data-is-collapsed={collapsed}
          >
            <div
              className={classnames(
                classes.organizationSection,
                classes.headerMembershipSection
              )}
              data-is-collapsed={collapsed}
            >
              <div className={classes.sectionName}>Your organization</div>
              <div className={classes.headerMembershipItem}>
                {organizationName ? (
                  <>
                    <AvatarGroup
                      src={organizationAvatar}
                      size={collapsed ? 22 : 32}
                      nth={0}
                      initials={nameToInitials(organizationName)}
                    />
                    <div
                      className={classnames(
                        classes.headerMembershipItemName,
                        classes.organizationName
                      )}
                      data-is-collapsed={collapsed}
                    >
                      {organizationName}
                    </div>
                  </>
                ) : (
                  <div className={classes.emptyOrgTeamMsg}>
                    You don&#39;t belong to any organization
                  </div>
                )}
              </div>
            </div>

            <div
              className={classnames(
                classes.teamsSection,
                classes.headerMembershipSection
              )}
              data-is-collapsed={collapsed}
            >
              <div className={classes.sectionName}>
                {`Your team${teams?.length > 1 ? 's' : ''}`}
              </div>
              {teams?.length > 0 ? (
                <Ellipsis
                  className={classes.teams}
                  infoMessage={missingItemsInfo}
                  infoClassName={classes.teamsEllipsisInfo}
                  tooltipClassName={classes.teamsTooltip}
                >
                  {teams.map((team, idx) => {
                    const {
                      rowId,
                      entity: { avatar, name }
                    } = team;
                    return (
                      <div key={rowId} className={classes.headerMembershipItem}>
                        <AvatarGroup
                          src={avatar}
                          size={22}
                          nth={idx}
                          initials={nameToInitials(name)}
                        />
                        <div
                          className={classnames(
                            classes.headerMembershipItemName,
                            classes.teamName
                          )}
                        >
                          {name}
                        </div>
                      </div>
                    );
                  })}
                </Ellipsis>
              ) : (
                <div className={classes.emptyOrgTeamMsg}>
                  You don&#39;t belong to any team
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes.headerRight} data-is-collapsed={collapsed}>
          <div className={classes.sectionName}>Today</div>
          <div className={classes.today}>
            {moment().format('MMM. DD, YYYY')}
          </div>
        </div>
        <button
          className={classes.collapseIcon}
          onClick={() => setCollapsed(!collapsed)}
          data-cy="collapse-trigger"
          data-is-collapsed={collapsed}
        >
          <Icon size={14} name="ArrowDownFill" />
        </button>
      </div>
    );
  }
);
UserDashboardHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  me: PropTypes.object,
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func.isRequired
};
UserDashboardHeader.defaultProps = {
  className: '',
  me: {},
  collapsed: false
};

export default injectSheet(styles)(UserDashboardHeader);
