export default theme => ({
  subHeader: {
    flexShrink: 0,
    margin: '0px 21px',
    padding: '16px 0px',
    borderBottom: `1px solid ${theme.grey6}`,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.mediaQueries.small]: {
      flexFlow: 'column nowrap',
      alignItems: 'flex-start'
    }
  },
  subHeaderLeft: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    width: '50%',
    height: 30,
    [theme.mediaQueries.small]: {
      width: '100%'
    }
  },
  subHeaderRight: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      paddingLeft: 10,
      paddingRight: 1,
      height: 25,
      marginLeft: 10
    },
    [theme.mediaQueries.small]: {
      width: '100%',
      marginTop: 10
    }
  },
  countInfo: {
    marginInline: '6px',
    fontSize: 14,
    color: theme.grey4
  },
  addGroupLabel: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    fontSize: 10,
    lineHeight: '13px',
    fontWeight: 500
  },
  addGroupIcon: {
    flexShrink: 0,
    display: 'block',
    height: 28,
    width: 28
  }
});
