import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Icon } from '@stratumn/atomic';
import { TICKET_API_URL } from 'constant/api';
import { saveBlob } from 'utils';
import Axios from 'axios';

// import { files } from 'jszip';
import styles from './ticket_bar.style';

export const TicketContext = createContext();

export const TicketProvider = ({ children }) => {
  const [tickets, setTickets] = useState([]);

  return (
    <TicketContext.Provider value={{ tickets, setTickets }}>
      {children}
    </TicketContext.Provider>
  );
};

TicketProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const renderIcon = fileTmp => {
  switch (fileTmp.status) {
    case 'pending':
      return <Icon size={20} name="Clock" />;
    case 'started':
      return <Icon size={20} name="Update" />;
    case 'finished':
      return <Icon size={20} name="CheckCircle" />;
    default:
      return <Icon size={20} name="Clock" />;
  }
};

const renderTimer = fileTmp => {
  if (fileTmp.timer) {
    return ` - ${fileTmp.timer}s`;
  }
  return '';
};

export const IndividualTicket = ({ classes, ticket }) => (
  <div className={classes.file}>
    {`zip creation: ${ticket.ticket_id.split('-')[0]}${renderTimer(ticket)}`}
    {renderIcon(ticket)}
  </div>
);

IndividualTicket.propTypes = {
  classes: PropTypes.object.isRequired,
  ticket: PropTypes.object.isRequired
};

export const TicketBar = ({ classes }) => {
  const [isDropped, setIsDropped] = useState(false);
  const { tickets, setTickets } = useContext(TicketContext);

  const doIntervalData = async () => {
    // const intervalTickets = JSON.parse(localStorage.getItem('tickets'))
    const intervalTickets = tickets;

    const data = await (
      await fetch(
        `${TICKET_API_URL}/tickets?ids=${intervalTickets
          .map(t => t.ticket_id)
          .join(',')}`,
        {
          method: 'GET',
          credentials: 'include'
        }
      )
    ).json();

    const ticketIdsToDataMapping = data.reduce((acc, curr) => {
      acc[curr.id] = {
        data: curr.data,
        status: curr.status
      };
      return acc;
    }, {});

    const tmp = [];

    intervalTickets
      .map(t => t.ticket_id)
      .forEach(async ticketId => {
        switch (ticketIdsToDataMapping[ticketId].status) {
          case 'FINISHED': {
            Axios.request({
              responseType: 'blob',
              url: ticketIdsToDataMapping[ticketId].data.url,
              headers: {},
              validateStatus: status => status >= 200 && status < 400
            }).then(downloadData =>
              saveBlob(
                downloadData.data,
                ticketIdsToDataMapping[ticketId].data?.name
              )
            );
            break;
          }
          case 'ERROR':
            console.log('error in ticketing', ticketIdsToDataMapping[ticketId]);
            break;
          default:
            tmp.push({
              ticket_id: ticketId,
              timer: Math.round(
                (new Date() -
                  new Date(ticketIdsToDataMapping[ticketId].start_time)) /
                  1000
              ),
              ...ticketIdsToDataMapping[ticketId]
            });
        }
      });

    tmp.sort((a, b) => a.created_at - b.created_at);

    setTickets(tmp);
  };

  // fetching logic every 2,5s
  useEffect(() => {
    if (tickets.length === 0) {
      return;
    }
    const intervalId = setInterval(async () => {
      await doIntervalData();
    }, 2500);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [tickets]);

  if (tickets.length > 0) {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <p>Running tasks</p>
          <div
            className={classes.buttonGroupRight}
            onClick={() => setIsDropped(!isDropped)}
          >
            <div
              style={{
                transform: isDropped ? 'rotate(0deg)' : 'rotate(180deg)'
              }}
            >
              <Icon size={14} name="ArrowDownFill" />
            </div>
          </div>
        </div>
        <div
          style={{
            display: isDropped ? 'block' : 'none'
          }}
          className={classes.files}
        >
          {tickets.map(t => (
            <IndividualTicket key={t.ticket_id} classes={classes} ticket={t} />
          ))}
        </div>
      </div>
    );
  }

  return <></>;
};

TicketBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectSheet(styles)(TicketBar);
