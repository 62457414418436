import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const SearchContext = createContext();
const SearchContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'addString':
          return { ...prevState, string: action.payload };
        case 'setFilter':
          return {
            ...prevState,
            filters: {
              ...prevState.filters,
              [action.payload.name]: action.payload.value
            }
          };
        case 'removeFilter': {
          const {
            [action.payload]: removedFilter,
            ...restFilters
          } = prevState.filters;
          return { ...prevState, filters: restFilters };
        }
        case 'setOrderBy':
          return {
            ...prevState,
            orderBy: { param: action.payload.name, order: action.payload.order }
          };
        case 'removeOrderBy':
          return { ...prevState, orderBy: { param: '' } };
        default:
          throw new Error();
      }
    },
    {
      string: '',
      filters: {},
      orderBy: {
        param: '',
        order: 'desc'
      }
    }
  );
  return (
    <SearchContext.Provider value={{ state, dispatch }}>
      {children}
    </SearchContext.Provider>
  );
};

SearchContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SearchContextProvider;
