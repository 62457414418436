export default theme => ({
  groupActions: {
    display: 'flex',
    flexFlow: 'column nowrap',
    borderBottom: `1px solid ${theme.grey8}`,
    overflow: 'hidden',
    padding: 10,
    marginRight: 5,
    position: 'relative'
  },
  groupActionsSelectedIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: 5,
    backgroundColor: theme.teal1
  },
  groupHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  groupHeaderLeft: {
    marginRight: 10,
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  groupAvatarContainer: {
    marginRight: 15
  },
  groupHeaderRight: {
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: theme.grey2,
    fontSize: 12,
    fontWeight: 500
  },
  groupActionsToggleIcon: {
    padding: 5,
    fill: theme.grey2
  },
  groupKey: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.grey1,
    fontWeight: 600,
    fontSize: 14,
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  actionsWrapper: {
    paddingLeft: 50,
    overflow: 'hidden',
    height: 0,
    '&[data-is-visible=true]': {
      paddingTop: 10,
      height: 'auto'
    }
  },
  radioButton: {
    marginBottom: 10
  },
  radioButtonSelected: {
    marginBottom: 10,
    '& div': {
      fontWeight: 'bold'
    }
  }
});
