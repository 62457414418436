import gql from 'graphql-tag';

export default {
  actionQuery: gql`
    fragment NewLinkFragment on Workflow {
      rowId
      name
      config {
        actions
        rowId
      }
      groups {
        nodes {
          rowId
          label
          name
          avatar
        }
      }
      rawActionByKey(key: $actionKey)
      # These fields might have $refs we need to resolve
      # and they're not resolved in rawActionByKey
      actionByKey(actionKey: $actionKey, traceIds: $traceIds) {
        dataImporter
        dataEditor
        form {
          schema
          uiSchema
        }
      }
      traces(filter: { rowId: { in: $traceIds } }) {
        nodes {
          rowId
          name
          readers {
            accountId
            name
            accountType
          }
          state {
            meta
            rowId
            data
            nextActions
            trace {
              rowId
              head {
                linkHash
                createdByAccount {
                  rowId
                  entity {
                    __typename
                    ... on Account_User {
                      rowId
                      name
                    }
                    ... on Account_Bot {
                      rowId
                      name
                    }
                  }
                }
              }
            }
          }
          workflow {
            rowId
            config {
              rowId
              info
            }
          }
        }
      }
    }
  `
};
