import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import debounce from 'debounce';
import injectSheet from 'react-jss';
import { MagnifyingGlass } from '@stratumn/icons';
import shortid from 'shortid';
import { SearchContext } from './searchContext';
import styles from './search.style';

export const SearchBar = ({ classes, config }) => {
  const { state: searchState, dispatch } = useContext(SearchContext);
  // here the state represent the data in the context and dispatch is the action to update specific key in the state
  const { string } = searchState || ''; // Can add filters and orderBy when advanced search is added.
  const handleAddString = payload => {
    dispatch({ type: 'addString', payload: payload });
  };
  const handleSearch = event =>
    debounce(handleAddString(event.target.value), 500);
  const [hasFocus, setHasFocus] = useState(false);
  const searchId = useMemo(() => shortid.generate(), []);
  const { placeholder } = config || 'Search';
  return (
    <label
      className={classnames(classes.root, {
        [classes.hasFocus]: hasFocus
      })}
      htmlFor={searchId}
    >
      <MagnifyingGlass className={classes.icon} />
      <input
        className={classes.input}
        value={string}
        onChange={handleSearch}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        id={searchId}
        placeholder={placeholder}
      />
    </label>
  );
};
SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
};
export default injectSheet(styles)(SearchBar);
