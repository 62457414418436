import { useState, useRef, useCallback } from 'react';

/**
 * @function useFocus
 * @desc this hook provides a focused state variable
 * @desc that keeps a ref focused when the state changes (optional when blurring)
 * @desc useful for buttons or inputs
 * @param {boolean} initFocused - whether the state is initially focused
 * @param {boolean} keepFocusOnBlur - whether to keep the ref focused after blur
 * @returns {Array} - an array that contains isFocused, focus, blur and the ref
 */
export default (initFocused, keepFocusOnBlur = false) => {
  // State and setter for focus
  const [isFocused, setFocus] = useState(initFocused);

  // ref that will be focused
  const ref = useRef(null);

  // handle initFocus state to true by focusing after a short inital delay
  if (initFocused) {
    setTimeout(() => {
      if (ref.current) {
        ref.current.focus();
      }
    });
  }

  // callbacks
  const focus = useCallback(() => {
    setFocus(true);
    if (ref.current) {
      ref.current.focus();
    }
  }, []);
  const blur = useCallback(() => {
    setFocus(false);
    if (ref.current && keepFocusOnBlur) {
      ref.current.focus();
    }
  }, []);

  return [isFocused, focus, blur, ref];
};
