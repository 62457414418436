export const ROUTE_WORKFLOW_DASHBOARD = '/';
// export const ROUTE_WORKFLOW_BOARD = '/workflow/:id'; // Kanban view removed
export const ROUTE_WORKFLOW_OVERVIEW = '/workflowoverview/:id';
export const ROUTE_WORKFLOW_EXPORT = '/workflow/:id/export';
// export const ROUTE_GROUP_BOARD = '/group/:id'; // Kanban view removed
// export const ROUTE_GROUP_FORMS = '/group/:id/forms'; // Kanban view removed
export const ROUTE_GROUP_FORM = '/group/:groupid/form/:actionkey';
export const ROUTE_GROUP_SETTINGS = '/group/:id/settings';
export const ROUTE_NEW_LINK = '/workflow/:wfid/newlink';
export const ROUTE_INSPECT_TRACE = '/trace/:id';
export const ROUTE_INSPECT_TRACE_LINK = '/trace/:id/:linkid';
export const ROUTE_USER_DASHBOARD = '/userdashboard';
export const ROUTE_WORKFLOW_GROUPS = '/workflow/:id/settings/groups';
export const ROUTE_LOGIN = '/login';
export const ROUTE_OAUTH = '/oauth';
export const ROUTE_UNAUTHORIZED = '/unauthorized';
export const ROUTE_GDPR_POLICY = '/gdpr-privacy-policy';

// These routes are only for demo purposes.
export const ROUTE_WORKFLOW_DATA_DASHBOARD = '/workflow/:id/dashboard';
