import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import injectSheet from 'react-jss';
import classnames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import Path from 'path-to-regexp';

import moment from 'moment';

import {
  AvatarGroup,
  Avatar,
  Pushbutton,
  Pulldown,
  PulldownMenuItem
} from '@stratumn/atomic';
import DynamicIcon from '@stratumn/icons/lib/DynamicIcon';

import { ROUTE_NEW_LINK, ROUTE_INSPECT_TRACE } from 'constant/routes';

import GroupsTaskListItemError from '../groupsTaskListItemError';

import styles from './groupsTaskListItem.style';

// buffer to put the deadline display in a warning mode
const DEADLINE_WARNING_BUFFER = 0;

// group task list item of the user dashboard
export const GroupsTaskListItem = React.memo(
  ({ classes, history, className, priority, displayGroupInfo }) => {
    // Missing trace error check
    if (!priority.trace) {
      return (
        <div className={classnames(className, classes.item)}>
          <GroupsTaskListItemError title="Missing trace data" />
        </div>
      );
    }

    // Valid priority
    const {
      group: { label: groupLabel, name: groupName },
      trace: {
        rowId: traceRowId,
        name: traceName,
        workflow: {
          rowId: workflowRowId,
          name: workflowName,
          actions: { nodes: workflowActions }
        },
        state: {
          meta: {
            head: {
              action: {
                icon: headActionIcon = 'Document',
                title: headActionTitle
              },
              createdAt: headCreatedAt,
              group: { avatar: headGroupAvatar, name: headGroupName },
              createdBy: { avatar: headAuthorAvatar, name: headAuthorName }
            }
          },
          nextActions,
          tasks: { deadlines = [], groups: groupsTasks = {} } = {}
        }
      }
    } = priority;

    // check available next actions for this group
    // if there is a "todo" in this specific group's tasks, display them
    // otherwise display all nextActions
    let groupNextActions = nextActions[groupLabel];
    if (
      groupsTasks[groupLabel] &&
      Array.isArray(groupsTasks[groupLabel].todo) &&
      groupsTasks[groupLabel].todo.length > 0
    ) {
      groupNextActions = groupsTasks[groupLabel].todo;
    }

    // The next actions structure can either be an array (v1 next actions)
    // or an object (v2 next actions/transitions)
    let actions;
    if (!groupNextActions) {
      actions = [];
    } else if (groupNextActions.length > 0) {
      actions = groupNextActions
        .map(action =>
          workflowActions.find(wfAction => wfAction.key === action)
        )
        .filter(Boolean);
    } else {
      actions = Object.entries(groupNextActions).reduce(
        (arr, [key, allowAction]) => {
          if (allowAction) {
            const action = workflowActions.find(a => a.key === key);
            if (action) {
              arr.push(action);
            }
          }
          return arr;
        },
        []
      );
    }

    // navigate to the referenced trace
    const traceLink = useMemo(
      () => ({
        pathname: Path.compile(ROUTE_INSPECT_TRACE)({
          id: traceRowId
        }),
        state: {
          from: history.location
        }
      }),
      [traceRowId, history]
    );

    // navigate to the newlink tab with a selected action on this trace for this group
    const getActionLink = useCallback(
      actionKey => ({
        pathname: Path.compile(ROUTE_NEW_LINK)({
          wfid: workflowRowId
        }),
        search: `?groupKey=${groupLabel}&actionKey=${actionKey}&traceIds=${traceRowId}`,
        state: {
          from: history.location
        }
      }),
      [workflowRowId, groupLabel, traceRowId, history]
    );

    // single button if only one nextActions, pulldown otherwise
    let actionsBtn;
    switch (true) {
      case actions.length === 0:
        break;
      case actions.length === 1:
        actionsBtn = (
          <Link
            className={classes.navLink}
            to={getActionLink(actions[0].key)}
            data-cy="action-link"
          >
            <Pushbutton primary>{actions[0].title}</Pushbutton>
          </Link>
        );
        break;
      default:
        actionsBtn = (
          <Pulldown buttonLabel={`${actions.length} next actions`} primary>
            {actions.map(action => (
              <Link
                key={action.key}
                className={classes.navLink}
                to={getActionLink(action.key)}
                data-cy="action-link"
              >
                <PulldownMenuItem
                  icon={
                    <DynamicIcon
                      icon={action.icon || 'Document'}
                      className={classes.icon}
                    />
                  }
                  onClick={() => {}} // note: onClick is required by atomic PulldownMenuItem, but here we overload it with Link
                >
                  {action.title}
                </PulldownMenuItem>
              </Link>
            ))}
          </Pulldown>
        );
    }

    // handle deadline warning display
    let countdownStr;
    let warning = false;
    if (deadlines.length > 0) {
      const timeToUpcomingDeadline = moment
        .utc(deadlines[0].date)
        .local()
        .diff(moment(), 'days');

      warning = timeToUpcomingDeadline - DEADLINE_WARNING_BUFFER <= 0;

      switch (true) {
        case timeToUpcomingDeadline === 0:
          countdownStr = 'Due today';
          break;
        case timeToUpcomingDeadline > 0:
          countdownStr = `Due in ${timeToUpcomingDeadline} day${
            timeToUpcomingDeadline > 1 ? 's' : ''
          }`;
          break;
        default:
          countdownStr = `${-timeToUpcomingDeadline} day${
            timeToUpcomingDeadline < -1 ? 's' : ''
          } overdue`;
      }
    }

    return (
      <div className={classnames(className, classes.item)}>
        <div className={classes.header}>
          <Link
            className={classes.headerLeft}
            to={traceLink}
            data-cy="trace-link"
          >
            <div className={classes.traceName}>{traceName}</div>
            <div className={classes.workflowInfo}>
              <div className={classes.workflowName}>{workflowName}</div>
              {displayGroupInfo && (
                <>
                  <div className={classes.workflowNameSeparator}>|</div>
                  <div className={classes.groupOwnerName}>{groupName}</div>
                </>
              )}
            </div>
          </Link>
          <div className={classes.headerRight}>
            {countdownStr && (
              <div
                className={
                  warning ? classes.deadlineLate : classes.deadlineOnTime
                }
              >
                {countdownStr}
              </div>
            )}
            <div className={classes.actions}>{actionsBtn}</div>
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.contentItem}>
            <div className={classes.contentItemHeader}>Last action</div>
            <div className={classes.contentItemProperties}>
              <div className={classes.contentItemProperty}>
                <DynamicIcon
                  icon={headActionIcon}
                  className={classes.icon}
                  data-is-svg
                />
                <div className={classes.contentItemPropertyInfo}>
                  {headActionTitle}
                </div>
              </div>
              <div className={classes.contentItemProperty}>
                <DynamicIcon
                  icon="Clock"
                  className={classes.icon}
                  data-is-svg
                />
                <div className={classes.contentItemPropertyInfo}>
                  {moment.utc(headCreatedAt).local().fromNow()}
                </div>
              </div>
              <div className={classes.contentItemProperty}>
                {headGroupAvatar && (
                  <AvatarGroup src={headGroupAvatar} size={18} />
                )}
                <div className={classes.contentItemPropertyInfo}>
                  {headGroupName}
                </div>
              </div>
              <div className={classes.contentItemProperty}>
                {headAuthorAvatar && (
                  <Avatar src={headAuthorAvatar} size={18} />
                )}
                <div className={classes.contentItemPropertyInfo}>
                  {headAuthorName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

GroupsTaskListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  className: PropTypes.string,
  priority: PropTypes.object.isRequired,
  displayGroupInfo: PropTypes.bool
};
GroupsTaskListItem.defaultProps = {
  className: '',
  displayGroupInfo: false
};

export default withRouter(injectSheet(styles)(GroupsTaskListItem));

// export the priority fragment
export const fragments = {
  priority: gql`
    fragment PriorityFragment on Priority {
      id
      responsibility
      investment
      urgency
      group {
        id
        rowId
        label
        name
        avatar
      }
      trace {
        rowId
        name
        workflow {
          rowId
          name
          description
          actions {
            nodes {
              key
              title
              icon
              description
            }
          }
        }
        state {
          rowId
          data
          nextActions
          meta
          tasks
        }
      }
    }
  `
};
