import React, { PureComponent, useEffect } from 'react';
import PropTypes from 'prop-types';

import compose from 'lodash.flowright';
import injectSheet from 'react-jss';
import classnames from 'classnames';

// React Window
import { VariableSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { ConfirmationModal } from '@stratumn/atomic';

// deep equality
import isequal from 'lodash.isequal';

import { deepGet } from 'utils';
import { exportDataTable } from 'utils/export';
import { getByPath } from 'utils/widgets';
import { withWorkflowContext } from 'utils/workflowContext';
import TraceIconSpinner from 'components/ui/traceIconSpinner';

// table components
import { localStorageKey } from 'components/workflowOverview/utils';
import { withWorkflowOverviewContext } from 'components/workflowOverview/context';

import {
  getUsedDisplayConfig,
  getCompletedDisplayConfig,
  reorder,
  getDataToDisplay,
  cleanupUserDisplayConfig,
  SCROLLBAR_SIZE
} from './displayConfigUtils';

import ColHeaders from './colHeaders';
import ColHeadersEditor from './colHeadersEditor';
import TableHeaders from './tableHeaders';
import TableFilters from './tableFilters';
import ResizableRow from './resizableRow';
import AggregationRow from './aggregationRow';

import { TableContext } from './tableContext';
import TableCell from './tableCell';

import styles from './table.style';

const NB_ROWS_IF_EMPTY = 100;

const DEFAULT_EXPORT_CSV = {
  delimiter: ';',
  filename: 'table_export'
};

const CSS_BOX_SHADOW = 'box-shadow';
const CSS_BORDER_RIGHT = 'border-right';

const DEFAULT_OVERSCANROWCOUNT = 10;
const FLOATING_GRID_MIN_RATIO = 1 / 3;
const SHRINK_HEADER_ICON_THRESHOLD = 1100;
const FIXED_GRID_VERTICAL_SHADOW = '15px 0px 15px -5px rgba(75, 53, 210, 0.1)';
const GRID_HEADERS_HORIZONTAL_SHADOW =
  '0px 15px 15px -5px rgba(75, 53, 210, 0.1)';

// ToggleAwareGrid below is a small HOC tool that is able to ask a react-window grid to recompute its columns layout
// using a ref to it, based on the changes of a toggle prop
// it is used by the floating columns grid of the Table to trigger recomputation of the columns layout
// when the width is such that the layout changes between fixed/float grid to a merged grid
// this is needed because there is no equivalent in classes of useEffect on variables computed in render methods
// that are neither props or state (and impact just a small part of the rendering)
// could have used a ref on the toggle as well but didn't like the idea of moving out of react pattern
// when rewriting the Table component as a function we'll be able to handle this in a simple useEffect
const ToggleAwareGridDriver = React.memo(({ toggle, gridRef, children }) => {
  useEffect(() => {
    if (gridRef.current) {
      // recompute columns only
      gridRef.current.resetAfterColumnIndex(0);
    }
  }, [toggle]);
  return children;
});
ToggleAwareGridDriver.propTypes = {
  toggle: PropTypes.bool.isRequired,
  gridRef: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

// table component itself
export class Table extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    config: PropTypes.object.isRequired,
    update: PropTypes.shape({
      loading: PropTypes.bool,
      disableClientSideSortFilter: PropTypes.bool,
      onClickConfig: PropTypes.func,
      configClicked: PropTypes.bool,
      onClickNew: PropTypes.func,
      newClicked: PropTypes.bool,
      onClickUpdate: PropTypes.func,
      updateClicked: PropTypes.bool,
      setSelectedRows: PropTypes.func,
      userDisplay: PropTypes.shape({
        onUpdate: PropTypes.func,
        localStorageKey: PropTypes.string,
        config: PropTypes.object
      }),
      edit: PropTypes.shape({
        modifiedData: PropTypes.object,
        onDataModified: PropTypes.func,
        allowDisplayDiffs: PropTypes.bool,
        onClickBatchEdit: PropTypes.func,
        batchEditClicked: PropTypes.bool
      }),
      pagination: PropTypes.shape({
        buffer: PropTypes.number.isRequired,
        hasNextPage: PropTypes.bool.isRequired,
        onLoadMore: PropTypes.func.isRequired
      }),
      group: PropTypes.shape({
        indexPath: PropTypes.string,
        rowsPath: PropTypes.string,
        onLoadMore: PropTypes.func
      }),
      exportSetup: PropTypes.shape({
        getConfirmationSetup: PropTypes.func,
        generateExportPayload: PropTypes.func
      })
    }),
    workflowContext: PropTypes.object,
    context: PropTypes.shape({
      savedViewsArray: PropTypes.arrayOf(PropTypes.object),
      updateSavedViewsArray: PropTypes.func,
      userDisplayConfig: PropTypes.object
    })
  };

  static defaultProps = {
    update: {},
    workflowContext: null,
    context: {
      savedViewsArray: null,
      setsavedViewsArray: () => {}
    }
  };

  constructor(props) {
    super(props);
    const { data, config, update } = props;

    // grid ref, kept for resizing and scrolling events
    this.colHeadersRef = React.createRef();

    this.fixedColHeadersRef = React.createRef();
    this.fixedGridRef = React.createRef();
    this.fixedGridOuterRef = React.createRef();
    this.fixedAggregationRowRef = React.createRef();

    this.floatingColHeadersRef = React.createRef();
    this.floatingGridRef = React.createRef();
    this.floatingGridOuterRef = React.createRef();
    this.floatingAggregationRowRef = React.createRef();

    // this is a ref to keep track of whether the grid merging status has changed
    // and trigger floatingGrid ref actualisation
    this.mergeGrids = React.createRef(false);

    let userDisplayConfig = deepGet(update, 'userDisplay.config', null);
    const key = deepGet(update, 'userDisplay.localStorageKey');
    if (!userDisplayConfig && key) {
      // get the user display config document from local storage
      const localStorageDisplayConfigStr = localStorage.getItem(key);
      userDisplayConfig = localStorageDisplayConfigStr
        ? JSON.parse(localStorageDisplayConfigStr)
        : null;
    }

    // complete the display config with all required widths etc...
    const displayConfig = getCompletedDisplayConfig(config, userDisplayConfig);

    // initial sort and filter of the data to display if necessary
    const dataToDisplay =
      !update || !update.disableClientSideSortFilter
        ? getDataToDisplay(data, displayConfig)
        : data;

    this.state = {
      userDisplayConfig, // this is the raw display config read from the specs or local storage
      displayConfig,
      selectedData: [],
      isEditing: false,
      dataToDisplay,
      displayDiffs: false,
      exporting: false,
      exportConfirmation: null,
      fileType: 'csv'
    };
  }

  componentDidMount() {
    const { userDisplayConfig } = this.props.context;

    this.updateUserDisplayConfig(userDisplayConfig);
  }

  saveConfig = name => {
    const { workflowContext } = this.props;
    const { savedViewsArray, updateSavedViewsArray } = this.props.context;

    const config = JSON.parse(
      localStorage.getItem(localStorageKey(workflowContext.rowId))
    );
    const newView = {
      id: Date.now(),
      name,
      config
    };
    const updatedConfigsArray = [...savedViewsArray, newView];
    updateSavedViewsArray(updatedConfigsArray);
  };

  updateGrid = () => {
    // tell the Grid components to update its cache
    if (this.fixedGridRef.current) {
      this.fixedGridRef.current.resetAfterColumnIndex(0);
      this.fixedGridRef.current.resetAfterRowIndex(0);
    }
    if (this.floatingGridRef.current) {
      this.floatingGridRef.current.resetAfterColumnIndex(0);
      this.floatingGridRef.current.resetAfterRowIndex(0);
    }
  };

  onFixedGridScroll = ({ scrollTop }) => {
    if (
      this.floatingGridRef.current &&
      this.floatingGridOuterRef.current &&
      this.floatingGridOuterRef.current.scrollTop !== scrollTop
    ) {
      this.floatingGridRef.current.scrollTo({
        scrollTop
      });
    }
  };

  onFloatingGridScroll = ({ scrollLeft, scrollTop }) => {
    // check if we need to scroll floating col headers horizontally
    if (
      this.floatingColHeadersRef.current &&
      this.floatingColHeadersRef.current.scrollLeft !== scrollLeft
    ) {
      this.floatingColHeadersRef.current.scrollLeft = this.floatingGridOuterRef.current.scrollLeft;
    }
    // check if we need to scroll floating aggregation row horizontally
    if (
      this.floatingAggregationRowRef.current &&
      this.floatingAggregationRowRef.current.scrollLeft !== scrollLeft
    ) {
      this.floatingAggregationRowRef.current.scrollLeft = this.floatingGridOuterRef.current.scrollLeft;
    }
    // check if we need to scroll fixed grid vertically
    if (
      this.fixedGridRef.current &&
      this.fixedGridOuterRef.current &&
      this.fixedGridOuterRef.current.scrollTop !== scrollTop
    ) {
      this.fixedGridRef.current.scrollTo({
        scrollTop
      });
    }
    // check if we need to apply box shadow to col headers bottom border
    if (this.colHeadersRef.current) {
      if (scrollTop === 0) {
        this.colHeadersRef.current.style.removeProperty(CSS_BOX_SHADOW);
      } else {
        this.colHeadersRef.current.style.setProperty(
          CSS_BOX_SHADOW,
          GRID_HEADERS_HORIZONTAL_SHADOW
        );
      }
    }
    // check if we need to apply box shadow to fixed col headers and grid right border
    if (this.fixedColHeadersRef.current && this.fixedGridOuterRef.current) {
      if (scrollLeft === 0) {
        this.fixedColHeadersRef.current.style.removeProperty(CSS_BOX_SHADOW);
        this.fixedGridOuterRef.current.style.removeProperty(CSS_BOX_SHADOW);
        this.fixedGridOuterRef.current.style.removeProperty(CSS_BORDER_RIGHT);
        if (this.fixedAggregationRowRef.current) {
          this.fixedAggregationRowRef.current.style.removeProperty(
            CSS_BOX_SHADOW
          );
        }
      } else {
        this.fixedColHeadersRef.current.style.setProperty(
          CSS_BOX_SHADOW,
          FIXED_GRID_VERTICAL_SHADOW
        );
        this.fixedGridOuterRef.current.style.setProperty(
          CSS_BOX_SHADOW,
          FIXED_GRID_VERTICAL_SHADOW
        );
        if (this.fixedAggregationRowRef.current) {
          this.fixedAggregationRowRef.current.style.setProperty(
            CSS_BOX_SHADOW,
            FIXED_GRID_VERTICAL_SHADOW
          );
        }
      }
    }
  };

  // scroll the floating grid left if the floating col headers scroll (happens with col headers drag n drop)
  onFloatingColHeadersScroll = () => {
    if (
      this.floatingGridRef.current &&
      this.floatingGridOuterRef.current &&
      this.floatingGridOuterRef.current.scrollLeft !==
        this.floatingColHeadersRef.current.scrollLeft
    ) {
      this.floatingGridRef.current.scrollTo({
        scrollLeft: this.floatingColHeadersRef.current.scrollLeft
      });
    }
  };

  // eslint-disable-next-line
  UNSAFE_componentWillUpdate = nextProps => {
    const newUserDisplayConfig =
      nextProps.update &&
      (!this.props.update ||
        nextProps.update.userDisplay !== this.props.update.userDisplay);
    if (
      nextProps.config !== this.props.config ||
      nextProps.data !== this.props.data ||
      newUserDisplayConfig
    ) {
      const userDisplayConfig = deepGet(nextProps, 'update.userDisplay.config');
      this.regenerateCompletedDisplayConfig(
        nextProps,
        userDisplayConfig !== undefined
          ? userDisplayConfig
          : this.state.userDisplayConfig,
        true
      );
    }
  };

  getFixedColumnWidth = idx => {
    const { selectBoxWidth, fixedColumns } = this.state.displayConfig;
    // fixed columns grid holds selectBox at idx=0
    return idx === 0 ? selectBoxWidth : fixedColumns[idx - 1].width;
  };

  getFloatingColumnWidth = idx => {
    const { columns } = this.state.displayConfig;
    return columns[idx].width;
  };

  getMergedColumnsWidth = idx => {
    const { fixedColumns, columns } = this.state.displayConfig;
    const mergedColumns = [...fixedColumns, ...columns];
    return mergedColumns[idx].width;
  };

  getEstimatedFloatingColumnWidth = () => {
    const { columns } = this.state.displayConfig;
    const totalWidth = columns.reduce(
      (width, column) => width + column.width,
      0.0
    );
    return totalWidth / columns.length;
  };

  getEstimatedMergedColumnWidth = () => {
    const { fixedColumns, columns } = this.state.displayConfig;
    const mergedColumns = [...fixedColumns, ...columns];
    const totalWidth = mergedColumns.reduce(
      (width, column) => width + column.width,
      0.0
    );
    return totalWidth / mergedColumns.length;
  };

  getRowHeight = () =>
    // if the table is asked to display diffs vs patches, double the rows height
    (this.state.displayDiffs ? 2 : 1) * this.state.displayConfig.rowsHeight;

  // returns a copy / sanitized version of the current user display config (the one cached in localStorage)
  getCurrentDisplayConfig = () =>
    getUsedDisplayConfig(this.props.config, this.state.userDisplayConfig);

  updateUserDisplayConfig = newUserDisplayConfig => {
    const { config, update } = this.props;
    const { userDisplay = {} } = update || {};

    // first cleanup a bit the new config, ie remove empty things
    // this is required to reduce the user config
    // and have a better sense of when it is not empty (and show the reset button)
    const usedDisplayConfig = cleanupUserDisplayConfig(
      config,
      newUserDisplayConfig
    );

    // If a user display config handler has been passed, use it
    if (userDisplay.onUpdate) {
      userDisplay.onUpdate(usedDisplayConfig);
      return;
    }

    if (userDisplay.localStorageKey) {
      // update the local storage only if a cached config key has been provided
      if (!usedDisplayConfig) {
        // delete the cached display config
        localStorage.removeItem(userDisplay.localStorageKey);
      } else {
        // save it to local storage
        localStorage.setItem(
          userDisplay.localStorageKey,
          JSON.stringify(usedDisplayConfig)
        );
      }
    }

    this.regenerateCompletedDisplayConfig(this.props, usedDisplayConfig);
  };

  // Regenerates the config to be used for the current display.
  // This updates the table config wth the user display config
  regenerateCompletedDisplayConfig = (
    props,
    newUserDisplayConfig,
    forceUpdate = false
  ) => {
    const { config, data, update } = props;
    const displayConfig = getCompletedDisplayConfig(
      config,
      newUserDisplayConfig
    );

    // update the data to display
    // if necessary (ie either sorting or filtering has changed)
    const { displayConfig: currentDisplayConfig } = this.state;
    let { dataToDisplay } = this.state;

    if (
      forceUpdate ||
      !isequal(currentDisplayConfig.sortSetup, displayConfig.sortSetup) ||
      !isequal(currentDisplayConfig.applyFilters, displayConfig.applyFilters) ||
      !isequal(currentDisplayConfig.filters, displayConfig.filters) ||
      !isequal(
        currentDisplayConfig.columns.map(col => col.filter),
        displayConfig.columns.map(col => col.filter)
      ) ||
      !isequal(
        currentDisplayConfig.fixedColumns.map(col => col.filter),
        displayConfig.fixedColumns.map(col => col.filter)
      )
    ) {
      dataToDisplay =
        !update || !update.disableClientSideSortFilter
          ? getDataToDisplay(data, displayConfig)
          : data;
    }

    // update the state
    this.setState(
      {
        userDisplayConfig: newUserDisplayConfig,
        displayConfig,
        dataToDisplay
      },
      () => {
        this.updateGrid();
      }
    );
  };

  toggleEdition = () => {
    this.setState(prevState => ({
      isEditing: !prevState.isEditing
    }));
  };

  reorderColumns = result => {
    // reorder user columns following drag n drop in ColHeadersEditor

    // note: col headers drag n dropping may result in the col headers editor being scrolled too far
    // (because of the vertical scrollbar width buffer)
    // so we resync col headers scroll here to where the grid outer ref has stopped
    if (
      this.floatingColHeadersRef.current &&
      this.floatingGridOuterRef.current
    ) {
      this.floatingColHeadersRef.current.scrollLeft = this.floatingGridOuterRef.current.scrollLeft;
    }

    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      // dropped outside the list or left at the same place
      return;
    }

    const newUserDisplayConfig = this.getCurrentDisplayConfig();
    const {
      displayConfig: { columns }
    } = this.state;
    const currentColumnKeys = columns.map(col => col.key);

    newUserDisplayConfig.columns = reorder(
      currentColumnKeys,
      result.source.index,
      result.destination.index
    );

    this.updateUserDisplayConfig(newUserDisplayConfig);
  };

  setColumnWidth = (colKey, width) => {
    // set a user column width from resizing event in ColHeadersEditor
    const newUserDisplayConfig = this.getCurrentDisplayConfig();

    if (!newUserDisplayConfig.widths) {
      newUserDisplayConfig.widths = {};
    }
    newUserDisplayConfig.widths[colKey] = width;

    this.updateUserDisplayConfig(newUserDisplayConfig);
  };

  setRowsHeight = height => {
    // set a user rows height from resizing event in ColHeadersEditor
    const newUserDisplayConfig = this.getCurrentDisplayConfig();

    newUserDisplayConfig.rowsHeight = height;

    this.updateUserDisplayConfig(newUserDisplayConfig);
  };

  setSortSetup = (setup, sortConfig) => {
    const cfg = this.getCurrentDisplayConfig();

    if (setup) {
      cfg.sortSetup = {
        ...setup,
        config: sortConfig
      };
    } else {
      cfg.sortSetup = undefined;
    }
    this.updateUserDisplayConfig(cfg);
  };

  setGroupBy = group => {
    const newUserDisplayConfig = this.getCurrentDisplayConfig();
    newUserDisplayConfig.groupBy = group;
    this.updateUserDisplayConfig(newUserDisplayConfig);
  };

  toggleFilters = () => {
    const newUserDisplayConfig = this.getCurrentDisplayConfig();

    newUserDisplayConfig.applyFilters = !newUserDisplayConfig.applyFilters;

    this.updateUserDisplayConfig(newUserDisplayConfig);
  };

  setColumnFilter = (colKey, filterValue, filterConfig) => {
    // set a user filter on a specific column
    const newUserDisplayConfig = this.getCurrentDisplayConfig();

    if (!newUserDisplayConfig.filters) {
      newUserDisplayConfig.filters = {};
    }

    if (filterValue === undefined) {
      delete newUserDisplayConfig.filters[colKey];
    } else {
      newUserDisplayConfig.filters[colKey] = {
        value: filterValue,
        config: filterConfig
      };
    }
    this.updateUserDisplayConfig(newUserDisplayConfig);
  };

  toggleColumnSelection = colKey => {
    const newUserDisplayConfig = this.getCurrentDisplayConfig();

    const {
      displayConfig: { columns }
    } = this.state;
    const currentColumnKeys = columns.map(col => col.key);
    const colIndex = currentColumnKeys.findIndex(key => key === colKey);
    if (colIndex > -1) {
      // remove the column
      currentColumnKeys.splice(colIndex, 1);
      // remove other display configs related to it
      if (
        newUserDisplayConfig.filters &&
        newUserDisplayConfig.filters[colKey]
      ) {
        delete newUserDisplayConfig.filters[colKey];
      }
      if (newUserDisplayConfig.widths && newUserDisplayConfig.widths[colKey]) {
        delete newUserDisplayConfig.widths[colKey];
      }
      if (
        newUserDisplayConfig.sortSetup &&
        newUserDisplayConfig.sortSetup.key === colKey
      ) {
        delete newUserDisplayConfig.sortSetup;
      }
      if (
        newUserDisplayConfig.groupBy &&
        newUserDisplayConfig.groupBy.column === colKey
      ) {
        delete newUserDisplayConfig.groupBy;
      }
    } else {
      // add it
      currentColumnKeys.push(colKey);
    }
    newUserDisplayConfig.columns = currentColumnKeys;
    this.updateUserDisplayConfig(newUserDisplayConfig);
  };

  resetDisplayConfig = () => {
    this.updateUserDisplayConfig(null);
  };

  toggleRowSelected = rowSelector => {
    const { selectedData } = this.state;
    const { update } = this.props;

    const isSelected = selectedData.includes(rowSelector);

    let newSelectedData;
    if (isSelected) {
      // already selected so unselect it
      newSelectedData = selectedData.filter(
        selector => selector !== rowSelector
      );
    } else {
      // select it
      newSelectedData = [...selectedData, rowSelector];
    }
    this.setState({
      selectedData: newSelectedData
    });
    // if callback provided
    // warn the client (parent) about the changes in selectedRows
    if (update.setSelectedRows) {
      update.setSelectedRows(newSelectedData);
    }
  };

  toggleSelectAll = () => {
    const { dataToDisplay, selectedData } = this.state;
    const { config, update } = this.props;

    const dataSelectorPath = config.dataSelectorPath || 'rowId';

    const newSelectedData =
      selectedData.length > 0
        ? []
        : dataToDisplay.map(rowData => getByPath(rowData, dataSelectorPath));

    this.setState({
      selectedData: newSelectedData
    });
    // if callback provided
    // warn the client (parent) about the changes in selectedRows
    if (update.setSelectedRows) {
      update.setSelectedRows(newSelectedData);
    }
  };

  toggleGroup = label => {
    // Update display config to save the uncollapsed group
    const newDisplayConfig = this.getCurrentDisplayConfig();
    newDisplayConfig.groupBy = {
      ...newDisplayConfig.groupBy,
      // If the selected group row is already open, collapse it
      selected: newDisplayConfig.groupBy.selected === label ? undefined : label
    };
    this.updateUserDisplayConfig(newDisplayConfig);
  };

  toggleDisplayDiffs = () => {
    this.setState(
      prevState => ({
        displayDiffs: !prevState.displayDiffs
      }),
      () => {
        this.updateGrid();
      }
    );
  };

  toggleShowAggregation = () => {
    this.setState(
      prevState => ({ showAggregation: !prevState.showAggregation }),
      () => {
        this.updateGrid();
        // when displaying the aggregation row
        // check if the grid is already scrolled horizontally
        // and potentially apply scroll to the row and box shadow
        if (
          this.state.showAggregation &&
          !!this.floatingGridOuterRef.current?.scrollLeft
        ) {
          // apply box shadow
          if (this.fixedAggregationRowRef.current) {
            this.fixedAggregationRowRef.current.style.setProperty(
              CSS_BOX_SHADOW,
              FIXED_GRID_VERTICAL_SHADOW
            );
          }
          // scroll the floating aggregation row left
          if (this.floatingAggregationRowRef.current) {
            this.floatingAggregationRowRef.current.scrollLeft = this.floatingGridOuterRef.current.scrollLeft;
          }
        }
      }
    );
  };

  clickExport = async () => {
    const { update } = this.props;
    // if getConfirmationSetup is provided,
    // it means the client is expecting a confirmation modal to be thrown before
    const getConfirmationSetup = update?.exportSetup?.getConfirmationSetup;
    if (getConfirmationSetup) {
      this.setState({
        exportConfirmation: getConfirmationSetup()
      });
      return;
    }

    // otherwise call directly the exportData function
    this.exportData();
  };

  cancelExport = () => {
    this.setState({
      exportConfirmation: null
    });
  };

  exportData = async () => {
    const { dataToDisplay, displayConfig, fileType } = this.state;
    const { config, workflowContext, update } = this.props;

    // build a function that can apply a single row parsing to all data
    // if an export function from the client exists use it (it will implement asynchronous pagination)
    // otherwise just pass a function that applies the row mapping to all currently available data
    const generateExportPayload =
      update?.exportSetup?.generateExportPayload ||
      (rowPayloadFn => dataToDisplay.map(rowPayloadFn));

    // update state to exporting
    this.setState({
      exporting: true,
      exportConfirmation: null
    });

    await exportDataTable(
      generateExportPayload,
      displayConfig,
      {
        ...DEFAULT_EXPORT_CSV,
        ...config.exportCsv
      },
      workflowContext,
      fileType
    );

    // unset exporting state
    this.setState({
      exporting: false
    });
  };

  // Change type file for export function
  changeTypeFile = type => {
    this.setState({ fileType: type });
  };

  // Table Headers
  renderTableHeaders = (nbRows, shrinkRightIcons, currentNbRows) => {
    const { config, update } = this.props;
    const {
      userDisplayConfig,
      displayConfig,
      selectedData,
      isEditing,
      displayDiffs,
      showAggregation
    } = this.state;

    const isUserConfigModified =
      !!userDisplayConfig && Object.keys(userDisplayConfig).length > 0;

    return (
      <TableHeaders
        toggleFilters={this.toggleFilters}
        isEditing={isEditing}
        toggleEdition={this.toggleEdition}
        tableConfig={config}
        displayConfig={displayConfig}
        toggleColumnSelection={this.toggleColumnSelection}
        setGroupBy={this.setGroupBy}
        nbRows={nbRows}
        currentNbRows={currentNbRows}
        update={update}
        displayDiffs={displayDiffs}
        toggleDisplayDiffs={this.toggleDisplayDiffs}
        onExport={this.clickExport}
        showAggregation={showAggregation}
        toggleShowAggregation={this.toggleShowAggregation}
        nbRowsSelected={selectedData.length}
        toggleSelectAll={this.toggleSelectAll}
        resetDisplayConfig={
          isUserConfigModified ? this.resetDisplayConfig : null
        }
        shrinkRightIcons={shrinkRightIcons}
        saveConfig={this.saveConfig}
        updateUserDisplayConfig={this.updateUserDisplayConfig}
        changeTypeFile={this.changeTypeFile}
        fileType={this.state.fileType}
      />
    );
  };

  // Table Column  Headers and Filters
  renderTableColumnHeaders = (
    selectBoxWidth,
    fixedColumns,
    floatingColumns,
    sortSetup,
    applyFilters,
    invalidFilters,
    isEditing,
    mergeGrids
  ) => {
    const { classes } = this.props;

    // fixed column headers container
    const fixedColumnHeaders = (
      <div
        className={classnames(
          classes.tableColHeadersStack,
          classes.tableFixedColHeaders
        )}
        ref={this.fixedColHeadersRef}
      >
        <ColHeaders
          height={30}
          selectBoxWidth={selectBoxWidth}
          columns={mergeGrids ? [] : fixedColumns}
          sortSetup={sortSetup}
          setSortSetup={this.setSortSetup}
        />
        {applyFilters && (
          <TableFilters
            selectBoxWidth={selectBoxWidth}
            columns={mergeGrids ? [] : fixedColumns}
            setColumnFilter={this.setColumnFilter}
            invalidFilters={invalidFilters}
          />
        )}
      </div>
    );

    // floating column headers container, scrollable in sync with the floating columns grid
    const floatingColumnHeaders = (
      <div
        className={classes.tableFloatingColHeaders}
        ref={this.floatingColHeadersRef}
        onScroll={this.onFloatingColHeadersScroll}
      >
        {mergeGrids && (
          <div className={classes.tableColHeadersStack}>
            <ColHeaders
              height={30}
              columns={fixedColumns}
              sortSetup={sortSetup}
              setSortSetup={this.setSortSetup}
            />
            {applyFilters && (
              <TableFilters
                columns={fixedColumns}
                setColumnFilter={this.setColumnFilter}
                invalidFilters={invalidFilters}
              />
            )}
          </div>
        )}
        <div className={classes.tableColHeadersStack}>
          {isEditing ? (
            <ColHeadersEditor
              height={30}
              columns={floatingColumns}
              reorderColumns={this.reorderColumns}
              setColumnWidth={this.setColumnWidth}
            />
          ) : (
            <ColHeaders
              height={30}
              columns={floatingColumns}
              sortSetup={sortSetup}
              setSortSetup={this.setSortSetup}
            />
          )}
          {applyFilters && (
            <TableFilters
              columns={floatingColumns}
              setColumnFilter={this.setColumnFilter}
              invalidFilters={invalidFilters}
            />
          )}
        </div>
        <div className={classes.gridScrollbarPlaceholder} />
      </div>
    );

    // aggregate the 2 sets of column headers
    return (
      <div className={classes.tableColHeaders} ref={this.colHeadersRef}>
        {fixedColumnHeaders}
        {floatingColumnHeaders}
      </div>
    );
  };

  // Table Grids
  renderTableGrids = (
    height,
    fixedGridWidth,
    fixedColumns,
    floatingGridWidth,
    floatingColumns,
    sharedGridContext,
    mergeGrids,
    rowCount,
    overscanRowCount,
    loading
  ) => {
    const { classes } = this.props;

    // Note: Grid takes as child a component that will be used to render all the cells by react-window
    // react-window props are made of rowIndex, columnIndex and INLINE POSITIONING STYLE
    // so we will use a rendering component TableCell that has access to the table context

    const rowsHeight = this.getRowHeight();

    const usedFixedColumns = mergeGrids ? [] : fixedColumns;
    const usedFloatingColumns = mergeGrids
      ? [...fixedColumns, ...floatingColumns]
      : floatingColumns;

    // fixed columns grid

    // if the floating grid is effectively scrolling horizontally we need to add a blank of height SCROLLBAR_SIZE
    // below the fixed grid to compensate for the one injected by react-window at the bottom of the floating grid
    // or the 2 grids will be displaced when reaching the bottom of the container
    // small subtlety: if the grid is also scrollable vertically
    // we need to take into account the width of the vertical scrollbar to check if the grid will be scrollable horizontally...
    const floatingGridHorizontalScroll =
      usedFloatingColumns.reduce((sum, col) => sum + col.width, 0) >
      floatingGridWidth - (rowsHeight * rowCount > height ? SCROLLBAR_SIZE : 0);

    const fixedGridHeight =
      height - (floatingGridHorizontalScroll ? SCROLLBAR_SIZE : 0);
    const fixedGridContext = {
      ...sharedGridContext,
      isFixedGrid: true,
      columns: usedFixedColumns
    };
    const fixedGrid = (
      <TableContext.Provider value={fixedGridContext}>
        <Grid
          ref={this.fixedGridRef}
          columnCount={usedFixedColumns.length + 1}
          columnWidth={this.getFixedColumnWidth}
          width={fixedGridWidth}
          rowCount={rowCount}
          estimatedRowHeight={rowsHeight}
          rowHeight={this.getRowHeight}
          height={fixedGridHeight}
          overscanRowCount={overscanRowCount || DEFAULT_OVERSCANROWCOUNT}
          onScroll={this.onFixedGridScroll}
          outerRef={this.fixedGridOuterRef}
          className={classnames(
            classes.tableFixedGrid,
            loading ? classes.reactWindowGridLoading : ''
          )}
        >
          {TableCell}
        </Grid>
      </TableContext.Provider>
    );

    // floating columns grid
    const floatingGridContext = {
      ...sharedGridContext,
      isFixedGrid: false,
      columns: usedFloatingColumns
    };
    const floatingGrid = (
      <TableContext.Provider value={floatingGridContext}>
        <ToggleAwareGridDriver
          toggle={mergeGrids}
          gridRef={this.floatingGridRef}
        >
          <Grid
            ref={this.floatingGridRef}
            columnCount={usedFloatingColumns.length}
            estimatedColumnWidth={
              mergeGrids
                ? this.getEstimatedMergedColumnWidth()
                : this.getEstimatedFloatingColumnWidth()
            }
            columnWidth={
              mergeGrids
                ? this.getMergedColumnsWidth
                : this.getFloatingColumnWidth
            }
            width={floatingGridWidth}
            rowCount={rowCount}
            estimatedRowHeight={rowsHeight}
            rowHeight={this.getRowHeight}
            height={height}
            overscanRowCount={overscanRowCount || DEFAULT_OVERSCANROWCOUNT}
            onScroll={this.onFloatingGridScroll}
            outerRef={this.floatingGridOuterRef}
            className={loading ? classes.reactWindowGridLoading : ''}
          >
            {TableCell}
          </Grid>
        </ToggleAwareGridDriver>
      </TableContext.Provider>
    );

    // aggregate the 2 grids
    return (
      <div className={classes.tableGrids}>
        {fixedGrid}
        {floatingGrid}
      </div>
    );
  };

  // Table Aggregation row
  renderTableAggregationRow = (
    selectBoxWidth,
    fixedColumns,
    floatingColumns,
    mergeGrids,
    data
  ) => {
    const { classes } = this.props;

    // fixed column aggregation row container
    const fixedColumnAggregationRow = (
      <div
        className={classes.fixedAggregationRow}
        ref={this.fixedAggregationRowRef}
      >
        <AggregationRow
          height={30}
          selectBoxWidth={selectBoxWidth}
          columns={mergeGrids ? [] : fixedColumns}
          data={data}
        />
      </div>
    );

    // floating column aggregation row container, scrollable in sync with the floating columns grid
    const floatingColumnAggregationRow = (
      <div
        className={classes.floatingAggregationRow}
        ref={this.floatingAggregationRowRef}
      >
        {mergeGrids && (
          <AggregationRow height={30} columns={fixedColumns} data={data} />
        )}
        <AggregationRow height={30} columns={floatingColumns} data={data} />
        <div className={classes.gridScrollbarPlaceholder} />
      </div>
    );

    // aggregate the 2 sets of aggregation cells
    return (
      <div className={classes.aggregationRow}>
        {fixedColumnAggregationRow}
        {floatingColumnAggregationRow}
      </div>
    );
  };

  render = () => {
    const { classes, config, update } = this.props;

    const { dataSelectorPath = 'rowId', overscanRowCount } = config;
    const { onClickUpdate, edit, pagination, group, loading } = update;

    const {
      dataToDisplay,
      displayConfig,
      selectedData,
      isEditing,
      displayDiffs,
      showAggregation,
      exporting,
      exportConfirmation
    } = this.state;
    const {
      selectBoxWidth,
      fixedColumns,
      columns: floatingColumns,
      rowsHeight,
      minRowsHeight,
      sortSetup,
      groupBy,
      applyFilters,
      rowInlineStyle
    } = displayConfig;

    // check filtered columns and report if invalid filters combination
    const filteredFixedColumns = fixedColumns.filter(col => col.filter);
    const filteredFloatingColumns = floatingColumns.filter(col => col.filter);
    const nbActiveFilters =
      filteredFixedColumns.length + filteredFloatingColumns.length;
    const invalidFilters =
      applyFilters && dataToDisplay.length === 0 && nbActiveFilters > 0;

    // table context that will be passed down to the linked cell renderers
    const tableData = loading ? [] : dataToDisplay;
    const displayGroups = tableData.length > 0 && group && groupBy;

    // row count depends on if a group is uncollapsed
    // AND if there is pagination
    let rowCount;
    if (displayGroups && groupBy.selected !== undefined) {
      const groupData = dataToDisplay.find(
        data => deepGet(data, group.indexPath) === groupBy.selected
      );
      const groupRows = getByPath(groupData, group.rowsPath);
      if (groupRows) {
        rowCount = dataToDisplay.length + groupRows.length - 1;
      } else {
        rowCount = dataToDisplay.length;
      }
    } else {
      rowCount = tableData.length; // todo: check totalCount if pagination
    }
    // Get the number of rows from the workflow context (in case of the table displays the list of a workflow's traces)
    const currentNbRows = this.props.workflowContext?.totalRowCount;
    rowCount = currentNbRows || rowCount || NB_ROWS_IF_EMPTY; // if table is empty display a nb of empty lines instaed of void table

    const displayLoadingState = loading || exporting;

    // context shared between fixed and floating grids
    const sharedGridContext = {
      loading: displayLoadingState,
      data: tableData,
      isEditing,
      selectedData,
      allowRowsSelection: !!onClickUpdate || (edit && !!edit.onClickBatchEdit),
      dataSelectorPath,
      toggleRowSelected: this.toggleRowSelected,
      pagination,
      group: displayGroups
        ? {
            ...group,
            display: groupBy,
            toggle: this.toggleGroup
          }
        : null,
      edit,
      displayDiffs,
      rowInlineStyle
    };

    return (
      <div className={classes.tableWrapper}>
        <AutoSizer>
          {({ width, height }) => {
            // Check width split between fixed and floating grids
            let fixedGridWidth =
              selectBoxWidth +
              fixedColumns.reduce((sum, col) => sum + col.width, 0);
            let floatingGridWidth = width - fixedGridWidth;

            // if there is not enough room on the right to let the floating grid scroll horizontally
            // merge fixed and floating grid, leaving only select boxes as fixed

            // merge if less than FLOATING_GRID_MIN_RATIO of the total grid size is available for the floating grid
            const mergeGrids =
              floatingColumns.length > 0 &&
              floatingGridWidth < width * FLOATING_GRID_MIN_RATIO;
            if (mergeGrids) {
              fixedGridWidth = selectBoxWidth;
              floatingGridWidth = width - fixedGridWidth;
            }

            // shrink table headers right icons if the bale is too small
            const shrinkRightIcons = width < SHRINK_HEADER_ICON_THRESHOLD;

            return (
              <div className={classes.tableContainer} style={{ width, height }}>
                {/* Table Headers */}
                {this.renderTableHeaders(
                  rowCount,
                  shrinkRightIcons,
                  currentNbRows
                )}
                {/* Columns Headers */}
                {this.renderTableColumnHeaders(
                  selectBoxWidth,
                  fixedColumns,
                  floatingColumns,
                  sortSetup,
                  applyFilters,
                  invalidFilters,
                  isEditing,
                  mergeGrids
                )}
                {/* Grids */}
                <div className={classes.tableGridsWrapper}>
                  {isEditing && (
                    <ResizableRow
                      height={rowsHeight}
                      minHeight={minRowsHeight}
                      setRowsHeight={this.setRowsHeight}
                    />
                  )}
                  <AutoSizer disableWidth>
                    {({ height: gridsHeight }) =>
                      this.renderTableGrids(
                        gridsHeight,
                        fixedGridWidth,
                        fixedColumns,
                        floatingGridWidth,
                        floatingColumns,
                        sharedGridContext,
                        mergeGrids,
                        rowCount,
                        overscanRowCount,
                        displayLoadingState,
                        showAggregation
                      )
                    }
                  </AutoSizer>
                  {displayLoadingState && (
                    <div className={classes.loadingIconContainer}>
                      <TraceIconSpinner className={classes.loadingIcon} small />
                    </div>
                  )}
                  {invalidFilters && (
                    <div className={classes.invalidFiltersMessageContainer}>
                      <div className={classes.invalidFiltersMessage}>
                        {nbActiveFilters === 1 ? (
                          <>
                            No data to display,
                            <span className={classes.invalidActiveFilters}>
                              1 filter
                            </span>
                            is active
                          </>
                        ) : (
                          <>
                            No data to display,
                            <span className={classes.invalidActiveFilters}>
                              {`${nbActiveFilters} filters`}
                            </span>
                            are active
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {/* Aggregation Row */}
                {showAggregation &&
                  this.renderTableAggregationRow(
                    selectBoxWidth,
                    fixedColumns,
                    floatingColumns,
                    mergeGrids,
                    tableData
                  )}
              </div>
            );
          }}
        </AutoSizer>
        {exportConfirmation && (
          <ConfirmationModal
            {...exportConfirmation}
            confirm={this.exportData}
            cancel={this.cancelExport}
          />
        )}
      </div>
    );
  };
}

export default compose(
  injectSheet(styles),
  withWorkflowContext,
  withWorkflowOverviewContext
)(Table);
