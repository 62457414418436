import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';

import {
  getUserDisplayConfig,
  saveUserDisplayConfig,
  savedConfigsKey,
  DisplayConfigURLParam
} from './utils';

const WorkflowOverviewContext = React.createContext();
export const withWorkflowOverviewContext = Component => props => (
  <WorkflowOverviewContext.Consumer>
    {ctx => <Component {...props} context={ctx} />}
  </WorkflowOverviewContext.Consumer>
);

const WorkflowOverviewContextProvider = React.memo(({ children, match }) => {
  const workflowId = match.params.id;

  // 'displayConfig' url query parameter as state variable
  const [queryStringDisplayConfig, setQueryStringDisplayConfig] = useQueryParam(
    'displayConfig',
    DisplayConfigURLParam
  );

  // Ref boolean value to make sure the following code is only executed once, before the component will render its children
  const willMount = useRef(true);
  // If it's the first execution...
  if (willMount.current) {
    // ...and if a config is available in the URL,
    // we save it in the local storage to ensure that the overview table (that will render inside this context) uses it when fetching the intial data.
    if (queryStringDisplayConfig)
      saveUserDisplayConfig(workflowId, queryStringDisplayConfig);
    // Update ref to prevent the above block to be executed on the next render
    willMount.current = false;
  }

  const [selectedTraceIds, setSelectedTraceIds] = useState([]);
  const [showNewTracesTray, setNewTracesTray] = useState(false);
  const [showTracesUpdateTray, setUpdateTracesTray] = useState(false);
  // Initialize userDisplayConfig with value from URL or get it from localStorage
  const [userDisplayConfig, setUserDisplayConfig] = useState(
    queryStringDisplayConfig || getUserDisplayConfig(workflowId)
  );
  const [savedViewsArray, setsavedViewsArray] = useState(
    JSON.parse(window.localStorage.getItem(savedConfigsKey(workflowId))) || []
  );

  // When userDisplayConfig value changes, repercute the changes to the URL parameter
  useEffect(() => {
    setQueryStringDisplayConfig(userDisplayConfig, 'replaceIn');
  }, [userDisplayConfig]);

  const toggleNewTracesTray = useCallback(
    () => setNewTracesTray(!showNewTracesTray),
    [showNewTracesTray, setNewTracesTray]
  );
  const toggleUpdateTracesTray = useCallback(
    () => setUpdateTracesTray(!showTracesUpdateTray),
    [showTracesUpdateTray, setUpdateTracesTray]
  );

  const savedViewStorageEventHandler = e => {
    setsavedViewsArray(e.detail);
  };

  useEffect(() => {
    window.addEventListener('savedViewsStorage', savedViewStorageEventHandler);
    return () => {
      window.removeEventListener(
        'savedViewsStorage',
        savedViewStorageEventHandler
      );
    };
  }, [savedViewsArray]);

  const updateSavedViewsArray = newValue => {
    window.localStorage.setItem(
      savedConfigsKey(workflowId),
      JSON.stringify(newValue)
    );
    const event = new CustomEvent('savedViewsStorage', { detail: newValue });

    window.dispatchEvent(event);
  };

  const context = {
    selectedTraceIds,
    setSelectedTraceIds,
    showNewTracesTray,
    toggleNewTracesTray,
    showTracesUpdateTray,
    toggleUpdateTracesTray,
    userDisplayConfig,
    setUserDisplayConfig,
    savedViewsArray,
    setsavedViewsArray,
    updateSavedViewsArray,
    workflowId
  };

  return (
    <WorkflowOverviewContext.Provider value={context}>
      {children}
    </WorkflowOverviewContext.Provider>
  );
});

WorkflowOverviewContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  match: PropTypes.object.isRequired
};

export default withRouter(WorkflowOverviewContextProvider);
