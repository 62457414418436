import injectSheet from 'react-jss';
import { ToastT } from 'sonner/dist';
import styles from './toast.styles';
import { ToastIcon } from './toastIcon';

type Props = {
  classes: any;
  type: ToastT['type'];
  title: string;
  description?: string;
};

export const CustomToast = injectSheet(styles)(
  ({ classes, type = 'error', title, description }: Props) => {
    return (
      <div className={classes.container}>
        <div className={classes.icon}>
          <ToastIcon type={type} />
        </div>
        <div className={classes.content}>
          <div className={classes.title}>{title}</div>
          <div className={classes.description}>{description}</div>
        </div>
      </div>
    );
  }
);
