import {
  SORT_DESCENDING,
  DEFAULT_SORT_WORKFLOW_TRACE_STATE_PATH,
  DEFAULT_SORT_WORKFLOW_TRACE_STATE_COLUMN
} from 'utils/sorting';

export const savedConfigsKey = id =>
  `overviewTableSavedDisplayConfigs_Workflow_${id}`;

export const localStorageKey = id =>
  `overviewTableDisplayConfig_Workflow_${id.toString()}`;

// Custom parameter type to be used with use-query-params
export const DisplayConfigURLParam = {
  encode(object) {
    if (object) {
      try {
        const encodedVaue = Buffer.from(
          JSON.stringify(object),
          'utf-8'
        ).toString('base64');
        return encodedVaue;
      } catch {
        console.error('value is not a valid JSON and cant be encoded');
        return undefined;
      }
    }
    return undefined;
  },
  decode(strValue) {
    if (strValue) {
      try {
        const decodedValue = JSON.parse(
          Buffer.from(strValue, 'base64').toString('utf-8')
        );
        return decodedValue;
      } catch {
        console.error(`url value can't be decoded: ${strValue}`);
        return undefined;
      }
    }
    return undefined;
  }
};

export const getUserDisplayConfig = id => {
  try {
    const localStorageDisplayConfigStr = localStorage.getItem(
      localStorageKey(id)
    );
    return localStorageDisplayConfigStr
      ? JSON.parse(localStorageDisplayConfigStr)
      : null;
  } catch (err) {
    return null;
  }
};

export const saveUserDisplayConfig = (id, cfg) => {
  const lsKey = localStorageKey(id);
  if (!cfg) {
    localStorage.removeItem(lsKey);
    return;
  }
  localStorage.setItem(lsKey, JSON.stringify(cfg));
};

export const getSortFromUserDisplayConfig = userDisplayConfig => {
  if (!(userDisplayConfig && userDisplayConfig.sortSetup))
    return [
      {
        path: DEFAULT_SORT_WORKFLOW_TRACE_STATE_PATH,
        column: DEFAULT_SORT_WORKFLOW_TRACE_STATE_COLUMN.toUpperCase(),
        direction: SORT_DESCENDING
      }
    ];
  const pathParts = userDisplayConfig.sortSetup.config.path.split('.');
  const column = pathParts[0];

  if (!['data', 'meta'].includes(column)) {
    console.error(
      `invalid filter path ${userDisplayConfig.sortSetup.config.path.path}`
    );
    return undefined;
  }
  const path = pathParts.slice(1).join('.');
  return userDisplayConfig.sortSetup.config
    ? [
        {
          path,
          column: column.toUpperCase(),
          direction: userDisplayConfig.sortSetup.direction
        }
      ]
    : undefined;
};

export const getGroupFromUserDisplayConfig = userDisplayConfig => {
  if (!(userDisplayConfig && userDisplayConfig.groupBy)) return undefined;
  return userDisplayConfig.groupBy.path;
};

// convert date format from mometjs to postgre, for most frequent month and day format
// moment: https://momentjs.com/docs/#/displaying/
// postgre: https://www.postgresql.org/docs/9.1/functions-formatting.html
export const convertDateFormatToSQL = dateFormat =>
  dateFormat
    ? dateFormat
        .replace('MMMM', 'Month')
        .replace('MMM', 'Mon')
        .replace('dddd', 'Day')
        .replace('ddd', 'Dy')
    : undefined;

const getTextInputFilter = (filterPath, filterValue, filterConfig) => {
  // filter value needs to be a real string
  if (typeof filterValue !== 'string') return undefined;

  const filterValues = filterValue.split(',').map(value => value.trim());

  const filter = {
    path: filterPath,
    value: filterValues.length === 1 ? filterValues[0] : filterValues
  };
  const filterInterpreter = filterConfig.interpreter || {};
  switch (filterInterpreter.type) {
    case 'number':
      filter.type = 'number';
      break;
    case 'date':
      filter.type = 'date';
      filter.format = convertDateFormatToSQL(filterInterpreter.format);
      filter.inputFormat = convertDateFormatToSQL(
        filterInterpreter.inputFormat
      );
      break;
    default:
      filter.type = 'text';
  }

  return filter;
};

const getDateRangeInputFilter = (filterPath, filterValue, filterConfig) => {
  // filter value needs to be a real js object
  if (typeof filterValue !== 'object' || Array.isArray(filterValue)) {
    return undefined;
  }

  const { startDate, endDate } = filterValue;

  let value;
  switch (true) {
    case !!startDate && !!endDate:
      value = `${startDate} =<<= ${endDate}`;
      break;
    case !!startDate:
      value = `>= ${startDate}`;
      break;
    case !!endDate:
      value = `<= ${endDate}`;
      break;
    default:
      value = filterConfig.placeholder || 'Select a date range';
  }

  return {
    type: 'date',
    format: convertDateFormatToSQL(filterConfig.format),
    inputFormat: convertDateFormatToSQL(filterConfig.inputFormat),
    path: filterPath,
    value
  };
};

export const getFiltersFromUserDisplayConfig = userDisplayConfig => {
  if (
    !(
      userDisplayConfig &&
      userDisplayConfig.applyFilters &&
      userDisplayConfig.filters
    )
  )
    return undefined;
  const filteredColumns = Object.keys(userDisplayConfig.filters).reduce(
    (filters, colKey) => {
      const filterSetup = userDisplayConfig.filters[colKey];
      if (filterSetup.config) {
        const { config: filterConfig, value: filterValue } = filterSetup;

        const pathParts = filterConfig.path.split('.');
        const col = pathParts[0];
        if (!['data', 'meta'].includes(col)) {
          console.error(`invalid filter path ${filterConfig.path}`);
          return filters;
        }
        const filterPath = pathParts.slice(1).join('.');

        let filter;
        switch (filterConfig.type) {
          case 'dateRange':
            filter = getDateRangeInputFilter(
              filterPath,
              filterValue,
              filterConfig
            );
            break;
          default:
            // default to text input filter
            filter = getTextInputFilter(filterPath, filterValue, filterConfig);
        }

        if (filter) {
          const filterKey = col === 'data' ? 'dataByPath' : 'metaByPath';
          filters.push({ [filterKey]: filter });
        }
      }
      return filters;
    },
    []
  );

  return filteredColumns.length === 0 ? undefined : { and: filteredColumns };
};

export const findGroupTraceStatesByIndex = (groups, index) => {
  if (index !== undefined) {
    const group = groups.nodes.find(g => g.index === index);
    if (group) return group.traceStates;
  }
  if (groups.nodes[0]) {
    return groups.nodes[0].traceStates;
  }
  // If no groups (i.e. no traceStates found), return an empty traces connection
  return {
    __typename: 'TraceStatesConnection',
    totalCount: 0,
    pageInfo: {
      __typename: 'PageInfo',
      endCursor: null,
      hasNextPage: false
    },
    nodes: []
  };
};

// count traces taking into account potential grouping
export const countTraces = workflow => {
  const { groupedTraceStates: { nodes: groups = [] } = {} } = workflow || {};
  return groups.reduce((nb, group) => {
    const {
      traceStates: { totalCount }
    } = group;
    return nb + totalCount;
  }, 0);
};
