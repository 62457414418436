// utils used by the data importer

export const importTableCommonConfig = {
  columnsWidth: 'medium',
  minColumnsWidth: 'small',
  rowsHeight: 42,
  minRowsHeight: 30,
  allowColumnsSelection: false
};

// build a 'default' table config from the configuration of the import
export const buildTableConfigFromMapping = mapping => {
  const { columns: mappingColumns } = mapping;
  const columns = [];
  mappingColumns.forEach(({ from: fromPath, to: toPath, parser }) => {
    const { type: parserType } = parser || {};
    columns.push({
      key: toPath,
      header: fromPath,
      cell: {
        view: {
          type: parserType,
          path: toPath
        }
      }
    });
  });
  const tableConfig = {
    ...importTableCommonConfig,
    columns
  };
  return tableConfig;
};

// build a 'default' table config from the list of found columns (if no import config)
export const buildTableConfigFromColumnNames = colNames => {
  const columns = [];
  colNames.forEach(colName => {
    columns.push({
      key: colName,
      header: colName,
      cell: {
        view: {
          path: `"${colName}"` // note: this is so that jmespath can handle potential special characters in the column names
        }
      }
    });
  });
  const tableConfig = {
    ...importTableCommonConfig,
    columns
  };
  return tableConfig;
};
