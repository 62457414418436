import gql from 'graphql-tag';
import { traceClient } from 'gql';
import { notify } from 'components/toast';

import {
  getSortFromUserDisplayConfig,
  getFiltersFromUserDisplayConfig
} from '../utils';

const LIMIT = 5000;

export const exportQuery = gql`
  query exportWorkflowQuery(
    $workflowId: BigInt!
    $limit: Int!
    $cursor: Cursor
    $orderBy: [TraceStatesOrderByPath!]
    $filter: TraceStateFilter
  ) {
    workflow: workflowByRowId(rowId: $workflowId) {
      rowId
      groupedTraceStates {
        nodes {
          index
          traceStates(
            first: $limit
            after: $cursor
            filter: $filter
            orderBy: $orderBy
          ) {
            totalCount
            pageInfo {
              endCursor
              hasNextPage
            }
            nodes {
              rowId
              data
              meta
            }
          }
        }
      }
    }
  }
`;

/**
 * @description Export workflow overview data to csv
 * @param {string} workflowId workflow row id for the export query
 * @param {string} workflowName workflow name to display in success or error message
 * @param {object} userDisplayConfig table configuration (columns, sorting, filtering...)
 * @param {function} tracePayloadFn mapping function for one trace state row
 */
export const generatePayload = async (
  workflowId,
  workflowName,
  userDisplayConfig,
  tracePayloadFn
) => {
  const filter = getFiltersFromUserDisplayConfig(userDisplayConfig);
  const orderBy = getSortFromUserDisplayConfig(userDisplayConfig);

  try {
    const payload = [];
    let stop = false;
    let cursor;
    while (!stop) {
      // eslint-disable-next-line no-await-in-loop
      const { data } = await traceClient.query({
        query: exportQuery,
        variables: {
          workflowId,
          limit: LIMIT,
          cursor,
          filter,
          orderBy
        }
      });

      // Build the export payload by applying the row payload function to each received trace
      const traces = data.workflow.groupedTraceStates.nodes[0].traceStates;
      payload.push(...traces.nodes.map(tracePayloadFn));

      // Update cursor or exit
      if (traces.pageInfo.hasNextPage) {
        cursor = traces.pageInfo.endCursor;
      } else {
        stop = true;
      }
    }

    notify.success(`You successfully exported ${workflowName} workflow data.`);
    return payload;
  } catch (err) {
    notify.error(
      `Something went wrong while exporting ${workflowName} workflow data.`
    );
    console.error(err);
    return [];
  }
};
