import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';
import { graphql } from 'react-apollo';
import to from 'await-to-js';
import { Cross, Help } from '@stratumn/icons';
import { notify } from 'components/toast';
import Tooltip from 'components/ui/utils/tooltip';
import { withSpanAsync, SpanType } from '../../../../../tracing';
import styles from './subscriptionsMenu.style';
import mutations from './mutations';
import RadioButtonsGroup from './radioButtonsGroup';

const TOOLTIP_POSITION = {
  place: 'below',
  adjustPlace: true
};

const enhanceSubscriptions = (subscriptions, actions) =>
  subscriptions.map(subscription => {
    const foundAction = actions.find(
      action => action.key === subscription.actionkey
    );
    const enhancedSubscription = {
      ...subscription,
      actionTitle: foundAction?.title ?? subscription.actionkey,
      loading: false,
      showSuccess: false
    };
    return enhancedSubscription;
  });

const SubscriptionsMenu = ({
  userId,
  workflowId,
  subscriptions,
  actions,
  onCrossClick,
  updateNotificationSubscription,
  classes
}) => {
  const [currentSubscriptions, setSubscriptions] = useState(() =>
    enhanceSubscriptions(subscriptions, actions)
  );
  const [showHelperMenu, setShowHelperMenu] = useState(false);
  const helperButtonRef = useRef(null);

  const updateSubscription = (index, newSubscription) => {
    setSubscriptions(prevSubscriptions => {
      const updatedSubscriptions = [...prevSubscriptions];
      updatedSubscriptions[index] = {
        ...prevSubscriptions[index],
        ...newSubscription
      };
      return updatedSubscriptions;
    });
  };

  const toggleHelper = useCallback(() => {
    setShowHelperMenu(!showHelperMenu);
  }, [showHelperMenu]);

  const setSubscriptionShowSuccess = (actionKey, showSuccess) => {
    const index = currentSubscriptions.findIndex(
      s => s.actionkey === actionKey
    );
    setSubscriptions(prevSubscriptions => {
      const updatedSubscriptions = [...prevSubscriptions];
      updatedSubscriptions[index] = {
        ...prevSubscriptions[index],
        showSuccess
      };
      return updatedSubscriptions;
    });
  };

  const renderHelperMenu = () => (
    <Tooltip
      clientEl={helperButtonRef.current}
      portalEl={document.body}
      position={TOOLTIP_POSITION}
      onClickOutside={toggleHelper}
      arrowUp={<div className={classes.helperTooltipArrowUp} />}
    >
      <div className={classes.helperTooltipWrapper}>
        <ul>
          <li>
            <span className={classes.helperParameterName}>Off:</span> I will
            never receive any notifications for the given action.
          </li>
          <li>
            <span className={classes.helperParameterName}>Default:</span> I will
            receive notifications as they are predefined in the workflow.
          </li>
          <li>
            <span className={classes.helperParameterName}>Always:</span> I will
            receive all notifications for the given action, every time.
          </li>
        </ul>
      </div>
    </Tooltip>
  );

  const executeTransaction = async (subscription, actionkey) => {
    const [err] = await to(
      updateNotificationSubscription({
        variables: {
          userId,
          workflowId,
          actionkey,
          subscription
        }
      })
    );
    return err;
  };

  const handleTabClick = (clickedSubscription, actionkey) => {
    const newSubscription = clickedSubscription.toUpperCase();

    const indexToUpdate = currentSubscriptions.findIndex(
      s => s.actionkey === actionkey
    );

    const prevSubscription = currentSubscriptions[indexToUpdate].subscription;
    if (prevSubscription === newSubscription) {
      return null;
    }

    updateSubscription(indexToUpdate, {
      subscription: newSubscription,
      loading: true
    });

    return withSpanAsync(
      'updateNotificationSubscription',
      SpanType.processing,
      async () => {
        const promise = executeTransaction(newSubscription, actionkey);
        notify.promise(promise, {
          loading: 'Updating notification subscription...',
          success: `Notification subscription updated`,
          error: `Notification subscription update failed`
        });
        const err = await executeTransaction(newSubscription, actionkey);

        if (err) {
          updateSubscription(indexToUpdate, {
            subscription: prevSubscription,
            loading: false
          });
        } else {
          // Faking update in case showSuccess is already true
          updateSubscription(indexToUpdate, {
            subscription: newSubscription,
            loading: false,
            showSuccess: false
          });
          updateSubscription(indexToUpdate, {
            subscription: newSubscription,
            loading: false,
            showSuccess: true
          });
        }
      }
    );
  };

  return (
    <div className={classes.subscriptionsMenuContainer}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          Notification subscriptions
          <div className={classes.headerTitleUnderline} />
        </div>
        <div className={classes.headerButtonGrp}>
          <button
            ref={helperButtonRef}
            onClick={toggleHelper}
            className={classes.helpBtn}
          >
            <Help className={classes.helpBtnIcon} />
          </button>
          {showHelperMenu && renderHelperMenu()}
          <button onClick={onCrossClick} className={classes.crossBtn}>
            <Cross className={classes.crossBtnIcon} />
          </button>
        </div>
      </div>
      <div className={classes.main}>
        {currentSubscriptions.map(s => (
          <div key={s.actionkey} className={classes.subscription}>
            <div className={classes.actionTitle}>{s.actionTitle}</div>
            <RadioButtonsGroup
              tabs={['Off', 'Custom', 'Always']}
              value={s.subscription}
              loading={s.loading}
              showSuccess={s.showSuccess}
              onChange={subscription =>
                handleTabClick(subscription, s.actionkey)
              }
              onSuccessEnd={() =>
                setSubscriptionShowSuccess(s.actionkey, false)
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

SubscriptionsMenu.propTypes = {
  userId: PropTypes.string.isRequired,
  workflowId: PropTypes.string.isRequired,
  subscriptions: PropTypes.array.isRequired,
  actions: PropTypes.array.isRequired,
  onCrossClick: PropTypes.func.isRequired,
  updateNotificationSubscription: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default compose(
  graphql(mutations.updateNotificationSubscription, {
    name: 'updateNotificationSubscription'
  }),
  injectSheet(styles)
)(SubscriptionsMenu);
