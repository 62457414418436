export default theme => ({
  message: {
    paddingBottom: 15,
    borderBottom: `1px solid ${theme.grey6}`,
    textAlign: 'justify',
    fontSize: 12,
    lineHeight: '15px'
  },
  groupName: {
    color: theme.indigo2,
    fontWeight: 700
  },
  teamsWrapper: {
    overflow: 'auto',
    minHeight: 54,
    maxHeight: 320
  },
  teams: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  loadingIcon: {
    width: 34,
    height: 34,
    marginTop: 20,
    alignSelf: 'center'
  },
  noTeamsMessage: {
    paddingTop: 10,
    fontSize: 16
  }
});
