import axios from 'axios';
import { ROOT_API_URL } from 'constant/api';
import { getAuthToken } from 'utils/localStorage';
import { withSpanAsync, SpanType } from '../tracing';

const getAuthHeader = () => ({
  Authorization: `Bearer ${getAuthToken()}`
});

export const getToken = async authCode =>
  withSpanAsync('getToken', SpanType.outgoingRequest, async () => {
    const response = await axios.request({
      url: `${ROOT_API_URL}/oauth`,
      method: 'POST',
      data: JSON.stringify({ authCode }),
      headers: { 'Content-Type': 'application/json' }
    });

    return response.data.token;
  });

export const downloadAuditTrail = async traceId =>
  withSpanAsync('downloadAuditTrail', SpanType.outgoingRequest, async () => {
    const rsp = await axios.request({
      url: `${ROOT_API_URL}/audittrail/download`,
      params: { trace_id: traceId },
      headers: getAuthHeader(),
      responseType: 'blob'
    });
    return rsp.data;
  });
