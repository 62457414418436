import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Path from 'path-to-regexp';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';
import gql from 'graphql-tag';
import to from 'await-to-js';

import { LayoutKanban, Pushbutton } from '@stratumn/atomic';

import { Navbar } from 'components/layouts';

import {
  ROUTE_USER_DASHBOARD,
  ROUTE_GROUP_SETTINGS,
  ROUTE_WORKFLOW_OVERVIEW
} from 'constant/routes';
import { TOOLTIP_PORTAL } from 'constant/htmlIds';
import { withError } from 'components/errorBoundary';

import { useInput, useToggle } from 'utils/hooks';

import { notify } from 'components/toast';
import fragments from './fragments';
import styles from './workflowGroups.style';

import SubHeader from './subHeader';
import GroupsList from './groupsList';
import AddGroupModal from './addGroupModal';

// some constants specific to workflow groups
export const GROUPS_ORDER_BY = 'NAME_ASC';
export const GROUPS_PAGINATION_SIZE = 10;
export const GROUPS_SEARCH_DEBOUNCE = 250; // delay before running a new search query when text is typed

// build an or filter setup from a search string with comma-separated searches
const getFilterSetupFromSearchStr = searchStr =>
  !searchStr
    ? undefined
    : {
        or: searchStr.split(',').map(searchPart => ({
          name: { likeInsensitive: `%${searchPart.trim()}%` }
        }))
      };

// function to ask for more pages of groups data
// statically defined outside the component for clarity
const handleFetchMore = (
  fetchMorePromiseRef,
  fetchMoreFn,
  workflowRowId,
  pageInfo,
  searchGroups
) => {
  // If a fetch more query is in progress, do not try to load
  if (fetchMorePromiseRef.current) return;

  const { endCursor } = pageInfo;

  fetchMorePromiseRef.current = fetchMoreFn({
    query: WORKFLOW_GROUPS_QUERY,
    variables: {
      workflowRowId,
      orderBy: GROUPS_ORDER_BY,
      first: GROUPS_PAGINATION_SIZE,
      cursor: endCursor,
      filter: getFilterSetupFromSearchStr(searchGroups)
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      const previousGroups = previousResult.workflowByRowId.groups;
      const newGroups = fetchMoreResult.workflowByRowId.groups;

      const {
        pageInfo: previousPageInfo,
        nodes: previousGroupsNodes
      } = previousGroups;
      const { pageInfo: newPageInfo, nodes: newGroupsNodes } = newGroups;

      return {
        workflowByRowId: {
          ...previousResult.workflowByRowId,
          groups: {
            ...previousGroups,
            pageInfo: {
              ...previousPageInfo,
              ...newPageInfo
            },
            nodes: [...previousGroupsNodes, ...newGroupsNodes]
          }
        }
      };
    }
  }).then(() => {
    fetchMorePromiseRef.current = null;
  });
};

export const WorkflowGroups = ({
  errorContext: { handleError },
  history,
  match,
  location,
  classes,
  workflowGroupsQuery,
  addGroupMutation
}) => {
  const {
    loading,
    error,
    fetchMore,
    refetch,
    workflowByRowId: workflow
  } = workflowGroupsQuery;

  // modal state
  const [showAddGroupModal, switchShowAddGroupModal] = useToggle(false);

  // set doc title at mount
  useEffect(() => {
    const { name } = workflow || {};
    if (name) document.title = `${name} - Groups - Trace`;
  }, [workflow]);

  // Raise 404 page when workflow not found.
  const workflowError = error || (!loading && !workflow);
  useEffect(() => {
    const { params } = match;

    // This check avoids rerendering the component after the erroBoundary has been triggered
    if (workflowError) {
      handleError('workflow', params.id, ROUTE_USER_DASHBOARD);
    }
  }, [workflowError, match, handleError]);

  // memoized header config
  const header = useMemo(() => {
    const headerConfig = { loading: loading || !workflow };
    headerConfig.bottomLevel = {
      workflowPage: true,
      infoContext: {}
    };
    headerConfig.bottomLevel.infoContext.links = [
      {
        icon: 'Group',
        label: 'Groups settings'
      }
    ];

    if (workflow) {
      headerConfig.bottomLevel.infoContext.links.unshift({
        icon: 'TableColumns',
        label: workflow?.name ?? 'Workflow',
        path: Path.compile(ROUTE_WORKFLOW_OVERVIEW)({
          id: workflow?.rowId
        })
      });
    }

    return <Navbar config={headerConfig} />;
  }, [workflow]);

  // refetch data when the search input is updated
  const refetchGroupsWithSearch = useCallback(
    searchValue => {
      const { rowId } = workflow || {};
      refetch({
        workflowRowId: rowId,
        orderBy: GROUPS_ORDER_BY,
        first: GROUPS_PAGINATION_SIZE,
        filter: getFilterSetupFromSearchStr(searchValue)
      });
    },
    [workflow]
  );

  // search groups state linked to input field and refetch debounced callback
  const [searchGroups, updateSearch] = useInput(
    '',
    refetchGroupsWithSearch,
    GROUPS_SEARCH_DEBOUNCE
  );

  // go back to source url
  const goBack = useCallback(() => {
    if (location.state && location.state.from) {
      history.push(location.state.from);
      return;
    }
    // by default go back to user dahsboard
    history.push(ROUTE_USER_DASHBOARD);
  }, [location, history]);

  // callback to get more groups from pagination
  const fetchMorePromise = useRef(null);
  const getMoreGroups = useCallback(() => {
    if (!workflow) return;

    const {
      rowId,
      groups: { pageInfo }
    } = workflow;

    handleFetchMore(fetchMorePromise, fetchMore, rowId, pageInfo, searchGroups);
  }, [workflow, fetchMore, searchGroups]);

  // add group mutation callback
  const addGroup = useCallback(
    async groupName => {
      // close the modal
      switchShowAddGroupModal();

      // generate a group key as 'group-idx'
      const { rowId, name, nonFilteredGroups: { totalCount: nbGroups } = {} } =
        workflow || {};

      const promise = addGroupMutation({
        variables: {
          workflowRowId: rowId,
          name: groupName,
          key: `group-${nbGroups + 1}`,
          orderBy: GROUPS_ORDER_BY,
          first: GROUPS_PAGINATION_SIZE,
          filter: getFilterSetupFromSearchStr(searchGroups)
        }
      });

      notify.promise(promise, {
        loading: `Adding group ${groupName}...`,
        success: `Group ${groupName} was correctly added to Workflow ${name}`,
        error: `Something went wrong when adding Group ${groupName} to Workflow ${name}`
      });

      const [err, response] = await to(promise);

      if (err) {
        return;
      }

      const {
        data: {
          createGroup: {
            group: { rowId: newGroupRowId }
          }
        }
      } = response;
      history.push(Path.compile(ROUTE_GROUP_SETTINGS)({ id: newGroupRowId }));
    },
    [switchShowAddGroupModal, workflow, addGroupMutation, searchGroups, history]
  );

  return (
    <>
      <div id={TOOLTIP_PORTAL} />
      <LayoutKanban>
        {header}
        <div className={classes.content}>
          <SubHeader
            workflow={workflow}
            searchGroups={searchGroups}
            updateSearch={updateSearch}
            openAddGroupModal={switchShowAddGroupModal}
            loading={loading}
          />
          <div className={classes.groupsList}>
            <GroupsList
              loading={loading}
              workflow={workflow}
              getMoreGroups={getMoreGroups}
              hasFilters={!!searchGroups}
            />
          </div>
          <div className={classes.footer}>
            <Pushbutton
              onClick={goBack}
              disabled={false}
              dataCy="go-back-button"
            >
              Go Back
            </Pushbutton>
          </div>
        </div>
      </LayoutKanban>
      {showAddGroupModal && (
        <AddGroupModal
          workflow={workflow}
          submit={addGroup}
          cancel={switchShowAddGroupModal}
        />
      )}
    </>
  );
};

WorkflowGroups.propTypes = {
  errorContext: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  workflowGroupsQuery: PropTypes.object.isRequired,
  addGroupMutation: PropTypes.func.isRequired
};

WorkflowGroups.defaultProps = {};

const WORKFLOW_GROUPS_QUERY = gql`
  query workflowGroupsQuery(
    $workflowRowId: BigInt!
    $orderBy: [GroupsOrderBy!]
    $first: Int
    $cursor: Cursor
    $filter: GroupFilter
  ) {
    workflowByRowId(rowId: $workflowRowId) {
      ...WorkflowGroupsFragment
    }
  }
  ${fragments.workflowGroups}
`;

const ADD_GROUP_MUTATION = gql`
  mutation addGroupMutation(
    $workflowRowId: BigInt!
    $name: String!
    $key: String!
    $orderBy: [GroupsOrderBy!]
    $first: Int
    $cursor: Cursor
    $filter: GroupFilter
  ) {
    createGroup(
      input: { group: { workflowId: $workflowRowId, label: $key, name: $name } }
    ) {
      group {
        rowId
      }
      workflow {
        ...WorkflowGroupsFragment
      }
    }
  }
  ${fragments.workflowGroups}
`;

export default compose(
  graphql(WORKFLOW_GROUPS_QUERY, {
    name: 'workflowGroupsQuery',
    options: ({ match }) => ({
      variables: {
        workflowRowId: match.params.id,
        orderBy: GROUPS_ORDER_BY,
        first: GROUPS_PAGINATION_SIZE,
        filter: undefined
      },
      fetchPolicy: 'cache-and-network'
    })
  }),
  graphql(ADD_GROUP_MUTATION, {
    name: 'addGroupMutation'
  }),
  withRouter,
  withError,
  injectSheet(styles),
  React.memo
)(WorkflowGroups);
