import gql from 'graphql-tag';

export default {
  // get all workflow groups info
  workflowGroups: gql`
    fragment WorkflowGroupsFragment on Workflow {
      rowId
      id
      name
      description
      canUpdate
      groups(
        orderBy: $orderBy
        first: $first
        after: $cursor
        filter: $filter
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        nodes {
          rowId
          id
          createdAt
          updatedAt
          label
          name
          avatar
          members {
            totalCount
            nodes {
              ...GroupMemberFragment
            }
          }
        }
      }
      nonFilteredGroups: groups {
        totalCount
      }
    }

    fragment GroupMemberFragment on GroupMember {
      accountId
      reader
      admin
      account {
        rowId
        id
        entity {
          ... on Account_User {
            rowId
          }
          ... on Account_Bot {
            rowId
          }
          ... on Account_Team {
            rowId
            accountId
            members {
              nodes {
                userId
              }
            }
            bots {
              nodes {
                rowId
              }
            }
          }
          ... on Account_Organization {
            rowId
            accountId
            members {
              nodes {
                userId
              }
            }
            bots {
              nodes {
                rowId
              }
            }
            teams {
              nodes {
                rowId
              }
            }
          }
        }
      }
    }
  `
};
