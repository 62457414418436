import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import moment from 'moment';
import Path from 'path-to-regexp';
import { ROUTE_INSPECT_TRACE_LINK } from 'constant/routes';
import { Link } from 'react-router-dom';
import { RichEditor } from '@stratumn/atomic';
import { Paperclip } from '@stratumn/icons';
import { TraceInspectorContext } from 'components/traceInspector/context';
import { getByPath } from 'utils/widgets';
import { isJSON } from 'utils';

import styles from './answer.style';

const convertDate = date => {
  const newdate = moment(date).format('DD MMMM YYYY LT');
  return newdate;
};

const checkAnswerExists = answer => {
  const isHtml = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
  if (isHtml(answer)) {
    return true;
  }

  const answerIsNotEmpty =
    isJSON(answer) &&
    JSON.parse(answer)
      .map(c => c.children.filter(child => child.text !== ''))
      .map(c => c.length > 0);
  if (isJSON(answer) && answerIsNotEmpty.every(v => v === true)) {
    return true;
  }
  if (answer?.length > 0 && !isJSON(answer) && !isHtml(answer)) {
    return true;
  }
  return false;
};
const DEFAULT_ANSWER_PATHS = {
  NAME: 'user.name',
  AVATAR: 'user.avatar',
  GROUP: 'group',
  DATE: 'date',
  ACTION: 'action',
  ANSWER: 'answer',
  HASH: 'linkHash',
  HEIGHT: 'height',
  FILES: 'files'
};

export const AnswerView = React.memo(({ classes, view, data }) => {
  const traceInspectorContext = useContext(TraceInspectorContext);

  const { traceId, currentHash } =
    traceInspectorContext !== null && traceInspectorContext;
  const {
    namePath = DEFAULT_ANSWER_PATHS.NAME,
    avatarPath = DEFAULT_ANSWER_PATHS.AVATAR,
    groupPath = DEFAULT_ANSWER_PATHS.GROUP,
    datePath = DEFAULT_ANSWER_PATHS.DATE,
    actionPath = DEFAULT_ANSWER_PATHS.ACTION,
    answerPath = DEFAULT_ANSWER_PATHS.ANSWER,
    linkHashPath = DEFAULT_ANSWER_PATHS.HASH,
    heightPath = DEFAULT_ANSWER_PATHS.HEIGHT,
    filesPath = DEFAULT_ANSWER_PATHS.FILES
  } = view;

  const name = getByPath(data, namePath);
  const avatar = getByPath(data, avatarPath);
  const group = getByPath(data, groupPath);
  const date = getByPath(data, datePath);
  const action = getByPath(data, actionPath);
  const hash = getByPath(data, linkHashPath);
  const height = getByPath(data, heightPath);
  const files = getByPath(data, filesPath);
  const answer = getByPath(data, answerPath);

  const isAnswerWithSubject =
    isJSON(answer) && JSON.parse(answer).some(c => c?.type === 'subject');

  return (
    data &&
    checkAnswerExists(data.answer) && (
      <div className={classes.answer}>
        <div
          className={classes.answerAvatar}
          style={{
            backgroundImage: avatar ? `url(${avatar})` : null
          }}
        />
        <div className={classes.answerContainer}>
          <ul>
            {data.user && (
              <li className={classes.userId}>
                <div className={classes.userName}>{name}</div>
                {group && <div className={classes.userGroup}>{group}</div>}
              </li>
            )}
            {date && traceId && (
              <li>
                <Link
                  to={Path.compile(ROUTE_INSPECT_TRACE_LINK)({
                    id: traceId,
                    linkid: hash
                  })}
                  className={
                    currentHash === hash ? classes.inactiveLink : classes.link
                  }
                >
                  <strong className={classes.action}>
                    {height} - {action}
                  </strong>{' '}
                  <span className={classes.date}>
                    | Answered on {convertDate(date)}
                  </span>
                </Link>
              </li>
            )}
            <li>
              <div className={classes.answerRoot}>
                <RichEditor
                  initialValue={answer}
                  withSubject={isAnswerWithSubject}
                  readOnly
                />
              </div>
            </li>
            {files && (
              <li className={classes.files}>
                <Paperclip className={classes.paperClip} />
                {`Attachment${files.length > 1 ? `s` : ``} (${files.length})`}
              </li>
            )}
          </ul>
        </div>
      </div>
    )
  );
});

// sort defaults to text on datePath (text is ok assuming iso dates)
const getSortConfig = view => ({
  type: 'text',
  path: view.datePath || DEFAULT_ANSWER_PATHS.DATE
});

// filtering defaults to text search on answer content
const getFilterConfig = view => ({
  type: 'text',
  path: view.answerPath || DEFAULT_ANSWER_PATHS.ANSWER
});

// width defaults to 'large'
const getDefaultWidth = () => 'large';

// stringifies data at answerPath
const getStringifyFunction = view => {
  const { answerPath = DEFAULT_ANSWER_PATHS.ANSWER } = view;
  return data => getByPath(data, answerPath) || '';
};

AnswerView.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default {
  component: injectSheet(styles)(AnswerView),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
