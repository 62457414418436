import get from 'lodash.get';

// export your utils here
export { formatDate, getNow, formatDateWithoutSeconds } from './dates';
export { idWithType, dataIdFromObject } from './cache';
export { default as randomId } from './randomId';
export { default as graphqlWithFilters } from './graphqlWithFilters';
export * from './optimisticResponseHelper';
export * from './localStorage';
export * from './sorter';
export * from './widgets';
export * from './actions';
export * from './sorting';
export * from './numberFormat';
export { getFileExt, getFileIconStr } from './fileTypes';
export * from './strings';
export { isJSON, checkCommentExists } from './textCheck';

export const deepGet = get;

// copies the public key to the clipboard
// see: https://stackoverflow.com/a/30810322
export const addTextToClipBoard = text => {
  const textArea = document.createElement('textarea');
  textArea.style = {
    width: 0,
    height: 0,
    opacity: 0
  };
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

/**
 Convert a blob into a string.
 */
export const blobToString = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(blob);
  });

/**
 Save a blob locally.
 */
export const saveBlob = (data, filename) => {
  // save the archive locally
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  link.click();
};

/**
 Deep copy data.
 */
export const deepCopy = obj => JSON.parse(JSON.stringify(obj));

/**
 * Validate url
 */
const pattern = new RegExp(
  '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
  'i'
);
export const isValidURL = str => !!pattern.test(str);

/**
 * Get the size of the scrollbar in the current browser
 */
export const getScrollbarSize = () => {
  const scrollDiv = document.createElement('div');
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  scrollDiv.style.width = '50px';
  scrollDiv.style.height = '50px';
  scrollDiv.style.overflow = 'scroll';
  document.body.appendChild(scrollDiv);
  const size = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return size;
};

/**
 * Function to round a number with an specific number of digits
 * @param {number} number The number that needs to be rounded
 * @param {number} digits The (integer) number of digits expected after the decimal point
 * @returns {number} The rounded number
 */
export const roundNumber = (number, digits = 0) => {
  const digitsMultiplier = 10 ** Number(digits);
  const roundedNumber =
    Math.round(number * digitsMultiplier) / digitsMultiplier;
  return roundedNumber;
};

/**
 * Finds the number of files in the `state.data` object. \
 * A file is any object witch has the property `digest`.
 * */
export const findNumberOfFiles = (obj: any) => {
  try {
    const arraysWithDigest = Object.values(obj)
      .filter(Array.isArray)
      .find(array =>
        array.some(item => typeof item === 'object' && 'digest' in item)
      );
    return arraysWithDigest ? arraysWithDigest.length : 0;
  } catch (_) {
    return 0;
  }
};
