export default theme => ({
  root: {
    height: 135,
    color: theme.warningRed,
    borderRadius: 5,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.grey9,
    fontSize: 14,
    '& strong': {
      fontWeight: 600
    }
  }
});
