import { formatNumber as format } from './numberFormat';

/**
 * @function pluralize
 * @param {number} count - how many of the word
 * @param {string} word - word to pluralize
 * @param {boolean} firstLetterUppercase - capitalize the first letter of the word
 * @param {boolean} formatNumber - use browser local format to display numbers
 */
export const pluralize = (
  count,
  word,
  firstLetterUppercase = false,
  formatNumber = false
) => {
  const firstLetter = firstLetterUppercase ? word[0].toUpperCase() : word[0];
  return `${formatNumber ? format(count) : count} ${
    firstLetter + word.slice(1)
  }${count !== 1 ? 's' : ''}`;
};

/**
 * @function camelize
 * @param {string} word - string to camelize
 */
export const camelize = string => {
  const cleanStr = string
    .replace(/[^A-zÀ-ú0-9\s]+/g, '')
    .replace(/\s+$/g, '')
    .toLowerCase();
  return (cleanStr.slice(0, 1) + cleanStr.slice(1))
    .replace(/([-_ ]){1,}/g, ' ')
    .split(/[-_ ]/)
    .reduce((cur, acc) => cur + acc[0].toUpperCase() + acc.substring(1));
};

/**
 * @function nameToInitials - transforms a name into initials that can be displayed in an Avatar
 * @param {string} name
 */
export const nameToInitials = name => {
  const regex = /[ ,-]+/; // space, dash or comma
  const nameArr = name.split(regex);
  if (nameArr.length > 1) {
    return nameArr[0].charAt(0) + nameArr[1].charAt(0);
  }
  return name[0];
};
