import React from 'react';
import PropTypes from 'prop-types';

import { Pushbutton, RadioButton } from '@stratumn/atomic';
import { Download } from '@stratumn/icons';
import injectSheet from 'react-jss';

import styles from './typeFileViewsMenu.style';

const TypeFileViewsMenu = ({ classes, onClick, fileType, changeTypeFile }) => (
  <div className={classes.typeFileViewsMenuContainer}>
    <div className={classes.typeFileViewsMenuHeader}>Export data</div>
    <div className={classes.typeFileViewsMenuRadioButtons}>
      <RadioButton
        dataCy="csv-radio"
        showLabel
        label="CSV (.csv)"
        value="csv"
        checked={fileType === 'csv'}
        handleChange={() => changeTypeFile('csv')}
      />
      <RadioButton
        dataCy="excel-radio"
        showLabel
        label="Excel (.xls)"
        value="excel"
        checked={fileType === 'excel'}
        handleChange={() => changeTypeFile('excel')}
      />
    </div>
    <div className={classes.typeFileViewsButton}>
      <Pushbutton dataCy="export-button" secondary onClick={onClick}>
        <Download className={classes.typeFileViewsButtonIcon} />
        Export table
      </Pushbutton>
    </div>
  </div>
);

TypeFileViewsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  changeTypeFile: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired
};

export default injectSheet(styles)(TypeFileViewsMenu);
