import React, { Component } from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Pushtext, Avatar, Icon } from '@stratumn/atomic';
import { addTextToClipBoard } from 'utils';

import styles from './segmentUserDetail.style';

export class SegmentUserDetail extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    link: PropTypes.object.isRequired,
    linkCreatedByAccount: PropTypes.object.isRequired
  };

  state = {
    publicKeyCopied: false
  };

  componentWillUnmount() {
    clearTimeout(this.publicKeyTimer);
  }

  copyPublicKey = () =>
    addTextToClipBoard(this.props.linkCreatedByAccount.signingKey.publicKey);

  handlePublicKeyClick = () => {
    this.copyPublicKey();
    this.setState({ publicKeyCopied: true });
    clearTimeout(this.publicKeyTimer);
    this.publicKeyTimer = setTimeout(() => {
      this.setState({ publicKeyCopied: false });
    }, 3000);
  };

  renderPublicKey = () => {
    const { classes } = this.props;
    let icon = 'Key';
    let text = 'Copy Public Key';
    let size = 26;
    if (this.state.publicKeyCopied) {
      icon = 'Clipboard';
      text = 'Public key copied';
      size = 20;
    }
    return (
      <Pushtext
        prefix={<Icon name={icon} size={size} />}
        onClick={this.handlePublicKeyClick}
        className={classes.keyContainer}
      >
        <p className={classes.keyText}>{text}</p>
      </Pushtext>
    );
  };

  parsePhoneNumber = phoneNumber => {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    if (parsedPhoneNumber) return parsedPhoneNumber.formatInternational();
    return phoneNumber;
  };

  render = () => {
    const { link, linkCreatedByAccount, classes } = this.props;

    // Links are created by an account.
    // However, its entity can be null if the current user is a collaborator
    // and the entity is not visible to the group.
    // The display falls back to the link group in that case.
    if (!linkCreatedByAccount?.entity) {
      return (
        <div className={classes.root}>
          <div className={classes.avatar}>
            <Avatar size={44} src={link.group.avatar} />
          </div>
          <div>
            <div className={classes.nameContainer}>
              <div className={classes.name}>{link.group.name}</div>
            </div>
          </div>
        </div>
      );
    }
    const createdBy = linkCreatedByAccount.entity;

    return (
      <div className={classes.root}>
        <div className={classes.avatar}>
          <Avatar size={44} src={createdBy.avatar} />
        </div>
        <div>
          <div className={classes.nameContainer}>
            <div className={classes.name}>{createdBy.name}</div>
            {this.renderPublicKey()}
          </div>
          <div className={classes.details}>
            {createdBy.email && (
              <div className={classes.email}>
                <a href={`mailto:${createdBy.email}`}>{createdBy.email}</a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
}

export default injectSheet(styles)(SegmentUserDetail);
