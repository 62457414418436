import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { getByPath, formatNumber } from 'utils';

import styles from './number.style';

// simple number to display
const NumberViewImpl = React.memo(({ classes, view, data }) => {
  const { path, format, denominatorPath, tabRight } = view;
  // get the number to display and format it
  const numberValue = getByPath(data, path);

  if (numberValue === undefined) return null; // ensure we return empty view and not 0 if data is not here

  let displayedNumber = formatNumber(numberValue, format);

  // check if we have to display a denominator
  if (denominatorPath !== undefined) {
    const denominatorValue = getByPath(data, denominatorPath);
    const displayedDenominator = formatNumber(denominatorValue, format);
    displayedNumber += ` / ${displayedDenominator}`;
  }

  return (
    <div className={classes.numberView} data-tabbed-right={tabRight}>
      {displayedNumber}
    </div>
  );
});
NumberViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to number on path
const getSortConfig = view => ({
  type: 'number',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = view => ({
  type: 'text',
  path: view.path,
  placeholder: 'Eg >20, 10<<15',
  interpreter: {
    type: 'number'
  }
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// stringifies data at path
const getStringifyFunction = view => {
  const { path } = view;

  return data => {
    // get the number to display and format it
    const numberValue = getByPath(data, path);
    if (numberValue === undefined) return '';

    return Number(numberValue);
  };
};

// apparently, numbers should aggregate in sum by default
// whatever their meaning and level of homogeneity ¯\_(ツ)_/¯
// we return exactly the same number view config
// just changing the path to return the sum across all data rows
const getAggregationConfig = view => {
  const { path, aggregationKey, aggregationValue, aggregationFunction } = view;

  let aggregationView;
  if (aggregationKey && aggregationValue) {
    aggregationView = {
      view: {
        ...view,
        aggregationKey,
        aggregationValue: aggregationValue,
        path: `data[?${aggregationKey}=='${aggregationValue}'].${path} | ${
          aggregationFunction || 'sum'
        }(@)`
      }
    };
  } else {
    aggregationView = {
      view: {
        ...view,
        path: `data[].${path} | sum(@)`
      }
    };
  }
  return aggregationView;
};

export default {
  component: injectSheet(styles)(NumberViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction,
  getAggregationConfig
};
