export default theme => ({
  answer: {
    display: 'flex',
    paddingBottom: 15
  },
  answerAvatar: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.grey7,
    minWidth: 30,
    height: 30,
    borderRadius: '50%',
    position: 'relative',
    top: 5
  },
  answerContainer: {
    width: '100%',
    paddingBottom: 17,
    margin: '0 13px',
    borderBottom: `1px solid #F8F8F8`,
    minHeight: 70
  },
  userId: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center'
  },
  userName: {
    color: theme.grey1,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '23px',
    marginRight: 10
  },
  userGroup: {
    color: theme.grey2,
    fontSize: 12,
    paddingLeft: 10,
    borderLeft: `1px solid ${theme.grey4}`,
    lineHeight: '15px'
  },
  link: {
    padding: '2px 0',
    color: theme.grey3,
    fontSize: 10,
    fontWeight: 'normal',
    lineHeight: '24px',
    textDecoration: 'none'
  },
  inactiveLink: {
    padding: '2px 0',
    fontSize: 10,
    fontWeight: 'normal',
    lineHeight: '24px',
    textDecoration: 'none',
    pointerEvents: 'none'
  },
  action: {
    textDecoration: 'underline'
  },
  date: {
    padding: '2px 0',
    color: theme.grey3,
    fontSize: 10,
    fontWeight: 'normal',
    lineHeight: '24px'
  },
  answerRoot: {
    fontSize: 14,
    lineHeight: '13px',
    fontWeight: 500,
    color: theme.grey1,
    '& h1': {
      fontSize: 18,
      fontWeight: 700,
      color: theme.grey1
    },
    '& strong': {
      fontWeight: 700,
      color: theme.grey1
    },
    '& li': {
      position: 'relative',
      paddingLeft: 22,
      fontSize: 14,
      '&::before': {
        position: 'absolute',
        content: '"•"',
        color: theme.indigo3,
        fontWeight: 'bold',
        width: '1em',
        marginLeft: '-1em'
      }
    }
  },
  files: {
    color: theme.grey3,
    fontSize: 12,
    position: 'relative',
    paddingLeft: 16
  },
  paperClip: {
    height: 16,
    transform: 'rotate(30deg)',
    position: 'absolute',
    left: -10,
    top: 2
  }
});
