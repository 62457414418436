import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import { TOOLTIP_PORTAL } from 'constant/htmlIds';

import { useMeasure } from 'utils/hooks';

import Tooltip from 'components/ui/utils/tooltip';

import styles from './ellipsis.style';

import truncateContent from './truncateContent';

const INFO_TOOLTIP_POSITION = {
  place: 'below',
  adjustPlace: true,
  anchor: 'left',
  adjustAnchor: true
};

// ellipsis component, displays as many children components as possible in the container
// plus a message to indicate how many were hidden, that can be hovered to provide a tooltip with the rest
// the info message will be appended to the children so it will display in the natural layout of the container (eg flex row, column etc...)
// ( obviously the presence of the info message might require to hide additional children )
export const Ellipsis = React.memo(
  ({
    classes,
    className,
    infoMessage,
    infoClassName,
    tooltipClassName,
    tooltipPosition,
    delay,
    children
  }) => {
    if (!children || !children.length) return null;

    const infoRef = useRef();

    const containerRef = useRef();
    const [measureRef, bounds] = useMeasure(containerRef, delay);

    const [hiddenItems, setHiddenItems] = useState([]);

    const [showTooltip, setShowTooltip] = useState(false);
    const unhideTooltip = useCallback(() => setShowTooltip(true), []);
    const hideTooltip = useCallback(() => setShowTooltip(false), []);

    // in this effect we observe the current layout of items inside the container
    // any time the container is resized (or the children change)
    // the goal is to try to make the ellipsis info visible by iterating backwards on the children
    // and setting display: 'none' to children that make this info unvisible
    // the actual effect is debounced to avoid too many interactions with the DOM eg when the window is resized
    useEffect(() => {
      // truncate the content elements and get the nub of hidden items
      const nbHiddenItems = truncateContent(containerRef.current);

      // set hidden children components to be displayed in the tooltip
      const nbChildren = children.length;
      setHiddenItems(children.slice(nbChildren - nbHiddenItems, nbChildren));
    }, [containerRef, bounds, children]);

    return (
      <div
        className={classnames(classes.ellipsisContainer, className)}
        ref={measureRef}
      >
        {children}
        <div
          ref={infoRef}
          className={classnames(classes.info, infoClassName)}
          onMouseEnter={unhideTooltip}
          onMouseLeave={hideTooltip}
          data-cy="ellipsis-info"
        >
          {infoMessage(hiddenItems.length)}
          {showTooltip && (
            <Tooltip
              clientEl={infoRef.current}
              portalEl={document.getElementById(TOOLTIP_PORTAL)}
              position={tooltipPosition || INFO_TOOLTIP_POSITION}
              arrowUp={<div className={classes.infoTooltipArrow} />}
            >
              <div
                className={classnames(classes.infoTooltip, tooltipClassName)}
              >
                {hiddenItems}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    );
  }
);

Ellipsis.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  infoMessage: PropTypes.func,
  infoClassName: PropTypes.string,
  tooltipClassName: PropTypes.string,
  tooltipPosition: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.node),
  delay: PropTypes.number
};

Ellipsis.defaultProps = {
  className: '',
  infoMessage: nbMissingItems => `+${nbMissingItems} other items`,
  infoClassName: '',
  tooltipClassName: '',
  tooltipPosition: null,
  children: null,
  delay: 50 // ms
};

export default injectSheet(styles)(Ellipsis);
