export const ITEM_BORDER_RADIUS = 15;
export default theme => ({
  dashboardContainer: {
    position: 'fixed',
    top: 70,
    overflowX: 'auto',
    overflowY: 'scroll',
    backgroundColor: theme.grey8,
    height: 'calc(100% - 70px)',
    width: '100%'
  },
  dashboardContent: {
    padding: 15,
    display: 'flex',
    flexFlow: 'column nowrap',
    position: 'relative'
  },
  dashboardItem: {
    margin: 10,
    borderRadius: ITEM_BORDER_RADIUS,
    border: `1px solid ${theme.grey7}`
  }
});
