export default theme => ({
  singleReference: {
    display: 'flex'
  },
  references: {
    backgroundColor: theme.white1,
    borderColor: theme.grey6,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    marginBottom: 18
  },
  referencesTitle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.grey2,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    minHeight: 34,
    '&::before': {
      content: 'attr(data-prefix)',
      fontWeight: 700,
      display: 'inline-block',
      marginRight: 8
    },
    '&[aria-expanded]': {
      cursor: 'pointer'
    }
  },
  toggleLabel: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: 11,
    fontWeight: 400,
    color: theme.grey3
  },
  toggleLabelIcon: {
    display: 'block',
    width: 18,
    height: 18,
    marginLeft: 5,
    fill: theme.indigo3,
    flexShrink: 0
  },
  referenceItem: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    paddingTop: 8,
    paddingBottom: 8,
    minHeight: 34,
    borderTop: `1px solid ${theme.grey6}`
  },
  referenceList: {
    '&[aria-hidden="true"]': {
      display: 'none'
    }
  }
});
