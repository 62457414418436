import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { Prompt, useHistory } from 'react-router-dom';
import { ConfirmationModal } from '@stratumn/atomic';
import { useFunctionAsState } from 'utils/hooks';

// Context creation with two default empty functions
// This will prevent errors if a component tries to use this context when it is not wrapped inside a Provider
const BeforeLeavingAlertContext = createContext({
  setAskBeforeLeaving: () => {},
  setLeavingAlertModalConfig: () => {},
  setLeavingCallback: () => {}
});
BeforeLeavingAlertContext.displayName = 'BeforeLeavingAlertContext';

// Context provider component with internal logic to display the alert
export const BeforeLeavingAlertProvider = ({ children }) => {
  // Use history
  const history = useHistory();

  // State variables that will be exposed to children through the context consumer
  // - Boolean defining if the user should be asked for confirmation when changing route
  const [askBeforeLeaving, setAskBeforeLeaving] = useState(false);
  // - Object containing props to define the ConfirmationModal component (title, content & confirmBtnText)
  const [leavingAlertModalConfig, setLeavingAlertModalConfig] = useState();
  // - Localstorage backup key to clear backup when the user confirms leaving
  const [leavingCallback, setLeavingCallback] = useFunctionAsState();

  // Internal state variables
  // Boolean that will be set to true once the user confirms
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  // Object to store details about the expected location (location object + history action)
  const [expectedLocation, setExpectedLocation] = useState();
  // Boolean defining if the modal should be visible or not
  const [modalVisible, setModalVisible] = useState(false);

  // Function  attached to react router's <Prompt> component
  const handleBlockedNavigation = (location, action) => {
    // If the navigation was not already confirmed and if the Modal config was defined, block the navigation and ask user for confirmation.
    if (!confirmedNavigation && !!leavingAlertModalConfig) {
      setModalVisible(true);
      setExpectedLocation({ location, action });
      return false;
    }
    // If not...
    // If the value of leavingCallback is a function, then execute it before leaving the page
    if (typeof leavingCallback === 'function') {
      leavingCallback();
    }
    return true;
  };

  // Modal confirm envent handler
  const handleConfirm = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  // Modal cancel envent handler
  const handleCancel = () => {
    setModalVisible(false);
    setConfirmedNavigation(false);
  };

  // Effect to handle navigation after user confirmation
  useEffect(() => {
    // If the navigation is confirmed by the user, navigate to the previous blocked location.
    if (confirmedNavigation && expectedLocation) {
      const { location, action } = expectedLocation;

      if (action === 'REPLACE') {
        // Use history.replace if the expected location change used it
        history.replace(location);
      } else {
        // If not (PUSH OR POP) use history.push by default
        // POP is when user uses browser history arrows, but since we cannot now details about it (back VS forward ? how many steps ?), we simply push a new entry in the history.
        history.push(location);
      }
    }
  }, [confirmedNavigation, expectedLocation]);

  // Event handler for strandard browser beforeunload event (triggered when the tab is refreshed, closed or links to an external URI)
  useEffect(() => {
    const beforeunloadHandler = event => {
      if (askBeforeLeaving) {
        event.preventDefault();
        event.returnValue = '';
        return event.returnValue;
      }
      return false;
    };
    const unloadHandler = () => {
      // If the value of leavingCallback is a function, then execute it before leaving the page
      if (typeof leavingCallback === 'function') {
        leavingCallback();
      }
    };
    window.addEventListener('beforeunload', beforeunloadHandler);
    window.addEventListener('unload', unloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeunloadHandler);
      window.removeEventListener('unload', unloadHandler);
    };
  }, [askBeforeLeaving]);

  return (
    <BeforeLeavingAlertContext.Provider
      value={{
        setAskBeforeLeaving,
        setLeavingAlertModalConfig,
        setLeavingCallback
      }}
    >
      <Prompt when={!!askBeforeLeaving} message={handleBlockedNavigation} />

      {modalVisible && (
        <ConfirmationModal
          warning
          confirm={handleConfirm}
          cancel={handleCancel}
          {...leavingAlertModalConfig}
        />
      )}
      {children}
    </BeforeLeavingAlertContext.Provider>
  );
};

BeforeLeavingAlertProvider.propTypes = {
  children: PropTypes.node.isRequired
};

// HOC function that will wrap the component inside BeforeLeavingAlertProvider
export const withLeavingAlertProvider = Component => props => (
  <BeforeLeavingAlertProvider>
    <Component {...props} />
  </BeforeLeavingAlertProvider>
);

// HOC function that will extend the component props by adding the context obect as props
// This means that the component will have 3 new props "setAskBeforeLeaving", "setLeavingAlertModalConfig" & "setLeavingCallback"
// allowing it to control the confirmation modal content, if the user should be asked for confirmation or not, and an optional callback executed after leaving
export const withLeavingAlertContext = Component => props => (
  <BeforeLeavingAlertContext.Consumer>
    {context => <Component {...props} {...context} />}
  </BeforeLeavingAlertContext.Consumer>
);
