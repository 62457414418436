import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';

import injectSheet from 'react-jss';

import { Modal } from '@stratumn/atomic';
import { Profile, Members } from '@stratumn/icons';

import Tabs from 'components/ui/tabs';

import AddUsers from './addUsers';
import AddTeams from './addTeams';

import styles from './addParticipantsModal.style';

export const ADD_PARTICIPANTS_USERS = 'users';
export const ADD_PARTICIPANTS_TEAMS = 'teams';

// The modal to add participants to a group
// by navigating across tabs it is possible to display different interfaces:
// 1 - add several users using their email
// 2 - add several teams from the organization
export const AddParticipantsModal = ({
  classes,
  groupName,
  submitAddUsers,
  submitAddTeams,
  cancel
}) => {
  const [selectedInterface, setSelectedInterface] = useState(
    ADD_PARTICIPANTS_USERS
  );

  const tabs = useMemo(
    () => [
      {
        id: ADD_PARTICIPANTS_USERS,
        header: (
          <div className={classes.tabHeader}>
            <Profile className={classes.tabHeaderIcon} size={20} />
            <span>Add participants</span>
          </div>
        )
      },
      {
        id: ADD_PARTICIPANTS_TEAMS,
        header: (
          <div className={classes.tabHeader}>
            <Members className={classes.tabHeaderIcon} size={20} />
            <span>Add teams</span>
          </div>
        )
      }
    ],
    []
  );

  const displayInterface = useMemo(() => {
    switch (selectedInterface) {
      case ADD_PARTICIPANTS_USERS:
        return <AddUsers submit={submitAddUsers} cancel={cancel} />;
      case ADD_PARTICIPANTS_TEAMS:
        return (
          <AddTeams
            groupName={groupName}
            submit={submitAddTeams}
            cancel={cancel}
          />
        );
      default:
        throw Error(`Unhandled interface ${selectedInterface}`);
    }
  }, [selectedInterface, groupName, submitAddUsers, submitAddTeams, cancel]);

  return (
    <Modal
      title="Add participants"
      closeButtonLabel="Cancel"
      handleCollapse={cancel}
      hideHeaderBorderBottom
    >
      <Tabs
        tabs={tabs}
        activeTab={selectedInterface}
        setActiveTab={setSelectedInterface}
      />
      {displayInterface}
    </Modal>
  );
};
AddParticipantsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  groupName: PropTypes.string.isRequired,
  submitAddUsers: PropTypes.func.isRequired,
  submitAddTeams: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};
AddParticipantsModal.defaultProps = {};

export default compose(injectSheet(styles), React.memo)(AddParticipantsModal);
