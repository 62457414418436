export default {
  infoTooltipBody: {
    borderRadius: 5,
    padding: '10px 20px',
    fontSize: 11,
    fontWeight: 600,
    letterSpacing: 0.1,
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    lineHeight: '20px'
  },
  infoTooltipArrowUp: {
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottomWidth: 6,
    borderBottomStyle: 'solid'
  }
};
