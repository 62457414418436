import { FILTERS_PANEL_OPEN_QUERY, FILTERS_QUERY } from 'gql/localQueries';
import defaults from './defaults';

export default {
  Mutation: {
    updateSearch: (_, { search }, { cache }) => {
      const data = cache.readQuery({ query: FILTERS_QUERY });
      data.filters.search = search;
      cache.writeData({ data });
      return null;
    },
    updateFilter: (_, { name, value }, { cache }) => {
      const data = cache.readQuery({ query: FILTERS_QUERY });
      data.filters[name] = value;
      cache.writeData({ data });
      return null;
    },
    resetFilters: (_, __, { cache }) => {
      const data = cache.readQuery({ query: FILTERS_QUERY });
      data.filters = {
        ...defaults.filters,
        search: data.filters.search
      };
      cache.writeData({ data });
      return null;
    },
    toggleFilters: (_, __, { cache }) => {
      const state = cache.readQuery({ query: FILTERS_PANEL_OPEN_QUERY });
      const data = {
        filtersPanelOpen: !state.filtersPanelOpen
      };

      cache.writeData({ data });
      return null;
    }
  }
};
