export default {
  errorPage: {
    position: 'absolute',
    maxWidth: '500px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    alignItems: 'center'
  },
  emailBody: {
    marginTop: '20px',
    height: '200px',
    wordBreak: 'break-all',
    overflow: 'scroll',
    border: '1px solid #ccc'
  },
  sendMailButton: {
    display: 'flex',
    marginTop: '36px',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
