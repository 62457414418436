export const gdprPolicyContent = {
  intro: 'Information for Users of the solution Stratumn Trace.',
  preamble: `The company Sia Partners, SASU with a capital of 200,000 euros registered with the Paris Register of Trade and Commerce under registration number B 423 507 730 and whose registered office is located at 21 rue de Berri 75008 Paris offers in its portfolio of activities the supply of solutions for artificial intelligence (AI). In this context, it edits and operates the  solution Stratumn Trace.
              For the processing of personal data relating to the offer of its IA solutions, Sia Partners determines the purposes and the means of implementation of the processing activity. As such, it acts as Data Controller within the meaning of the regulations relating to the protection of personal data.
              The following information details the processing of the personal data of the Users of the SaaS solution Stratumn Trace.`,
  identification: {
    title: `Identification and qualification of the stakeholders`,
    bullets: [
      `The company Sia Partners is the Data Controller of personal data (DP) provided natively in the SaaS solution Stratumn Trace.`,
      `For its organization, Sia Partners has appointed a Data Protection Officer (DPO) in charge of the protection of personal data. The DPO is the recipient of any requests for the exercise of Users' rights.`,
      `The Client Company is the legal entity that has signed a contract for the use of the  solution Stratumn Trace with Sia Partners. It is responsible for any processing of personal data that it carries out from the results obtained from the SaaS solution  solution Stratumn Trace.`,
      `The User is a person from the Client Company. He is authorized to use the SaaS solution Stratumn Trace within the framework of the contractual relationship concluded between Sia Partners and the Client Company. His authorization was granted to him by the Super Administrator.`,
      `The Super Administrator is a person from the Client Company responsible for the administration of user accounts for the SaaS solution Stratumn Trace(creation, modification, deletion).`
    ]
  },
  purposes: {
    title: `For what purposes is the personal data of Users collected?`,
    intro: `For the implementation of the processing purposes, different categories of data are collected:`,
    bullets: [
      `The creation of a user profile and associated access rights`,
      `The authentication and the access control of the solution`,
      `The statistical monitoring of the use of the platform for technical optimization purposes`,
      `Receive, store and retrieve encrypted data for business processes and workflows defined in the platform`
    ]
  },
  whatData: {
    title: `What personal data is collected`,
    intro: `For the implementation of the processing purposes, different categories of data are collected:`,
    bullets: [
      `Identification data: connection identifier, surname, first name, email address`,
      `Connection data: connection log, connection history, IP address`,
      `Data relating to professional life: organisation/employer, application role, status`,
      `Working life data: organisation/employer, country/department (managed by the second level of the teams)`
    ]
  },
  howDataCollectedTable: {
    title: `How is the Users’ personal data collected?`,
    items: [
      {
        text: `Category of data`,
        rowStart: 1,
        rowEnd: 2,
        column: 1
      },
      {
        text: `Data`,
        rowStart: 1,
        rowEnd: 1,
        column: 2
      },
      {
        text: `Type of collection`,
        rowStart: 1,
        rowEnd: 1,
        column: 3
      },
      {
        text: `Identification data`,
        rowStart: 2,
        rowEnd: 5,
        column: 1
      },
      {
        text: `identification number`,
        rowStart: 2,
        rowEnd: 3,
        column: 2
      },
      {
        text: `Indirect collection. The internal identification number is generated by the application automatically.`,
        rowStart: 2,
        rowEnd: 3,
        column: 3
      },
      {
        text: `Data relating to professional life`,
        rowStart: 5,
        rowEnd: 6,
        column: 1
      },
      {
        text: `surname, first name, telephone number`,
        rowStart: 3,
        rowEnd: 4,
        column: 2
      },
      {
        text: `Direct collection. The user enters this information either when creating their account or when updating their profile.`,
        rowStart: 3,
        rowEnd: 4,
        column: 3
      },
      {
        text: `Données d’utilisation de la plateforme`,
        rowStart: 6,
        rowEnd: 7,
        column: 1
      },
      { text: `email address`, rowStart: 4, rowEnd: 5, column: 2 },
      {
        text: `Indirect collection. This data is entered by the Super Administrator when creating their users' accounts or by the User when using the application.`,
        rowStart: 4,
        rowEnd: 5,
        column: 3
      },
      {
        text: `Données de créations de links`,
        rowStart: 7,
        rowEnd: 8,
        column: 1
      },
      {
        text: `organisation/employer, country/department (managed by the second team level)`,
        rowStart: 5,
        rowEnd: 6,
        column: 2
      },
      {
        text: `Indirect collection. This data is entered by the Super Administrator when inviting new users to his organisation or teams.`,
        rowStart: 5,
        rowEnd: 6,
        column: 3
      },
      {
        text: `connection log, connection history, IP address, API calls`,
        rowStart: 6,
        rowEnd: 7,
        column: 2
      },
      {
        text: `Indirect collection. This data is collected on the application servers.`,
        rowStart: 6,
        rowEnd: 7,
        column: 3
      },
      {
        text: `date of creation of the link, user's e-mail address`,
        rowStart: 7,
        rowEnd: 8,
        column: 2
      },
      {
        text: `date of creation of the link, user's e-mail address`,
        rowStart: 7,
        rowEnd: 8,
        column: 3
      }
    ]
  },
  legalBasis: {
    title: `What is the legal basis for the collection and the processing of data`,
    items: [
      {
        text: `Purpose of processing`,
        rowStart: 1,
        rowEnd: 2,
        column: 1
      },
      {
        text: `Legal`,
        rowStart: 1,
        rowEnd: 2,
        column: 2
      },
      {
        text: `Creation of a user profile and associated access rights`,
        rowStart: 2,
        rowEnd: 3,
        column: 1
      },
      {
        text: `The execution of a contract or the execution of pre-contractual measures between Sia Partners and the Client company (article 6-1b of the GDPR)`,
        rowStart: 2,
        rowEnd: 4,
        column: 2
      },
      {
        text: `Authentication and access control to the solution`,
        rowStart: 3,
        rowEnd: 4,
        column: 1
      },
      {
        text: `Legitimate interest of Sia Partners (article 6-1f of the GDPR).
      Justification: enable Sia Partners' technical teams to optimize hardware resources and improve the use of software platforms.
      `,
        rowStart: 4,
        rowEnd: 5,
        column: 2
      },
      {
        text: `Statistical monitoring of the use of the platform at technical optimization purposes`,
        rowStart: 4,
        rowEnd: 5,
        column: 1
      },
      {
        text: `Performance of a contract or pre-contractual measures between Sia Partners and the Client company (article 6-1b of the GDPR)`,
        rowStart: 5,
        rowEnd: 5,
        column: 2
      },
      {
        text: `Receive, store and retrieve encrypted data for business processes and workflows defined in the Stratumn Trace platform`,
        rowStart: 5,
        rowEnd: 5,
        column: 1
      }
    ]
  },
  recipients: {
    title: `Who are the recipients of the personal data of the Users?`,
    intro: `These are:`,
    bullets: [
      `The Super Administrator for access rights management purposes`,
      `The Sia Partners teams for the purposes of maintaining the solution`,
      `The third-party hosts as part of their solution hosting service SaaS Stratumn on behalf of Sia Partners`
    ]
  },
  howLong: {
    title: `How long is the personal data of Users`,
    bullets: {
      title: `Identification data and data relating to professional life are kept, after the deactivation of the User account, in accordance with the periods previously defined with the client.`,
      insideBullet: {
        title: `Concerning the connection data:`,
        insideBullets: [
          `The technical logs (IP address) are kept for 1 year`,
          `The connection logs are kept for 1 year How to exercise`
        ]
      }
    }
  },
  exerciseRights: {
    title: `How to exercise your rights`,
    intro: `In accordance with the regulations in force, the data subject has a right of access, a right to rectification, a right to erasure, a right limit the processing scope of his data, a right of opposition and a right to the portability of his data.
  Rights can be exercised with the DPO of Sia Partners:
  `,
    emailText: `by e-mail:`,
    email: `sia-partners-conformite-rgpd@sia-partners.com`,
    postText: `by post: `,
    postAddress: `Sia Partners, Data Protection, 21 rue de Berri, 75008 Paris, France`,
    outroText: `The data subject also has the right to lodge a complaint with the CNIL (Commission Nationale de l'Informatique et des Libertés), the supervisory authority in charge of compliance with the obligations relating to the protection of personal data.`
  }
};
