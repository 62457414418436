export default theme => ({
  tableFilters: {
    flexGrow: 1,
    width: 'fit-content',
    display: 'flex',
    flexFlow: 'row',
    fontSize: '10px',
    color: theme.grey4,
    textAlign: 'left',
    backgroundColor: theme.grey7,
    borderBottom: `1px solid ${theme.grey4}`
  },
  tableFilterCell: {
    position: 'relative',
    padding: '10px 5px'
  },
  tableActiveFilterIndicator: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 3,
    backgroundColor: theme.teal1,
    '&[data-filters-invalid=true]': {
      backgroundColor: theme.warningRed
    }
  }
});
