import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { Check } from '@stratumn/atomic';

import styles from './selectableItemWrapper.style';
import { useSelectableItemsContext, getIsAllSelected } from '..';

const SelectableItemWrapper = ({ classes, children }) => {
  const {
    selected,
    children: selectableChildren,
    handleChangeCheck
  } = useSelectableItemsContext();

  return (
    <div className={classes.wrapper}>
      <div className={classes.checkbox}>
        <Check
          label="Select item"
          showLabel={false}
          handleChange={handleChangeCheck}
          checked={selected}
          indeterminate={
            !selectableChildren?.every(child => getIsAllSelected(child))
          }
        />
      </div>
      <div className={classes.children}>{children}</div>
    </div>
  );
};

SelectableItemWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default injectSheet(styles)(SelectableItemWrapper);
