import React, { useState, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import { DownArrowFill } from '@stratumn/icons';

import { FieldSelectCompact, OptionDrop, Check } from '@stratumn/atomic';

import Tooltip from 'components/ui/utils/tooltip';

import styles from './multiselect.style';

const TOOLTIP_POSITION = {
  place: 'below',
  adjustPlace: true,
  anchor: 'left',
  adjustAnchor: true
};

// simple multiselect input
// if no header (inline mode) display an info on the nb of selected items, with a tooltip with options on click
// else (batch form mode) display an expandable inline list
// if disabed display a static list with teal markers for selected items, instead of checkboxes
const MultiselectInput = React.memo(
  ({
    classes,
    data,
    options,
    onChange,
    placeholder,
    className,
    header,
    disabled
  }) => {
    // focus state
    const btnRef = useRef(null);
    const inputRef = useRef(null);
    const [isFocused, setFocus] = useState(false);
    const focus = useCallback(() => {
      setFocus(true);
    }, []);
    const blur = useCallback(() => {
      setFocus(false);
      if (!header) btnRef.current.focus(); // keep the btn focused until explicitely blurred
    }, []);

    // handle list of strings as well as list of objects {value,label} for options
    const multiselectOptions = useMemo(
      () =>
        // If options is an array of objects, take it as it is,
        // otherwise it's an array of strings which should be reformatted.
        options.length === 0 || typeof options[0] === 'object'
          ? options
          : options.map(option => ({
              label: option,
              value: option
            })),
      [options]
    );

    // handle clicking on one option
    const toggleOption = useCallback(
      optionValue => {
        if (disabled) return; // disable selecting when input disabled...
        const newData = [...data];
        const optionIndex = data.findIndex(
          dataOption => dataOption === optionValue
        );
        if (optionIndex > -1) {
          newData.splice(optionIndex, 1);
        } else {
          newData.push(optionValue);
        }
        onChange(newData.length === 0 ? undefined : newData);
      },
      [disabled, data, options]
    );

    // get label to display
    const textDisplay = !data.length
      ? (!disabled && placeholder) || 'No item selected'
      : `${data.length} item${data.length > 1 ? 's' : ''} selected`;
    const labelComponent = (
      <>
        <div className={classes.textDisplay} data-is-unset={!data.length}>
          {textDisplay}
        </div>
        <DownArrowFill
          className={classes.multiselectDropdownIcon}
          data-input-focused={isFocused}
        />
      </>
    );

    // build the list of options to display when focused
    const optionsList = multiselectOptions.map(
      ({ label: optionLabel, value: optionValue }) => {
        const isSelected = data.includes(optionValue);
        return disabled ? (
          <div
            key={optionValue}
            className={classes.disabledOption}
            data-is-selected={isSelected}
          >
            {isSelected && <div className={classes.disabledOptionMarker} />}
            <div className={classes.disabledOptionLabel}>{optionLabel}</div>
          </div>
        ) : (
          <div
            key={optionValue}
            className={classes.option}
            data-is-selected={isSelected}
          >
            <Check
              label={optionLabel}
              checked={isSelected}
              showLabel
              largeLabel
              handleChange={() => toggleOption(optionValue)}
              dataCy="multiselect-checkbox"
            />
          </div>
        );
      }
    );

    if (disabled && !data.length)
      return (
        <div
          className={classnames(
            className,
            classes.disabledMultiselectEmptyInput
          )}
        >
          No item selected
        </div>
      );

    return header ? (
      <div
        className={classnames(className, classes.multiselectCompactInput)}
        data-input-focused={isFocused}
        onClick={!isFocused ? focus : null}
        data-cy="multiselect-button-compact"
      >
        {!isFocused ? (
          <FieldSelectCompact onValueChange={() => {}} label={header}>
            <OptionDrop
              label={textDisplay}
              value={textDisplay}
              selected={data.length > 0}
            />
          </FieldSelectCompact>
        ) : (
          <div className={classes.multiselectCompactInputDropDown}>
            <div
              className={classes.tooltipHeader}
              onClick={blur}
              data-is-compact
              data-cy="multiselect-blur-compact"
            >
              <div className={classes.textDisplay}>{`${header}${
                data.length ? ` - ${textDisplay}` : ''
              }`}</div>
              <DownArrowFill
                className={classes.multiselectDropdownIcon}
                data-input-focused={isFocused}
              />
            </div>
            <div className={classes.optionsList}>{optionsList}</div>
          </div>
        )}
      </div>
    ) : (
      <>
        <button
          ref={btnRef}
          className={classnames(className, classes.multiselectInput)}
          onClick={focus}
          data-input-focused={isFocused}
          data-cy="multiselect-button"
        >
          <div ref={inputRef} className={classes.inputRef} />
          {labelComponent}
        </button>
        {isFocused && (
          <Tooltip
            clientEl={inputRef.current}
            portalEl={document.body}
            position={TOOLTIP_POSITION}
            onClickOutside={blur}
          >
            <div className={classes.tooltipContent}>
              <div className={classes.tooltipHeader} onClick={blur}>
                {labelComponent}
              </div>
              <div className={classes.optionsList}>{optionsList}</div>
            </div>
          </Tooltip>
        )}
      </>
    );
  }
);
MultiselectInput.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.string,
  disabled: PropTypes.bool
};
MultiselectInput.defaultProps = {
  options: [],
  placeholder: '',
  className: '',
  header: '',
  disabled: false
};

export default injectSheet(styles)(MultiselectInput);
