export default theme => ({
  loadingIndicatorContainer: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  loadingIndicator: {
    border: `1px solid ${theme.grey8}`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    zIndex: 1,
    backgroundColor: `${theme.lightTransparentGrey}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexWrap: 'nowrap'
  },
  loadingIndicatorSuccess: {
    pointerEvents: 'none',
    opacity: 1,
    transition: 'opacity 250ms ease'
  },
  loadingIndicatorSuccessHovered: {
    opacity: 0
  }
});
