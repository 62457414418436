/* tooltip portal util */

import { getScrollbarSize } from 'utils';

const SCROLLBAR_SIZE = getScrollbarSize();

// get corner positions of an element
const getDomElementAbsoluteRect = el => {
  const rect = el.getBoundingClientRect();

  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return {
    top: rect.top + scrollTop,
    height: rect.height,
    bottom: rect.bottom + scrollTop,
    left: rect.left + scrollLeft,
    width: rect.width,
    right: rect.right + scrollLeft
  };
};

// get total window sizes
const getWindowSizes = () => {
  const viewportWidth =
    window.innerWidth || document.documentElement.clientWidth;
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    windowWidth: viewportWidth + scrollLeft,
    windowHeight: viewportHeight + scrollTop
  };
};

// tooltip position const strings
const TOOLTIP_PLACE_BELOW = 'below';
const TOOLTIP_PLACE_ABOVE = 'above';
const TOOLTIP_PLACE_LEFT = 'left';
const TOOLTIP_PLACE_RIGHT = 'right';
const TOOLTIP_ANCHOR_BOTTOM = 'bottom';
const TOOLTIP_ANCHOR_TOP = 'top';
const TOOLTIP_ANCHOR_LEFT = 'left';
const TOOLTIP_ANCHOR_RIGHT = 'right';
const TOOLTIP_ANCHOR_CENTER = 'center';

// A window buffer to leave some space between the extreme positions of the re-positioned tooltip and the window borders
const TOOLTIP_WINDOW_BUFFER = 15;

// position the tooltip element once mounted
// place and shift elements according to position configuration, then show
export const positionTooltipElementAndShow = (
  tooltipEl,
  clientRefEl,
  position,
  arrowEl,
  delay
) => {
  // get window size
  const { windowWidth, windowHeight } = getWindowSizes();

  // get client rect position
  const {
    top: clientTop,
    height: clientHeight,
    bottom: clientBottom,
    left: clientLeft,
    width: clientWidth,
    right: clientRight
  } = getDomElementAbsoluteRect(clientRefEl);

  // check the size of the tooltip to display
  const {
    height: tooltipHeight,
    width: tooltipWidth
  } = getDomElementAbsoluteRect(tooltipEl);

  // check the height of the upward arrow
  const { height: arrowHeight, width: arrowWidth } = getDomElementAbsoluteRect(
    arrowEl
  );

  const { style: tooltipStyle } = tooltipEl;
  const tooltipBorderRadius = parseInt(
    getComputedStyle(tooltipEl.children[0]).borderRadius || 0,
    10
  );

  tooltipStyle.width = `${tooltipWidth}px`;
  tooltipStyle.height = `${tooltipHeight}px`;

  const { style: arrowStyle } = arrowEl;
  let arrowRotation = 0;

  // read positioning specification
  let { place = TOOLTIP_PLACE_BELOW } = position;
  const {
    placeShift = 0,
    adjustPlace = false,
    anchor = TOOLTIP_ANCHOR_CENTER,
    anchorShift = 0,
    adjustAnchor = true
  } = position;
  const totalPlaceShift = placeShift + arrowHeight;

  // try to apply the position spec and move to the other side if it feels better...

  // a first run through the potential places
  // checking if we should adjust
  // ie if part of the tooltip is hidden in the prefered direction and if the opposite could give more room

  // how much room would remain given place ?
  const roomBelow =
    windowHeight - clientBottom - totalPlaceShift - tooltipHeight;
  const roomAbove = clientTop - totalPlaceShift - tooltipHeight;
  const roomLeft = clientLeft - totalPlaceShift - tooltipWidth;
  const roomRight = windowWidth - clientRight - totalPlaceShift - tooltipWidth;
  switch (place) {
    case TOOLTIP_PLACE_ABOVE:
      if (
        adjustPlace &&
        roomAbove < TOOLTIP_WINDOW_BUFFER &&
        roomBelow > roomAbove
      ) {
        // partly hidden above and there is more space below
        place = TOOLTIP_PLACE_BELOW;
      }
      break;
    case TOOLTIP_PLACE_LEFT:
      if (
        adjustPlace &&
        roomLeft < TOOLTIP_WINDOW_BUFFER &&
        roomRight > roomLeft
      ) {
        // partly hidden left and there is more space right
        place = TOOLTIP_PLACE_RIGHT;
      }
      break;
    case TOOLTIP_PLACE_RIGHT:
      if (
        adjustPlace &&
        roomRight < TOOLTIP_WINDOW_BUFFER &&
        roomLeft > roomRight
      ) {
        // partly hidden right and there is more space left
        place = TOOLTIP_PLACE_LEFT;
      }
      break;
    case TOOLTIP_PLACE_BELOW:
    default:
      if (
        adjustPlace &&
        roomBelow < TOOLTIP_WINDOW_BUFFER &&
        roomAbove > roomBelow
      ) {
        // partly hidden below and there is more space above
        place = TOOLTIP_PLACE_ABOVE;
      }
  }

  // now do apply the concrete placing
  switch (place) {
    case TOOLTIP_PLACE_ABOVE:
      tooltipStyle.top = `${Math.max(
        clientTop - totalPlaceShift - tooltipHeight,
        TOOLTIP_WINDOW_BUFFER
      )}px`; // note: don't overflow on the top of the window or we won't be able to scroll anymore
      arrowStyle.top = `${clientTop - totalPlaceShift}px`;
      arrowRotation = 180;
      break;
    case TOOLTIP_PLACE_LEFT:
      tooltipStyle.left = `${Math.max(
        clientLeft - totalPlaceShift - tooltipWidth,
        TOOLTIP_WINDOW_BUFFER
      )}px`; // note: don't overflow on the left of the window or we won't be able to scroll anymore
      arrowStyle.left = `${clientLeft - totalPlaceShift}px`;
      arrowRotation = 90;
      break;
    case TOOLTIP_PLACE_RIGHT:
      tooltipStyle.left = `${clientRight + totalPlaceShift}px`;
      arrowStyle.left = `${clientRight + placeShift}px`;
      arrowRotation = 270;
      break;
    case TOOLTIP_PLACE_BELOW:
    default:
      tooltipStyle.top = `${clientBottom + totalPlaceShift}px`;
      arrowStyle.top = `${clientBottom + placeShift}px`;
  }

  // now it's placed look at the cross axis positioning
  // try to apply the anchor spec first and then potentialy move it a bit
  if (place === TOOLTIP_PLACE_LEFT || place === TOOLTIP_PLACE_RIGHT) {
    // anchor is vertical
    let tooltipTop = null;
    switch (anchor) {
      case TOOLTIP_ANCHOR_TOP:
        // anchor the tooltip top
        tooltipTop = clientTop + anchorShift;
        break;
      case TOOLTIP_ANCHOR_BOTTOM:
        // anchor the tooltip bottom
        tooltipTop = clientBottom - tooltipHeight - anchorShift;
        break;
      case TOOLTIP_ANCHOR_CENTER:
      default:
        // center the tooltip
        tooltipTop = Math.floor(
          clientTop + 0.5 * clientHeight - 0.5 * tooltipHeight + anchorShift
        );
    }
    // don't overflow top
    tooltipTop = Math.max(tooltipTop, TOOLTIP_WINDOW_BUFFER);
    tooltipStyle.top = `${tooltipTop}px`;

    // check if we should adjust bottom
    if (
      adjustAnchor &&
      tooltipTop + tooltipHeight > windowHeight - TOOLTIP_WINDOW_BUFFER
    ) {
      tooltipTop = Math.max(
        tooltipTop -
          (tooltipTop + tooltipHeight - windowHeight + TOOLTIP_WINDOW_BUFFER),
        TOOLTIP_WINDOW_BUFFER
      );
      tooltipStyle.top = `${tooltipTop}px`;
    }

    // now compute the size of the arrow container
    // which is the vertical intersection of client and tooltip
    const arrowTop = Math.max(clientTop, tooltipTop);
    arrowStyle.top = `${arrowTop}px`;
    arrowStyle.height = `${
      Math.min(clientBottom, tooltipTop + tooltipHeight) - arrowTop
    }px`;
    arrowStyle.width = `${arrowHeight}px`; // use height as width because of rotation
  } else {
    // anchor is horizontal
    let tooltipLeft = null;
    switch (anchor) {
      case TOOLTIP_ANCHOR_RIGHT:
        // anchor the tooltip right
        tooltipLeft = clientRight - tooltipWidth - anchorShift;
        break;
      case TOOLTIP_ANCHOR_LEFT:
        // anchor the tooltip left
        tooltipLeft = clientLeft + anchorShift;
        break;
      case TOOLTIP_ANCHOR_CENTER:
      default:
        // center the tooltip
        tooltipLeft = Math.floor(
          clientLeft + 0.5 * clientWidth - 0.5 * tooltipWidth + anchorShift
        );
    }
    // don't overflow left
    tooltipLeft = Math.max(tooltipLeft, TOOLTIP_WINDOW_BUFFER);
    tooltipStyle.left = `${tooltipLeft}px`;

    // check if we should adjust right
    // in this case we'll also take into account the scrollbar width in addition to the buffer
    const bufferAndScrollbar = TOOLTIP_WINDOW_BUFFER + SCROLLBAR_SIZE;
    if (
      adjustAnchor &&
      tooltipLeft + tooltipWidth > windowWidth - bufferAndScrollbar
    ) {
      tooltipLeft = Math.max(
        tooltipLeft -
          (tooltipLeft + tooltipWidth - windowWidth + bufferAndScrollbar),
        bufferAndScrollbar
      );
      tooltipStyle.left = `${tooltipLeft}px`;
    }

    // Now compute the size and position of the arrow container
    // The left position should be placed at the center of the tooltip by default
    const arrowLeftDefault = clientLeft + clientWidth / 2 - arrowWidth / 2;
    // In case of when the tooltip is shifted to not overflow window left,
    // the anchor position shouldn't exceed tooltip left side
    // Tooltip border radius is taken into account so the arrow is placed right next to the end of the rounded corner
    const arrowLeftMin = tooltipLeft + tooltipBorderRadius;
    // In case of when the tooltip is shifted to not overflow window right,
    // the anchor position shouldn't exceed tooltip right side
    // Tooltip border radius is taken into account so the arrow is placed right next to the end of the rounded corner
    const arrowLeftMax =
      tooltipLeft + tooltipWidth - arrowWidth - tooltipBorderRadius;
    // Putting everything together
    const arrowLeft = Math.max(
      arrowLeftMin,
      Math.min(arrowLeftDefault, arrowLeftMax)
    );
    arrowStyle.left = `${arrowLeft}px`;
    arrowStyle.width = `${arrowWidth}px`;
    arrowStyle.height = `${arrowHeight}px`;
  }

  // finally rotate the arrow
  arrowEl.children[0].style.transform = `rotate(${arrowRotation}deg)`;

  // now it's replaced set the visibility on, potentialy after a delay setup in the props
  setTimeout(() => {
    tooltipStyle.visibility = 'visible';
    arrowStyle.visibility = 'visible';
  }, delay);
};
