export default theme => ({
  content: {
    position: 'fixed',
    top: 105,
    backgroundColor: theme.grey8,
    height: 'calc(100% - 105px)',
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    color: theme.grey1
  },
  groupsList: {
    flexGrow: 1,
    overflow: 'auto'
  },
  footer: {
    flexShrink: 0,
    height: 63,
    padding: '10px 21px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  }
});
