import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { Iconbutton, Tally } from '@stratumn/atomic';
import { Add, Minus } from '@stratumn/icons';

import InfoTooltip from 'components/ui/utils/infoTooltip';

import styles from './groupButton.style';

export const GroupButton = ({ classes, collapsed, rowCount, onToggle }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const enableTooltip = React.useCallback(() => setShowTooltip(true), []);
  const disableTooltip = React.useCallback(() => setShowTooltip(false), []);
  const containerRef = React.useRef();

  return (
    <div ref={containerRef} className={classes.groupIconContainer}>
      <Iconbutton
        variant={collapsed ? 'float' : 'outline'}
        label={collapsed ? 'Uncollapse' : 'Collapse'}
        onMouseOver={enableTooltip}
        onFocus={enableTooltip}
        onMouseLeave={disableTooltip}
        onBlur={disableTooltip}
        onClick={onToggle}
      >
        {collapsed ? <Add /> : <Minus />}
      </Iconbutton>
      {collapsed && (
        <div className={classes.tallyContainer}>
          <Tally value={rowCount} color="white1" backgroundColor="indigo1" />
        </div>
      )}
      {showTooltip && collapsed && (
        <InfoTooltip
          clientRef={containerRef}
          text={`${rowCount} row${rowCount > 1 ? 's' : ''} grouped`}
          textColor="white"
          backgroundColor="indigo2"
          position={{
            place: 'right',
            placeShift: 3,
            adjustPlace: true,
            anchor: 'left'
          }}
          delay={100}
        />
      )}
    </div>
  );
};

GroupButton.propTypes = {
  classes: PropTypes.object.isRequired,
  collapsed: PropTypes.bool,
  rowCount: PropTypes.number,
  onToggle: PropTypes.func.isRequired
};

GroupButton.defaultProps = {
  collapsed: false,
  rowCount: 0
};

export default injectSheet(styles)(GroupButton);
