export default () => ({
  groups: {
    padding: '15px 21px',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  noGroupsMessage: {
    padding: '5px 0px',
    fontSize: 14,
    lineHeight: '18px'
  },
  loadMoreButton: {
    margin: '15px 0px'
  },
  refetchIcon: {
    width: 34,
    height: 34,
    margin: 15
  }
});
