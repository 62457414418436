import React, { useEffect } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import compose from 'lodash.flowright';
import Path from 'path-to-regexp';
import { Pushbutton } from '@stratumn/atomic';
import { sig } from '@stratumn/js-crypto';

import { WorkflowContext } from 'utils/workflowContext';

import { withLeavingAlertContext } from 'components/beforeLeavingAlert';

import { Widget } from 'components/ui/widget';
import { Footer } from 'components/layouts';
import { getByPath } from 'utils';
import { ROUTE_WORKFLOW_OVERVIEW } from 'constant/routes';

import styles from './newLink.style';

export const Signature = React.memo(props => {
  const {
    classes,
    action,
    match,
    history,
    location,
    workflowContext,
    user,
    traces,
    createLinks
  } = props;

  const [trace] = traces;

  const signersArray = getByPath(trace, action.eSignature.signersArrayPath);
  const signatures = signersArray.filter(
    o => o.email === user?.me?.email && o.status !== 'signed'
  );
  const signature = signatures[0];

  const getSigningPrivateKey = () => {
    const {
      me: {
        account: { signingKey }
      },
      password
    } = user;

    const { passwordProtected, decrypted } = signingKey.privateKey;

    if (passwordProtected)
      return new sig.SigningPrivateKey({
        pemPrivateKey: decrypted,
        password
      }).export();

    return new sig.SigningPrivateKey({
      pemPrivateKey: decrypted
    }).export();
  };

  const goToWorkflowOverview = () => {
    history.push(
      Path.compile(ROUTE_WORKFLOW_OVERVIEW)({ id: match.params.wfid })
    );
  };

  const goBack = () => {
    if (location.state && location.state.from) {
      history.push(location.state.from);
      return;
    }
    // By default go back to workflow overview
    goToWorkflowOverview();
  };

  useEffect(() => {
    window.addEventListener('message', e => {
      // Check if the origin of the message is really come from Yousign
      if (e.origin === 'https://yousign.app' && e.data.type === 'yousign') {
        if (e.data.event === 'success') {
          const sign = getSigningPrivateKey();
          createLinks(sign, {
            signatureRequestId: e.data.signature_request_id
          });
        }
      }
    });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.formBody}>
        <div
          className={classes.form}
          data-cy="formjs-writer"
          data-has-traceinfo={trace && !!trace.workflow.config.info}
          style={{
            width: '90%',
            maxWidth: '100%'
          }}
        >
          <h1 style={{ paddingBottom: '20px' }}>Signing document</h1>
          {signature.signature_link && (
            <iframe
              style={{
                border: '0',
                width: '100%',
                height: '650px'
              }}
              title="Signature Link"
              src={`${signature.signature_link}&disable_domain_validation=true`}
            >
              {' '}
            </iframe>
          )}
          <div className={classes.actionButtonWrapper}>
            <div className={classes.actionButton}>
              <Pushbutton onClick={() => goBack()} dataCy="cancel">
                Cancel
              </Pushbutton>
            </div>
          </div>
        </div>
        {trace && trace.workflow.config.info && (
          <div data-cy="trace-info" className={classes.traceInfo}>
            <WorkflowContext.Provider value={workflowContext}>
              <Widget widget={trace.workflow.config.info} data={trace.state} />
            </WorkflowContext.Provider>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
});

Signature.propTypes = {
  classes: PropType.object.isRequired,
  action: PropType.object.isRequired,
  history: PropType.object.isRequired,
  location: PropType.object.isRequired,
  match: PropType.object.isRequired,
  workflowContext: PropType.object.isRequired,
  traces: PropType.arrayOf(PropType.object).isRequired,
  user: PropType.object.isRequired,
  createLinks: PropType.func.isRequired
};

export default compose(
  injectSheet(styles),
  withRouter,
  withLeavingAlertContext
)(Signature);
