export default theme => ({
  typeFileViewsMenuContainer: {
    width: '200px'
  },
  typeFileViewsMenuHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 15,
    margin: 5,
    marginBottom: 2,
    padding: 3,
    borderBottom: `1px solid ${theme.grey5}`
  },
  typeFileViewsMenuRadioButtons: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-around'
  },
  typeFileViewsButton: {
    display: 'block',
    margin: 5,
    '& button': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& span': {
        display: 'flex',
        alignItems: 'center'
      }
    }
  },
  typeFileViewsButtonIcon: {
    height: 22
  }
});
