import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import { Dropdown, FieldSelectCompact, OptionDrop } from '@stratumn/atomic';

import styles from './select.style';

// simple select input
const SelectDropdown = React.memo(
  ({
    classes,
    dataStr,
    options,
    onChange,
    placeholder,
    className,
    header,
    disabled
  }) => {
    // handle list of strings as well as list of objects {value,label} for options
    const dropDownOptions = useMemo(() => {
      // If options is an array of objects, take it as it is,
      // otherwise it's an array of strings which should be reformatted.
      const res =
        options.length === 0 || typeof options[0] === 'object'
          ? options
          : options.map(option => ({
              label: option,
              value: option
            }));

      // Some dropdowns can have current values that we don't want the user to select
      // (e.g. "Valid (from TA)" in the AGRe FF workflow).
      // We therefore add it as an option to make it visible in the dropdown.
      // TODO: Implement a cleaner way to define selectable and unselectable dropdown options
      if (!!dataStr && !res.find(({ value }) => value === dataStr)) {
        res.unshift({
          label: dataStr,
          value: dataStr
        });
      }
      return res;
    }, [dataStr, options]);

    const optionDrops = dropDownOptions.map((option, i) => (
      <OptionDrop
        label={option.label}
        value={option.value}
        selected={option.value === dataStr}
        key={`${i}_${option.value}`}
      />
    ));

    if (disabled)
      return (
        <div className={classnames(className, classes.disabledSelectInput)}>
          {dataStr}
        </div>
      );

    return header ? (
      <div className={classnames(className, classes.selectCompactInput)}>
        <FieldSelectCompact
          onValueChange={e => onChange(e.target.value)}
          label={header}
        >
          {optionDrops}
        </FieldSelectCompact>
      </div>
    ) : (
      <div className={classnames(className, classes.dropDownInput)}>
        <Dropdown
          onValueChange={e => onChange(e.target.value)}
          value={dataStr}
          label={placeholder}
          hideLabel
        >
          {optionDrops}
        </Dropdown>
      </div>
    );
  }
);
SelectDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  dataStr: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.string,
  disabled: PropTypes.bool
};
SelectDropdown.defaultProps = {
  options: [],
  placeholder: '',
  className: '',
  header: '',
  disabled: false
};

export default injectSheet(styles)(SelectDropdown);
