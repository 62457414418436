import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import { Pen, DownArrowFill } from '@stratumn/icons';

import { FieldTextAreaCompact } from '@stratumn/atomic';

import Tooltip from 'components/ui/utils/tooltip';

import styles from './comment.style';

const TOOLTIP_POSITION = {
  place: 'below',
  adjustPlace: true,
  anchor: 'left',
  adjustAnchor: true
};

// simple debounced comment input
// if no header (inline mode) display a simple text, with a tooltip with textarea on click
// else (batch form mode) display an expandable textarea
// if disabed display a static text with full content in a tooltip on click
const CommentInput = React.memo(
  ({
    classes,
    dataStr,
    onChange,
    placeholder,
    delay,
    className,
    header,
    disabled
  }) => {
    // focus state
    const btnRef = useRef(null);
    const inputRef = useRef(null);
    const [isFocused, setFocus] = useState(false);
    const focus = useCallback(() => {
      setFocus(true);
    }, []);
    const blur = useCallback(() => {
      setFocus(false);
      if (!header) btnRef.current.focus(); // keep the btn focused until explicitely blurred
    }, []);

    // when focused, tooltip opens, then auto focus the textarea input
    const tooltipInputRef = useRef(null);
    useEffect(() => {
      if (isFocused) {
        setTimeout(() => tooltipInputRef.current.focus());
      }
    }, [isFocused]);

    const [inputStr, setInputStr] = useState(dataStr);
    const updateInputStr = useCallback(e => {
      setInputStr(e.target.value);
    }, []);

    // change the text displayed if the data provided changes
    useEffect(
      () => {
        setInputStr(dataStr);
      },
      [dataStr] // Only re-call effect if dataStr changes
    );

    // debounce update of the text input
    useEffect(
      () => {
        if (inputStr === dataStr) return undefined;

        // call onChange after delay
        const handler = setTimeout(() => {
          onChange(inputStr);
        }, delay);

        // Cancel the timeout if value changes (also on unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [inputStr] // Only re-call effect if inputStr changes
    );

    // callback to close tooltip on escape
    const closeOnEsc = useCallback(e => {
      if (e.key === 'Escape') {
        blur();
      }
    }, []);

    if (disabled && !dataStr) return null;

    return header ? (
      <div className={className}>
        <FieldTextAreaCompact
          label={header}
          onValueChange={updateInputStr}
          value={inputStr}
          rows={6}
          noResize
          disabled={disabled}
        />
      </div>
    ) : (
      <>
        <button
          ref={btnRef}
          className={classnames(className, classes.commentInput)}
          onClick={focus}
          data-input-focused={isFocused}
          data-cy="comment-input"
        >
          <div ref={inputRef} className={classes.inputRef} />
          <div
            className={classes.textDisplay}
            data-is-unset={!inputStr}
            data-is-disabled={disabled}
          >
            {inputStr || placeholder || 'Add comment...'}
          </div>
          {disabled ? (
            <DownArrowFill
              className={classes.commentInputIcon}
              data-is-visible
              data-input-focused={isFocused}
            />
          ) : (
            <Pen
              className={classes.commentInputIcon}
              data-is-visible={!!inputStr}
            />
          )}
        </button>
        {isFocused && (
          <Tooltip
            clientEl={inputRef.current}
            portalEl={document.body}
            position={TOOLTIP_POSITION}
            onClickOutside={blur}
          >
            <div className={classes.tooltipContent}>
              {disabled && (
                <div className={classes.tooltipIconContainer} onClick={blur}>
                  <DownArrowFill
                    className={classes.commentInputIcon}
                    data-is-visible
                    data-input-focused
                  />
                </div>
              )}
              <textarea
                ref={tooltipInputRef}
                className={classes.tooltipTextarea}
                rows={6}
                value={inputStr}
                placeholder={placeholder}
                onChange={updateInputStr}
                disabled={disabled}
                onKeyDown={closeOnEsc}
              />
            </div>
          </Tooltip>
        )}
      </>
    );
  }
);
CommentInput.propTypes = {
  classes: PropTypes.object.isRequired,
  dataStr: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  delay: PropTypes.number,
  className: PropTypes.string,
  header: PropTypes.string,
  disabled: PropTypes.bool
};
CommentInput.defaultProps = {
  placeholder: '',
  delay: 250,
  className: '',
  header: '',
  disabled: false
};

export default injectSheet(styles)(CommentInput);
