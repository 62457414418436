import gql from 'graphql-tag';

export default {
  updateWorkflowDocumentation: gql`
    mutation updateWorkflowDocumentation(
      $rowId: BigInt!
      $url: String
      $fileDigest: String
    ) {
      updateWorkflowDocumentationByRowId(
        input: { rowId: $rowId, url: $url, fileDigest: $fileDigest }
      ) {
        workflow {
          rowId
          documentation {
            url
            file {
              name
              size
              digest
            }
          }
        }
      }
    }
  `
};
