import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import largeIcon from 'assets/loader_large.gif';
import smallIcon from 'assets/loader_small.gif';

import styles from './traceIconSpinner.style';

export class TraceIconSpinner extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    className: PropType.string,
    small: PropType.bool
  };

  static defaultProps = {
    className: '',
    small: false
  };

  render() {
    const { classes, small, className } = this.props;
    const img = small ? smallIcon : largeIcon;
    return <img src={img} alt="" className={className || classes.root} />;
  }
}

export default injectSheet(styles)(TraceIconSpinner);
