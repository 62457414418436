import React from 'react';

import PropTypes from 'prop-types';

import { Widget } from 'components/ui/widget';

import BoxSectionContext from './context';

export const BoxItem = React.memo(({ index, item, data }) => (
  <BoxSectionContext.Provider value={{ index }}>
    <Widget widget={item} data={data} />
  </BoxSectionContext.Provider>
));

export const BoxView = React.memo(({ view, data }) => (
  <div>
    {view.sections &&
      view.sections.map((item, index) => (
        <BoxItem
          key={item.view.title}
          index={index}
          item={item}
          data={data}
          sections={view.sections}
        />
      ))}
  </div>
));

export default {
  component: BoxView
};

BoxView.propTypes = {
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

BoxItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};
