export default theme => ({
  comment: {
    display: 'flex'
  },
  commentAvatar: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.grey7,
    minWidth: 30,
    height: 30,
    borderRadius: '50%',
    position: 'relative',
    top: 5
  },
  commentContainer: {
    width: '100%',
    margin: '0 13px',
    minHeight: 70
  },
  userId: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center'
  },
  userName: {
    color: theme.grey1,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '23px',
    marginRight: 10
  },
  userGroup: {
    color: theme.grey2,
    fontSize: 12,
    paddingLeft: 10,
    borderLeft: `1px solid ${theme.grey4}`,
    lineHeight: '15px'
  },
  link: {
    padding: '2px 0',
    color: theme.grey3,
    fontSize: 10,
    fontWeight: 'normal',
    lineHeight: '24px',
    textDecoration: 'none',
    userSelect: 'none'
  },
  inactiveLink: {
    pointerEvents: 'none'
  },
  action: {
    color: theme.blue800,
    backgroundColor: theme.blue50,
    padding: '5px 10px',
    borderRadius: 14,
    marginRight: 8,
    '&:hover': {
      outline: `solid 1px ${theme.blue800}`
    }
  },
  date: {
    padding: '2px 0',
    color: theme.grey3,
    fontSize: 10,
    fontWeight: 'normal',
    lineHeight: '24px'
  },
  commentRoot: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.grey1,
    '& h1': {
      fontSize: 18,
      fontWeight: 700,
      color: theme.grey1
    },
    '& strong': {
      fontWeight: 700,
      color: theme.grey1
    }
  },
  files: {
    color: theme.grey3,
    fontSize: 12,
    position: 'relative',
    paddingLeft: 16
  },
  paperClip: {
    height: 16,
    transform: 'rotate(30deg)',
    position: 'absolute',
    left: -10,
    top: 2
  },
  answersButton: {
    color: '#B4B1BD',
    textDecoration: 'underline',
    fontSize: 12,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center'
  },
  answersButtonIcon: {
    width: 12,
    height: 12,
    marginLeft: 4,
    transition: 'transform 0.2s ease-in-out',
    '&[data-is-collapsed="false"]': {
      transform: 'rotate(-180deg)'
    }
  },
  answer: {
    display: 'flex',
    backgroundColor: '#F8F8F8',
    marginTop: 10,
    marginBottom: 10,
    padding: 15
  },
  answerAvatar: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.grey9,
    minWidth: 20,
    height: 20,
    borderRadius: '50%',
    position: 'relative',
    top: 5
  },
  answerContainer: {
    width: '100%',
    paddingBottom: 17,
    margin: '0 13px',
    minHeight: 70
  },
  answerUserName: {
    color: theme.grey1,
    fontSize: 12,
    fontWeight: 700,
    marginRight: 10
  },
  answerUserGroup: {
    color: theme.grey2,
    fontSize: 10,
    fontWeight: 400,
    paddingLeft: 10,
    borderLeft: `1px solid ${theme.grey2}`
  },
  answerDate: {
    color: theme.grey2,
    fontSize: 10,
    fontWeight: 400
  },
  answerRoot: {
    fontSize: 12,
    lineHeight: '13px',
    fontWeight: 500,
    color: theme.grey1,
    '& h1': {
      fontSize: 14,
      fontWeight: 700,
      color: theme.grey1
    },
    '& strong': {
      fontWeight: 700,
      color: theme.grey1
    },
    '& li': {
      position: 'relative',
      paddingLeft: 22,
      fontSize: 11,
      '&::before': {
        position: 'absolute',
        content: '"•"',
        color: theme.indigo3,
        fontWeight: 'bold',
        width: '1em',
        marginLeft: '-1em'
      }
    }
  }
});
