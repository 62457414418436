import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment';

import { Reset, ArrowRight } from '@stratumn/icons';

import Tooltip from 'components/ui/utils/tooltip';
import { useFocus } from 'utils/hooks';

import styles from './dateRange.style';

const FILTER_PLACEHOLDER = 'Select a date range';
const START_DATE_INPUT_PLACEHOLDER = 'Start Date';
const END_DATE_INPUT_PLACEHOLDER = 'End Date';
const TOOLTIP_POSITION = {
  place: 'below',
  placeShift: 10,
  adjustPlace: true
};
const START_DATE_FOCUS = 'startDate';
const END_DATE_FOCUS = 'endDate';
const MINIMUM_VALID_DATE_INPUT = moment.utc('01-01-1000', 'DD-MM-YYYY', true);
const isValidDateInput = dateInput =>
  !dateInput || (dateInput.isValid() && dateInput >= MINIMUM_VALID_DATE_INPUT);

const voidFunction = () => {};

// date range filter
export const DateRangeFilter = React.memo(
  ({ classes, colConfig, setColumnFilter, filterConfig }) => {
    // check the filter value from column config
    let filterValue = {};
    let initialFocus = START_DATE_FOCUS;
    if (colConfig.filter) {
      // check if the filter value type is correct (eg if the config changed the filter value type may change...)
      const filterConfigValue = colConfig.filter.value;
      if (
        !!filterConfigValue &&
        typeof filterConfigValue === 'object' &&
        !Array.isArray(filterConfigValue)
      ) {
        filterValue = filterConfigValue;
        // if start date is set initially and end date is not, focus end date immediately
        if (!!filterValue.startDate && !filterValue.endDate) {
          initialFocus = END_DATE_FOCUS;
        }
      }
    }

    // show date range picker
    const [showPicker, openPicker, closePicker, btnRef] = useFocus(false, true);

    // start and end dates are strings
    const [{ startDate, endDate }, setDates] = useState(filterValue);

    // start and end dates input strings, typed manually, before being validated as real dates
    const startDateInputRef = useRef(null);
    const endDateInputRef = useRef(null);
    const [
      { startDate: startDateInputStr, endDate: endDateInputStr },
      setDatesInputStr
    ] = useState(filterValue);

    // state for which date the picker should focus on
    const [datePickerFocus, setDatePickerFocus] = useState(initialFocus);

    // Update state when filter value changes by save view
    useEffect(() => {
      const { startDate: newStartDate, endDate: newEndDate } =
        colConfig.filter?.value || {};
      setDates({ startDate: newStartDate, endDate: newEndDate });
      setDatesInputStr({ startDate: newStartDate, endDate: newEndDate });
    }, [colConfig.filter]);

    // handle inputs refs and set date focus (both input and picker)
    const setDateFocus = useCallback(
      focus => {
        setDatePickerFocus(focus);
        if (focus === START_DATE_FOCUS) {
          if (startDateInputRef.current) startDateInputRef.current.focus();
        } else if (endDateInputRef.current) endDateInputRef.current.focus();
      },
      [startDateInputRef, endDateInputRef]
    );

    // receive new set of dates object (from date picker or from parsed string inputs)
    const onDatesChange = useCallback(
      newDates => {
        ReactDOM.unstable_batchedUpdates(() => {
          // read the new dates
          const { startDate: newStartDate, endDate: newEndDate } = newDates;

          // cast them to string
          const newStartDateStr = newStartDate
            ? newStartDate.format(filterConfig.format)
            : undefined;
          const newEndDateStr = newEndDate
            ? newEndDate.format(filterConfig.format)
            : undefined;

          // update component display state
          const newDatesStr = {
            startDate: newStartDateStr,
            endDate: newEndDateStr
          };
          setDates(newDatesStr);
          setDatesInputStr(newDatesStr);

          // what to focus next ? this depends on the new setup
          switch (true) {
            case !!newStartDateStr && !!newEndDateStr:
              // both dates are set and we can close the tooltip
              // keeping the current focus
              closePicker();
              break;
            case !!newStartDateStr:
              // start date is set but not end date, focus end date
              setDateFocus(END_DATE_FOCUS);
              break;
            case !!newEndDateStr:
              // end date is set but not start date, focus start date
              setDateFocus(START_DATE_FOCUS);
              break;
            default:
            // no date set which means that we've just deleted one of them
            // or both via 'reset dates'
            // keep the current focus
          }

          // reset filter setup
          setColumnFilter(
            colConfig.key,
            !newStartDateStr && !newEndDateStr
              ? undefined
              : {
                  startDate: newStartDateStr,
                  endDate: newEndDateStr
                },
            filterConfig
          );
        });
      },
      [filterConfig]
    );

    // build a memo to get start and end date objects
    const { startDateObj, endDateObj } = useMemo(
      () => ({
        startDateObj: startDate
          ? moment.utc(startDate, filterConfig.format, true)
          : undefined,
        endDateObj: endDate
          ? moment.utc(endDate, filterConfig.format, true)
          : undefined
      }),
      [startDate, endDate, filterConfig]
    );

    // handling start and end dates direct input fields
    const onStartDateInputStrChange = useCallback(
      e => {
        // read the new input string and convert it to a date
        const newStartDateStr = e.target.value;
        const newStartDateObj = newStartDateStr
          ? moment.utc(newStartDateStr, filterConfig.format, true)
          : undefined;

        // only update main dates if this is a valid date
        if (isValidDateInput(newStartDateObj)) {
          onDatesChange({
            startDate: newStartDateObj,
            endDate: endDateObj
          });
        } else {
          // otherwise update only dates inputs strings
          setDatesInputStr({
            startDate: newStartDateStr,
            endDate
          });
        }
      },
      [endDate, endDateObj, filterConfig, onDatesChange]
    );

    const onEndDateInputStrChange = useCallback(
      e => {
        // read the new input string and convert it to a date
        const newEndDateStr = e.target.value;
        const newEndDateObj = newEndDateStr
          ? moment.utc(newEndDateStr, filterConfig.format, true)
          : undefined;

        // only update main dates if this is a valid date
        if (isValidDateInput(newEndDateObj)) {
          onDatesChange({
            startDate: startDateObj,
            endDate: newEndDateObj
          });
        } else {
          // otherwise update only dates inputs strings
          setDatesInputStr({
            startDate,
            endDate: newEndDateStr
          });
        }
      },
      [startDate, startDateObj, filterConfig, onDatesChange]
    );

    // This renders the Calendar section where we can clear the dates
    const renderCalendarInfo = useCallback(
      () => (
        <div
          className={classes.calendarInfoWrapper}
          onClick={() => {
            onDatesChange({});
          }}
          data-cy="reset-dates-button"
        >
          <Reset size={14} />
          <span className={classes.calendarInfoText}>Reset dates</span>
        </div>
      ),
      [onDatesChange]
    );

    const buttonLabel = useMemo(() => {
      switch (true) {
        case !!startDate && !!endDate:
          return startDate === endDate
            ? startDate
            : `${startDate} - ${endDate}`;
        case !!startDate:
          return `>= ${startDate}`;
        case !!endDate:
          return `<= ${endDate}`;
        default:
          return filterConfig.placeholder || FILTER_PLACEHOLDER;
      }
    }, [filterConfig, startDate, endDate]);

    return (
      <>
        <button
          ref={btnRef}
          className={classes.button}
          data-is-set={!!startDate || !!endDate}
          data-is-focused={showPicker}
          onClick={openPicker}
          data-cy="filter-button"
        >
          <span className={classes.buttonLabel} title={buttonLabel}>
            {buttonLabel}
          </span>
        </button>
        {showPicker && (
          <Tooltip
            clientEl={btnRef.current}
            portalEl={document.body}
            position={TOOLTIP_POSITION}
            onClickOutside={closePicker}
          >
            <div className={classes.tooltipContent}>
              <div className={classes.dayPickerInputs}>
                <div className={classes.dayPickerDate}>
                  <input
                    type="search"
                    ref={startDateInputRef}
                    className={classes.dayPickerDateInput}
                    placeholder={START_DATE_INPUT_PLACEHOLDER}
                    value={startDateInputStr || ''}
                    onChange={onStartDateInputStrChange}
                    onFocus={() => setDatePickerFocus(START_DATE_FOCUS)}
                    data-cy="start-date-input"
                  />
                  {datePickerFocus === START_DATE_FOCUS && (
                    <div className={classes.dayPickerDateFocusedIndicator} />
                  )}
                </div>
                <ArrowRight className={classes.dayPickerInputsArrow} />
                <div className={classes.dayPickerDate}>
                  <input
                    type="search"
                    ref={endDateInputRef}
                    className={classes.dayPickerDateInput}
                    placeholder={END_DATE_INPUT_PLACEHOLDER}
                    value={endDateInputStr || ''}
                    onChange={onEndDateInputStrChange}
                    onFocus={() => setDatePickerFocus(END_DATE_FOCUS)}
                    data-cy="end-date-input"
                  />
                  {datePickerFocus === END_DATE_FOCUS && (
                    <div className={classes.dayPickerDateFocusedIndicator} />
                  )}
                </div>
              </div>
              <div className={classes.dayPickerRangeContainer}>
                <DayPickerRangeController
                  startDate={startDateObj}
                  endDate={endDateObj}
                  onDatesChange={onDatesChange}
                  focusedInput={datePickerFocus}
                  onFocusChange={voidFunction} // component re-focus after date selection is handled in onDatesChange
                  initialVisibleMonth={null}
                  hideKeyboardShortcutsPanel
                  enableOutsideDays
                  numberOfMonths={2}
                  renderCalendarInfo={renderCalendarInfo}
                  noBorder
                  minimumNights={0}
                />
              </div>
            </div>
          </Tooltip>
        )}
      </>
    );
  }
);
DateRangeFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  colConfig: PropTypes.object.isRequired,
  setColumnFilter: PropTypes.func.isRequired,
  filterConfig: PropTypes.object.isRequired
};
DateRangeFilter.defaultProps = {};

export default injectSheet(styles)(DateRangeFilter);
