import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';
import classNames from 'classnames';
import { SFTickCircle } from '@stratumn/icons';
import styles from './radioButtonsGroup.style';

const RadioButtonsGroup = ({
  tabs,
  value,
  loading,
  showSuccess,
  onChange,
  classes
}) => {
  const handleTabClick = useCallback(
    async tab => {
      onChange(tab);
    },
    [onChange]
  );

  return (
    <div className={classes.mainContainer}>
      <div className={classes.tabContainer}>
        {tabs.map(tab => {
          const tabSelected = value.toLowerCase() === tab.toLowerCase();
          return (
            <button
              className={classNames({
                [classes.tab]: true,
                [classes.tabSelected]: tabSelected,
                [classes.tabLoading]: loading && tabSelected
              })}
              key={tab}
              disabled={loading || tabSelected}
              onClick={() => handleTabClick(tab)}
            >
              {tab === 'Custom' ? 'Default' : tab}
            </button>
          );
        })}
      </div>
      {showSuccess && !loading && (
        <SFTickCircle
          className={classNames(classes.statusIcon, classes.successIcon)}
        />
      )}
    </div>
  );
};

RadioButtonsGroup.propTypes = {
  tabs: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  showSuccess: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

RadioButtonsGroup.defaultProps = {
  loading: false,
  showSuccess: false
};

export default compose(injectSheet(styles))(RadioButtonsGroup);
