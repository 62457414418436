import jmespath from 'jmespath';
import set from 'lodash.set';
import unset from 'lodash.unset';

/* getter */
export const getByPath = (data, path) => {
  if (data === undefined || path === undefined) {
    return undefined;
  }
  if (path === '') {
    return data;
  }
  const value = jmespath.search(data, path);
  if (value === null) {
    return undefined; // unifying the returned value if no path or if path does not exist in object
  }
  return value;
};

/* setter */
export const setAtPath = (data, path, value) => {
  if (path === undefined) {
    return;
  }

  // do the actualisation
  if (value === undefined) {
    // remove the key from the data
    unset(data, path);
  } else {
    set(data, path, value);
  }
};

/* setter */
export const setAtKey = (data, key, value) => {
  if (key === undefined) {
    return;
  }

  // do the actualisation
  if (value === undefined) {
    // remove the key from the data
    delete data[`${key}`];
  } else {
    data[`${key}`] = value;
  }
};

// check if a number satisfies a boundaries condition
export const numberSatisfiesCondition = (nb, condition) => {
  if (condition.equal !== undefined && nb !== condition.equal) {
    return false;
  }
  if (condition.notEqual !== undefined && nb === condition.notEqual) {
    return false;
  }
  if (condition.minimum !== undefined && nb < condition.minimum) {
    return false;
  }
  if (
    condition.exclusiveMinimum !== undefined &&
    nb <= condition.exclusiveMinimum
  ) {
    return false;
  }
  if (condition.maximum !== undefined && nb > condition.maximum) {
    return false;
  }
  if (
    condition.exclusiveMaximum !== undefined &&
    nb >= condition.exclusiveMaximum
  ) {
    return false;
  }
  return true;
};

// find style to apply to a given number, last match will apply
export const getNumberConditionalStyle = (nb, conditionalStyles) => {
  let foundStyle = {};
  if (!!conditionalStyles && nb !== undefined) {
    conditionalStyles.forEach(conditionalStyle => {
      if (
        !conditionalStyle.condition || // no condition  means always apply this style...
        numberSatisfiesCondition(nb, conditionalStyle.condition)
      ) {
        foundStyle = conditionalStyle.style;
      }
    });
  }
  return foundStyle;
};

export const stringifyData = (data, defaultValue) => {
  if (typeof data === 'object') {
    return JSON.stringify(data, null, 2);
  }
  if (data !== undefined) {
    return data.toString();
  }
  if (defaultValue !== undefined) {
    return defaultValue;
  }
  return data;
};
