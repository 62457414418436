export default theme => ({
  root: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.grey5}`,
    borderRadius: 14,
    overflow: 'hidden',
    height: 30,
    maxWidth: 540,
    color: theme.grey3,
    paddingLeft: 7,
    flexGrow: 1,
    transition: 'border-color 100ms linear'
  },
  hasFocus: {
    borderColor: theme.indigo3,
    color: theme.grey2
  },
  icon: {
    width: 18,
    height: 18,
    fill: 'currentColor',
    marginRight: 7
  },
  input: {
    backgroundColor: 'transparent',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    padding: '12px 0',
    borderWidth: 0,
    color: theme.grey2,
    marginRight: 7,
    flexGrow: 1,
    '&::placeholder': {
      color: theme.grey3
    },
    '&:focus': {
      outline: 'none',
      color: theme.grey1
    }
  }
});
