import { TYPE_ORGANIZATION, TYPE_TEAM } from 'constant/account';

export const parseEntities = memberOfNodes => {
  const teams = [];
  const organizations = [];

  memberOfNodes.forEach(account => {
    const accountType = account.entity.__typename;
    if (accountType === TYPE_ORGANIZATION) organizations.push(account);
    if (accountType === TYPE_TEAM) teams.push(account);
  });

  let organization;
  if (organizations[0]) organization = organizations[0].entity;
  else organization = teams?.[0]?.entity?.organization;

  return {
    organization,
    teams: teams?.sort(compareName)
  };
};

const compareName = (a, b) => a.entity.name.localeCompare(b.entity.name);
