export default theme => ({
  tableHeaderElement: {
    fontSize: 12,
    color: theme.grey2,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    cursor: 'pointer',
    '&[data-is-clicked=true]': {
      color: theme.indigo3
    }
  },
  tableHeaderElementIcon: {
    display: 'block',
    flexShrink: 0,
    width: 22,
    height: 22
  },
  tableHeaderElementLabel: {
    display: 'block',
    flexGrow: 1,
    fontSize: 12,
    paddingLeft: 7,
    color: theme.grey2
  },
  displayHeaderWrapper: {
    display: 'flex',
    flexFlow: 'column nowrap'
  }
});
