export default theme => ({
  dropDownInput: {
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    flexFlow: 'row wrap',
    '& button': {
      cursor: 'pointer',
      borderRadius: 3,
      width: '100%',
      fontSize: 13,
      fontWeight: 'unset',
      color: theme.grey1,
      border: '1px solid transparent',
      padding: '5px 6px 5px 10px',
      outline: 'none',
      transition: 'border-color 150ms linear 0ms',
      backgroundColor: 'unset',
      height: 'unset',
      minWidth: 'unset',
      '&::placeholder': {
        color: theme.grey3
      },
      '&:hover': {
        backgroundColor: theme.white1,
        borderColor: theme.grey5,
        '& svg': {
          color: theme.grey3
        }
      },
      '&:focus': {
        backgroundColor: theme.white1,
        fontWeight: 'normal',
        borderColor: theme.indigo3,
        color: theme.grey2,
        '& svg': {
          color: theme.indigo3
        }
      },
      '&[data-is-open=true]': {
        backgroundColor: theme.white1,
        fontWeight: 'normal',
        color: theme.grey2,
        '& svg': {
          color: theme.indigo3
        }
      },
      '& svg': {
        color: theme.grey4
      }
    }
  },
  selectCompactInput: {
    width: '100%',
    height: 40,
    display: 'flex',
    flexFlow: 'row wrap',
    '& button': {
      height: '100%'
    }
  },
  disabledSelectInput: {
    padding: '5px 10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.grey4,
    cursor: 'not-allowed'
  }
});
