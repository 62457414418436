import React from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { getFilterComponent } from '../filters';

import styles from './tableFilters.style';

// table filters
const TableFilters = React.memo(
  ({ classes, selectBoxWidth, columns, setColumnFilter, invalidFilters }) => (
    <div className={classes.tableFilters}>
      {selectBoxWidth > 0 && (
        <div
          style={{
            width: selectBoxWidth
          }}
        />
      )}
      {columns.map((colConfig, index) => {
        // retrieve the appropriate filter component
        const filterComponent = getFilterComponent(colConfig, setColumnFilter);
        const isActive = colConfig.filter;
        return (
          <div
            key={`tableFilter_${index}`}
            className={classes.tableFilterCell}
            style={{
              width: colConfig.width
            }}
          >
            {filterComponent}
            {isActive && (
              <div
                className={classes.tableActiveFilterIndicator}
                data-filters-invalid={invalidFilters}
              />
            )}
          </div>
        );
      })}
    </div>
  )
);
TableFilters.propTypes = {
  classes: PropTypes.object.isRequired,
  selectBoxWidth: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  setColumnFilter: PropTypes.func.isRequired,
  invalidFilters: PropTypes.bool
};
TableFilters.defaultProps = {
  selectBoxWidth: 0,
  invalidFilters: false
};

export default injectSheet(styles)(TableFilters);
