export const infoConfigLocalStorageKey = id =>
  `infoDisplayConfig_Workflow_${id.toString()}`;

export const getUserInfoDisplayConfig = id => {
  try {
    const localStorageDisplayConfigStr = localStorage.getItem(
      infoConfigLocalStorageKey(id)
    );
    return localStorageDisplayConfigStr
      ? JSON.parse(localStorageDisplayConfigStr)
      : null;
  } catch (err) {
    return null;
  }
};

/**
 *
 * @param {string} id - the workflow id
 * @param {*} cfg - the configuration attached to the workflow
 * @returns - if no config, we remove the item, otherwise we set the item
 */
export const saveUserInfoDisplayConfig = (id, cfg) => {
  const lsKey = infoConfigLocalStorageKey(id);
  if (!cfg) {
    localStorage.removeItem(lsKey);
    return;
  }
  localStorage.setItem(lsKey, JSON.stringify(cfg));
};

/**
 * @function sectionsLocalStorage
 * @param {array} sections - from the trace info
 * @returns a new array with a property isCollapsed
 */
export const sectionsLocalStorage = sections =>
  sections.reduce((acc, _curr, idx) => {
    // First section is opened by default
    acc.push({ isCollapsed: idx !== 0 });
    return acc;
  }, []);

/**
 * @function manageLocalStorage
 * @param {object} trace
 * @param {string} trace.rowId
 * @param {object} trace.workflow
 * @param {string} trace.workflow.rowId
 * @param {object} payload
 * @param {number} payload.index
 * @param {boolean} payload.isCollapsed
 * @returns a callback to save a new config in localStorage
 * */
export const manageLocalStorage = (trace, { index, isCollapsed }) => {
  const { rowId: traceId, workflow } = trace;
  const { rowId } = workflow;

  if (
    !workflow?.config?.info?.view?.sections ||
    workflow?.config?.info?.view?.sections.length === 0
  )
    return;

  const wfUserDisplayConfig = getUserInfoDisplayConfig(rowId);

  // Check if entry in the localStorage already exists for this workflow and trace
  const newSections =
    wfUserDisplayConfig && wfUserDisplayConfig[traceId]
      ? [...getUserInfoDisplayConfig(rowId)[traceId]]
      : sectionsLocalStorage(workflow?.config?.info?.view?.sections);

  newSections[index].isCollapsed = isCollapsed;

  const newConfig = {
    ...(getUserInfoDisplayConfig(rowId) && getUserInfoDisplayConfig(rowId)),
    [traceId]: newSections
  };

  saveUserInfoDisplayConfig(rowId, newConfig);
};
