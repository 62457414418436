export default () => ({
  dropZoneWrapper: {
    minWidth: 336
  },
  fieldTextWrapper: {
    width: '100%',
    marginTop: -21 // cheat to visually center the input field
  },
  fileImportWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  fileImportRemoveButton: {
    marginTop: 20,
    '& > button': {
      width: '100%',
      justifyContent: 'center'
    }
  }
});
