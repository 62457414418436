import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import styles from './groupsTaskListItemError.style';

// group task list item error (missing trace) of the user dashboard
export const GroupsTaskListItemError = ({ classes, title }) => (
  <div className={classes.root}>
    <div>
      <strong>Error:</strong> {title}
    </div>
  </div>
);

GroupsTaskListItemError.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default injectSheet(styles)(GroupsTaskListItemError);
