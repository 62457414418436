/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from 'react';
import PropTypes from 'prop-types';

import compose from 'lodash.flowright';

import { saveUserDisplayConfig } from 'components/workflowOverview/utils';

import injectSheet from 'react-jss';
import classNames from 'classnames';

import {
  Dropdown,
  OptionDrop,
  Pushbutton,
  Modal,
  ModalContent,
  FieldTextCompact,
  ModalActions
} from '@stratumn/atomic';

import { withWorkflowOverviewContext } from 'components/workflowOverview/context';

import DynamicIcon from 'components/ui/dynamicIcon';
import styles from './savedViewsMenu.style';

// display menu
export const SavedViewsMenu = ({
  classes,
  saveConfig,
  context,
  updateUserDisplayConfig,
  resetDisplayConfig,
  setVisible
}) => {
  const {
    savedViewsArray,
    updateSavedViewsArray,
    userDisplayConfig,
    workflowId
  } = context;

  // Modals visible state
  const [creationModalVisible, setCreationModalVisible] = useState(false);
  const [savePromptModalVisible, setSavePromptModalVisible] = useState(false);

  // Save mode (create VS update)
  const [saveMode, setSaveMode] = useState('create');

  // State variable to copy the view currently edited (renamed)
  const [viewToEdit, setViewToEdit] = useState(undefined);

  // State variable to store the last selected view
  const [selectedView, setSelectedView] = useState(undefined);

  // State variable to store the vewId to replace (when save mode is update)
  const [viewIdToUpdate, setViewIdToUpdate] = useState(undefined);

  // Error (to prevent duplicate names)
  const [showError, setShowError] = useState(false);

  // Get the view id that matches the current display config
  const activeViewId = useMemo(
    () =>
      savedViewsArray.find(
        view =>
          JSON.stringify(view.config) === JSON.stringify(userDisplayConfig)
      )?.id,
    [userDisplayConfig, savedViewsArray]
  );

  // Function to apply a config to the table
  const applyConfig = config => {
    // Save the current config in local storage using workflowOverview utils
    saveUserDisplayConfig(workflowId, config);
    // Apply the config to the overview table using workflowOverview context
    updateUserDisplayConfig(config);
  };

  // Event handler when the user clicks on a view in the menu
  const handleClickView = e => {
    const id = Number(e.currentTarget.getAttribute('data-id'));
    const view = savedViewsArray.find(savedView => savedView.id === id);
    if (view.id === selectedView?.id) {
      updateUserDisplayConfig(null);
      setSelectedView(undefined);
      return;
    }
    // Save the selected view in a state variable
    setSelectedView(view);
    // If the current display doesn't match an existing view or isn't the default config, ask the user if he wants to save it first
    if (!activeViewId && userDisplayConfig) {
      setSavePromptModalVisible(true);
    } else {
      // If not, apply the config
      applyConfig(view.config);
    }
  };

  // Event handler when the users clicks on the delete button
  const handleDeleteView = e => {
    e.stopPropagation();
    const idToDelete = Number(e.currentTarget.value);
    // If the list deleted is currently active, reset the table display and close the menu
    if (activeViewId === idToDelete) {
      if (resetDisplayConfig) resetDisplayConfig();
      setVisible(false);
    }
    // Update the list of views
    updateSavedViewsArray(
      savedViewsArray.filter(view => view.id !== idToDelete)
    );
  };

  // Event handler when the user clicks on the edit button
  const handleRenameView = e => {
    e.stopPropagation();
    const id = e.currentTarget?.value && Number(e.currentTarget?.value);
    toggleCreateViewModal(id);
  };

  // Function to display or hide the creation modal
  const toggleCreateViewModal = id => {
    setCreationModalVisible(!creationModalVisible);
    // If an id is providerd this means that we are editing (ie renaming) a view
    if (id) {
      setViewToEdit(savedViewsArray.find(view => view.id === id));
    } else {
      // Set the form to its default state
      setViewIdToUpdate(undefined);
      setViewToEdit(undefined);
      setSaveMode('create');
      setShowError(false);
    }
  };

  // Event handler for the dropdown to change Save mode
  const handleChangeSaveMode = e => {
    setSaveMode(e.target.value);
  };

  // Event handler for the name text input
  const handleChangeName = e => {
    setViewToEdit({ ...viewToEdit, name: e.target.value });
  };

  // Function (can be used as event handler) to save the view (when the modal form is completed)
  const handleSaveView = () => {
    const { name, id } = viewToEdit;
    // Check if name already exist to prevent duplicates
    if (savedViewsArray.find(view => view.name === name)) {
      setShowError(true);
    } else if (id) {
      // If the view has an id update the existing view in the array
      const updatedViews = savedViewsArray.map(view =>
        view.id === id ? viewToEdit : view
      );
      updateSavedViewsArray(updatedViews);
      setCreationModalVisible(false);
    } else {
      // If not, save the current config
      saveConfig(name);
      setCreationModalVisible(false);
      // If a view was initialy selected by the user (before saving the current one), apply it
      if (selectedView) {
        applyConfig(selectedView.config);
      }
    }
  };

  // Event handler for the dropdown to change the view to update
  const handleChangeViewIdToUpdate = e => {
    setViewIdToUpdate(Number(e.target.value));
  };

  // Event handler tu update a view (when the modal form is completed)
  const handleUpdateView = () => {
    const updatedViews = savedViewsArray.map(view =>
      view.id === viewIdToUpdate ? { ...view, config: userDisplayConfig } : view
    );
    updateSavedViewsArray(updatedViews);
    setCreationModalVisible(false);
  };

  // Name input ref
  const nameInputRef = useRef(null);
  // Event handler to detect keypress
  const handleEnterKey = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSaveView();
      }
    },
    [viewToEdit]
  );
  // When the create view modal is shown, focus the input
  useEffect(() => {
    if (creationModalVisible && saveMode === 'create') {
      setTimeout(() => {
        nameInputRef.current?.focus();
      }, 0);
    }
  }, [creationModalVisible, saveMode]);
  // When the event handler changes re-attach it to the event listener
  useEffect(() => {
    const input = nameInputRef.current;
    input?.addEventListener('keypress', handleEnterKey, true);
    return () => {
      input?.removeEventListener('keypress', handleEnterKey, true);
    };
  }, [handleEnterKey]);

  // Confirmation modal / Event handler for the button to apply a view (from the confirmation modal)
  const handleApplyNow = () => {
    applyConfig(selectedView.config);
    setSavePromptModalVisible(false);
  };
  // Confirmation modal / Event handler for the button to save before applying
  const handleSaveThenApply = () => {
    setCreationModalVisible(true);
    setSavePromptModalVisible(false);
  };
  const handleCollapsePromptModal = () => {
    setSavePromptModalVisible(false);
  };

  return (
    <>
      <div className={classes.savedViewsMenuTitle}>Saved views</div>
      <ul className={classes.savedViewsMenuList}>
        {savedViewsArray?.map(({ id, name }) => (
          <li
            className={classNames({
              [classes.savedViewsMenuItem]: true,
              [classes.savedViewsMenuItemSelected]: activeViewId === id
            })}
            key={id}
            data-id={id}
            onClick={handleClickView}
          >
            <div className={classes.savedViewsMenuItemName} title={name}>
              <DynamicIcon
                icon={`lucide_${activeViewId === id ? 'Eye' : 'EyeOff'}`}
                size={14}
              />
              <span>{name}</span>
            </div>
            <button
              className={classes.savedViewsMenuItemIconButton}
              data-cy="edit-button"
              title="Rename view"
              value={id}
              onClick={handleRenameView}
            >
              <DynamicIcon icon="lucide_Pen" size={14} />
            </button>
            <button
              className={classes.savedViewsMenuItemIconButton}
              data-cy={`delete-button-${id}`}
              title="Delete view"
              value={id}
              onClick={handleDeleteView}
            >
              <DynamicIcon icon="lucide_Trash" size={14} />
            </button>
          </li>
        ))}
      </ul>
      <div className={classes.saveViewButtonContainer}>
        <button
          className={classes.saveViewButton}
          disabled={!!activeViewId}
          onClick={toggleCreateViewModal}
        >
          <DynamicIcon icon="lucide_Save" size={14} />
          Save current view
        </button>
      </div>

      {/* CREATION MODAL */}
      {creationModalVisible && (
        <Modal
          title={!viewToEdit?.id ? 'Save view' : 'Rename view'}
          handleCollapse={toggleCreateViewModal}
          closeButtonLabel="Cancel"
        >
          <ModalContent>
            {!viewToEdit?.id && savedViewsArray?.length > 0 && (
              <div className={classes.savedModeDromDown}>
                <Dropdown
                  label="Create new view or update existing one?"
                  hideLabel
                  onValueChange={handleChangeSaveMode}
                  value={saveMode}
                >
                  <OptionDrop
                    label="Create new view"
                    value="create"
                    selected={saveMode === 'create'}
                  />
                  <OptionDrop
                    label="Update existing view"
                    value="update"
                    selected={saveMode === 'update'}
                  />
                </Dropdown>
              </div>
            )}
            {(viewToEdit?.id || saveMode === 'create') && (
              <FieldTextCompact
                label="View name"
                value={viewToEdit?.name}
                onValueChange={handleChangeName}
                inputRef={ref => {
                  nameInputRef.current = ref;
                }}
                disabled={!saveMode === 'Create new view'}
                invalid={showError}
              />
            )}
            {showError && (
              <div className={classes.errorMessage}>
                Invalid name: View name already exists
              </div>
            )}
            {saveMode === 'update' && (
              <Dropdown
                onValueChange={handleChangeViewIdToUpdate}
                label="Select the view you want to update"
                hideLabel
              >
                {savedViewsArray.map(config => (
                  <OptionDrop
                    key={config.id}
                    value={config.id.toString()}
                    label={config.name}
                    selected={config.id === viewIdToUpdate}
                  />
                ))}
              </Dropdown>
            )}
          </ModalContent>
          <ModalActions>
            <Pushbutton onClick={toggleCreateViewModal}>cancel</Pushbutton>
            <Pushbutton
              primary
              disabled={
                saveMode === 'update' ? !viewIdToUpdate : !viewToEdit?.name
              }
              onClick={
                saveMode === 'update' ? handleUpdateView : handleSaveView
              }
            >
              {saveMode === 'update'
                ? 'Update'
                : !viewToEdit?.id
                ? 'Create'
                : 'Rename'}
            </Pushbutton>
          </ModalActions>
        </Modal>
      )}

      {/* CONFIRMATION MODAL */}
      {savePromptModalVisible && (
        <Modal
          title={
            <>
              Apply <strong>{selectedView.name}</strong>?
            </>
          }
          handleCollapse={handleCollapsePromptModal}
          closeButtonLabel={`Apply ${selectedView.name} now`}
        >
          <ModalContent>
            <p className={classes.promptMessage}>
              You&apos;re about to lose your current table view.
              <br /> Do you want to save it before applying{' '}
              <strong>{selectedView.name}</strong>?
            </p>
          </ModalContent>
          <ModalActions>
            <Pushbutton secondary onClick={handleApplyNow}>
              Apply now
            </Pushbutton>
            <Pushbutton primary onClick={handleSaveThenApply}>
              Save current view then apply
            </Pushbutton>
          </ModalActions>
        </Modal>
      )}
    </>
  );
};

SavedViewsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  saveConfig: PropTypes.func.isRequired,
  updateUserDisplayConfig: PropTypes.func.isRequired,
  resetDisplayConfig: PropTypes.func,
  setVisible: PropTypes.func.isRequired,
  context: PropTypes.shape({
    savedViewsArray: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateSavedViewsArray: PropTypes.func.isRequired,
    userDisplayConfig: PropTypes.object,
    workflowId: PropTypes.string.isRequired
  }).isRequired
};

SavedViewsMenu.defaultProps = {
  resetDisplayConfig: null
};

export default compose(
  injectSheet(styles),
  withWorkflowOverviewContext
)(SavedViewsMenu);
