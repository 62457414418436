export default theme => ({
  ellipsisContainer: {
    overflow: 'hidden'
  },
  info: {
    cursor: 'default'
  },
  infoTooltipArrow: {
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: `6px solid ${theme.white1}`
  },
  infoTooltip: {
    backgroundColor: theme.white1,
    boxShadow: theme.indigoShadow2,
    borderRadius: 5,
    padding: '15px 20px 10px 20px',
    display: 'flex',
    flexFlow: 'column nowrap'
  }
});
