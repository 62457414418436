import {
  ENTITY_TYPE_USER,
  ENTITY_TYPE_BOT,
  ENTITY_TYPE_TEAM,
  ENTITY_TYPE_ORGANIZATION
} from 'gql/types';

// tool to sort participants by name
const sortParticipants = (participant1, participant2) => {
  const name1 = participant1.entity.name || '';
  const name2 = participant2.entity.name || '';
  return name1.localeCompare(name2);
};
// tool to sort teams by name
const sortTeams = (team1, team2) => team1.name.localeCompare(team2.name);

// from the group members query get the teams and 'other participants' (ie single users and bots)
export const getGroupParticipants = groupMembers => {
  // use maps to check for unicity of teams and other participants
  const teams = {};
  const otherParticipants = {};

  // this set is to count unique participants (across teams and 'others')
  const participantKeys = new Set();

  groupMembers.forEach(groupMember => {
    const {
      account: { rowId: accountId, entity }
    } = groupMember;

    const entityKey = `${entity.__typename}-${entity.rowId}`;
    switch (entity.__typename) {
      case ENTITY_TYPE_USER:
      case ENTITY_TYPE_BOT: {
        // register the user or bot
        if (!otherParticipants[entityKey]) {
          otherParticipants[entityKey] = {
            accountId,
            entity
          };
          participantKeys.add(entityKey);
        }
        break;
      }
      case ENTITY_TYPE_TEAM:
      case ENTITY_TYPE_ORGANIZATION: {
        // note: in terms of display, treat organization as a unique team
        // register the team
        if (!teams[entityKey]) {
          const {
            name: teamName,
            avatar: teamAvatar,
            members: { nodes: teamUsers },
            bots: { nodes: teamBots }
          } = entity;

          teams[entityKey] = {
            accountId: accountId,
            name: teamName,
            avatar: teamAvatar
          };

          // parse its users and bots
          // and build its participants list
          const teamParticipants = [];
          teamUsers.forEach(({ user }) => {
            teamParticipants.push({ entity: user });
            participantKeys.add(`${ENTITY_TYPE_USER}-${user.rowId}`);
          });
          teamBots.forEach(bot => {
            teamParticipants.push({ entity: bot });
            participantKeys.add(`${ENTITY_TYPE_BOT}-${bot.rowId}`);
          });

          // sort them by name ascending
          teams[entityKey].participants = teamParticipants.sort(
            sortParticipants
          );
        }
        break;
      }
      default:
        throw Error(`Unknown entity type ${entity.__typename}`);
    }
  });

  // sort teams and 'other participants' by their name
  return {
    teams: Object.values(teams).sort(sortTeams),
    otherParticipants: Object.values(otherParticipants).sort(sortParticipants),
    nbParticipants: participantKeys.size
  };
};
