import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { DATA_EDITOR_UPDATE } from 'constant/dataEditor';

import { deepGet } from 'utils';
import { getByPath } from 'utils/widgets';
import { Widget } from 'components/ui/widget';
import InlineStyle from 'components/ui/utils/inlineStyle';
import { Check } from '@stratumn/atomic';

import { withTableContext } from '../tableContext';

import GroupButton from './groupButton';
import styles from './tableCell.style';

// check whether TableCell props are equal and no re-rendering is required
const shallowCompareProps = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(key => obj1[key] === obj2[key]);
const areEqual = (prevProps, nextProps) => {
  // check style and tableContext shallow contents
  const {
    style: prevStyle,
    tableContext: prevTableContext,
    ...prevRest
  } = prevProps;
  const {
    style: nextStyle,
    tableContext: nextTableContext,
    ...nextRest
  } = nextProps;
  return (
    shallowCompareProps(prevRest, nextRest) &&
    shallowCompareProps(prevStyle, nextStyle) &&
    shallowCompareProps(prevTableContext, nextTableContext)
  );
};

/* cell component */
const TableCell = React.memo(
  ({ classes, rowIndex, columnIndex, style, tableContext }) => {
    const {
      loading,
      isFixedGrid,
      data,
      columns,
      isEditing,
      selectedData,
      allowRowsSelection,
      dataSelectorPath,
      toggleRowSelected,
      pagination,
      displayDiffs,
      group,
      edit: { modifiedData, onDataModified } = {},
      rowInlineStyle
    } = tableContext;

    if (pagination) {
      const { buffer, hasNextPage, onLoadMore } = pagination;

      if (!loading && hasNextPage && rowIndex >= data.length - buffer) {
        onLoadMore();
      }
    }
    let rowData = null;
    let groupData = null;
    let isSelectable = false;
    let groupButton = null;
    let isGroupHeaderRow = false;
    const isGroupUncollapsed =
      group && group.display && group.display.selected !== undefined;
    // We use lodash.get instead of getByPath for group indexes because getByPath yields `undefined`
    // on null values, and we need to distinguish the group.display.selected === null from the
    // group.display.selected === undefined case
    const selectedRowIndex = isGroupUncollapsed
      ? data.findIndex(
          d => deepGet(d, group.indexPath) === group.display.selected
        )
      : null;
    if (isGroupUncollapsed) {
      /**
       * If a group has been uncollapsed, its underlying rows have to be integrated in the data row count
       * e.g. with group.display.selected = 'group2' and groups = [
       *   { index: "group1", rows: ["row1", "row2"] },
       *   { index: "group2", rows: ["row3", "row4"] },
       *   { index: "group3", rows: ["row5"] }
       * ]:
       * - selectedRowIndex = 1, corresponding to the 2nd item with index 'group2'
       * - with rowIndex = 0, the row should be "group1" (which is collapsed).
       *   rowIndex - selectedRowIndex = -1, and groupRows[-1] = undefined,
       *   0 < selectedRowIndex and data[0] = "group1".
       * - with rowIndex = 1, the row should be "row3" from "group2", which is uncollapsed.
       *   Since it's the first row of the group, it should have a collapse button as well.
       *   rowIndex - selectedRowIndex = 0, and groupRows[0] = "row3"
       * - with rowIndex = 2, the row should be "row4" from "group2"
       *   rowIndex - selectedRowIndex = 1, and groupRows[1] = "row4"
       * - with rowIndex = 3, the row should be "group3" (which is collapsed)
       *   rowIndex - selectedRowIndex = 2, and groupRows[2] = undefined,
       *   3 > selectedRowIndex, rowIndex - groupRows.length + 1 = 2, and data[2] = "group3"
       */
      const selectedGroupRows = getByPath(
        data[selectedRowIndex],
        group.rowsPath
      );
      const subRowIndex = rowIndex - selectedRowIndex;
      // If the rowIndex is inside the uncollapsed group, get the underlying row
      if (selectedGroupRows[subRowIndex]) {
        isSelectable = true;
        rowData = selectedGroupRows[subRowIndex];
        if (subRowIndex === 0) {
          groupButton = (
            <GroupButton
              onToggle={() => group.toggle(group.display.selected)}
            />
          );
        }
      } else {
        let groupIndex = rowIndex;
        if (rowIndex > selectedRowIndex) {
          groupIndex += 1 - selectedGroupRows.length;
        }
        groupData = data[groupIndex];
        // Get the first row of the group for row display
        [rowData] = getByPath(groupData, group.rowsPath) || [];
        const groupRowCount = getByPath(groupData, group.rowCountPath);
        groupButton = (
          <GroupButton
            collapsed
            rowCount={groupRowCount}
            onToggle={() => group.toggle(deepGet(groupData, group.indexPath))}
          />
        );
      }
    } else if (group) {
      // If collapsed group
      groupData = data[rowIndex];
      isGroupHeaderRow = true;
      // Get the first row of the group for row display
      [rowData] = getByPath(groupData, group.rowsPath) || [];
      const groupRowCount = getByPath(groupData, group.rowCountPath);
      groupButton = (
        <GroupButton
          collapsed
          rowCount={groupRowCount}
          onToggle={() => group.toggle(deepGet(groupData, group.indexPath))}
        />
      );
    } else {
      // If regular row
      isSelectable = true;
      rowData = data[rowIndex];
    }

    // get the value of the row selector
    const rowSelector = rowData && getByPath(rowData, dataSelectorPath);

    // check if the row is selected
    const isSelected = allowRowsSelection && selectedData.includes(rowSelector);

    // provide sub-components like views and wrappers
    // with a way to let this cell know that a patch is being applied
    const [isPatched, setIsPatched] = useState(false);

    // check if selector cell
    let cellContent = null;
    const patch = modifiedData ? modifiedData[rowSelector] : null;

    if (isFixedGrid && columnIndex === 0) {
      const toggleRowSelection = useCallback(() => {
        toggleRowSelected(rowSelector);
      }, [rowSelector, toggleRowSelected]);
      const checkContent = allowRowsSelection ? (
        <>
          <Check
            label=""
            checked={isSelected}
            showLabel={false}
            handleChange={toggleRowSelection}
            disabled={!rowSelector}
          />
          {!!patch && <div className={classes.tableCellPatchIndicator} />}
        </>
      ) : (
        <div className={classes.rowIndex}>{rowIndex + 1}</div>
      );

      // Only show fillers in first columns if a group is uncollapsed
      cellContent = (
        <div className={classes.firstColumnWrapper}>
          {groupButton ||
            (isGroupUncollapsed && (
              <div className={classes.groupButtonFiller} />
            ))}
          {isSelectable
            ? checkContent
            : isGroupUncollapsed && <div className={classes.checkFiller} />}
        </div>
      );
    } else {
      // real table cell
      const { key, def } = columns[columnIndex - (isFixedGrid ? 1 : 0)];
      let { cell } = def;

      // if grouping is enabled and the group is collapsed,
      // only display the data of the specified column and display "..." in the other columns
      if (groupData && group.display.column && group.display.column !== key) {
        cell = {
          view: {
            type: 'text',
            path: "'...'"
          }
        };
      }

      // bind the onChange function to the current row selector
      const onChange = useCallback(
        modifiedCellData => {
          onDataModified({
            type: DATA_EDITOR_UPDATE,
            rowSelector,
            dataToChange: modifiedCellData
          });
        },
        [rowSelector, onDataModified]
      );

      // if no patch provided set is patched to false
      useEffect(() => {
        if (!patch) setIsPatched(false);
      }, [patch]);

      // bind an update object with the patch and the updater
      const update = useMemo(
        () => ({
          onChange: onDataModified ? onChange : null,
          patch,
          setIsPatched
        }),
        [onDataModified, onChange, patch]
      );

      if (rowData) {
        if (displayDiffs) {
          // display cell with diffs
          cellContent = (
            <div className={classes.tableCellWithDiffWrapper}>
              <div className={classes.tableCellDiffItem} data-initial-item>
                <Widget
                  widget={cell}
                  data={rowData}
                  className={classes.tableCell}
                  disableWrappers={isEditing || isGroupHeaderRow}
                />
                {isPatched && (
                  <div
                    className={classes.tableCellPatchIndicator}
                    data-initial-item
                  />
                )}
              </div>
              <div className={classes.tableCellDiffItem}>
                <Widget
                  widget={cell}
                  data={rowData}
                  update={update}
                  className={classes.tableCell}
                  disableWrappers={isEditing || isGroupHeaderRow}
                />
                {isPatched && (
                  <div className={classes.tableCellPatchIndicator} />
                )}
              </div>
            </div>
          );
        } else {
          // simple cell display
          cellContent = (
            <>
              <Widget
                widget={cell}
                data={rowData}
                update={update}
                className={classes.tableCell}
                disableWrappers={isEditing || isGroupHeaderRow}
              />
              {isPatched && <div className={classes.tableCellPatchIndicator} />}
            </>
          );
        }
        // wrap with inline style
        if (rowInlineStyle) {
          cellContent = (
            <InlineStyle
              data={rowData}
              rules={rowInlineStyle}
              style={{ height: '100%' }}
            >
              {cellContent}
            </InlineStyle>
          );
        }
      } else {
        cellContent = <div className={classes.tableCell} />;
      }
    }

    return (
      <div
        className={classes.tableCellWrapper}
        style={style}
        data-is-evenrow={!(rowIndex % 2)}
        data-is-selected={isSelected}
        data-is-patched={isPatched}
        data-cy="table-cell"
      >
        {cellContent}
      </div>
    );
  },
  areEqual
);

TableCell.propTypes = {
  classes: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnIndex: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  tableContext: PropTypes.object.isRequired
};

export default injectSheet(styles)(withTableContext(TableCell));
