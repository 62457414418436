export default {
  fileCompactView: {
    height: '100%',
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'start',
    cursor: 'pointer'
  },
  fileName: {
    flexGrow: 1,
    overflow: 'hidden',
    marginLeft: 5,
    textDecoration: 'underline',
    lineHeight: '25px'
  }
};
