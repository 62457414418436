import React, { useState, useCallback, useRef } from 'react';
import PropType from 'prop-types';

import injectSheet from 'react-jss';

import { AvatarButton, AvatarGroupButton } from '@stratumn/atomic';

import { useTooltip } from 'utils/hooks';

import CropDialog from './cropDialog';

import styles from './uploadAvatar.style';

export const UploadAvatar = ({
  classes,
  user,
  avatar,
  loading,
  submitNewAvatar,
  size,
  nth,
  initials
}) => {
  const [srcFile, setSrcFile] = useState(null);

  const [onMouseEnter, onMouseLeave, hovered] = useTooltip(true);

  const fileInputRef = useRef(null);
  const onAvatarClick = useCallback(() => fileInputRef.current.click(), []);
  const displayUploadIcon = hovered || loading || !!srcFile;
  const avatarButton = (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-cy="avatar-button"
    >
      {user ? (
        <AvatarButton
          src={avatar}
          onClick={onAvatarClick}
          size={size}
          overlay={displayUploadIcon}
        />
      ) : (
        <AvatarGroupButton
          src={avatar}
          onClick={onAvatarClick}
          size={size}
          initials={initials}
          nth={nth}
          overlay={displayUploadIcon}
        />
      )}
    </div>
  );

  const onFileChange = useCallback(e => {
    if (e.target.files?.length > 0) {
      const targetFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setSrcFile(reader.result);
      };
      reader.readAsDataURL(targetFile);
    }
  }, []);

  const onCropCancel = useCallback(() => {
    setSrcFile(null);
    if (fileInputRef.current) fileInputRef.current.value = '';
  }, []);

  const onCropSubmit = useCallback(
    cropResult => {
      // cleanup to close the CropDialog
      setSrcFile(null);
      if (fileInputRef.current) fileInputRef.current.value = '';

      // submit with the new file
      submitNewAvatar(cropResult.file);
    },
    [submitNewAvatar]
  );

  return (
    <>
      {avatarButton}
      <input
        type="file"
        ref={fileInputRef}
        className={classes.fileInput}
        onChange={onFileChange}
      />
      {srcFile && (
        <CropDialog
          imageSrc={srcFile}
          actionLabel={user ? 'crop' : 'save'}
          submit={onCropSubmit}
          cancel={onCropCancel}
        />
      )}
    </>
  );
};

UploadAvatar.propTypes = {
  classes: PropType.object.isRequired,
  user: PropType.bool,
  avatar: PropType.string,
  loading: PropType.bool,
  submitNewAvatar: PropType.func.isRequired,
  size: PropType.number,
  nth: PropType.number,
  initials: PropType.string
};

UploadAvatar.defaultProps = {
  user: false,
  avatar: null,
  loading: false,
  size: 32,
  nth: null,
  initials: null
};

export default injectSheet(styles)(React.memo(UploadAvatar));
