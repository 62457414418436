import gql from 'graphql-tag';

export default {
  // get all teams of the user's organization
  organizationTeams: gql`
    fragment UserOrganizationTeamsFragment on Account_User {
      rowId
      organizations(first: 1) {
        nodes {
          rowId
          teams {
            totalCount
            nodes {
              rowId
              name
              accountId
              members {
                totalCount
              }
              bots {
                totalCount
              }
            }
          }
        }
      }
    }
  `
};
