import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';

import injectSheet from 'react-jss';

import styles from './tabs.style';

// update layout exported to be tested independently
export const moveActiveTabIndicator = (
  tabsContainerEl,
  activeTabEl,
  activeTabIndicatorEl
) => {
  if (!tabsContainerEl || !activeTabEl || !activeTabIndicatorEl) return;

  const tabsContainerClientRect = tabsContainerEl.getBoundingClientRect();
  const activeTabClientRect = activeTabEl.getBoundingClientRect();

  activeTabIndicatorEl.style.setProperty(
    'left',
    `${
      activeTabClientRect.left -
      tabsContainerClientRect.left +
      tabsContainerEl.scrollLeft
    }px`
  );
  activeTabIndicatorEl.style.setProperty(
    'width',
    `${activeTabClientRect.width}px`
  );
};

// A basic wrapper for tabs selector
// provided components are only the tabs headers
// component is fully controlled, ie the caller must provide an activeTab id and a setActiveTab callback
export const Tabs = ({ classes, tabs, activeTab, setActiveTab }) => {
  const tabsContainerRef = useRef(null);
  const activeTabRef = useRef(null);
  const activeTabIndicatorRef = useRef(null);
  useEffect(
    () =>
      moveActiveTabIndicator(
        tabsContainerRef.current,
        activeTabRef.current,
        activeTabIndicatorRef.current
      ),
    [
      tabsContainerRef.current,
      activeTabRef.current,
      activeTabIndicatorRef.current
    ]
  );

  return (
    <div className={classes.tabs} ref={tabsContainerRef}>
      {tabs.map(({ id, header }) => (
        <div
          key={id}
          className={classes.tab}
          onClick={() => setActiveTab(id)}
          ref={activeTab === id ? activeTabRef : null}
          data-cy="tab"
        >
          {header || id}
        </div>
      ))}
      <div className={classes.activeTabIndicator} ref={activeTabIndicatorRef} />
    </div>
  );
};
Tabs.propTypes = {
  classes: PropTypes.object.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, header: PropTypes.node })
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired
};
Tabs.defaultProps = {};

export default compose(injectSheet(styles), React.memo)(Tabs);
