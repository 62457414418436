export default theme => ({
  savedViewsMenu: {
    padding: '10px'
  },
  savedViewsMenuList: {
    fontSize: 12
  },
  savedViewsMenuTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '5px 10px',
    color: theme.grey3
  },
  savedViewsMenuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px',
    height: 30,
    '& button': {
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: 22,
      '&:hover': {
        cursor: 'pointer',
        color: theme.indigo2
      }
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.grey8,
      '& button': {
        display: 'flex'
      }
    }
  },
  savedViewsMenuItemSelected: {
    fontWeight: 'bold',
    '& svg': {
      color: '#000 !important',
      strokeWidth: '2px'
    }
  },
  savedViewsMenuItemName: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    maxWidth: '84px',
    gap: '8px',
    '& > span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& > svg': {
      color: theme.grey5
    }
  },
  errorMessage: {
    color: theme.warningRed,
    fontSize: 12,
    margin: '5px 0'
  },
  saveViewButtonContainer: {
    padding: 5,
    display: 'flex'
  },
  saveViewButton: {
    height: 24,
    width: '100%',
    padding: '0 6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    border: `1px solid ${theme.grey6}`,
    color: '#2f2f31',
    fontWeight: '600',
    minWidth: '28px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    gap: '10px',
    '&:hover': {
      color: '#1b1b1b',
      backgroundColor: '#f1f1f1'
    },
    '& > svg': {
      strokeWidth: '2.5px'
    }
  },
  saveViewButtonIcon: {
    marginRight: 5,
    height: 22
  },
  savedViewsMenuHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 15,
    margin: 5,
    marginBottom: 2,
    padding: 3,
    borderBottom: `1px solid ${theme.grey5}`
  },
  // Modald content
  savedModeDromDown: {
    marginBottom: 16
  },
  promptMessage: {
    fontSize: 16,
    '& strong': {
      fontWeight: 'bold',
      color: theme.indigo2
    }
  }
});
