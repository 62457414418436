export default theme => ({
  container: {
    width: 400,
    backgroundColor: 'white',
    borderRadius: '5px 5px 0 0',
    boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.28)'
  },
  header: {
    height: 50,
    backgroundColor: theme.colorBrandPrimary,
    padding: 7,
    paddingLeft: 15,
    borderRadius: '5px 5px 0 0',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p': {
      padding: 0,
      margin: 0
    }
  },
  buttonGroupRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: 25
  },
  files: {
    paddingLeft: 15,
    paddingRight: 14,
    padding: 7,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 150,
    overflowY: 'auto'
  },
  file: {
    marginTop: 7,
    marginBottom: 7,
    color: theme.grey1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});
