import React from 'react';
import PropTypes from 'prop-types';

import { Prose } from '@stratumn/atomic';

import { getByPath } from 'utils/widgets';

// prose to display
const ProseView = React.memo(({ view, data }) => {
  const { path, small, light } = view;

  let proseData = getByPath(data, path);
  if (!proseData) return null;

  if (typeof proseData === 'object') {
    proseData = JSON.stringify(proseData, null, 2);
  }

  return <Prose text={proseData} small={small} light={light} />;
});
ProseView.propTypes = {
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to text on path
const getSortConfig = view => ({
  type: 'text',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = view => ({
  type: 'text',
  path: view.path
});

// width defaults to 'xlarge'
const getDefaultWidth = () => 'xlarge';

// stringifies data at path
const getStringifyFunction = view => {
  const { path } = view;
  return data => {
    let proseData = getByPath(data, path);
    if (!proseData) return '';

    if (typeof proseData === 'object') {
      proseData = JSON.stringify(proseData, null, 2);
    }
    return proseData;
  };
};

export default {
  component: ProseView,
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
