import React from 'react';
import PropTypes from 'prop-types';

import { ProseList } from '@stratumn/atomic';

import { getByPath } from 'utils/widgets';

// list to display
const ListView = React.memo(({ view, data }) => {
  const { path, ordered, small, light } = view;

  const listData = getByPath(data, path);
  if (!listData) return null;

  return (
    <ProseList items={listData} ordered={ordered} small={small} light={light} />
  );
});
ListView.propTypes = {
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to length on path
const getSortConfig = view => ({
  type: 'length',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = view => ({
  type: 'text',
  path: view.path
});

// width defaults to 'large'
const getDefaultWidth = () => 'large';

// stringifies data at path
const getStringifyFunction = view => {
  const { path } = view;
  return data => {
    const listData = getByPath(data, path);
    if (!listData) return '';

    return listData.join(', ');
  };
};

export default {
  component: ListView,
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
