export default theme => ({
  commentInput: {
    outline: 'none',
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    border: '1px solid transparent',
    transition: 'border-color 150ms linear 0ms',
    borderRadius: 3,
    padding: '5px 10px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.white1,
      borderColor: theme.grey5,
      '& $commentInputIcon': {
        color: theme.grey3,
        width: 17
      }
    },
    '&:focus, &[data-input-focused=true]': {
      backgroundColor: theme.white1,
      borderColor: theme.indigo3,
      '& $commentInputIcon': {
        color: theme.indigo3,
        width: 17
      },
      '&:hover': {
        '& $commentInputIcon': {
          color: theme.indigo3
        }
      }
    },
    '&[data-is-disabled=true]': {
      cursor: 'not-allowed'
    },
    '&::-moz-focus-inner': {
      border: 0 // disable inner dotted border on focused button for firefox
    }
  },
  inputRef: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  textDisplay: {
    flexGrow: 1,
    fontSize: 13,
    lineHeight: '15px',
    color: theme.grey1,
    outline: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&[data-is-unset=true], &[data-is-disabled=true]': {
      color: theme.grey3
    }
  },
  commentInputIcon: {
    marginLeft: 5,
    flexShrink: 0,
    height: 17,
    width: 0,
    color: theme.grey4,
    '&[data-is-visible=true]': {
      width: 17
    },
    transition: 'transform 0.3s ease-in-out',
    '&[data-input-focused=true]': {
      transform: 'rotate(180deg)',
      color: theme.indigo3
    }
  },
  tooltipContent: {
    zIndex: 2,
    width: 440,
    backgroundColor: theme.white1,
    boxShadow: theme.indigoShadow2,
    borderRadius: 5,
    padding: 10,
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  tooltipTextarea: {
    padding: '0px 10px',
    outline: 'none',
    backgroundColor: 'transparent',
    color: theme.grey1,
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'justify',
    resize: 'none',
    '&::placeholder': {
      color: theme.grey4
    },
    '&:disabled': {
      cursor: 'not-allowed',
      color: theme.grey3
    },
    ...theme.scrollbar
  },
  tooltipIconContainer: {
    cursor: 'pointer',
    paddingBottom: 10,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end'
  }
});
