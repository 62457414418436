export default {
  iconView: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'start',
    '& > svg': {
      flexShrink: 0 // don't shrink icons, the label will adapt and overflow
    }
  },
  iconViewLabel: {
    marginLeft: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};
