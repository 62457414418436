import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Oops } from '@stratumn/atomic';
import { history } from 'components/root';

import { ROUTE_WORKFLOW_DASHBOARD } from 'constant/routes';

export const PageNotFound = props => {
  const { description, label } = props;

  const goTo = () => history.push(ROUTE_WORKFLOW_DASHBOARD);

  useEffect(() => {
    document.title = 'Oops! Page not found - Trace';
  }, []);

  return <Oops onHomeClick={goTo} description={description} label={label} />;
};

PageNotFound.propTypes = {
  description: PropTypes.string,
  label: PropTypes.string
};

PageNotFound.defaultProps = {
  description: "Sorry, we can't find the page you're looking for.",
  label: 'take me home'
};

export default PageNotFound;
