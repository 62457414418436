import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormReader } from '@stratumn/atomic';

import { ENABLE_BATCH_DOWNLOAD } from 'constant/featureFlags';
import { TicketContext } from 'components/ui/ticketBar';
import { multipleFileDownload } from 'utils/downloadFile/multipleFileDownload';
import { downloadFile } from 'utils/downloadFile';

export const FormBody = ({
  linkCreatedByAccount,
  link,
  traceId,
  workflowContext
}) => {
  const { action, data } = link;
  const { description } = action || {};

  const schema = action?.form?.schema || {
    type: 'object',
    description: action?.description
  };
  const uiSchema = action?.form?.uiSchema || {};
  const { name } = linkCreatedByAccount?.entity || link.group;

  const { setTickets } = useContext(TicketContext) || {};

  const extractFiles = link_ => {
    if (!link_.action || !link_.action.form) return [];
    if (!link_.data) return [];
    return Object.entries(link_.data)
      .filter(
        ([k]) => uiSchema[k] && uiSchema[k]['ui:field'] === 'FileUploadField'
      )
      .reduce((prev, files) => [...prev, ...files.slice(1)[0]], []);
  };

  const filesSegment = extractFiles(link);
  const multiFiles = {
    files: [
      {
        trace_uuid: traceId,
        digests: filesSegment.map(file => file.digest)
      }
    ],
    workflow_id: link.workflowId
  };

  const downloadAllFiles = async () => {
    const ticket = await multipleFileDownload(multiFiles);
    return setTickets(currentTickets => [
      ...currentTickets,
      {
        ticket_id: ticket,
        status: 'pending'
      }
    ]);
  };
  if (!action) {
    // Fallback on displaying the JSON data if
    // action is not set for some reason.
    return <pre>{JSON.stringify(data, null, 2)}</pre>;
  }

  const formData = link?.answer
    ? { comment: link.answer, files: link.files }
    : data;

  return (
    <FormReader
      description={description}
      schema={schema}
      uiSchema={uiSchema}
      formData={formData}
      who={name}
      when={link.createdAt}
      workflowContext={workflowContext}
      downloadFile={downloadFile}
      downloadAllFiles={ENABLE_BATCH_DOWNLOAD ? downloadAllFiles : undefined}
    />
  );
};

FormBody.propTypes = {
  action: PropTypes.object,
  data: PropTypes.object,
  linkCreatedByAccount: PropTypes.object.isRequired,
  link: PropTypes.object.isRequired,
  traceId: PropTypes.string.isRequired,
  workflowContext: PropTypes.object.isRequired
};

FormBody.defaultProps = {
  action: {},
  data: {}
};

export default FormBody;
