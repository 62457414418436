import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import {
  Widget,
  getAggregationConfig as getWidgetAggregationConfig
} from 'components/ui/widget';

import styles from './aggregationRow.style';

// get the relevant aggregation config to apply
export const getAggregationConfig = colConfig =>
  colConfig.def?.aggregation || // complete custom aggregation directly defined in the workflow
  getWidgetAggregationConfig(colConfig.def?.cell || {}); // computed aggregation provided by the cell widget given an aggregation key and function defined in the workflow

// table aggregation row
const AggregationRow = ({ classes, height, selectBoxWidth, columns, data }) => {
  const aggregationData = useMemo(
    () => ({
      data
    }),
    [data]
  );
  const aggregationColumns = [];
  columns.forEach(column => {
    const aggregationKey = column.def.cell.aggregation?.aggregationKey;
    if (aggregationKey && aggregationColumns.indexOf(aggregationKey) === -1) {
      aggregationColumns.push(aggregationKey);
    }
  });
  return (
    <div className={classes.aggregationRow}>
      {selectBoxWidth > 0 && (
        <div
          className={classes.firstColumnPlaceholder}
          style={{
            width: selectBoxWidth,
            height
          }}
        >
          {data.length}
        </div>
      )}
      {columns.map((colConfig, index) => {
        const { width } = colConfig;
        const aggregations = [];
        if (colConfig.def?.aggregation) {
          // The aggregation config is already completely defined
          // in the workflow config and can be injected directly
          // in the widget

          aggregations.push(getAggregationConfig(colConfig));
        } else {
          // The aggregation config will be computed

          const newColConfig = JSON.parse(JSON.stringify(colConfig));

          // If the column is of type 'text' but can be edited as a number,
          // this means that we can convert back the cells in the 'number'
          // type so that we can compute the aggregated values for that
          // column.

          if (
            Object.prototype.hasOwnProperty.call(
              colConfig.def.cell.view,
              'editor'
            )
          ) {
            if (
              colConfig.def.cell.view.type === 'text' &&
              colConfig.def.cell.view.editor.type === 'number'
            ) {
              newColConfig.def.cell.view.type = 'number';
            }
          }

          const aggregationKey =
            newColConfig.def.cell.aggregation?.aggregationKey;
          const aggregationFunction =
            newColConfig.def.cell.aggregation?.aggregationFunction;

          if (aggregationKey) {
            // Given the aggregation key, for eg the 'currency' column,
            // we compute all the unique values of that aggregation key
            // that will be used to group lines together

            const aggregationValues = [
              ...new Set(aggregationData.data.map(item => item[aggregationKey]))
            ];

            // For each group of lines, for eg for each currency, we
            // prepare the configuration that will be used in the number
            // widget to compute the value we want (for eg sum by currency,
            // avg by currency, etc)

            aggregationValues.forEach(value => {
              const aggregatedColConfig = {
                def: {
                  cell: {
                    view: {
                      ...newColConfig.def.cell.view
                    },
                    aggregation: {
                      aggregationKey: aggregationKey,
                      aggregationValue: value,
                      aggregationFunction: aggregationFunction
                    }
                  }
                }
              };
              aggregations.push(getAggregationConfig(aggregatedColConfig));
            });
          } else if (aggregationColumns.includes(colConfig.key)) {
            const aggregationValues = [
              ...new Set(aggregationData.data.map(item => item[colConfig.key]))
            ];
            aggregationValues.forEach(value => {
              const aggregatedColConfig = {
                def: {
                  cell: {
                    view: {
                      ...newColConfig.def.cell.view
                    },
                    aggregation: {
                      aggregationValue: value
                    }
                  }
                }
              };
              aggregations.push(getAggregationConfig(aggregatedColConfig));
            });
          } else {
            aggregations.push(getAggregationConfig(newColConfig));
          }
        }
        return (
          <div key={index}>
            {aggregations.map((agg, aggIndex) => (
              <div
                key={aggIndex}
                style={{ width, height }}
                data-is-computed={!!agg}
                className={classes.aggregationCellWrapper}
              >
                <Widget
                  widget={agg || {}}
                  data={aggregationData}
                  className={classes.aggregationCell}
                />
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};
AggregationRow.propTypes = {
  classes: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  selectBoxWidth: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};
AggregationRow.defaultProps = {
  selectBoxWidth: 0
};

export default injectSheet(styles)(React.memo(AggregationRow));
