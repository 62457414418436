import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { history } from 'components/root';

import styles from './bottomLink.styles';

const VIEW_TOGGLE = 'viewToggle';

export const BottomLink = React.memo(
  ({ classes, label, path, active, type, icon, onClick }) => (
    <button
      data-cy={`to-${label.replace(' ', '-').toLowerCase()}`}
      className={type === VIEW_TOGGLE ? classes.viewToggle : classes.standard}
      type="button"
      data-is-active={active}
      onClick={
        type === VIEW_TOGGLE && !active ? () => history.push(path) : onClick
      }
    >
      {icon && icon} {label}
    </button>
  )
);

BottomLink.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  path: PropTypes.string,
  active: PropTypes.bool,
  type: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func
};

BottomLink.defaultProps = {
  label: null,
  path: null,
  active: false,
  type: '',
  icon: null,
  onClick: () => {}
};

export default injectSheet(styles)(BottomLink);
