export const idWithType = (typename, id) => `${typename}:${id}`;

const objectsWithoutRowId = new Set([
  'Filters',
  'ActionForm',
  'GroupedTrace',
  'Documentation',
  'File',
  'Action'
]);

export const dataIdFromObject = obj => {
  if (!obj.__typename) {
    console.warn('Missing __typename for obj', obj);
    return null;
  }

  // If the object is a paginated list, do not cache it
  if (obj.__typename.endsWith('Connection')) return null;
  if (obj.__typename === 'PageInfo') return null;

  // Use linkHash as key for links
  if (obj.__typename === 'Link') {
    if (!obj.linkHash) {
      console.warn('Missing linkHash for Link', obj);
    }
    return obj.linkHash;
  }

  if (obj.__typename === 'Evidence') {
    if (!obj.linkHash) {
      console.warn('Missing linkHash for Evidence', obj);
    }
    return idWithType(obj.__typename, obj.linkHash);
  }

  // Use priority id for Priority
  if (obj.__typename === 'Priority') {
    if (!obj.id) {
      console.warn('Missing id for Priority', obj);
    }
    return idWithType(obj.__typename, obj.id);
  }

  // Use accountId for GroupMember
  if (obj.__typename === 'GroupMember') {
    if (!obj.accountId) {
      console.warn('Missing accountId for GroupMember', obj);
    }
    return idWithType(obj.__typename, obj.accountId);
  }

  // Use userId for Account_Member
  if (obj.__typename === 'Account_Member') {
    if (!obj.userId) {
      console.warn('Missing userId for Account_Member', obj);
    }
    return idWithType(obj.__typename, obj.userId);
  }

  // If the object does not have an ID, do not cache it.
  if (!obj.rowId) {
    if (!objectsWithoutRowId.has(obj.__typename)) {
      console.warn('Missing rowId for obj', obj);
    }
    return null;
  }

  return idWithType(obj.__typename, obj.rowId);
};
