import saveCsv from 'save-csv';
import moment from 'moment';
import { deepGet } from 'utils';
import { downloadExcel } from 'react-export-table-to-excel';

const fields = [
  [
    'ID enquête',
    trace =>
      deepGet(trace, 'data.demandeEnquete.version') === '2'
        ? deepGet(trace, 'data.demandeEnquete.data.meta.enquete.id')
        : deepGet(trace, 'data.demandeEnquete.data.enquete.requete.id')
  ],
  [
    'ID defunt',
    trace =>
      deepGet(trace, 'data.demandeEnquete.version') === '2'
        ? deepGet(trace, 'data.demandeEnquete.data.meta.defunt.id')
        : deepGet(trace, 'data.demandeEnquete.data.defunt.identification.id')
  ],
  [
    'Nombre de bénéficiaires',
    trace =>
      deepGet(trace, 'data.retourEnquete.version') === '2'
        ? deepGet(trace, 'data.retourEnquete.data.meta.nombreBeneficiaires', 0)
        : deepGet(trace, 'data.retourEnquete.data.beneficiaires.length', 0)
  ],
  [
    'Prix HT (€)',
    trace =>
      deepGet(trace, 'data.retourEnquete.version') === '2'
        ? deepGet(trace, 'data.retourEnquete.data.meta.facturation.prixHT')
        : deepGet(trace, 'data.retourEnquete.data.reponse.facturation.prixHT')
  ],
  [
    'Prix TTC (€)',
    trace =>
      deepGet(trace, 'data.retourEnquete.version') === '2'
        ? deepGet(trace, 'data.retourEnquete.data.meta.facturation.prixTTC')
        : deepGet(trace, 'data.retourEnquete.data.reponse.facturation.prixTTC')
  ],
  [
    'Date de début',
    trace => {
      if (!trace.createdAt) return undefined;
      return moment(trace.createdAt).format('YYYY-MM-DD');
    }
  ],
  [
    'Date de fin',
    trace => {
      const date = deepGet(trace, 'data.finEnquete.dateEnvoi');
      if (!date) return undefined;
      return moment(date).format('YYYY-MM-DD');
    }
  ],
  [
    'Date de facturation',
    trace =>
      deepGet(trace, 'data.retourEnquete.version') === '2'
        ? deepGet(trace, 'data.retourEnquete.data.meta.facturation.date')
        : deepGet(trace, 'data.retourEnquete.data.reponse.facturation.date')
  ],
  [
    'Résultat positif',
    trace =>
      deepGet(trace, 'data.retourEnquete.version') === '2'
        ? deepGet(trace, 'data.retourEnquete.data.meta.positif')
        : deepGet(trace, 'data.retourEnquete.data.reponse.positif')
  ],
  [
    'Date de décès',
    trace =>
      deepGet(trace, 'data.demandeEnquete.version') === '2'
        ? deepGet(trace, 'data.demandeEnquete.data.meta.defunt.dateDeces')
        : deepGet(trace, 'data.demandeEnquete.data.defunt.deces.dateDeces')
  ],
  [
    'Urgent',
    trace =>
      deepGet(trace, 'data.demandeEnquete.version') === '2'
        ? deepGet(trace, 'data.demandeEnquete.data.meta.enquete.urgent')
        : deepGet(trace, 'data.demandeEnquete.data.enquete.requete.urgent')
  ],
  [
    'Contre-enquête',
    trace =>
      deepGet(trace, 'data.demandeEnquete.version') === '2'
        ? deepGet(trace, 'data.demandeEnquete.data.meta.enquete.contreEnquete')
        : deepGet(
            trace,
            'data.demandeEnquete.data.enquete.requete.contreEnquete'
          )
  ],
  [
    'Contre-enquête Justifiée',
    trace =>
      deepGet(trace, 'data.retourEnquete.version') === '2'
        ? deepGet(trace, 'data.retourEnquete.data.meta.contreEnqueteJustifiee')
        : deepGet(
            trace,
            'data.retourEnquete.data.reponse.contreEnqueteJustifiee'
          )
  ],
  ['Enquêteur', trace => deepGet(trace, 'data.aiguillage.enqueteur')],
  ['Date de relance', trace => deepGet(trace, 'data.relance.dateRelance')],
  [
    "Libellé demande d'enquête",
    trace =>
      deepGet(trace, 'data.demandeEnquete.version') === '2'
        ? deepGet(trace, 'data.demandeEnquete.data.meta.enquete.libelleNature')
        : deepGet(
            trace,
            'data.demandeEnquete.data.enquete.requete.libelleNature'
          )
  ],
  [
    "ID enquête d'origine",
    trace =>
      deepGet(trace, 'data.demandeEnquete.version') === '2'
        ? deepGet(
            trace,
            'data.demandeEnquete.data.meta.enquete.idOrigine',
            undefined
          )
        : deepGet(
            trace,
            'data.demandeEnquete.data.enquete.requete.idOrigine',
            undefined
          )
  ],
  [
    'Réseau',
    trace =>
      deepGet(trace, 'data.demandeEnquete.version') === '2'
        ? deepGet(
            trace,
            'data.demandeEnquete.data.meta.defunt.reseau',
            undefined
          )
        : deepGet(trace, 'data.demandeEnquete.data.defunt.reseau', undefined)
  ],
  [
    "Date d'annulation",
    trace => deepGet(trace, 'data.dateAnnulation', undefined)
  ]
];

export default ({ workflow, traces, fileType }) => {
  const payload = traces.map(trace => {
    const row = {};
    fields.forEach(([fieldName, getFromTrace]) => {
      row[fieldName] = getFromTrace(trace);
    });
    return row;
  });
  const header = fields.map(([fieldName]) => fieldName);

  if (fileType === 'csv')
    saveCsv(payload, {
      filename: `${workflow.name}.csv`
    });

  if (fileType === 'excel')
    downloadExcel({
      fileName: workflow.name,
      sheet: workflow.name,
      tablePayload: {
        header,
        body: payload
      }
    });
};
