export default theme => ({
  resizableColHeader: {
    '&[data-is-resizable=true]': {
      borderRight: `1px solid ${theme.indigo3}`
    }
  },
  resizableColHeaderCell: {
    height: '100%',
    padding: '0px 6px',
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  resizableColHeaderLabel: {
    paddingTop: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  resizableColHeaderIconWrapper: {
    flexShrink: 0,
    width: 16,
    height: 16,
    overflow: 'hidden',
    marginRight: 1
  },
  resizableColHeaderIcon: {
    color: theme.indigo3,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    '& > g': {
      transform: 'scale(1.85) translateX(1px) translateY(1px)'
    },
    '&[data-is-dragging=true]': {
      color: 'white'
    }
  }
});
