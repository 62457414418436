import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { Avatar, AvatarGroup } from '@stratumn/atomic';

import { getByPath } from 'utils/widgets';
import { WorkflowContext } from 'utils/workflowContext';

import styles from './avatar.style';

const AvatarViewImpl = ({ classes, view, data }) => {
  const workflowContext = useContext(WorkflowContext);

  const {
    path, // path to the name or label of user / group
    avatarPath, // path to the avatar of the user / group
    isGroup = false, // whether to display an hexagonal Group Avatar instead of the circle User Avatar
    useContext: useWorkflowContext = false // whether to consider path as pointing to a label and resolve name + avatar using the workflow context
  } = view;

  let name;
  let avatar;
  if (useWorkflowContext) {
    if (!workflowContext) {
      throw Error('No workflow context provided to the Avatar view');
    }

    if (isGroup) {
      const { groups = [] } = workflowContext;
      // path is pointing at the group label
      const label = getByPath(data, path);
      if (!label) return null;

      // find the group in the workflow
      const group = groups.find(g => g.label === label);

      if (!group) {
        // group not found, return the label only
        name = label;
      } else {
        ({ name, avatar } = group);
      }
    } else {
      throw Error('Contextualized user avatars not available yet');
    }
  } else {
    name = getByPath(data, path);
    avatar = getByPath(data, avatarPath);
  }

  const AvatarComponent = isGroup ? AvatarGroup : Avatar;

  if (!name && !avatar) return null;

  return (
    <div className={classes.avatarView}>
      <span className={classes.avatarPic}>
        <AvatarComponent size={28} src={avatar} nth={0} />
      </span>
      {name && <span className={classes.avatarName}>{name}</span>}
    </div>
  );
};
AvatarViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to text on path
// potentially transformed through workflow context
const getSortConfig = view => {
  const { path } = view;
  return {
    type: 'text',
    path
  };
};

// filtering defaults to text search on path
// potentially transformed through workflow context
const getFilterConfig = view => {
  const { path } = view;
  return {
    type: 'text',
    path
  };
};

// width defaults to 'medium' if a label is shown, 'small' otherwise
const getDefaultWidth = () => 'medium';

// stringifies data at namePath or path
const getStringifyFunction = (view, workflowContext) => {
  const {
    path,
    isGroup = false,
    useContext: useWorkflowContext = false
  } = view;

  if (useWorkflowContext) {
    if (!workflowContext) {
      throw Error('No workflow context provided to the Avatar view');
    }

    if (isGroup) {
      const { groups = [] } = workflowContext;

      return data => {
        // path is pointing at the group label
        const label = getByPath(data, path);
        if (!label) return null;

        // find the group in the workflow
        const group = groups.find(g => g.label === label);

        if (!group) {
          // group not found, return the label only
          return label;
        }
        return group.name;
      };
    }
    throw Error('Contextualized user avatars not available yet');
  } else {
    return data => getByPath(data, path);
  }
};

export default {
  component: injectSheet(styles)(React.memo(AvatarViewImpl)),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
