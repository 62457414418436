import { SCROLLBAR_SIZE } from './displayConfigUtils';

export default theme => ({
  tableWrapper: {
    height: '100%',
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0px 2px 20px rgba(75, 53, 210, 0.1)',
    overflow: 'hidden'
  },
  tableContainer: {
    display: 'flex',
    flexFlow: 'column'
  },
  tableColHeaders: {
    display: 'flex',
    flexFlow: 'row nowrap',
    zIndex: 2,
    transition: 'box-shadow 0.2s ease-in-out'
  },
  tableFixedColHeaders: {
    zIndex: 1,
    transition: 'box-shadow 0.2s ease-in-out'
  },
  tableFloatingColHeaders: {
    display: 'flex',
    flexFlow: 'row nowrap',
    overflowX: 'hidden'
  },
  tableColHeadersStack: {
    display: 'flex',
    flexFlow: 'column'
  },
  tableGridsWrapper: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    position: 'relative',
    fontSize: 13,
    lineHeight: 1.25,
    whiteSpace: 'nowrap',
    color: theme.grey1
  },
  tableGrids: {
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  reactWindowGridLoading: {
    opacity: 0.3
  },
  tableFixedGrid: {
    zIndex: 1,
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    transition: 'box-shadow 0.2s ease-in-out',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  loadingIconContainer: {
    zIndex: 3,
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    opacity: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingIcon: {
    margin: 0
  },
  invalidFiltersMessageContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center'
  },
  invalidFiltersMessage: {
    backgroundColor: theme.warningRed,
    color: 'white',
    width: 'fit-content',
    padding: '10px 15px',
    borderRadius: 3,
    position: 'absolute',
    top: 20,
    zIndex: 6
  },
  invalidActiveFilters: {
    fontWeight: 'bold',
    marginLeft: 4,
    marginRight: 4
  },
  aggregationRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    backgroundColor: theme.indigoPastel2
  },
  fixedAggregationRow: {
    transition: 'box-shadow 0.2s ease-in-out'
  },
  floatingAggregationRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    overflowX: 'hidden'
  },
  gridScrollbarPlaceholder: {
    width: SCROLLBAR_SIZE,
    flexShrink: 0
  }
});
