export default theme => ({
  multiselectInput: {
    outline: 'none',
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    border: '1px solid transparent',
    transition: 'border-color 150ms linear 0ms',
    borderRadius: 3,
    padding: '5px 6px 5px 10px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.white1,
      borderColor: theme.grey5,
      '& $multiselectDropdownIcon': {
        color: theme.grey3
      }
    },
    '&:focus, &[data-input-focused=true]': {
      backgroundColor: theme.white1,
      borderColor: theme.indigo3,
      '& $multiselectDropdownIcon': {
        color: theme.indigo3
      },
      '&:hover': {
        '& $multiselectDropdownIcon': {
          color: theme.indigo3
        }
      }
    },
    '&::-moz-focus-inner': {
      border: 0 // disable inner dotted border on focused button for firefox
    }
  },
  inputRef: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  textDisplay: {
    flexGrow: 1,
    fontSize: 13,
    lineHeight: '15px',
    color: theme.grey1,
    outline: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&[data-is-unset=true]': {
      color: theme.grey3
    }
  },
  multiselectDropdownIcon: {
    marginLeft: 5,
    flexShrink: 0,
    height: 17,
    width: 17,
    color: theme.grey4,
    transition: 'transform 0.3s ease-in-out',
    '&[data-input-focused=true]': {
      transform: 'rotate(180deg)',
      color: theme.indigo3
    }
  },
  multiselectCompactInput: {
    outline: 'none',
    position: 'relative',
    width: '100%',
    height: 40,
    display: 'flex',
    flexFlow: 'row wrap',
    '& button': {
      height: '100%'
    },
    '&[data-input-focused=true]': {
      height: 'auto'
    }
  },
  multiselectCompactInputDropDown: {
    width: '100%',
    border: `1px solid ${theme.indigo3}`
  },
  tooltipContent: {
    zIndex: 2,
    minWidth: 100,
    width: 'auto',
    backgroundColor: theme.white1,
    borderRadius: 2,
    border: `1px solid ${theme.grey5}`,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    color: theme.grey1,
    fontSize: 13,
    lineHeight: '16px'
  },
  tooltipHeader: {
    cursor: 'pointer',
    padding: '6px 8px 10px 8px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&[data-is-compact=true]': {
      height: 40
    }
  },
  optionsList: {
    marginRight: 5,
    paddingRight: 5,
    maxHeight: 160,
    overflow: 'auto',
    '&::placeholder': {
      color: theme.grey4
    },
    ...theme.scrollbar
  },
  option: {
    cursor: 'pointer',
    padding: '11px 10px',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    '&[data-is-selected=true]': {
      backgroundColor: theme.grey8
    },
    '&:hover': {
      backgroundColor: theme.grey8
    }
  },
  disabledOption: {
    position: 'relative',
    cursor: 'default',
    padding: '11px 10px',
    fontSize: 14,
    lineHeight: '18px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&[data-is-selected=true]': {
      backgroundColor: theme.grey8
    }
  },
  disabledOptionMarker: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 5,
    backgroundColor: theme.teal1
  },
  disabledOptionLabel: {
    paddingLeft: 10,
    color: theme.grey4,
    cursor: 'not-allowed'
  },
  disabledMultiselectEmptyInput: {
    padding: '5px 10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.grey4,
    cursor: 'not-allowed'
  }
});
