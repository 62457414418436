export default {
  textFilter: {
    '& input': {
      backgroundColor: 'white',
      borderRadius: 3,
      width: '100%',
      fontSize: 13
    }
  }
};
