import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import styles from './contextMenu.style';

const BOTTOM_OFFSET = 50;

const ContextMenu = ({ classes, children }) => {
  const contextMenuMenuRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);

  const handleWindowResize = useCallback(() => {
    const menuElement = contextMenuMenuRef.current;
    if (menuElement) {
      const { top } = menuElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      setMaxHeight(windowHeight - top - BOTTOM_OFFSET);
    }
  }, []);

  useLayoutEffect(() => {
    handleWindowResize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  return (
    <div className={classes.contextMenuWrapper} ref={contextMenuMenuRef}>
      <svg
        className={classes.contextMenuArrow}
        data-name="arrow"
        aria-hidden="true"
        viewBox="0 0 20 10"
      >
        <polyline points="5,8 10,0.85 15,8 15,10 5,10" stroke="none" />
        <polyline points="5,8 10,0.85 15,8" />
      </svg>
      <div
        className={classes.contextMenuContent}
        style={{ maxHeight: `${maxHeight}px` }}
      >
        {children}
      </div>
    </div>
  );
};

ContextMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default injectSheet(styles)(ContextMenu);
