import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import injectSheet from 'react-jss';

import { getByPath, getNumberConditionalStyle, formatNumber } from 'utils';

import InfoTooltip from 'components/ui/utils/infoTooltip';

import styles from './progress.style';

// % number displayed as a progrees bar
// default default conditional colors
const defaultProgressConditionalStyle = [
  { style: { backgroundColor: '#E8544D' } },
  {
    condition: { minimum: 0.25 },
    style: { backgroundColor: '#E4E26D' }
  },
  {
    condition: { minimum: 0.5 },
    style: { backgroundColor: '#8ED376' }
  },
  {
    condition: { minimum: 0.75 },
    style: { backgroundColor: '#16AA00' }
  }
];
const ProgressViewImpl = React.memo(({ classes, view, data }) => {
  const { path, denominatorPath } = view;

  const tooltipAnchorRef = useRef(null);
  const [showToolip, setShowTooltip] = useState(false);

  const onMouseEnter = () => {
    setShowTooltip(true);
  };
  const onMouseLeave = () => {
    setShowTooltip(false);
  };

  let percentage = getByPath(data, path);
  // check if scaling is needed
  if (denominatorPath !== undefined) {
    const denominatorValue = Number(getByPath(data, denominatorPath));
    if (!Number.isNaN(denominatorValue) && denominatorValue !== 0) {
      percentage /= denominatorValue;
    }
  }

  const appliedStyle = getNumberConditionalStyle(
    percentage,
    defaultProgressConditionalStyle
  );
  const percentageWidth = `${percentage * 100}%`;
  const voidWidth = `${(1.0 - percentage) * 100}%`;
  return (
    <div
      className={classes.progressContainer}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={classes.progressView}>
        <div
          className={classes.progressViewFilled}
          style={{
            width: percentageWidth,
            ...appliedStyle
          }}
        />
        <div
          ref={tooltipAnchorRef}
          className={classes.progressViewTooltipAnchor}
        />
        <div
          className={classes.progressViewVoid}
          style={{
            width: voidWidth
          }}
        />
      </div>
      {showToolip && (
        <InfoTooltip
          clientRef={tooltipAnchorRef}
          text={`${formatNumber(percentage, {
            options: { style: 'percent', minimumFractionDigits: 0 }
          })}`}
          textColor="white"
          backgroundColor="black"
          delay={200}
          minWidth={0}
          position={{
            place: 'below',
            adjustPlace: true
          }}
        />
      )}
    </div>
  );
});
ProgressViewImpl.propTypes = {
  classes: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

// sort defaults to number on path
const getSortConfig = view => ({
  type: 'number',
  path: view.path
});

// filtering defaults to text search on path
const getFilterConfig = view => ({
  type: 'text',
  path: view.path,
  placeholder: 'Eg >0.5, 0.25<<0.75',
  interpreter: {
    type: 'number'
  }
});

// width defaults to 'medium'
const getDefaultWidth = () => 'medium';

// stringifies data at path
const getStringifyFunction = view => {
  const { path, denominatorPath } = view;
  return data => {
    let percentage = getByPath(data, path);
    // check if scaling is needed
    if (denominatorPath !== undefined) {
      const denominatorValue = Number(getByPath(data, denominatorPath));
      if (!Number.isNaN(denominatorValue) && denominatorValue !== 0) {
        percentage /= denominatorValue;
      }
    }
    return percentage;
  };
};

export default {
  component: injectSheet(styles)(ProgressViewImpl),
  getSortConfig,
  getFilterConfig,
  getDefaultWidth,
  getStringifyFunction
};
