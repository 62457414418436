import gql from 'graphql-tag';

export default gql`
  query filtersQuery($field: String) {
    filters @client {
      search
      time
    }
  }
`;
