import jmespath from 'jmespath';
import isempty from 'lodash.isempty';
import isequal from 'lodash.isequal';

import { deepCopy, getScrollbarSize } from 'utils';

import { sortData } from './sort';
import { filterData } from './filters';

/* Building the overview table display config document */

// preset keywords for column widths
const columnsWidthPresets = {
  xsmall: 50,
  small: 100,
  medium: 200,
  large: 300,
  xlarge: 500
};
// invert the dictionary as it will be consumed by the resizing col headers
export const columnsWidthPresetsInverted = Object.keys(
  columnsWidthPresets
).reduce((prev, key) => {
  prev[columnsWidthPresets[key]] = key;
  return prev;
}, {});

const getWidth = width => {
  if (typeof width === 'string') {
    return jmespath.search(columnsWidthPresets, width) || Number(width) || 0;
  }
  return width || 0;
};

// default sizing
const defaultSizes = {
  selectBoxWidth: 'xsmall',
  columnsWidth: 'medium',
  minColumnsWidth: 'xsmall',
  rowsHeight: 42,
  minRowsHeight: 30
};

// complete the table config with the content of the display config
const completeConfig = (tableConfig, displayConfig) => {
  const completedConfig = {
    applyFilters: displayConfig.applyFilters,
    sortSetup: displayConfig.sortSetup,
    groupBy: displayConfig.groupBy
  };

  // Double the first column width if a group is uncollapsed to make room
  // for both the collapse button and the select box
  const { groupBy } = displayConfig;
  const multiplier = groupBy && groupBy.selected !== undefined ? 2 : 1;
  const selectBoxWidth =
    getWidth(tableConfig.selectBoxWidth || defaultSizes.selectBoxWidth) *
    multiplier;
  completedConfig.selectBoxWidth = selectBoxWidth + 1; // add 1px for the right border

  // resolve min sizes values or defaults
  completedConfig.minColumnsWidth = getWidth(
    tableConfig.minColumnsWidth || defaultSizes.minColumnsWidth
  );
  completedConfig.minRowsHeight =
    tableConfig.minRowsHeight || defaultSizes.minRowsHeight;

  // resolve actual rows height
  completedConfig.rowsHeight =
    displayConfig.rowsHeight ||
    tableConfig.rowsHeight ||
    defaultSizes.rowsHeight;

  // resolve actual default columns width
  const defaultColumnsWidth =
    tableConfig.columnsWidth || defaultSizes.columnsWidth;

  const {
    fixedColumns: tableFixedColumns = [],
    columns: tableColumns = []
  } = tableConfig;
  const {
    columns: displayColumns = tableColumns.map(col => col.key),
    widths: displayWidths = {},
    filters: displayFilters = {}
  } = displayConfig;

  // parse fixed columns first
  // display is fully driven by table config
  // but we may still have filters and widths set in dislay config
  const newFixedColumns = tableFixedColumns.map(col => {
    const { key } = col;
    return {
      key,
      def: col,
      width: getWidth(displayWidths[key] || col.width || defaultColumnsWidth),
      filter: displayFilters[key]
    };
  });
  // update the actual fixed columns to display
  completedConfig.fixedColumns = newFixedColumns;

  // parse floating columns
  // display here is driven by display config
  // we may skip columns that are not (more) defined in the table definitions
  const newColumns = displayColumns.map(key => {
    const def = tableColumns.find(col => col.key === key);
    return {
      key,
      def,
      width: getWidth(displayWidths[key] || def.width || defaultColumnsWidth),
      filter: displayFilters[key]
    };
  });
  // update the actual columns to display
  completedConfig.columns = newColumns;

  // resolve row inline style
  completedConfig.rowInlineStyle =
    displayConfig.rowInlineStyle || tableConfig.rowInlineStyle;

  return completedConfig;
};

// get the raw (uncompleted) display config to use
// ie switch between userDisplayConfig (from cache) and tableConfig.defaultDisplay, or empty if none specified
// then reconcile to an object understandable by the Table component
// this is the place to ensure backward compatibility on cached display configs
// ie handle new / different elements of the config that some clients may not have yet in their cache
// but also handle backward compatibility in terms of configuration, eg remove filters on columns that don't exist anymore
export const getUsedDisplayConfig = (tableConfig, userDisplayConfig) => {
  const rawConfig = userDisplayConfig || tableConfig.defaultDisplay || {};

  // if columns are present but are objects then return empty object
  // for backward compatibility with the very first config structure
  if (rawConfig.columns && typeof rawConfig.columns[0] === 'object') {
    return {};
  }

  const usedConfig = deepCopy(rawConfig);

  // sanitize the content
  // ie reconcile vs the actual list of available columns in the table
  const {
    fixedColumns: tableFixedColumns = [],
    columns: tableColumns = []
  } = tableConfig;
  const fixedColumnsKeys = new Set(tableFixedColumns.map(col => col.key));
  const floatingColumnsKeys = new Set(tableColumns.map(col => col.key));

  // helper function to check if a cloumn key is either a fixed or a floating column
  const colExists = colKey =>
    fixedColumnsKeys.has(colKey) || floatingColumnsKeys.has(colKey);

  // helper function to clear map keys that are neither fixed or floating columns
  const cleanCustomMap = customMap =>
    Object.keys(customMap).forEach(colKey => {
      if (!colExists(colKey)) {
        delete customMap[colKey];
      }
    });

  // 1 - sanitize selected floating columns
  if (usedConfig.columns) {
    usedConfig.columns = usedConfig.columns.filter(colKey =>
      floatingColumnsKeys.has(colKey)
    );
  }
  // 2 - sanitize sort setup (remove if column does not exist)
  if (usedConfig.sortSetup && !colExists(usedConfig.sortSetup.key)) {
    delete usedConfig.sortSetup;
  }
  // 3 - sanitize groupBy (remove if column does not exist)
  if (usedConfig.groupBy && !colExists(usedConfig.groupBy.column)) {
    delete usedConfig.groupBy;
  }
  // 4 - sanitize filters (remove if column does not exist)
  if (usedConfig.filters) {
    cleanCustomMap(usedConfig.filters);
  }
  // 5 - sanitize widths (remove if column does not exist)
  if (usedConfig.widths) {
    cleanCustomMap(usedConfig.widths);
  }

  return usedConfig;
};

// complete the table display config document
// use user display config or default one (from table config)
export const getCompletedDisplayConfig = (tableConfig, userDisplayConfig) => {
  const usedDisplayConfig = getUsedDisplayConfig(
    tableConfig,
    userDisplayConfig
  );

  // complete the config with display config
  return completeConfig(tableConfig, usedDisplayConfig);
};

// a function to help us with reordering the table columns reordering result
// to use with drag n drop functionality
// removes the item at startIdx and places it at endIndex
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// apply sorting and filtering client side following the display config
export const getDataToDisplay = (data, displayConfig) => {
  // 1 - filter
  let dataToDisplay = displayConfig.applyFilters
    ? filterData(data, displayConfig)
    : data;

  // 2 - sort
  dataToDisplay = sortData(dataToDisplay, displayConfig);

  return dataToDisplay;
};

// after the user display config has been updated following an action
// clean it up and reconcile against default display
export const cleanupUserDisplayConfig = (tableConfig, newUserDisplayConfig) => {
  if (!newUserDisplayConfig) return null;

  if (isempty(newUserDisplayConfig.groupBy)) {
    delete newUserDisplayConfig.groupBy;
  }
  if (isempty(newUserDisplayConfig.widths)) {
    delete newUserDisplayConfig.widths;
  }
  if (isempty(newUserDisplayConfig.filters)) {
    delete newUserDisplayConfig.filters;
  }
  if (!newUserDisplayConfig.applyFilters) {
    delete newUserDisplayConfig.applyFilters;
  }
  // note: don't clean empty columns [] if they have been explicitly removed

  // if the cleaned up display config is equal in content to the default display then return null so the local storage can be cleaned up
  const defaultDisplay = tableConfig.defaultDisplay || {};
  return isequal(newUserDisplayConfig, defaultDisplay)
    ? null
    : newUserDisplayConfig;
};

export const SCROLLBAR_SIZE = getScrollbarSize();
