export const TYPE_ACCOUNT = 'Account';
export const TYPE_ACCOUNT_LIST = 'AccountList';

export const TYPE_WORKFLOW = 'Workflow';
export const TYPE_WORKFLOW_LIST = 'WorkflowList';

export const TYPE_GROUP = 'Group';
export const TYPE_GROUP_LIST = 'GroupList';

export const TYPE_INPUT = 'Input';
export const TYPE_INPUT_LIST = 'InputList';

export const TYPE_TRACE = 'Trace';
export const TYPE_TRACE_LIST = 'TraceList';

export const TYPE_SEGMENT = 'Segment';
export const TYPE_SEGMENT_LIST = 'SegmentList';

export const TYPE_STAGE = 'Stage';
export const TYPE_STAGE_LIST = 'StageList';

export const TYPE_ENTITY_ROLE = 'EntityRole';
export const TYPE_ENTITY_ROLE_LIST = 'EntityRoleList';

export const TYPE_FILE = 'File';
export const TYPE_FILE_LIST = 'FileList';

export const TYPE_PAGE_INFO = 'PageInfo';

export const TYPE_CREATE_INPUT_PAYLOAD = 'CreateInputPayload';

export const TYPE_UPDATE_INPUT_PAYLOAD = 'UpdateInputPayload';

export const TYPE_CREATE_TRACE_PAYLOAD = 'CreateTracePayload';

export const TYPE_UPDATE_TRACE_PAYLOAD = 'UpdateTracePayload';

export const TYPE_TRACE_NOTIFICATION = 'TraceNotification';

export const ROLE_ADMIN = 'ADMIN';
export const ROLE_READER = 'READER';

export const TYPE_WORKFLOW_CONFIG = 'WorkflowConfig';
export const TYPE_UPDATE_WORKFLOW_CONFIG_PAYLOAD =
  'UpdateWorkflowConfigPayload';
export const TYPE_NEW_WORKFLOW_CONFIG_PAYLOAD = 'NewWorkflowConfigPayload';

// for workflow groups
export const ENTITY_TYPE_USER = 'Account_User';
export const ENTITY_TYPE_BOT = 'Account_Bot';
export const ENTITY_TYPE_TEAM = 'Account_Team';
export const ENTITY_TYPE_ORGANIZATION = 'Account_Organization';
