import { FC, WheelEvent, useEffect, useRef } from 'react';
import moment from 'moment';
import Path from 'path-to-regexp';
import injectSheet from 'react-jss';

import { ROUTE_INSPECT_TRACE_LINK } from 'constant/routes';
import { NavLink } from 'react-router-dom';
import styles from './segmentList.style';
import Segment from '../segment';
import { LinkQueryResult } from '../traceInspector.types';

interface Props {
  classes: any;
  activeSegment: any;
  pulldown: () => JSX.Element;
  links: LinkQueryResult[];
  traceId: string;
}

interface NumberOfAnswersByLinks {
  [key: string]: number;
}

const SegmentList: FC<Props> = ({
  classes,
  activeSegment,
  pulldown,
  links = [],
  traceId
}) => {
  const segmentListRef = useRef<HTMLDivElement>(null);
  const activeItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    centerActiveItem();
  }, []);

  const centerActiveItem = (): void => {
    if (!activeItemRef?.current || !segmentListRef?.current) return;

    const {
      left: activeItemLeft,
      width: activeItemWidth
    } = activeItemRef.current.getBoundingClientRect();
    const { width: listWidth } = segmentListRef.current.getBoundingClientRect();
    const distanceOverCentre: number =
      activeItemLeft + activeItemWidth - 63 - listWidth / 2;

    if (distanceOverCentre > 0) {
      segmentListRef.current.scrollLeft = distanceOverCentre;
    }
  };

  const getNumberOfAnswersByLinks = (
    linksToCheck: LinkQueryResult[]
  ): NumberOfAnswersByLinks =>
    linksToCheck.reduce((answerCounts, link) => {
      const { data } = link;
      if (data && data.commentLinkHash) {
        answerCounts[data.commentLinkHash] =
          (answerCounts[data.commentLinkHash] || 0) + 1;
      }
      return answerCounts;
    }, {});

  const getTimeInterval = (date1: string, date2: string): string => {
    const time2 = moment.utc(date2).local();
    const time1 = moment.utc(date1).local();
    return moment.duration(time2.diff(time1)).humanize();
  };

  const numberOfAnswersByLinks = getNumberOfAnswersByLinks(links);

  const handleWheelEvent = (event: WheelEvent<HTMLDivElement>): void => {
    if (!segmentListRef?.current) return;
    segmentListRef.current.scrollLeft += event.deltaY;
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.segments}
        ref={segmentListRef}
        onWheel={handleWheelEvent}
      >
        {links
          .filter(link => link.actionKey !== 'answerComment')
          .slice(0)
          .reverse()
          .map((link, i) => {
            const answersCount = numberOfAnswersByLinks[link.linkHash];
            const reverseIndex = links.length + 1 - i;
            const isActive = activeSegment.linkHash === link.linkHash;
            const isLast = i === links.length - 1;
            const timeInterval = i
              ? getTimeInterval(
                  links[reverseIndex - 1].createdAt,
                  link.createdAt
                )
              : null;
            return (
              <NavLink
                key={link.linkHash}
                to={Path.compile(ROUTE_INSPECT_TRACE_LINK)({
                  id: traceId,
                  linkid: link.linkHash
                })}
                className={classes.segmentItem}
                ref={isActive ? activeItemRef : null}
                data-active-index={i}
                draggable={false}
              >
                <Segment
                  isActive={isActive}
                  link={link}
                  interval={timeInterval}
                  isFirst={!i}
                  isLast={isLast}
                  answersCount={answersCount}
                />
              </NavLink>
            );
          })}
        {pulldown}
      </div>
    </div>
  );
};

export default injectSheet(styles)(SegmentList);
